<div class="dialog">
    <div class="dialog-header">
        <div class="header-text center">
            <h2>Inactive Session Timeout</h2>
        </div>
        <div class="align-items-start">
            <div class="header-icon">
                <button mat-button class="icon-btn dialog-close-btn" (click)="dialogRef.close()">
                    <div class="icon"><fa-icon [icon] = "times"></fa-icon></div>
                </button>
            </div>
        </div>
    </div>
    <div class="dialog-content">
        <div class="inactive-session-container">
            <h3>You have been inactive for more than {{this.data.warningTime}} minutes. Would you like to continue the
                session?</h3>
            <div class="automatic-logout">
                <h3>You will be automatically logged out in: </h3>
                <h2 class="timer">
                    <span id="hours"> {{hoursToDday}} </span>Hrs
                    <span id="minutes"> {{minutesToDday}} </span>Min
                    <span id="seconds"> {{secondsToDday}} </span>S
                </h2>
            </div>
        </div>
    </div>
    <div class="dialog-footer">
        <div class="only-buttons">
            <button mat-button class="btn done" (click)="dialogRef.close()"><p>Continue Session</p></button>
        </div>
    </div>
</div>