<div class="dialog" id="riskAssessment-dialog">
    <div class="dialog-header">
        <div class="header-text center">
            <!-- <h2 id="riskAssessment-dialog-title" *ngIf="!newRiskAssessmentBool">Active Risk Management</h2> -->
            <h2 id="riskAssessment-dialog-title">New Risk Assessment</h2>
        </div>
        <div class="align-items-start">
            <div class="header-icon">
                <button mat-button class="icon-btn dialog-close-btn" id="riskAssessment-dialog-close-btn"
                    (click)="dialogRef.close()">
                    <div class="icon"><fa-icon [icon] = "times"></fa-icon></div>
                </button>
            </div>
        </div>
    </div>
    <!-- <div class="dialog-content" *ngIf="!newRiskAssessmentBool">
        <div class="new-risk-assessment-container">
            <mat-card (click)="newRiskAssessmentBool = true" id="riskAssessment-dialog-new-risk-assessment">
                New Risk Assessment
            </mat-card>
        </div>
        <hr />
        <div class="risk-assessment-template-container">
            <mat-card class="risk-assessment-template-card" id="riskAssessment-dialog-template-{{i}}"
                *ngFor="let riskAssessmentTemplate of riskAssessmentTemplates; let i = index;">
                {{riskAssessmentTemplate.riskAssessmentTitle}}
            </mat-card>
        </div>
    </div> -->
    <div class="dialog-content ra-dialog">
        <div class="required-items"><i>Required Items</i>
            <span class="required-asterisk">*</span>
        </div>
        <form [formGroup]="riskAssessmentForm">
            <div class="row new-ra-dialog">
                <div class="col-6">
                    <mat-form-field floatLabel="always" class="risk-assessment-type" id="riskAssessment-dialog-type">
                        <mat-label>Risk Assessment Type
                        </mat-label>
                        <mat-select formControlName="riskAssessmentTypeID" placeholder="Select Risk Assessment Type..."
                            (selectionChange)="setRiskAssessmentFormValidators_0525()">
                            <mat-option *ngFor="let riskAssessmentType of riskAssessmentTypes"
                                [value]="riskAssessmentType.iD"
                                (click)="riskAssessmentTypeName = riskAssessmentType.riskAssessmentType">
                                <p>{{riskAssessmentType.riskAssessmentType}}</p>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col" *ngIf="riskAssessmentForm.value.riskAssessmentTypeID === 1">
                    <mat-form-field floatLabel="always" class="risk-assessment-type-metadata"
                        id="riskAssessment-dialog-type-clinicalTrialREF">
                        <mat-label>Clinical Trial Reference
                        </mat-label>
                        <input matInput type="text" formControlName="clinicalTrialRef"
                            placeholder="Enter Clinical Trial Reference..." id="riskAssessment-dialog-clinicalTrialREF">
                    </mat-form-field>
                </div>
                <div class="col" *ngIf="riskAssessmentForm.value.riskAssessmentTypeID === 3">
                    <mat-form-field floatLabel="always" class="risk-assessment-type-metadata"
                        id="riskAssessment-dialog-type-product">
                        <mat-label>Product
                        </mat-label>
                        <mat-select formControlName="productID" placeholder="Select Product...">
                            <mat-option *ngFor="let product of products" [value]="product.iD">
                                <p>{{product.product}}</p>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col" *ngIf="riskAssessmentForm.value.riskAssessmentTypeID === 4">
                    <mat-form-field floatLabel="always" class="risk-assessment-type-vendor"
                        id="riskAssessment-dialog-type-vendor">
                        <mat-label>Vendor
                        </mat-label>
                        <mat-select formControlName="vendorID" placeholder="Select Vendor...">
                            <mat-option *ngFor="let vendor of vendors" [value]="vendor.iD">
                                <p>{{vendor.entityName}}</p>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- This will be moved when other risk assessment types are added back in -->
                <div class="col">
                    <mat-form-field floatLabel="always" class="risk-assessment-type-vendor"
                        id="riskAssessment-dialog-entity">
                        <mat-label>Risk Assessment Entity
                        </mat-label>
                        <mat-select formControlName="riskAssessmentEntityID"
                            placeholder="Select Risk Assessment Entity...">
                            <mat-option *ngFor="let vendor of vendors" [value]="vendor.iD"
                                (click)="riskAssessmentEntityName = vendor.entityName">
                                <p>{{vendor.entityName}}</p>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row new-ra-dialog">
                <mat-form-field floatLabel="always">
                    <mat-label>Risk Assessment Title
                    </mat-label>
                    <input matInput #Titleinput type="text" formControlName="riskAssessmentTitle"
                        id="riskAssessment-dialog-title-input" placeholder="Enter Risk Assessment Title..."
                        maxlength="2000">

                    <div class="character-count">
                        <mat-hint align="end">{{Titleinput.value?.length || 0}}/2000</mat-hint>
                    </div>
                </mat-form-field>
            </div>
            <hr />
            <div class="row risk-question-row">
                <div class="risk-question-radio-buttons">
                    <mat-radio-group formControlName="newRiskQuestionBool"
                        (change)="riskAssessmentForm.controls['riskQuestion'].setValue(''); existingRiskQuestionSelected = false;">
                        <mat-radio-button checked [value]="true" id="riskAssessment-dialog-new-riskQuestion">New Risk
                            Question</mat-radio-button>
                        <mat-radio-button [value]="false" id="riskAssessment-dialog-existing-riskQuestion">Choose from
                            existing Risk Questions</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="full-width-field" *ngIf="riskAssessmentForm.value.newRiskQuestionBool">
                    <mat-form-field floatLabel="always">
                        <mat-label>Risk Question
                        </mat-label>
                        <textarea rows="3" matInput type="text" formControlName="riskQuestion"
                            placeholder="Enter Risk Question..."
                            id="riskAssessment-dialog-riskQuestion-input" maxlength="2000"></textarea>
                        <span class="form-field-message"
                            *ngIf="riskQuestionExists_XXXX(riskAssessmentForm.value.riskQuestion)">
                            Risk Question already exists </span>
                    </mat-form-field>
                </div>
                <div class="full-width-field" *ngIf="!riskAssessmentForm.value.newRiskQuestionBool">
                    <mat-form-field floatLabel="always">
                        <mat-label>Risk Question
                        </mat-label>
                        <input type="text" placeholder="Select Risk Question..." matInput formControlName="riskQuestion"
                            [matAutocomplete]="auto" id="riskAssessment-dialog-new-riskQuestion-search">
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let riskQuestion of filteredRiskQuestions | async; let i = index;"
                                value="{{riskQuestion.riskQuestion}}"
                                id="riskAssessment-dialog-new-riskQuestion-option-{{i}}"
                                (click)="existingRiskQuestionSelected = true">
                                <p>{{riskQuestion.riskQuestion}}</p>
                            </mat-option>
                        </mat-autocomplete>
                        <span class="form-field-message" *ngIf="(filteredRiskQuestions | async).length == 0">
                            Invalid Selection. Does not exist in existing Risk Questions </span>
                    </mat-form-field>
                </div>
            </div>
            <hr />
            <div class="row" *ngIf="filteredUsers">
                <mat-form-field floatLabel="always">
                    <mat-label>Risk Assessment Lead
                    </mat-label>
                    <input type="text" placeholder="Select Risk Assessment Lead..." matInput
                        id="riskAssessment-dialog-riskAssessmentLead" formControlName="riskAssessmentLeadName"
                        [matAutocomplete]="auto" (keyup)="riskAssessmentLeadSelected = false">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let user of filteredUsers | async; let i = index;" value="{{user.fullName}}"
                            id="riskAssessment-dialog-riskAssessmentLead-option-{{i}}"
                            (click)="riskAssessmentLeadSelected = true;">
                            <p>{{user.fullName}} (<span class="email">{{user.email}}</span>)</p>
                        </mat-option>
                    </mat-autocomplete>
                    <span class="form-field-message" *ngIf="(filteredUsers | async).length == 0">
                        Invalid Selection. Does not exist in list of Users </span>
                </mat-form-field>
            </div>
            <div class="row new-ra-dialog">
                <div class="col">
                    <mat-form-field floatLabel="always">
                        <mat-label>Impacted Business Process
                        </mat-label>
                        <mat-select formControlName="impactedBusinessProcess"
                            placeholder="Select Impacted Business Process..."
                            id="riskAssessment-dialog-impactedBusinessProcess">
                            <mat-option *ngFor="let businessProcess of businessProcesses" [value]="businessProcess.iD"
                                (click)="businessProcessName = businessProcess.businessProcess">
                                <p>{{businessProcess.businessProcess}}</p>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field floatLabel="always">
                        <mat-label>Impacted Functional Area
                        </mat-label>
                        <mat-select formControlName="impactedFunctionalArea"
                            placeholder="Select Impacted Functional Area..."
                            id="riskAssessment-dialog-impactedFunctionalArea">
                            <mat-option *ngFor="let functionalArea of functionalAreas" [value]="functionalArea.iD"
                                (click)="functionalAreaName = functionalArea.functionalArea">
                                <p>{{functionalArea.functionalArea}}</p>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
    <div class="dialog-footer">
        <div class="only-buttons">
            <button mat-button class="btn cancel" id="riskAssessment-dialog-cancel-btn" (click)="dialogRef.close()">
                <p>Cancel</p>
            </button>
            <button mat-button appDebounceClick (debounceClick)="saveRiskAssessment_0415()" [debounceTime]="200" class="btn primary" id="riskAssessment-dialog-done-btn"
                [disabled]="riskAssessmentFormValid_XXXX()">
                <p>Done </p>
            </button>
        </div>
    </div>
</div>