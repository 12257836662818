import { Component, OnInit } from '@angular/core';
import { APIService, GlobalService } from '@irlca/irlcore';
import { ArmService } from '../../arm.service';

@Component({
  selector: 'app-view-attachment',
  templateUrl: './view-attachment.component.html',
  styleUrls: ['./view-attachment.component.scss']
})
export class ViewAttachmentComponent implements OnInit {

  attachmentLoaded: boolean = false;
  attachmentToView: any;
  pdf: any;
  constructor(public apiService: APIService, public globalService: GlobalService, public armService: ArmService) { }

  ngOnInit() {
    if (this.armService.selectedAttachment) {
      this.getAttachmentToView();
    }
  }

  async getAttachmentToView() {
    await this.apiService.getDataFromBackend_0090(this.globalService.serverBaseURL() + "/values/periodicReviewAttachment" + "?attachmentID=" + this.armService.selectedAttachment + "&clientID=" + this.globalService.clientID + "&entityID=" + this.globalService.entityID).then((res: any) => {
      this.attachmentToView = res;
      this.pdf = this.base64ToArrayBuffer_0139(res.result[0].key);
      this.attachmentLoaded = true;
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Attachments");
      window.alert(userErrMsg)
    });
  }

  base64ToArrayBuffer_0139(base64: string) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
}
