import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http'
import { finalize, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { APIService, GlobalService } from '@irlca/irlcore';
import { LogService } from './log.service';
import { environment } from '../environments/environment';

@Injectable()
export class HTTPInterceptor implements HttpInterceptor {
	requestsList: any = [];

	constructor(
		private apiService: APIService,
		private logService: LogService,
		private globalService: GlobalService,
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let userID;
		let clientID;
		let entityID;

		// let uuid = require("uuid");
		function dec2hex(dec: any) {
			return dec.toString(16).padStart(2, "0")
		}

		// generateId :: Integer -> String
		function generateId(len: number) {
			var arr = new Uint8Array((len || 40) / 2)
			window.crypto.getRandomValues(arr)
			return Array.from(arr, dec2hex).join('')
		}
		let id = generateId(20);

		this.requestsList.push(request);
		this.apiService.isLoading.next(true);

		if (this.globalService.userID) {
			userID = this.globalService.userID.toString()
		} else {
			userID = 'User Not logged in'
		}
		if (this.globalService.clientID) {
			clientID = this.globalService.clientID.toString()
		} else {
			clientID = 'ClientID-Not-Defined'
		}
		if (this.globalService.entityID) {
			entityID = this.globalService.entityID.toString()
		} else {
			entityID = 'EntityID-Not-Defined'
		}

		let traceID = "TRACEID-" + clientID + '-' + entityID + '-' + id;
		const modified = request.clone({
			setHeaders: {
				"traceID": traceID,
				"userID": userID,
			},
		});

		let status: string;
		const started = performance.now()
		let result = next.handle(modified)
			.pipe(
				tap(
					(event: HttpEvent<any>) => {
						status = event instanceof HttpResponse ? 'succeeded' : '';
					},
					(error: HttpErrorResponse) => {
						status = "failed";
						if (!request.url.includes(environment.applicationFELogging)) {
							let errorMesage: string = 'Error message: ' + error.error.ExceptionMessage + ', Exception type : ' +
								error.error.ExceptionType + ', StackTrace: \n' + error.error.StackTrace;
							this.logService.logToBackendError(environment.applicationFELogging + 'LogError', errorMesage);
						}
					}
				),
				finalize(() => {
					const elapsed = performance.now() - started;
					const message = request.method + " " + request.urlWithParams + " " + status + " = " + elapsed + "ms"
					if (!request.url.includes(environment.applicationFELogging)) {
						this.logService.logToBackendInfo(environment.applicationFELogging + 'LogInfo', message);
					}
					this.removeRequest(request, this.requestsList);
				}));

		return result
	}

	removeRequest(req: HttpRequest<any>, list: any[]) {
		const i = list.indexOf(req);
		if (i >= 0) {
			list.splice(i, 1);
		}
		this.apiService.isLoading.next((list.length > 0));
	}
}
