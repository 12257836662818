import { Component, OnInit, Inject, ComponentFactoryResolver } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { DataToGet, datasetGet, APIService, GlobalService } from '@irlca/irlcore';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ArmService } from '../../../arm.service';

@Component({
  selector: 'app-risk-assessment-team-dialog',
  templateUrl: './risk-assessment-team-dialog.component.html',
  styleUrls: ['./risk-assessment-team-dialog.component.scss']
})
export class RiskAssessmentTeamDialogComponent implements OnInit {
  memberForm!: FormGroup;
  userList: any[] = [];
  riskAssessmentRoles: any[] = [];
  filteredUsers!: Observable<any>;
  selectedName: any = null;
  selectedRiskAssessmentRole: any;
  selectedRiskAssessmentRoleID!: number;
  selectedReviewer: boolean = false;
  selectedApprover: boolean = false;
  selectedUser: any;
  mandatory: boolean = false;
  editState: boolean = false;
  addMember: boolean = false;
  userSelected: boolean = false;
  memberIDValues: number[] = [];
  checkboxChanged: boolean = false;
  teamMemberSelected: boolean = false;

  // icons 
  times = faTimes
  check = faCheck

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public globalService: GlobalService,
    public dialogRef: MatDialogRef<RiskAssessmentTeamDialogComponent>,
    private apiService: APIService,
    private armService: ArmService
  ) { }


  ngOnInit() {
    this.selectedUser = this.data.row;
    this.prepareDataForGetTeamMember_0190();
  }

  prepareDataForGetTeamMember_0190(){
    let datasets: datasetGet[] = [];

    let userDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
      },
      storedProcedure: "User"
    };

    let riskAssessmentRoleDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        roleType: "Process Role"
      },
      storedProcedure: "Role"
    }

    datasets.push(userDataset, riskAssessmentRoleDataset);
    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Risk Assessment Team Dialog", datasets);

    this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      this.userList = fromDB[0];
      this.riskAssessmentRoles = fromDB[1];
      this.filteredUsers = this.memberForm.get('user')!.valueChanges
        .pipe(
          startWith(''),
          map(value => this.filterUsers_0411(value))
        );

      this.setUpMemberForm_0130(this.memberForm, this.data);
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Risk Assessment Team Dialog Data");
      window.alert(userErrMsg)
    });

    this.memberForm = this.fb.group({
      riskAssessmentRole: [this.data.row.riskAssessmentRole, Validators.required],
      user: new FormControl(this.data.row.fullName, [Validators.required]),
      reviewer: [this.data.row.isReviewer],
      approver: [this.data.row.isApprover],
      email: [this.data.row.email],
      contactNumber: [this.data.row.contactNumber],
      organisationalRole: [this.data.row.organisationalRoleName],
      departmentTextArea: [this.data.row.department],
    });

    if (this.data.addMember === 'false' || this.data.row.roleID === 353) {
      this.memberForm.controls['riskAssessmentRole'].disable();
    }

    if (!this.data.addMember) this.teamMemberSelected = true;

    this.selectedName = this.data.row.fullName;
    if (this.data.row.isApprover) {
      this.selectedApprover = this.data.row.isApprover;
    }
    if (this.data.row.isReviewer) {
      this.selectedReviewer = this.data.row.isReviewer;
    }

    this.selectedRiskAssessmentRoleID = this.data.row.roleID;
    this.setUpMemberForm_0130(this.memberForm, this.data);
  }

  closeDialog_0118(dialogRef: any) {
    dialogRef.close({ closeType: 'cancel' });
  }

  filterUsers_0411(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.userList.filter(option => option.fullName.toLowerCase().includes(filterValue));
  }

  setUpMemberForm_0130(memberForm: FormGroup, data: any) {
    if (data.row.userID == 1) {
      this.selectedRiskAssessmentRole = data.row.riskAssessmentRole;
      this.selectedRiskAssessmentRoleID = data.row.roleID;
      this.selectedName = null;
    } else {
      this.selectedRiskAssessmentRole = data.row.riskAssessmentRole;
      this.selectedRiskAssessmentRoleID = data.row.roleID;

      this.selectedName = data.row.fullName;
    }

    if (data.addMember === 'false' || data.row.roleID === 353) {
      this.editState = true;
      memberForm.controls['user'].enable();
      memberForm.controls['riskAssessmentRole'].disable();
      memberForm.controls['reviewer'].enable;
      memberForm.controls['approver'].enable;

    } else {
      this.editState = false;
      memberForm.controls['user'].enable();
      memberForm.controls['riskAssessmentRole'].enable();
      memberForm.controls['reviewer'].enable;
      memberForm.controls['approver'].enable;
    }

    this.addMember = data.addMember;

    if (data.row.designation == 'mandatory') {
      this.mandatory = true;
    } else {
      this.mandatory = false;
    }

    data.table.forEach((element: any) => {
      this.memberIDValues.push(element.userID);
    });

    memberForm.get('user')!.valueChanges.subscribe(val => {
      if (val == 'noMatch') {
        memberForm.get('email')!.setValue('');
        memberForm.get('contactNumber')!.setValue('');
        memberForm.get('organisationalRole')!.setValue('');
        memberForm.get('departmentTextArea')!.setValue('');
      }
    });
  }

  updateReadOnly_0048(user: any, memberForm: FormGroup) {
    this.selectedName = user.fullName;
    this.selectedUser = user;
    memberForm.get('user')!.setErrors(null);
    memberForm.get('email')!.setValue(user.email);
    memberForm.get('contactNumber')!.setValue(user.contactNumber);
    memberForm.get('organisationalRole')!.setValue(user.organizationRoleName);
    memberForm.get('departmentTextArea')!.setValue(user.departmentName);
  }

  onEnterUser_0247(evt: any, user: any, memberForm: FormGroup) {
    if (evt.source.selected) {
      this.userSelected = true;
      this.selectedUser = user;
      this.updateReadOnly_0048(user, memberForm);
    }
  }

  postMemberEntry_0246(callType: string, data: any, component: string, dialogRef: any) {
    const dataToPost = [];

    const userToJoin: any = this.selectedUser;
    let auditTrail = {};
    if (data.addMember === true || data.row.designation === null) {

      userToJoin.riskAssessmentRole = this.selectedRiskAssessmentRole;
      userToJoin.riskAssessmentRoleID = this.selectedRiskAssessmentRoleID;
      userToJoin.organizationalRole = this.selectedUser.organizationRoleName;
      userToJoin.isApprover = this.selectedApprover;
      userToJoin.isReviewer = this.selectedReviewer;
      userToJoin.designation = null;
    } else {
      userToJoin.riskAssessmentRole = data.row.riskAssessmentRole;
      userToJoin.riskAssessmentRoleID = data.row.roleID;

      userToJoin.organizationalRole = this.selectedUser.organizationRoleName;
      userToJoin.designation = data.row.designation;
      userToJoin.isApprover = this.selectedApprover;
      userToJoin.isReviewer = this.selectedReviewer;

    }
    if (callType == 'INSERT') {
      dataToPost.push({
        iD: data.entryID,
        riskAssessmentID: this.globalService.selectedRiskAssessment.iD,
        riskAssessmentREFA: this.globalService.selectedRiskAssessment.riskAssessmentREF,
        userID: this.selectedUser.userID,
        userName: this.selectedUser.fullName,
        contactNumber: this.selectedUser.contactNumber,
        email: this.selectedUser.email,
        organisationalRoleName: this.selectedUser.organizationRoleName,
        riskAssessmentRole: this.selectedUser.riskAssessmentRole,
        department: this.selectedUser.departmentName,
        designation: this.selectedUser.designation,
        roleID: this.selectedRiskAssessmentRoleID,
        isReviewer: this.selectedReviewer,
        isApprover: this.selectedApprover,
        statementType: callType,
      });

      auditTrail = this.globalService.buildAuditTrail_0115({}, dataToPost[0], "tblRiskAssessmentTeamMember");
    } else if (callType == 'UPDATE' && data.row.statementType == 'INSERT') {
      dataToPost.push({
        iD: data.row.iD,
        riskAssessmentID: this.globalService.selectedRiskAssessment.iD,
        riskAssessmentREFA: this.globalService.selectedRiskAssessment.riskAssessmentREF,
        userID: this.selectedUser.userID,
        userName: this.selectedUser.fullName,
        contactNumber: this.selectedUser.contactNumber,
        email: this.selectedUser.email,
        organisaitonalRoleName: this.selectedUser.organizationRoleName,
        riskAssessmentRole: this.selectedUser.riskAssessmentRole,
        department: this.selectedUser.departmentName,
        designation: this.selectedUser.designation,
        roleID: this.selectedRiskAssessmentRoleID,
        isReviewer: this.selectedReviewer,
        isApprover: this.selectedApprover,
        // clientID: data.clientID,
        statementType: "INSERT",
      });
      userToJoin.iD = data.row.iD;

      auditTrail = this.globalService.buildAuditTrail_0115({}, dataToPost[0], "tblRiskAssessmentTeamMember");
    } else {

      let originalData = {
        iD: data.row.iD,
        // clientID: data.clientID,
        riskAssessmentID: this.globalService.selectedRiskAssessment.iD,
        userID: data.row.userID,
        userName: data.row.fullName,
        contactNumber: data.row.contactNumber,
        email: data.row.email,
        organisationalRoleName: data.row.organisationalRoleName,
        riskAssessmentRole: data.row.riskAssessmentRole,
        department: data.row.department,
        designation: data.row.designation,
        roleID: data.row.roleID,
        isReviewer: data.row.isReviewer,
        isApprover: data.row.isApprover,
      };


      dataToPost.push({
        iD: data.row.iD,
        // clientID: data.clientID,
        riskAssessmentID: this.globalService.selectedRiskAssessment.iD,
        riskAssessmentREFA: this.globalService.selectedRiskAssessment.riskAssessmentREF,
        userID: this.selectedUser.userID,
        userName: this.selectedUser.fullName,
        contactNumber: this.selectedUser.contactNumber,
        email: this.selectedUser.email,
        organisationalRoleName: this.selectedUser.organizationRoleName,
        riskAssessmentRole: this.selectedUser.riskAssessmentRole,
        department: this.selectedUser.departmentName,
        designation: this.selectedUser.designation,
        roleID: this.selectedRiskAssessmentRoleID,
        isReviewer: this.selectedReviewer,
        isApprover: this.selectedApprover,
        statementType: callType,
      });
      userToJoin.iD = data.row.iD;
      auditTrail = this.globalService.buildAuditTrail_0115(originalData, dataToPost[0], "tblRiskAssessmentTeamMember");
    }

    const temp = cloneDeep(dataToPost[0]);
    const merged = Object.assign(userToJoin, temp);
    dataToPost.push(merged);
    dataToPost.push(auditTrail);
    dialogRef.close({ closeType: 'save', dataToPost });
  }

  teamMemberFormValid_XXXX() {
    if (this.memberForm.valid && this.teamMemberSelected && (this.memberForm.dirty || this.checkboxChanged))
      return false;
    else return true;
  }

}
