import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APIService, datasetGet, DataToGet, GlobalService, IrlcoreAuthService } from '@irlca/irlcore';
import { Subject } from 'rxjs';
import { ArmService } from './arm.service';
import { HeatmapService } from './heatmap.service';
import { ARMDialogService } from './shared/dialog/dialog.service';
interface Permission {
  permissionName: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  configLoaded = false;
  userInactive: Subject<any> = new Subject();
  timeoutId: any;
  warningTime!: number;
  sessionTime!: number;

  constructor(public auth: IrlcoreAuthService, public router: Router, private location: Location, private http: HttpClient,
    public globalService: GlobalService, private apiService: APIService, private dialogService: ARMDialogService, private armService: ArmService, private heatmapService: HeatmapService
  ) {
    this.userInactive.subscribe((message) => {
      alert(message);
    });
  }

  // This array contains pages which can be accessed without a user being loggedin. Handle with care!
  // Factory Default: ['/OKTA/CALLBACK', '/WELCOME', '/LOGGEDOUT']
  private static permittedUnAuthFolders = ['/OKTA/CALLBACK', '/WELCOME', '/LOGGEDOUT', '/LOGIN', '/CONFIRMACCOUNT', '/FORCEPASSWORDRESET'];
  title = 'Active-Risk-Management-Application';
  failed!: boolean;
  public userAuthenticated = false;
  public userAuthorizations = false;

  async ngOnInit() {
    // Call to initialize authentication - DO NOT REMOVE
    await this.initializeAuthentication();
  } // ngOnInit
  logout() {
    this.auth.logout_0432();
  }
  // --------------------------------------------------------------------------------------------------
  // I M P O R T A N T   N O T I C E  T O  D E V E L O P E R S
  //
  // While the code below can be tweeked if need be, the essence must remain for Authentication to work
  //
  // I M P O R T A N T   N O T I C E  T O  D E V E L O P E R S
  // --------------------------------------------------------------------------------------------------
  private async initializeAuthentication() {
    // Powerup security. Note: Since this includes an asynchronous call to the configuration server this cannot be done in the 'constructor'
    await this.auth.initializeSecurity_0140();

    // Get Current Authentication State
    this.userAuthenticated = await this.auth.isAuthenticated_0150();

    // Bind local variable to authentication state
    this.auth.$authenticationState.subscribe(
      async (isAuthenticated: boolean) => {
        this.userAuthenticated = isAuthenticated;
        if (isAuthenticated) {
          let folderPath: string = this.location.path();
          folderPath = folderPath.split('?')[0];
          folderPath = folderPath.toUpperCase();
          await this.auth.requestUserFeatures_0142();
          await this.getStartUpData_XXXX();
          // If Welcome selected and already authenticated then move to home page
        }
      });


    // Bind local variable to authorization state (i.e. authorizations loaded or not)
    this.auth.$authorizationState.subscribe(
      (authorizationsLoaded: boolean) => this.userAuthorizations = authorizationsLoaded);

    // Get current state of 'Authorizations Loaded'. Future changes will then be updated via the above subscribe
    this.userAuthorizations = this.auth.authorizationsLoaded_0156();

    // What folder is being requested?
    let folderPath: string = this.location.path();
    folderPath = folderPath.split('?')[0];
    folderPath = folderPath.toUpperCase();

    // ----------------------------
    // USER AUTHENTICATED
    if (this.userAuthenticated) {
      await this.auth.requestUserFeatures_0142();

      await this.getStartUpData_XXXX();

      // If Welcome selected and already authenticated then move to home page
      const passwordReset = await this.auth.checkIfPasswordReset();
      if (passwordReset && folderPath != '/FORCEPASSWORDRESET') {
        this.router.navigate(['/resetPasswordLanding']);
      }
      if (folderPath === '/WELCOME') {
        this.router.navigate(['/home']);
        return;
      }
      if (folderPath === '/LOGIN') {
        this.router.navigate(['/home']);
        return;
      } else if (folderPath === '/LOGGEDOUT') {
        await this.auth.logout_0432('/loggedout');
        return;
      }
    } else {
      // Only permit whitelisted pages when a user is not logged in
      if (AppComponent.permittedUnAuthFolders.indexOf(folderPath) > -1) {
        return;
      } else {
        // Force login if not on permitted page list
        await this.auth.login_0143();

        // Get authentication state
        this.userAuthenticated = await this.auth.isAuthenticated_0150();

        // if not logged on then redirect to welcome page
        if (!this.userAuthenticated) { this.router.navigate(['/login']); }

        return;
      }
    }
    // USER NOT AUTHENTICATED
    // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  }

  async getStartUpData_XXXX() {
    if (this.auth.permissions.size < 1) {
      this.globalService.userSet = false;
      this.router.navigate(["/no-permissions"]);
      return;
    }
    let datasets: datasetGet[] = [];

    // const path = this.router.url;
    const test: any = await this.auth.getUserTokenData_0437();
    const user: any = await this.auth.getUserProfileData();
    this.globalService.currentUser = user[0][0];
    this.globalService.tutorialEnabled = user[0][0].tutorialEnabled;
    this.globalService.displayName = true;
    this.globalService.userID = test.userID;
    this.globalService.clientID = user[0][0].clientID;
    this.globalService.entityID = user[0][0].entityID;
    this.globalService.departmentID = user[0][0].departmentID;
    this.globalService.userName = user[0][0].userName;
    this.prepareDataForInstructionSet();

    datasets = [];
    const justificationArray: datasetGet = {
      parameters: {
        iD: null,
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        paramID: null,
        configurableParameter: 'Justification',
        designation: null
      },
      storedProcedure: 'ConfigurableList'
    };

    const sessionInfo: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        systemID: 1
      },
      storedProcedure: 'ClientApplications'
    }

    const configurableLists: datasetGet = {
      parameters: {
        iD: null,
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        paramID: null,
        configurableParameter: null,
        designation: null
      },
      storedProcedure: 'ConfigurableList'
    }

    const heatmapColours: datasetGet = {
      parameters: {},
      storedProcedure: 'Heatmap'
    }


    datasets.push(justificationArray, sessionInfo, configurableLists, heatmapColours);
    const dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, 'View Configurable List', datasets);

    this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      this.globalService.justificationArray = fromDB[0];

      if (user[0][0].disabled) {
        this.auth.userDisabled = true;
        this.globalService.userSet = false;
        this.router.navigate(["/not-registered"]);
      } else {
        this.globalService.userSet = true;
        this.configLoaded = true;
        this.warningTime = fromDB[1][0].inactiveSessionWarningTime;
        this.sessionTime = fromDB[1][0].inactiveSessionTimeout;
        this.checkTimeOut_XXXX();
      }

      // DOC Comment: This is quite delicate - very easy for something to go wrong here 
      // If there are 10 users logged in and 1 user makes a config param update, the change will not be applied for anyone else
      // This is an example of where state management could be used - NgRX is the Angular standard 
      if (fromDB[2].length > 0) {
        this.globalService.configurableParameters.psfmInRiskTableRiskAssessment = '';
        this.globalService.configurableParameters.psfmInRiskTableRiskAssessment = Boolean(JSON.parse(fromDB[2][2].value.toLowerCase()));
        this.globalService.configurableParameters.riskCause = fromDB[2][3].value;
        this.globalService.configurableParameters.riskEffect = fromDB[2][4].value;
        this.globalService.configurableParameters.showProximity = Boolean(JSON.parse(fromDB[2][5].value.toLowerCase()));
        this.globalService.configurableParameters.sodCategoryAsDescription = Boolean(JSON.parse(fromDB[2][6].value.toLowerCase()));
        this.globalService.configurableParameters.riskMode = fromDB[2][7].value;
        this.globalService.configurableParameters.addRiskPreventionControls = Boolean(JSON.parse(fromDB[2][8].value.toLowerCase()));
        this.globalService.configurableParameters.turnOffFMEA = Boolean(JSON.parse(fromDB[2][9].value.toLowerCase()));
        this.globalService.configurableParameters.itemGroup = fromDB[2][10].value;
        this.globalService.configurableParameters.turnOffTutorial = Boolean(JSON.parse(fromDB[2][12].value.toLowerCase()));
        this.globalService.configurableParameters.applicationVersion = fromDB[2][0].value;
        this.globalService.configurableParameters.applicationEnvironment = fromDB[2][1].value;
        this.globalService.configurableParameters.statusRE = Boolean(JSON.parse(fromDB[2][13].value.toLowerCase()));
        this.globalService.configurableParameters.notesRE = Boolean(JSON.parse(fromDB[2][14].value.toLowerCase()));
        this.globalService.configurableParameters.attachmentsRE = Boolean(JSON.parse(fromDB[2][15].value.toLowerCase()));
        this.globalService.configurableParameters.hasAssociationRE = Boolean(JSON.parse(fromDB[2][16].value.toLowerCase()));
        this.globalService.configurableParameters.descriptionRE = Boolean(JSON.parse(fromDB[2][17].value.toLowerCase()));
        this.globalService.configurableParameters.assignedToRE = Boolean(JSON.parse(fromDB[2][18].value.toLowerCase()));
        this.globalService.configurableParameters.dueDateRE = Boolean(JSON.parse(fromDB[2][19].value.toLowerCase()));
        this.globalService.configurableParameters.priorityRE = Boolean(JSON.parse(fromDB[2][20].value.toLowerCase()));
        this.globalService.configurableParameters.percentageCompleteRE = Boolean(JSON.parse(fromDB[2][21].value.toLowerCase()));
        this.globalService.configurableParameters.showNoteStatus = Boolean(JSON.parse(fromDB[2][23].value.toLowerCase()));
        this.globalService.configurableParameters.advancedNoticePeriodicReview = fromDB[2][24].value;
        this.globalService.configurableParameters.advancedNoticePeriodicReviewPrimaryColour = fromDB[2][25].value;
        this.globalService.configurableParameters.advancedNoticePeriodicReviewSecondaryColour = fromDB[2][26].value;
        this.globalService.configurableParameters.warningNoticePeriodicReview = fromDB[2][27].value;
        this.globalService.configurableParameters.warningNoticePeriodicReviewPrimaryColour = fromDB[2][28].value;
        this.globalService.configurableParameters.warningNoticePeriodicReviewSecondaryColour = fromDB[2][29].value;
        this.globalService.configurableParameters.periodicReviewCountdownPrimaryColour = fromDB[2][30].value;
        this.globalService.configurableParameters.periodicReviewCountdownSecondaryColour = fromDB[2][31].value;
        this.globalService.configurableParameters.periodicReviewOverduePrimaryColour = fromDB[2][32].value;
        this.globalService.configurableParameters.periodicReviewOverdueSecondaryColour = fromDB[2][33].value;
        this.globalService.configurableParameters.turnOffDAM = Boolean(JSON.parse(fromDB[2][11].value.toLowerCase()));
        this.globalService.configurableParameters.reportHeaderText = fromDB[2][34].value;
        this.globalService.configurableParameters.reportFooterText = fromDB[2][35].value;
      }
      this.heatmapService.cellColours = fromDB[3]
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Start Up");
      window.alert(userErrMsg)
    });
  }

  prepareDataForInstructionSet() {
    let datasets: datasetGet[] = [];
    let testDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        instructionSet: null,
        instructionSetName: null,
      },
      storedProcedure: "Instructions"
    }
    datasets.push(testDataset);
    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Risk Record", datasets);

    this.apiService.getDataFromBackend_0090(this.apiService.urlForInstructions, dataToGet).then((fromDB: any) => {
      this.globalService.instructions = fromDB.result;
      this.globalService.instructions.instructionSets.forEach((element: any) => {
        element.guidanceSteps = JSON.parse(element.guidanceSteps);
      });

    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Instructions");
      window.alert(userErrMsg)
    });
  }

  @HostListener('window:keydown')
  @HostListener('window:mousedown')
  checkUserActivity_XXXX() {
    if (this.auth.isAuthenticated) {
      clearTimeout(this.timeoutId);
      this.checkTimeOut_XXXX();
    }
  }

  checkTimeOut_XXXX() {
    if (this.configLoaded) {
      let localSessionTime = this.sessionTime * 60 * 1000;
      let localWarningTime = this.warningTime * 60 * 1000;
      let warningTimeLeft = localSessionTime - localWarningTime;

      this.timeoutId = setTimeout(() => {
        if (this.auth.isAuthenticated) {
          this.openInactiveTimeoutDialog_XXXX(warningTimeLeft);
        }
      }, localWarningTime);
    }
  }

  openInactiveTimeoutDialog_XXXX(warningTimeLeft: number) {
    const dialogConfigData = {
      width: 'auto',
      height: 'auto',
      data: {
        warningTimeLeft: warningTimeLeft,
        warningTime: this.warningTime
      }
    }

    const dialogRef = this.dialogService.openDialog_0088("InactiveTimeoutDialogComponent", dialogConfigData);
  }
}
