import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { APIService, dataset, DataToPost, GlobalService } from '@irlca/irlcore';
import { ArmService } from '../../../arm.service';

@Component({
  selector: 'app-risk-assessment-review-dialog',
  templateUrl: './risk-assessment-review-dialog.component.html',
  styleUrls: ['./risk-assessment-review-dialog.component.scss']
})

export class RiskAssessmentReviewDialogComponent implements OnInit {

  reviewDropDownOptions = ["Review With Comments", "Review Without Comments"];
  riskAssessmentReviewForm!: FormGroup;

  // icons
  times = faTimes
  
  constructor(public dialogRef: MatDialogRef<RiskAssessmentReviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder,
    private globalService: GlobalService, private apiService: APIService, public armService: ArmService) { }

  ngOnInit() {
    this.riskAssessmentReviewForm = this.createRiskAssessmentReviewFormGroup_0410();
  }

  createRiskAssessmentReviewFormGroup_0410() {
    return this.fb.group({
      comment: [],
      reason: []
    });
  }

  async prepareDataForPostReviewApprovalComments() {
    let datasets: dataset[] = [];
    let length = this.armService.workflow.length - 1;
    let reviewDataset: dataset = {
      data: [{
        iD: -1,
        clientID: this.globalService.clientID,
        workflowID: this.armService.workflow[length].iD,
        userID: this.globalService.userID,
        userName: this.globalService.currentUser.fullName,
        comment: this.riskAssessmentReviewForm.value.comment,
        statementType: "INSERT"
      }],
      dataset: "ReviewApprovalComments"
    };

    let auditTrailList = this.globalService.buildAuditTrail_0115(
      {},
      reviewDataset.data[0],
      "tblReviewApprovalComments"
    );

    datasets.push(reviewDataset);

    let toDB = new DataToPost(this.globalService.clientID, this.globalService.userID, "Test reason", "Add Attachment", datasets, auditTrailList);

    await this.apiService.postDataToBackend_0051(this.apiService.urlForSinglePost, toDB).then((result: any) => {
      if (result) {
        this.dialogRef.close({ closeType: 'Reviewed' })
      }
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Review Approval Comments");
      window.alert(userErrMsg)
    });
  }

}
