import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faLongArrowAltDown, faLongArrowAltUp, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { cloneDeep } from 'lodash';
import { FilterService } from '../../../filter.service';

@Component({
    selector: 'app-risk-register-filter-dialog',
    templateUrl: './risk-register-filter-dialog.component.html',
    styleUrls: ['./risk-register-filter-dialog.component.scss']
})
export class RiskRegisterFilterDialogComponent implements OnInit {

    displayFilterObjects: any;
    filteredListOfRisks: any;
    selectedFilterOptions: any[] = [];
    filterListIsSorted: any = {
        riskAssessmentType: true,
        functionalArea: true,
        businessProcess: true,
        initialRS: true,
        initialRPN: true,
        initialRiskLevel: true,
        riskDecision: true,
        residualRS: true,
        residualRPN: true,
        residualRiskLevel: true,
        riskStatus: true,
        riskAssessmentProximity: true,
        riskTeamMember: true
    };
    filterTitles = [
        { name: "Risk Assessment Ref", key: "riskAssessmentREF", class: "col-1" },
        { name: "Risk Assessment Type", key: "riskAssessmentType", class: "col-2" },
        { name: "Impacted Functional Area", key: "functionalArea", class: "col-2" },
        { name: "Impacted Business Process", key: "businessProcess", class: "col-2" },
        { name: "Risk Score", key: "initialRS", class: "col-1" },
        { name: "Risk RPN", key: "initialRPN", class: "col-1" },
        { name: "Risk Level", key: "initialRiskLevel", class: "col-1" },
        { name: "Risk Decision", key: "riskDecision", class: "col-1" },
        { name: "Residual Risk Score", key: "residualRS", class: "col-1" },
        { name: "Residual Risk RPN", key: "residualRPN", class: "col-1" },
        { name: "Residual Risk Level", key: "residualRiskLevel", class: "col-1" },
        { name: "Risk Status", key: "riskStatus", class: "col-2" },
        { name: "Risk Proximity", key: "riskAssessmentProximity", class: "col-1" },
        { name: "Process Step", key: "itemGroupName", class: "col-2" },
        { name: "Failure Mode", key: "failureMode", class: "col-2" },
        { name: "Risk Team Member", key: "riskTeamMember", class: "col-2" }
    ];
    firstToggleCall: boolean = false;
    searchedTerm: string = "";
    originalSelectedFilterOptions: any;
    disableFlag: boolean = false;
    disabledFlagApply: boolean = true;
    disabledFlagClearAll: boolean = false;
    originalDisplayFilterObjects: any;
    firstRun: boolean = true;

    // icons
    times = faTimes
    longArrowAltUp = faLongArrowAltUp
    longArrowAltDown = faLongArrowAltDown
    constructor(
        public dialogRef: MatDialogRef<RiskRegisterFilterDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private filterService: FilterService
    ) { }

    ngOnInit() {
        if (this.data.filterApplied) {
            this.selectedFilterOptions = cloneDeep(this.data.selectedFilterOptions);
            this.originalSelectedFilterOptions = cloneDeep(this.data.selectedFilterOptions);
            this.displayFilterObjects = this.filterService.getFilterOptionsRisk_0477(this.data.risks, this.data.riskTeamMembers);

            this.selectedFilterOptions.forEach((element: any) => {
                this.firstToggleCall = true;
                this.toggleFilterOptionListRisk_0482(element.type, element.filterValue, true);
                this.firstToggleCall = false;
            });
        } else {
            this.displayFilterObjects = this.filterService.getFilterOptionsRisk_0477(this.data.risks, this.data.riskTeamMembers);
        }

        let keys = Object.keys(this.displayFilterObjects);
        for (let key of keys) {
            this.sortFilterOptionsRisk_0472(key, false);
        }
        this.originalDisplayFilterObjects = cloneDeep(this.displayFilterObjects);
        this.firstRun = false;
        this.checkIfClearALLChangeRiskRegisterDilterDialog_XXXX();
        this.checkIfApplyChangeRiskRegisterDilterDialog_XXXX()
    }

    toggleFilterOptionListRisk_0482(key: string, value: string, event: any) {
        let checked: boolean = event.target.checked;
        this.checkFilterTypeRiskFilterDialog_0474(key, checked, value);

        if (!this.firstToggleCall) {
            this.updateSelectedFilterOptionsRiskFilterDialog_0473(key, checked, value);
        }

        this.filteredListOfRisks = this.filterService.filterRisks_0475(this.data.risks, this.selectedFilterOptions, this.data.riskTeamMembers);

        let filterOptionsForFilteredInspections = this.filterService.getFilterOptionsRisk_0477(this.filteredListOfRisks, this.data.riskTeamMembers);

        this.displayFilterObjects = this.determineFilterOptionVisibilityRiskFilterDialog_0471(key, checked, this.displayFilterObjects, filterOptionsForFilteredInspections);
        this.checkIfClearALLChangeRiskRegisterDilterDialog_XXXX();
        this.checkIfApplyChangeRiskRegisterDilterDialog_XXXX()
    }

    checkFilterTypeRiskFilterDialog_0474(key: string, checked: boolean, value: string) {
        this.displayFilterObjects[key].forEach((element: any) => {
            if (element.filterValue == value) { element.checked = checked }
        });
    }

    updateSelectedFilterOptionsRiskFilterDialog_0473(key: string, checked: boolean, value: string) {
        if (checked) {
            this.selectedFilterOptions.push({
                type: key,
                filterValue: value
            });
        } else {
            let indexToSplice = this.selectedFilterOptions.findIndex(filterOption => filterOption.filterValue == value);
            this.selectedFilterOptions.splice(indexToSplice, 1);
        }
    }

    checkIfClearALLChangeRiskRegisterDilterDialog_XXXX() {
        let keys = Object.keys(this.displayFilterObjects);
        this.disabledFlagClearAll = true;
        for (let key of keys) {
            if (!this.disabledFlagClearAll) {
                break;
            }
            this.displayFilterObjects[key].forEach((element: any) => {
                if (element.checked) {
                    this.disabledFlagClearAll = false;
                }
            });
        }
    }

    checkIfApplyChangeRiskRegisterDilterDialog_XXXX() {
        if (!this.firstRun) {
            let tempDisplayFilterObjects = cloneDeep(this.displayFilterObjects);
            let tempOriginalDisplayFilterObjects = cloneDeep(this.originalDisplayFilterObjects);

            let keys = Object.keys(this.displayFilterObjects);
            for (let key of keys) {
                tempDisplayFilterObjects[key].sort((s1: any, s2: any) => {
                    if (s1.filterValue < s2.filterValue) {
                        return 1;
                    }
                    else if (s1.filterValue > s2.filterValue) {
                        return -1;
                    }
                    else {
                        return 0;
                    }
                });
            }

            let keys2 = Object.keys(this.originalDisplayFilterObjects);
            for (let key of keys2) {
                tempOriginalDisplayFilterObjects[key].sort((s1: any, s2: any) => {
                    if (s1.filterValue < s2.filterValue) {
                        return 1;
                    }
                    else if (s1.filterValue > s2.filterValue) {
                        return -1;
                    }
                    else {
                        return 0;
                    }
                });
            }

            if (JSON.stringify(tempDisplayFilterObjects) === JSON.stringify(tempOriginalDisplayFilterObjects)) {
                this.disabledFlagApply = true
            } else {
                this.disabledFlagApply = false
            }
        }
    }

    determineFilterOptionVisibilityRiskFilterDialog_0471(key: string, checked: boolean, displayFilterObjects: any, filterOptionsForFilteredInspections: any) {
        let staticFilteredKeys = Object.keys(displayFilterObjects);
        staticFilteredKeys.forEach(staticFilteredKey => {
            if (staticFilteredKey !== key && checked) {
                displayFilterObjects[staticFilteredKey].forEach((filterOption: any) => {
                    if (filterOption.checked) {
                        filterOption.visible = true;
                    } else {
                        filterOption.visible = filterOptionsForFilteredInspections[staticFilteredKey].some((element: any) => element.filterValue == filterOption.filterValue);
                    }
                });
            }
            else if (!checked) {
                displayFilterObjects[staticFilteredKey].forEach((filterOption: any) => {
                    if (filterOption.checked) {
                        filterOption.visible = true;
                    } else {
                        filterOption.visible = filterOptionsForFilteredInspections[staticFilteredKey].some((element: any) => element.filterValue == filterOption.filterValue);
                    }
                });
            }
        });

        return displayFilterObjects;
    }

    exportFilterDialogObjectRiskRegisterFilterDialog_0484() {
        if (this.selectedFilterOptions.length == 0) {
            this.filteredListOfRisks = this.data.risks;
        }
        this.dialogRef.close({
            closeType: "apply",
            filteredRisks: this.filteredListOfRisks,
            selectedFilterOptions: this.selectedFilterOptions,
        });
    }

    clearSelectedRiskFilters_0481(): void {
        this.selectedFilterOptions = [];
        this.displayFilterObjects = this.filterService.getFilterOptionsRisk_0477(this.data.risks, this.data.riskTeamMembers);
        let keys = Object.keys(this.displayFilterObjects);
        for (let key of keys) {
            this.sortFilterOptionsRisk_0472(key, true);
        }
    }

    closeRiskFilterDialog_0118(dialogRef: MatDialogRef<RiskRegisterFilterDialogComponent>): void {
        dialogRef.close({ closeType: "cancel" });
    }

    sortFilterOptionsRisk_0472(type: string, switchFlag: boolean): void {
        if (switchFlag) {
            this.filterListIsSorted[type] = !this.filterListIsSorted[type];
        }

        let reA = /[^a-zA-Z]/g;
        let reN = /[^0-9]/g;

        function cmpAlphaNum(a: any, b: any) {
            let aA = a.filterValue.replace(reA, "");
            let bA = b.filterValue.replace(reA, "");
            if (aA === bA) {
                let aN = parseInt(a.filterValue.replace(reN, ""), 10);
                let bN = parseInt(b.filterValue.replace(reN, ""), 10);
                return aN === bN ? 0 : aN > bN ? 1 : -1;
            } else {
                return aA > bA ? 1 : -1;
            }
        }

        switch (type) {
            case "initialRS": case "initialRPN": case "residualRS": case "residualRPN": case "riskAssessmentProximity": {
                if (!this.filterListIsSorted[type]) {
                    this.displayFilterObjects[type] = <Array<any>>this.displayFilterObjects[type].sort((s1: any, s2: any) => {
                        if (s1.filterValue < s2.filterValue) {
                            return 1;
                        }
                        else if (s1.filterValue > s2.filterValue) {
                            return -1;
                        }
                        else {
                            return 0;
                        }
                    });
                }
                else {
                    this.displayFilterObjects[type] = <Array<any>>this.displayFilterObjects[type].sort((s1: any, s2: any) => {
                        if (s1.filterValue > s2.filterValue) {
                            return 1;
                        }
                        else if (s1.filterValue < s2.filterValue) {
                            return -1;
                        }
                        else {
                            return 0;
                        }
                    });
                }
                break;
            }
            case "initialRiskLevel": case "residualRiskLevel": {
                if (!this.filterListIsSorted[type]) {
                    this.displayFilterObjects[type] = <Array<any>>this.displayFilterObjects[type].sort((s1: any, s2: any) => {
                        if ((s1.filterValue == "High" && (s2.filterValue == "Medium" || s2.filterValue == "Low" || s2.filterValue == null))
                            || (s1.filterValue == "Medium" && (s2.filterValue == "Low" || s2.filterValue == null)) || (s1.filterValue == "Low" && s2.filterValue == null))
                            return 1;
                        else if ((s1.filterValue == null && (s2.filterValue == "Low" || s2.filterValue == "Medium" || s2.filterValue == "High"))
                            || (s1.filterValue == "Low" && (s2.filterValue == "Medium" || s2.filterValue == "High")) || (s1.filterValue == "Medium" && s2.filterValue == "High"))
                            return -1;
                        else return 0;
                    });
                }
                else {
                    this.displayFilterObjects[type] = <Array<any>>this.displayFilterObjects[type].sort((s1: any, s2: any) => {
                        if ((s1.filterValue == "High" && (s2.filterValue == "Medium" || s2.filterValue == "Low" || s2.filterValue == null))
                            || (s1.filterValue == "Medium" && (s2.filterValue == "Low" || s2.filterValue == null)) || (s1.filterValue == "Low" && s2.filterValue == null))
                            return -1;
                        else if ((s1.filterValue == null && (s2.filterValue == "Low" || s2.filterValue == "Medium" || s2.filterValue == "High"))
                            || (s1.filterValue == "Low" && (s2.filterValue == "Medium" || s2.filterValue == "High")) || (s1.filterValue == "Medium" && s2.filterValue == "High"))
                            return 1;
                        else return 0;
                    });
                }
                break;
            }
            default: {
                if (!this.filterListIsSorted[type]) {
                    let temp = <Array<any>>this.displayFilterObjects[type].sort(cmpAlphaNum);
                    this.displayFilterObjects[type] = temp.reverse();
                }
                else {
                    this.displayFilterObjects[type] = <Array<any>>this.displayFilterObjects[type].sort(cmpAlphaNum);
                }
                break;
            }
        }
        this.filterListIsSorted[type] = !this.filterListIsSorted[type];
        this.checkIfClearALLChangeRiskRegisterDilterDialog_XXXX();
        this.checkIfApplyChangeRiskRegisterDilterDialog_XXXX()
    }
}