import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { IrlcoreAuthService, APIService, datasetGet, DataToGet, GlobalService } from '@irlca/irlcore';
import { Router } from '@angular/router';
import { ArmService } from '../../../arm.service';
import { RegisterService } from '../register.service';
import DataSource from 'devextreme/data/data_source';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { Workbook } from 'exceljs';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as moment from 'moment';
import { faLineColumns, faTrash } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'app-register-risk',
    templateUrl: './register-risk.component.html',
    styleUrls: ['./register-risk.component.scss']
})
export class RegisterRiskComponent implements OnInit {
    displayedColumnsPicker = [
        { colName: "riskREF", displayed: true, menuTitle: "Risk ID", alignment: "center", width: "150" },
        { colName: "riskAssessmentREF", displayed: true, menuTitle: "Risk Assessment REF",alignment: "center", width: "225" },
        { colName: "failureEffect", displayed: true, menuTitle: this.globalService.configurableParameters.riskEffect, alignment: "center", width: "400" },
        { colName: "failureCause", displayed: true, menuTitle: this.globalService.configurableParameters.riskCause, alignment: "center", width: "400" },
        { colName: "functionalArea", displayed: true, menuTitle: "Functional Area", alignment: "center", width: "225" },
        { colName: "businessProcess", displayed: true, menuTitle: "Business Process", alignment: "center", width: "225" },
        { colName: "initialRS", displayed: true, menuTitle: "Risk Score", alignment: "center", width: "150" },
        { colName: "initialRPN", displayed: true, menuTitle: "RPN", alignment: "center", width: "125" },
        { colName: "initialRiskLevel", displayed: true, menuTitle: "Risk Level", alignment: "center", width: "125" },
        { colName: "riskDecision", displayed: true, menuTitle: "Risk Decision", alignment: "center", width: "150" },
        { colName: "residualRS", displayed: true, menuTitle: "Residual Risk Score", alignment: "center", width: "175" },
        { colName: "residualRPN", displayed: true, menuTitle: "Residual RPN", alignment: "center", width: "175" },
        { colName: "residualRiskLevel", displayed: true, menuTitle: "Residual Risk Level", alignment: "center", width: "175" },
        { colName: "riskOwnerName", displayed: true, menuTitle: "Risk Owner", alignment: "center", width: "150" },
        { colName: "riskStatus", displayed: true, menuTitle: "Risk Status", alignment: "center", width: "150" },
        { colName: "riskAssessmentTitle", displayed: true, menuTitle: "Risk Assessment Title", alignment: "center", width: "225" },
        { colName: "riskAssessmentType", displayed: true, menuTitle: "Risk Assessment Type", alignment: "center", width: "225" },
        { colName: "riskAssessmentVersion", displayed: true, menuTitle: "Risk Assessment Version", alignment: "center", width: "225" },
        { colName: "riskAssessmentStatus", displayed: true, menuTitle: "Risk Assessment Status", alignment: "center", width: "225" },
        { colName: "riskAssessmentEntityName", displayed: false, menuTitle: "Risk Assessment Entity", alignment: "center", width: "200" },
        { colName: "itemGroupName", displayed: false, menuTitle: "Process Step", alignment: "center", width: "150" },
        { colName: "failureMode", displayed: false, menuTitle: this.globalService.configurableParameters.riskMode, alignment: "center", width: "150" },
        { colName: "creationDate", displayed: true, menuTitle: "Date Entered", alignment: "center", width: "200" }
    ];

    columnsToDisplay: any[] = [];
    risks: any[] = [];
    currentPage!: number;
    quickFilterIsSelected: boolean = false;
    tableHidden: boolean = false;
    displayPic: boolean = true;
    root = document.documentElement;

    filters = [];
    pageEvent!: PageEvent;
    filterValue: any = '';
    filterValues = {};
    filterSelectObj: any = [];
    displayLoader: boolean = true;
    displayLoaderExport: boolean = false;
    selectedFilterOptions: any = [];
    filterApplied: boolean = false;
    dataSource!: DataSource;
    tableHeight: any;
    tableWidth: any;
    popupPosition: any;

    // icons 
    trash = faTrash
    lineColumns = faLineColumns
    @ViewChild(DxDataGridComponent, { static: false }) dataGrid!: DxDataGridComponent;

    @HostListener('window:resize')
    onResize() {
        this.tableHeight = window.innerHeight * 0.83;
        this.tableWidth = document.getElementById('risk-register-header')!.offsetWidth;
    }

    constructor(
        public globalService: GlobalService,
        public auth: IrlcoreAuthService,
        private apiService: APIService,
        private router: Router,
        public armService: ArmService,
        public registerService: RegisterService,
    ) {
        this.popupPosition = {
            of: window, at: 'top', my: 'top', offset: { y: 10 },
          };
    }

    async ngOnInit() {
        this.generateDisplayedColumns()
        if (this.registerService.navigateRiskRegister) {
            this.dataSource = this.registerService.filteredRisks;
            this.risks = this.registerService.allRisks;
            this.registerService.navigateRiskRegister = false;
        } else {
            this.prepareDataForGetRiskRiskAssessmentM2O_0190();
        }
        // this.onResize();
    }

    generateDisplayedColumns() {
        this.columnsToDisplay = [];
        for (var i = 0; i < this.displayedColumnsPicker.length; i++) {
            if (this.displayedColumnsPicker[i].displayed == true) {
                this.columnsToDisplay.push(this.displayedColumnsPicker[i])
            }
        }
        this.onResize();
    }
    async prepareDataForGetRiskRiskAssessmentM2O_0190() {
        let datasets: datasetGet[] = [];

        let risk: datasetGet = {
            parameters: {
                iD: null,
                clientID: this.globalService.clientID,
                riskAssessmentID: null,
                riskID: null,
                userID: this.globalService.userID,
            },
            storedProcedure: "DAMRiskRiskAssessmentM2O"
        };
        
        datasets.push(risk);
        let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View RiskRiskAssessment", datasets);

        await this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
            this.risks = fromDB[0];
            setTimeout(() => {
                this.dataSource = new DataSource({
                    store: {
                        type: "array",
                        key: "riskID",
                        data: this.risks
                    }
                });    
            });
        }).catch((err: any) => {
            console.error(err);
            let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Risk Register");
            window.alert(userErrMsg)
        });
    }

    goToRiskAnalysis_0466(event: any) {
        let row = event.data;
        let datasets: datasetGet[] = [];

        let teamDataset: datasetGet = {
            parameters: {
                clientID: this.globalService.clientID,
                entityID: this.globalService.entityID,
                riskAssessmentID: row.riskAssessmentID
            },
            storedProcedure: "RiskAssessmentTeamMember"
        };
        let riskAssessment: datasetGet = {
            parameters: {
                clientID: this.globalService.clientID,
                entityID: this.globalService.entityID,
                riskAssessmentID: row.riskAssessmentID
            },
            storedProcedure: "RiskAssessment"
        };

        datasets.push(teamDataset, riskAssessment);
        let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Risk Record Data", datasets);

        this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
            this.armService.teamDataSource.data = fromDB[0];
            this.armService.teamTableList = fromDB[0];
            this.armService.selectedRiskAssessment = fromDB[1][0];
            this.armService.selectedRiskAssessment["permissionName"] = row.permissionName;
            this.globalService.selectedRiskAssessment = fromDB[1][0];
            this.globalService.selectedRiskAssessment["permissionName"] = row.permissionName;
            this.registerService.selectedRiskForAnalysis = row;
            this.registerService.selectedRiskForAnalysisTeam = fromDB[0];
            this.registerService.navigateRiskRegister = true;
            this.registerService.riskRegisterFilterValue = this.filterValue;
            this.registerService.filteredRisks = this.dataSource;
            this.registerService.selectedFilterOptions = this.selectedFilterOptions;
            this.registerService.allRisks = this.risks;
            this.registerService.risksFilteredApplied = this.filterApplied;
            this.armService.setGlobalSelectedRisk_0455(row);
            this.armService.selectedRiskAssessmentAssociatedRisks = this.risks.filter((risk: any) => risk.riskAssessmentID === row.riskAssessmentID);
            this.armService.indexOfSelectedRisk = this.armService.selectedRiskAssessmentAssociatedRisks.findIndex((risk: any) => risk.riskID === this.armService.selectedRisk.riskID);
            this.router.navigate(['/register/risk-record']);
        }).catch((err: any) => {
            console.error(err);
            let userErrMsg = this.armService.constructUserErrorMessage(err, "Get data for Risk Record");
            window.alert(userErrMsg)
        });
    }

    // changeTableColumns_0537(resetBool: boolean) {
    //     let temp: any[] = [];
    //     if (resetBool) {
    //         this.columnsToDisplay = this.displayedColumns;
    //         this.displayedColumnsPicker.forEach((element) => {
    //             element.displayed = true;
    //         });
    //     } else {
    //         this.displayedColumnsPicker.forEach((element) => {
    //             if (element.displayed) {
    //                 temp.push(element.colName);
    //             }
    //         });
    //         this.columnsToDisplay = temp;
    //     }
    // }

    changeTableColumns_0537(resetBool: boolean) {
        if (resetBool) {
            this.displayedColumnsPicker.forEach((element) => {
                element.displayed = true;
            });
            this.generateDisplayedColumns();
        }
        else {
            // this.columnsToDisplay = [];
            this.generateDisplayedColumns();
        }
    }

    onExporting(e: any) {
        let date = moment().format('DD/MMM/YYYY').toString();

        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Risk Register');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Risk_Register_' + date + '.xlsx');
            });
        });
        e.cancel = true;
    }


    onCellPreparedEliminatedRisk(e: any) {
        if (e.rowType == "data" && e.data.riskDecision === "Eliminate" && e.data.riskStatus === "Managed") {
            e.cellElement.css("font-style", "italic");
            e.cellElement.css("color", "#ccc");
        }
    }
}