<div class="dialog">
    <div class="dialog-header">
        <div class="header-text center">
            <h2>Risk</h2>
        </div>
        <div class="align-items-start">
            <div class="header-icon">
                <button mat-button class="icon-btn dialog-close-btn" (click)="dialogRef.close()">
                    <div class="icon">
                        <fa-icon [icon]="times"></fa-icon>
                    </div>
                </button>
            </div>
        </div>
    </div>
    <div class="dialog-content">
        <div class="required-items"><i>Required Items</i>
            <span class="required-asterisk">*</span>
        </div>
        <form [formGroup]="riskForm">
            <div class="full-width-field" *ngIf="globalService.configurableParameters.psfmInRiskTableRiskAssessment">
                <mat-form-field floatLabel="always">
                    <mat-label>{{globalService.configurableParameters.itemGroup}}
                    </mat-label>
                    <!-- DF Comment: Issue with hitting escape and the keydown event - It clears the Failure Mode even when no changes is made to Process Step - Minor issue -->
                    <input type="text" placeholder="Select {{globalService.configurableParameters.itemGroup}}..."
                        matInput formControlName="itemGroup" [matAutocomplete]="itemGroupAuto"
                        id="risk-dialog-new-itemGroup-search" (keydown)="riskForm.controls['failureMode'].setValue('')">
                    <mat-autocomplete #itemGroupAuto="matAutocomplete">
                        <mat-option *ngFor="let itemGroup of filteredItemGroups | async; let i = index;"
                            value="{{itemGroup.itemGroupName}}" id="risk-dialog-new-itemGroup-option-{{i}}">
                            <p>{{itemGroup.itemGroupName}}</p>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <mat-form-field floatLabel="always">
                <mat-label>{{globalService.configurableParameters.riskCause}}
                </mat-label>
                <textarea rows="3" matInput type="text" formControlName="failureCause" id="dialog-risk-failureCause"
                    placeholder="Enter {{globalService.configurableParameters.riskCause}}" maxlength="1000"></textarea>
            </mat-form-field>
            <div class="full-width-field" *ngIf="globalService.configurableParameters.psfmInRiskTableRiskAssessment">
                <mat-form-field floatLabel="always" [ngClass]="{'disabled': !riskForm.value.itemGroup}">
                    <mat-label>{{globalService.configurableParameters.riskMode}}
                    </mat-label>
                    <input type="text" placeholder="Select {{globalService.configurableParameters.riskMode}}..."
                        matInput formControlName="failureMode" [matAutocomplete]="failureModeAuto"
                        id="risk-dialog-new-failureMode-search" (focus)="onFocusFailureMode()">
                    <mat-autocomplete #failureModeAuto="matAutocomplete">
                        <mat-option *ngFor="let failureMode of filteredFailureModes | async; let i = index;"
                            value="{{failureMode.failureMode}}" id="risk-dialog-new-failureMode-option-{{i}}">
                            <p>{{failureMode.failureMode}}</p>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <mat-form-field floatLabel="always">
                <mat-label>{{globalService.configurableParameters.riskEffect}}
                </mat-label>
                <textarea rows="3" matInput type="text" formControlName="failureEffect" id="dialog-risk-failureEffect"
                    placeholder="Enter {{globalService.configurableParameters.riskEffect}}" maxlength="2000"></textarea>
            </mat-form-field>
        </form>
    </div>
    <div class="dialog-footer">
        <div class="only-buttons">
            <button mat-button class="btn cancel" (click)="dialogRef.close()">
                <p>Cancel</p>
            </button>
            <button mat-button class="btn done" appDebounceClick (debounceClick)="prepareRiskForSave()"
                [debounceTime]="200" [disabled]="!riskForm.valid || riskForm.pristine">
                <p>Done</p>
            </button>
        </div>
    </div>
</div>