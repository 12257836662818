<div class="dialog">
    <div class="dialog-header">
        <div class="header-text center">
            Confirmation Dialog
        </div>
        <div class="align-items-start">
            <div class="header-icon">
                <button mat-button class="icon-btn dialog-close-btn" (click)="dialogRef.close({ closeType: 'cancel' })"
                    id="ConfirmationDialogCompenent-closeDialogIcon">
                    <div class="icon">
                        <fa-icon [icon]="times"></fa-icon>
                    </div>
                </button>
            </div>
        </div>
    </div>
    <div class="dialog-content justify-content-center flex-row">
        <fa-icon [ngStyle]="{'margin-right': '5px', 'align-self': 'center'}" [icon]="infoCirlce"></fa-icon>
        <p>THIS IS THE CONFIRM DIALOG, DO YOU WISH TO cancel/confirm YOUR CHANGES</p>
    </div>
    <div class="dialog dialog-footer-justification">
        <div *ngIf="!justificationNeeded; else justificationState">
            <div class="only-buttons">
                <button mat-button class="btn cancel" (click)="dialogRef.close({ closeType: 'cancel' })"
                    id="ConfirmationDialogCompenent-cancel">Cancel</button>
                <button mat-button class="btn primary" appDebounceClick
                    (debounceClick)="dialogRef.close({ closeType: 'confirm', justification: 'N/A' })"
                    [debounceTime]="200" id="team-TeamMemberDialogComponent-confirm">Confirm</button>
            </div>
        </div>
        <ng-template #justificationState>
            <div class="justification">
                <form [formGroup]="textAreaForm">
                    <mat-form-field floatLabel="always">
                        <p>WARNING: Please add a Justification to Confirm the changes</p>
                        <mat-label></mat-label>
                        <textarea matInput [(ngModel)]="justification" formControlName="textArea"
                            name="justificationTextarea" id="ConfirmationDialogComponent-justification"
                            placeholder="Enter Justification to Complete Action…"></textarea>
                    </mat-form-field>
                </form>
                <div class="button-div">
                    <button mat-button class="btn cancel" (click)="dialogRef.close({ closeType: 'cancel' })"
                        id="ConfirmationDialogComponent-cancel">
                        <p>Cancel</p>
                    </button>
                    <button mat-button class="btn primary" id="ConfirmationDialogComponent-confirm"
                        [disabled]="(!textAreaForm.valid )" appDebounceClick
                        (debounceClick)="dialogRef.close({ closeType: 'confirm', justification: this.justification })"
                        [debounceTime]="200">
                        <p>Confirm</p>
                    </button>
                </div>
            </div>
        </ng-template>
    </div>
</div>