import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'tooltipList' })
export class TooltipListPipe implements PipeTransform {

  transform(lines: string[]): string {

    let list: string = '';

    lines.forEach((line: any) => {
      if (line["functionID"] == null && line["groupID"] == null) {
        list += '' + line["fullName"] + ', ';
      }
      else if (line["functionID"] != null) {
        list += '' + line["functionName"] + ', ';
      }
      else {
        list += '' + line["groupName"] + ', ';
      }
    });
    if (list.length > 2) {
      list = list.substring(0, list.length - 2)
      list += "."
    }
    return list;
  }
}
