<nav *ngIf="userAuthenticated" id="app-nav" [ngClass]="{'disabled': globalService.editModeBool }">
    <a id="primary-navigation-sidebar-dashboard" [matMenuTriggerFor]="menuDash" #menuTriggerDash="matMenuTrigger"
        [ngClass]="{'active': child1RLA.isActive || child2RLA.isActive || child3RLA.isActive || child4RLA.isActive}"
        (mouseover)="menuTriggerDash.openMenu()" (mouseleave)="onDashboardMenuLeave_0538()" class="navbar-option">
        <svg class="nav-bar-icons" viewBox="0 0 35 35">
            <path class="icon"
                d="M32.818,21.091V5.182H4.182V21.091H32.818M32.818,2A3.182,3.182,0,0,1,36,5.182V24.273a3.182,3.182,0,0,1-3.182,3.182H21.682l3.182,4.773v1.591H12.136V32.227l3.182-4.773H4.182A3.181,3.181,0,0,1,1,24.273V5.182A3.171,3.171,0,0,1,4.182,2H32.818M5.773,6.773h17.5v7.955H5.773V6.773m19.091,0h6.364V9.955H24.864V6.773m6.364,4.773V19.5H24.864V11.545h6.364M5.773,16.318h7.955V19.5H5.773V16.318m9.545,0h7.955V19.5H15.318Z"
                transform="translate(-1 -2)" />
        </svg>
        <div class="nav-bar-font">DASHBOARD</div>
    </a>
    <mat-menu [hidden]="true" #menuDash="matMenu" xPosition="after" class="myMenu" [hasBackdrop]="false"
        [overlapTrigger]="false">
        <span (mouseleave)="onDashboardMenuLeave_0538()" (mouseover)="onDashboardMenuEnter_0539()">
            <button class="menu-button" routerLink="/dashboard/dashboard-risk-profile" [routerLinkActive]="['active']"
                #child1RLA="routerLinkActive" mat-menu-item>Risk Profile</button>
            <button class="menu-button" routerLink="/dashboard/dashboard-heatmap" [routerLinkActive]="['active']"
                #child2RLA="routerLinkActive" mat-menu-item>Heatmap</button>
            <button class="menu-button" routerLink="/dashboard/dashboard-risk-assessments"
                [routerLinkActive]="['active']" #child3RLA="routerLinkActive" mat-menu-item>Risk Assessments</button>
            <button class="menu-button" routerLink="/dashboard/dashboard-risks" [routerLinkActive]="['active']"
                #child4RLA="routerLinkActive" mat-menu-item>Risks</button>
            <!-- <button class="menu-button" routerLink="/dashboard/dashboard-mitigating-actions" [routerLinkActive]="['active']" mat-menu-item>Mitigating Actions</button> -->

        </span>
    </mat-menu>
    <a id="primary-navigation-sidebar-register" class="navbar-option" [matMenuTriggerFor]="menu"
        [ngClass]="{'active': child10RLA.isActive || child11RLA.isActive || child12RLA.isActive }"
        #menuTrigger="matMenuTrigger" (mouseover)="menuTrigger.openMenu()" (mouseleave)="onRegisterMenuLeave_0538()"
        [overlapTrigger]="false" *ngIf="auth.featurePermitted_0151(['View Risk Register'])">
        <svg class="nav-bar-icons" viewBox="0 0 35 35">
            <path class="icon"
                d="M33.729,19.959a.909.909,0,0,1,.647.29l2.18,2.18a.906.906,0,0,1,0,1.312l-1.7,1.7-3.492-3.492,1.7-1.7a.946.946,0,0,1,.664-.29M30.374,22.94l3.492,3.492L23.543,36.771H20.034V33.262L30.374,22.94M30.254,4.407a3.417,3.417,0,0,1,3.407,3.407v6.814L16.627,31.661v3.407H6.407A3.417,3.417,0,0,1,3,31.661V7.814A3.417,3.417,0,0,1,6.407,4.407h7.12a5.09,5.09,0,0,1,9.607,0h7.12m-11.924,0a1.7,1.7,0,1,0,1.7,1.7A1.708,1.708,0,0,0,18.331,4.407Z"
                transform="translate(-3 -1)" />
        </svg>
        <div class="nav-bar-font">REGISTER</div>
    </a>
    <mat-menu [hidden]="true" #menu="matMenu" xPosition="after" class="myMenu" [hasBackdrop]="false"
        [overlapTrigger]="false">
        <span (mouseleave)="onRegisterMenuLeave_0538()" (mouseover)="onRegisterMenuEnter_0539()">
            <button class="menu-button" routerLink="/register/risk-register" [routerLinkActive]="['active']"
                #child10RLA="routerLinkActive" mat-menu-item>Risks</button>
            <button class="menu-button" routerLink="/register/control-register" [routerLinkActive]="['active']"
                #child11RLA="routerLinkActive" mat-menu-item>Controls</button>
            <button class="menu-button" routerLink="/register/mitigating-action-register"
                (click)="armService.selectedRisk.iD = null; armService.taskReturn = ''; armService.expandedTaskView = false;"
                [routerLinkActive]="['active']" #child12RLA="routerLinkActive" mat-menu-item>Mitigating Actions</button>
        </span>
    </mat-menu>
    <a id="primary-navigation-sidebar-inspection" routerLink="/risk-assessment" [routerLinkActive]="['active']"
        class="navbar-option" *ngIf="auth.featurePermitted_0151(['View Risk Assessment'])">
        <svg class="nav-bar-icons" viewBox="0 0 35 35">
            <path class="icon" id="assesment-icon"
                d="M-12400-21873h-29a3,3,0,0,1-3-3v-29a3,3,0,0,1,3-3h29a3,3,0,0,1,3,3v29A3,3,0,0,1-12400-21873Zm-8.347-10.354v0l3.232,3.23,1.742-1.736-3.232-3.238,3.232-3.234-1.742-1.736-3.233,3.232-3.232-3.232-1.743,1.736,3.232,3.234-3.232,3.238,1.743,1.736,3.231-3.23Zm-18.655-2.984h0v2.49h11.194v-2.49Zm15.423-11.068h0l-1.743,1.742,4.354,4.35,6.966-6.961-1.742-1.742-5.224,5.223-2.611-2.611Zm-15.423,1.115h0v2.49h11.194v-2.49Z"
                transform="translate(12431.998 21908.002)" />
        </svg>
        <div class="nav-bar-font">ASSESSMENT</div>
    </a>
    <div class="seperator">&nbsp;</div>

    <a id="primary-navigation-sidebar-communications" routerLink="/communications" class="navbar-option">
        <svg class="nav-bar-icons" viewBox="0 0 35 35">
            <path class="icon" id="communication-icon"
                d="M13.025,33.5a1.488,1.488,0,0,1-1.575-1.575V27.2H5.15A3.159,3.159,0,0,1,2,24.05V5.15A3.159,3.159,0,0,1,5.15,2h25.2A3.159,3.159,0,0,1,33.5,5.15v18.9a3.159,3.159,0,0,1-3.15,3.15H20.742l-5.828,5.828a1.43,1.43,0,0,1-1.1.472h-.787M14.6,24.05v4.882l4.882-4.882H30.35V5.15H5.15v18.9H14.6M24.522,8.3l-2.205,4.725h3.308v6.3h-6.3V12.71L21.372,8.3h3.15m-9.45,0-2.205,4.725h3.308v6.3h-6.3V12.71L11.922,8.3Z"
                transform="translate(-3 -3)" />
        </svg>
        <div class="nav-bar-font">COMMS.</div>
    </a>
    <a id="primary-navigation-sidebar-review" routerLink="/review" class="navbar-option">
        <svg class="nav-bar-icons" viewBox="0 0 35 35">
            <path class="icon"
                d="M35,23.389l-7.556,7.556-5.289-5.289,2.267-2.267,3.022,3.022,5.289-5.289L35,23.389M17.622,11.3a4.533,4.533,0,1,1-4.533,4.533A4.533,4.533,0,0,1,17.622,11.3m0-6.8A17.872,17.872,0,0,1,34.244,15.833a17.208,17.208,0,0,1-1.39,2.8,8.719,8.719,0,0,0-2.871-1.1l.967-1.692a14.842,14.842,0,0,0-26.656,0,14.841,14.841,0,0,0,13.328,8.311l1.828-.106a8.812,8.812,0,0,0-.317,2.372v.7l-1.511.06A17.872,17.872,0,0,1,1,15.833,17.872,17.872,0,0,1,17.622,4.5Z"
                transform="translate(-1 -4.5)" />
        </svg>
        <div class="nav-bar-font">REVIEW</div>
    </a>
    <a id="primary-navigation-sidebar-tools-secondary" routerLink="/tools" [routerLinkActive]="['active']"
        class="navbar-option">
        <svg class="nav-bar-icons" viewBox="0 0 35 35">
            <path class="icon"
                d="M33.5,29.25v-7H28.25V24h-3.5V22.25H14.25V24h-3.5V22.25H5.5v7h28M9.577,11.75l-3.045,7H10.75V17h3.5v1.75h10.5V17h3.5v1.75h4.218l-3.045-7H9.577M14.25,6.5V8.25h10.5V6.5H14.25M36.72,19.818a3.321,3.321,0,0,1,.28,1.4V29.25a3.507,3.507,0,0,1-1.05,2.467A3.294,3.294,0,0,1,33.5,32.75H5.5a3.294,3.294,0,0,1-2.45-1.033A3.507,3.507,0,0,1,2,29.25V21.218a3.321,3.321,0,0,1,.28-1.4l4.095-9.433a3.218,3.218,0,0,1,3.2-2.135H10.75V6.5A3.466,3.466,0,0,1,14.25,3h10.5a3.466,3.466,0,0,1,3.5,3.5V8.25h1.173a3.218,3.218,0,0,1,3.2,2.135Z"
                transform="translate(-2 -3)" />
        </svg>
        <div class="nav-bar-font">TOOLS</div>
    </a>
    <!-- DF-Question: don't think we should use this separator class -->
    <div class="seperator">&nbsp;</div>
    <a id="primary-navigation-sidebar-landscape-secondary" routerLink="/landscape" [routerLinkActive]="['active']"
        class="navbar-option">
        <svg class="nav-bar-icons" viewBox="0 0 35 35">
            <path class="icon"
                d="M14.667,3V32.167H20.5V3H14.667M20.5,6.889l7.778,25.278,5.833-1.944L26.333,4.944,20.5,6.889m-13.611,0V32.167h5.833V6.889H6.889M3,34.111V38H38V34.111Z"
                transform="translate(-3 -3)" />
        </svg>
        <div class="nav-bar-font">LANDSCAPE</div>
    </a>
    <div class="seperator" *ngIf="auth.featurePermitted_0151(['View Admin CP'])">&nbsp;</div>
    <a id="primary-navigation-sidebar-inventory-settings" [matMenuTriggerFor]="menuSettings" 
    [ngClass]="{'active': child70RLA.isActive || child71RLA.isActive || child72RLA.isActive || child73RLA.isActive || child74RLA.isActive || child75RLA.isActive || child76RLA.isActive}"
        (mouseover)="menuTriggerSettings.openMenu()" (mouseleave)="onSettingsMenuLeave_0538()" class="navbar-option"
        *ngIf="auth.featurePermitted_0151(['View Admin CP'])" #menuTriggerSettings="matMenuTrigger">
        <svg class="nav-bar-icons" viewBox="0 0 35 35">
            <path class="icon"
                d="M19.775,26.288a6.3,6.3,0,1,1,6.3-6.3,6.3,6.3,0,0,1-6.3,6.3m13.367-4.552a13.979,13.979,0,0,0,.126-1.745,15.384,15.384,0,0,0-.126-1.8l3.8-2.933a.908.908,0,0,0,.216-1.151l-3.6-6.225a.884.884,0,0,0-1.1-.4l-4.48,1.8a13.041,13.041,0,0,0-3.04-1.763l-.666-4.768a.91.91,0,0,0-.9-.756h-7.2a.91.91,0,0,0-.9.756l-.666,4.768a13.041,13.041,0,0,0-3.04,1.763l-4.48-1.8a.884.884,0,0,0-1.1.4L2.4,14.108a.887.887,0,0,0,.216,1.151l3.8,2.933a15.384,15.384,0,0,0-.126,1.8,13.978,13.978,0,0,0,.126,1.745l-3.8,2.986A.887.887,0,0,0,2.4,25.874l3.6,6.225a.9.9,0,0,0,1.1.4l4.48-1.817a12.5,12.5,0,0,0,3.04,1.781l.666,4.768a.91.91,0,0,0,.9.756h7.2a.91.91,0,0,0,.9-.756l.666-4.768a13.1,13.1,0,0,0,3.04-1.781l4.48,1.817a.9.9,0,0,0,1.1-.4l3.6-6.225a.908.908,0,0,0-.216-1.151Z"
                transform="translate(-2.271 -2)" />
        </svg>
        <div class="nav-bar-font">SETTINGS</div>
    </a>
    <mat-menu [hidden]="true" #menuSettings="matMenu" xPosition="after" class="myMenu bottom-menu" [hasBackdrop]="false"
        [overlapTrigger]="false">
        <span (mouseleave)="onSettingsMenuLeave_0538()" (mouseover)="onSettingsMenuEnter_0539()">
            <button class="menu-button" routerLink="/irlcore/user-mgmt" [routerLinkActive]="['active']"
                #child70RLA="routerLinkActive" mat-menu-item>User Management</button>
            <button class="menu-button" routerLink="/irlcore/role-mgmt" [routerLinkActive]="['active']"
                #child71RLA="routerLinkActive" mat-menu-item>Role Management</button>
            <button class="menu-button" routerLink="/irlcore/configurations" [routerLinkActive]="['active']"
                #child72RLA="routerLinkActive" mat-menu-item>Configurations</button>
            <button class="menu-button" routerLink="/irlcore/audit-trail" [routerLinkActive]="['active']"
                #child73RLA="routerLinkActive" mat-menu-item>Audit Trail</button>
            <button class="menu-button" routerLink="/groups/groups-master" [routerLinkActive]="['active']"
                #child74RLA="routerLinkActive" mat-menu-item>DAM Groups</button>
            <button class="menu-button" routerLink="/functions/functions-master" [routerLinkActive]="['active']"
                #child75RLA="routerLinkActive" mat-menu-item>DAM Functions</button>
            <button class="menu-button" routerLink="/irlcore/login-logs" [routerLinkActive]="['active']"
                #child76RLA="routerLinkActive" mat-menu-item>Login logs</button>

        </span>
    </mat-menu>
    <!-- <div class="navbar-bottom">
    <div>
        <a id="primary-navigation-sidebar-settings" routerLink="/irlcore" [routerLinkActive]="['active']" class="settings">
            <svg width="35" height="35.982" viewBox="0 0 35 35.982">
                <path class="icon"
                    d="M19.775,26.288a6.3,6.3,0,1,1,6.3-6.3,6.3,6.3,0,0,1-6.3,6.3m13.367-4.552a13.979,13.979,0,0,0,.126-1.745,15.384,15.384,0,0,0-.126-1.8l3.8-2.933a.908.908,0,0,0,.216-1.151l-3.6-6.225a.884.884,0,0,0-1.1-.4l-4.48,1.8a13.041,13.041,0,0,0-3.04-1.763l-.666-4.768a.91.91,0,0,0-.9-.756h-7.2a.91.91,0,0,0-.9.756l-.666,4.768a13.041,13.041,0,0,0-3.04,1.763l-4.48-1.8a.884.884,0,0,0-1.1.4L2.4,14.108a.887.887,0,0,0,.216,1.151l3.8,2.933a15.384,15.384,0,0,0-.126,1.8,13.978,13.978,0,0,0,.126,1.745l-3.8,2.986A.887.887,0,0,0,2.4,25.874l3.6,6.225a.9.9,0,0,0,1.1.4l4.48-1.817a12.5,12.5,0,0,0,3.04,1.781l.666,4.768a.91.91,0,0,0,.9.756h7.2a.91.91,0,0,0,.9-.756l.666-4.768a13.1,13.1,0,0,0,3.04-1.781l4.48,1.817a.9.9,0,0,0,1.1-.4l3.6-6.225a.908.908,0,0,0-.216-1.151Z"
                    transform="translate(-2.271 -2)" />
            </svg>
            <div class="nav-bar-font">SETTINGS</div>
        </a>
    </div>
</div> -->
</nav>