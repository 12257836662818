import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { APIService, dataset, datasetGet, DataToGet, DataToPost, GlobalService } from '@irlca/irlcore';
import { DxDiagramComponent } from 'devextreme-angular/ui/diagram';
import { ArmService } from '../../arm.service';
import { Router } from '@angular/router';
import { faArrowLeft, faEllipsisH } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-flow-diagram',
  templateUrl: './flow-diagram.component.html',
  styleUrls: ['./flow-diagram.component.scss'],
})


export class FlowDiagramComponent implements OnInit {
  @ViewChild(DxDiagramComponent, { static: false }) diagram!: DxDiagramComponent;
  data: any;
  flowDiagramExists: boolean = false;
  oldObject: any;
  editModeBool: boolean = false;
  inWorkflow: boolean = false;
  diagramPullComplete: boolean = false;

  // icons 
  arrowLeft = faArrowLeft
  ellipsisHorizontal = faEllipsisH

  constructor(http: HttpClient, public apiService: APIService, public globalService: GlobalService, public armService: ArmService, public router: Router) {
    if (this.armService.workflow[this.armService.workflow.length - 1] != undefined) {
      if (!this.armService.workflow[this.armService.workflow.length - 1].completionDate) {
        this.inWorkflow = true;
      }
    }
    this.prepareDataForGetFlowDiagram();
  }

  ngOnInit() {
  }

  prepareDataForGetFlowDiagram() {
    let dataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        riskAssessmentID: this.armService.selectedRiskAssessment.iD
      },
      storedProcedure: "FlowDiagram"
    }
    let datasets = [];
    datasets.push(dataset)

    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Flow Diagram", datasets);
    this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((result: any) => {
      result = result[0]
      if (result.length > 0) {
        this.diagram.readOnly = true;
        this.diagram.instance.import(result[result.length - 1].flowDiagram);
        this.flowDiagramExists = true;
        this.oldObject = result[result.length - 1];
      }
      else {
        this.diagram.readOnly = true;
        this.diagram.instance.import(JSON.stringify({}));
        this.flowDiagramExists = false;
      }
    }).catch((err: any) => {
      this.diagram.readOnly = true;
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Flow Diagram");
      window.alert(userErrMsg)
    });
  }

  prepareDataForPostFlowDiagram() {
    let datasets = [];
    let auditTrail = [];
    if (this.flowDiagramExists) {
      let flowDiagram = this.diagram.instance.export();
      let dataset: dataset = {
        data: [{
          iD: this.oldObject.iD,
          clientID: this.globalService.clientID,
          riskAssessmentID: this.oldObject.riskAssessmentID,
          riskAssessmentREF: this.oldObject.riskAssessmentREF,
          flowDiagram: flowDiagram,
          statementType: "UPDATE"
        }],
        dataset: "FlowDiagram"
      }
      datasets.push(dataset)
      auditTrail = this.globalService.buildAuditTrail_0115(this.oldObject, dataset.data[0], "tblFlowDiagram")
    }
    else {
      let flowDiagram = this.diagram.instance.export();
      let dataset: dataset = {
        data: [{
          iD: -1,
          clientID: this.globalService.clientID,
          riskAssessmentID: this.armService.selectedRiskAssessment.iD,
          riskAssessmentREF: this.armService.selectedRiskAssessment.riskAssessmentREF,
          flowDiagram: flowDiagram,
          statementType: "INSERT"
        }],
        dataset: "FlowDiagram"
      }
      datasets.push(dataset)
      auditTrail = this.globalService.buildAuditTrail_0115({}, dataset.data[0], "tblFlowDiagram")
    }

    let dataToPost = new DataToPost(this.globalService.clientID, this.globalService.userID, '', "Update Flow Diagram", datasets, auditTrail)
    this.apiService.postDataToBackend_0051(this.apiService.urlForSinglePost, dataToPost).then((result: any) => {
      if (result) {
        this.turnOffEditMode();
      }
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Flow Diagram");
      window.alert(userErrMsg)
    });
  }

  turnOnEditMode() {
    this.editModeBool = true;
    this.globalService.editModeBool = true;
    this.diagram.readOnly = false;
  }

  turnOffEditMode() {
    this.editModeBool = false;
    this.globalService.editModeBool = false;
    this.diagram.readOnly = true;
    this.prepareDataForGetFlowDiagram();
  }

  navigateToExpandedDetailView_0409() {
    this.router.navigate(['/risk-assessment/risk-assessment-expanded-detail']);
  }

}
