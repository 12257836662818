<div class="risk-register">
    <div class="risk-register-header" id="risk-register-header">
        <div class="risk-register-title">
            <h3 id="risk-register-header-title" class="title uppercase">All Risks</h3>
        </div>
        <div class="risk-register-column-select">
            <label class="bold-label"> Clear Filters</label>
            <button mat-button class="btn icon-btn icon-btn-white" (click)="dataGrid.instance.clearFilter()">
                <div class="icon">
                    <fa-icon [icon]="trash"></fa-icon>
                </div>
            </button>
            <h5>Set Columns</h5>
            <button mat-button id="risk-register-display-columns" [matMenuTriggerFor]="menuCol"
                class="btn icon-btn icon-btn-white">
                <div class="icon">
                    <fa-icon [icon]="lineColumns"></fa-icon>
                </div>
            </button>
            <mat-menu #menuCol="matMenu" class="dropdown-menu" xPosition="before" id="risk-register-mat-menu">
                <button class="menu-button" id="risk-register-mat-menu-confirm-button"
                    (click)="changeTableColumns_0537(false)" mat-menu-item>Confirm</button>
                <button class="menu-button" id="risk-register-mat-menu-reset-button"
                    (click)="changeTableColumns_0537(true)" mat-menu-item>Reset</button>
                <mat-checkbox class="mat-menu-item" *ngFor="let columm of displayedColumnsPicker; let i = index;"
                    (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()"
                    [(ngModel)]="columm.displayed" ngDefaultControl color="primary"
                    id="risk-register-mat-menu-item-{{i}}">
                    <p>{{columm.menuTitle}}</p>
                </mat-checkbox>
            </mat-menu>
        </div>
    </div>
    <div class="general-table-overflow register-table" [hidden]="tableHidden" id="generalTable">
        <dx-data-grid id="gridContainer" [dataSource]="dataSource" [showBorders]="true" [allowColumnResizing]="true"
            [focusedRowEnabled]="true" columnResizingMode="widget" (onRowDblClick)="goToRiskAnalysis_0466($event)"
            [height]="tableHeight" [width]="tableWidth" (onExporting)="onExporting($event)"
            (onCellPrepared)="onCellPreparedEliminatedRisk($event)">
            <dxi-column *ngFor="let col of columnsToDisplay" dataField="{{col.colName}}" alignment="{{col.alignment}}"
                caption="{{col.menuTitle}}" width="{{col.width}}">
                <dxo-form-item></dxo-form-item>
            </dxi-column>
            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
            <dxo-filter-builder [allowHierarchicalFields]="true">
            </dxo-filter-builder>
            <dxo-filter-builder-popup [position]="popupPosition">
            </dxo-filter-builder-popup>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-search-panel [visible]="true" [width]="400" placeholder="Search..."></dxo-search-panel>
            <dxo-export [enabled]="true"></dxo-export>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
            <dxo-summary>
                <dxi-total-item column="riskREF" summaryType="count"> </dxi-total-item>
            </dxo-summary>
        </dx-data-grid>
    </div>
</div>