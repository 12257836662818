<div class="communications-container">
    <div class="page-card">
        <div class="page-card-header">
            <h3>RISK COMMUNICATIONS</h3>
        </div>

        <div class="page-card-body">

            <div class="development-message">

                <label>Objective</label>
                <div class="message-card">
                    <p>Risk communication is the sharing of information about risk and risk management between the decision makers and others. Parties can communicate at any stage of 
                        the risk management process. The output/result of the quality risk management process should 
                        be appropriately communicated and documented. Communications might include those among interested parties (e.g., regulators and industry; industry and the patient; within a 
                        company, industry, or regulatory authority). The included information might relate to the existence, nature, form, probability, severity, acceptability, control, treatment, 
                        detectability, or other aspects of risks to quality. Communication need not be carried out for each and every risk acceptance. Between the industry and regulatory authorities, 
                        communication concerning quality risk management decisions might be effected through existing channels as specified in regulations and guidances.
                    </p>
                    <div style="margin-top: 10px; color:#0850b2">Excerpt taken from: International Conference on Harmonization (ICH) guideline Q9 on Quality Risk
                        Management – June 2006 – Part 4, Section E (4.5)
                        </div>
                </div>
                <label>Status</label>
                <div class="message-card">

                    <div class="banner">
                        <h6>UNDER DEVELOPMENT</h6>
                    </div>
                    <p>This Feature is currently planned for release in 2022</p>

                </div>
            </div>
            <div class="development-picture">
             <img src="../../../assets/images/UnderConstruction.png" height="600">  
            </div>
        </div>
    </div>
</div>