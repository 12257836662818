import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContentComponent } from './content/content.component';
import { HeaderComponent } from './header/header.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';

import {
  IrlcoreAuthenticationModule,
  IrlcoreAuthConfig,
  IrlcoreCallbackComponent,
  IrlcoreAuthService,
  IrlcoreSecurityManagementConfig,
  IrlcoreSecurityManagementModule,
  IrlcoreAuthGuard,
  IrlcoreSecurityManagementService,
} from '@irlca/irlcore';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RiskAssessmentDialogComponent } from './shared/dialog/risk-assessment-dialog/risk-assessment-dialog.component';
import { RiskDialogComponent } from './shared/dialog/risk-dialog/risk-dialog.component';
import { RiskAssessmentTeamDialogComponent } from './shared/dialog/risk-assessment-team-dialog/risk-assessment-team-dialog.component';
import { ConfirmationDialogComponent } from './shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { RiskRegisterFilterDialogComponent } from './shared/dialog/risk-register-filter-dialog/risk-register-filter-dialog.component';
import { DialogFilterPipe } from "./shared/dialog/risk-register-filter-dialog/risk-register-filtering-dialog.pipe";
import { MatTableExporterModule } from 'mat-table-exporter';
import { RiskAssessmentMasterPipe } from './content/risk-assessment/risk-assessment-master/risk-assessment-master.pipe';
import { DashboardModule } from './content/dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { RiskAssessmentLeadDialogComponent } from './shared/dialog/risk-assessment-lead-dialog/risk-assessment-lead-dialog.component';
import { ControlDialogComponent } from './shared/dialog/control-dialog/control-dialog.component';
import { ControlRisksDialogComponent } from './shared/dialog/control-risks-dialog/control-risks-dialog.component';
import { ControlRegisterFilterDialogComponent } from './shared/dialog/control-register-filter-dialog/control-register-filter-dialog.component';
import { CommunicationsComponent } from './content/communications/communications.component';
import { ReviewComponent } from './content/review/review.component';
import { ToolsComponent } from './content/tools/tools.component';
import { LandscapeComponent } from './content/landscape/landscape.component';
import { PeriodicReviewDialogComponent } from './shared/dialog/periodic-review-dialog/periodic-review-dialog.component';
import { InactiveTimeoutDialogComponent } from './shared/dialog/inactive-timeout-dialog/inactive-timeout-dialog.component';
import { RiskAssessmentReviewDialogComponent } from './shared/dialog/risk-assessment-review-dialog/risk-assessment-review-dialog.component';
import { RiskAssessmentReviewCommentsDialogComponent } from './shared/dialog/risk-assessment-review-comments-dialog/risk-assessment-review-comments-dialog.component';
import { FMEAPipe } from './content/risk-assessment/risk-assessment-tools/fmea.pipe';
import { ItemGroupOrderDialogComponent } from './shared/dialog/item-group-order-dialog/item-group-order-dialog.component';
import { DxDiagramModule } from 'devextreme-angular';
import { FlowDiagramComponent } from './shared/flow-diagram/flow-diagram.component';
import { HTTPInterceptor } from './http-interceptor';
import { LogErrorHandler } from './logErrorHandler';
import { LogService } from './log.service';
import { SODRatingDialogComponent } from './shared/dialog/sod-rating-dialog/sod-rating-dialog.component';
import { SODControlDialogComponent } from './shared/dialog/sod-control-dialog/sod-control-dialog.component';
import { DxCheckBoxModule, DxDataGridModule, DxSelectBoxModule, DxTemplateModule, DxTooltipModule, DxTextAreaModule } from 'devextreme-angular';
import { StringifyControlsPipe } from './shared/dialog/cotrol-pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CdkColumnDef } from '@angular/cdk/table';

const securityManagementConfig: IrlcoreSecurityManagementConfig = {
  // // URI of application. This will used to request / save Security Managment User Data
  applicationBaseURL: environment.applicationBaseURL,
};

// _____________________________
// Authentication Bootstrap Data
// -----------------------------
const authConfig: IrlcoreAuthConfig = {
  systemID: 2,
  configBaseURL: environment.configBaseURL, // Virtual PC: IC001P
  // configBaseURL: 'https://arminternal.irlca.com:40000/', // Virtual PC: IC001P
  // configBaseURL:   'http://localhost:40000',

  // Should irlcore automatically retrieve user Permitted Features
  autoRetrieveUserFeatures: true,

  // URI of application. This will used to obtain users credentials e.g. Permitted Features (aka Permissions)
  applicationBaseURL: environment.applicationBaseURL,
};

@NgModule({
  declarations: [
    AppComponent,
    ContentComponent,
    HeaderComponent,
    NavbarComponent,
    FooterComponent,
    RiskAssessmentDialogComponent,
    RiskAssessmentTeamDialogComponent,
    ConfirmationDialogComponent,
    RiskDialogComponent,
    RiskRegisterFilterDialogComponent,
    DialogFilterPipe,
    SODRatingDialogComponent,
    SODControlDialogComponent,
    RiskAssessmentLeadDialogComponent,
    ControlDialogComponent,
    ControlRisksDialogComponent,
    ControlRegisterFilterDialogComponent,
    CommunicationsComponent,
    ReviewComponent,
    ToolsComponent,
    LandscapeComponent,
    PeriodicReviewDialogComponent,
    RiskAssessmentReviewDialogComponent,
    RiskAssessmentReviewCommentsDialogComponent,
    FMEAPipe,
    FlowDiagramComponent,
    StringifyControlsPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatBadgeModule,
    MatDialogModule,
    MatMenuModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatCardModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    NgxDaterangepickerMd.forRoot(),
    DragDropModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCheckboxModule,
    MatTableExporterModule,
    MatProgressSpinnerModule,
    DashboardModule,
    IrlcoreAuthenticationModule.initServerConfig(authConfig), // Initialise Authentication Module passing in application name and config server URI
    IrlcoreSecurityManagementModule.initConfig(securityManagementConfig),
    DxDiagramModule,
    DxDataGridModule,
    FontAwesomeModule,
    DxTextAreaModule,
    SharedModule
  ],
  entryComponents: [
    RiskAssessmentDialogComponent,
    RiskAssessmentTeamDialogComponent,
    ConfirmationDialogComponent,
    RiskDialogComponent,
    RiskRegisterFilterDialogComponent,
    SODRatingDialogComponent,
    SODControlDialogComponent,
    RiskAssessmentLeadDialogComponent,
    ControlDialogComponent,
    ControlRisksDialogComponent,
    ControlRegisterFilterDialogComponent,
    PeriodicReviewDialogComponent,
    InactiveTimeoutDialogComponent,
    RiskAssessmentReviewDialogComponent,
    RiskAssessmentReviewCommentsDialogComponent,
    ItemGroupOrderDialogComponent,
  ],

  providers: [
    LogService,
    {
      provide: ErrorHandler,
      useClass: LogErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPInterceptor,
      multi: true
    },
    IrlcoreAuthService, IrlcoreAuthGuard, IrlcoreSecurityManagementService,
    CdkColumnDef
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
