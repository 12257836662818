import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { CommunicationsComponent } from './content/communications/communications.component';
import { LandscapeComponent } from './content/landscape/landscape.component';
import { RegisterModule } from './content/register/register.module';
import { ReviewComponent } from './content/review/review.component';
import { ToolsComponent } from './content/tools/tools.component';
import { FlowDiagramComponent } from './shared/flow-diagram/flow-diagram.component';
import { ViewAttachmentComponent } from './shared/view-attachment/view-attachment.component';

const routes: Routes = [
    {
        path: 'risk-assessment',
        loadChildren: () => import('./content/risk-assessment/risk-assessment.module').then(m => m.RiskAssessmentModule)
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./content/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'register',
        loadChildren: () => import('./content/register/register.module').then(m => m.RegisterModule)
    },
    {
        path: '',
        redirectTo: '/dashboard/dashboard-risk-profile',
        pathMatch: 'full' //change to dashboard when its done
    },
    {
        path: "home",
        redirectTo: '/risk-assessment/risk-assessment-master',
        pathMatch: 'full' //change to dashboard when its done
    },
    {
        path: "landscape",
        component: LandscapeComponent,
    },
    {
        path: "tools",
        component: ToolsComponent,
    },
    {
        path: "communications",
        component: CommunicationsComponent,
    },
    {
        path: "review",
        component: ReviewComponent,
    },
    {
        path: "view-attachment",
        component: ViewAttachmentComponent,
    },
    {
        path: "flow-diagram",
        component: FlowDiagramComponent,
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes,
        { 
          preloadingStrategy: PreloadAllModules
        }),
        RegisterModule],
    exports: [RouterModule]
})
export class AppRoutingModule { }
