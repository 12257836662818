import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { APIService, datasetGet, DataToGet, GlobalService, IrlcoreAuthService } from '@irlca/irlcore';
import { cloneDeep } from 'lodash';
import { ArmService } from 'src/app/arm.service';
import { Router } from '@angular/router';
import { RegisterService } from '../../../content/register/register.service';

@Component({
  selector: 'app-task-risks-dialog',
  templateUrl: './task-risks-dialog.component.html',
  styleUrls: ['./task-risks-dialog.component.scss']
})
export class TaskRisksDialogComponent implements OnInit {

  times = faTimes;
  associatedRisksAssigned: boolean = false;
  taskDatasets: any[] = [];
  taskLinkingDatasets: any[] = [];
  allowDeleting: boolean = true;
  tasks: any[] = [];

  constructor(public dialogRef: MatDialogRef<TaskRisksDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public globalService: GlobalService, public auth: IrlcoreAuthService,
    public armService: ArmService, private apiService: APIService,
    private router: Router, public registerService: RegisterService,
  ) { }

  ngOnInit(): void {
    if (this.data.mode === 'register') {
      this.allowDeleting = false;
      this.tasks.push(this.data.action);
    } else {
      this.tasks = cloneDeep(this.globalService.tasks);
    }
    this.prepareDataForGetRiskAssessmentDialog_0190();
  }

  prepareDataForGetRiskAssessmentDialog_0190() {
    let datasets: datasetGet[] = [];

    if (this.data.mode === 'riskRecord') {
      // DOC Comment: Is there a better way to pass multiple IDs to a SP?
      this.tasks.forEach((task: any) => {
        if (task.iD > 0) {
          let tasksAssociatedRisksDataset: datasetGet = {
            parameters: {
              actionID: task.iD,
              clientID: this.globalService.clientID,
              entityID: this.globalService.entityID,
            },
            storedProcedure: "MitigatingActionAssociatedRisks"
          };
          datasets.push(tasksAssociatedRisksDataset);
        }
      });
    } else {
      let tasksAssociatedRisksDataset: datasetGet = {
        parameters: {
          actionID: this.data.action.iD,
          clientID: this.globalService.clientID,
          entityID: this.globalService.entityID,
        },
        storedProcedure: "MitigatingActionAssociatedRisks"
      };
      datasets.push(tasksAssociatedRisksDataset);
    }

    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Risk Assessment Dialog", datasets);
    this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      this.tasks.forEach((task: any, i: number) => {
        if (task.iD > 0 && task.iD === fromDB[i][0].actionID) {
          task['associatedRisks'] = fromDB[i];
        }
      });
      this.associatedRisksAssigned = true;
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Risk Assessment Dialog Data");
      window.alert(userErrMsg)
    });
  }

  onCellPreparedAssociatedRisk(e: any) {
    if (this.data.mode === 'riskRecord') {
      if (e.columnIndex == 3 && e.rowType == "data") {
        if (e.data.riskID != this.armService.selectedRisk.iD) {
          e.cellElement.find(".dx-link-delete").remove();
        }
      }
      if (e.rowType == "data" && e.data.riskID == this.armService.selectedRisk.iD) {
        e.cellElement.css("background-color", "#d8d8d8");
      }
    }
  }

  onCellPreparedActionTable(e: any) {
    if (this.data.mode === 'riskRecord') {
      if (e.columnIndex == 4 && e.rowType == "data") {
        if (e.data.associatedRisks && e.data.associatedRisks.length > 1) {
          e.cellElement.find(".dx-link-delete").remove();
        }
      }
    }
  }

  removeAssociation(e: any, associatedRisks: any) {
    let taskIndex = this.tasks.findIndex((task: any) => task.actionID === e.data.actionID);

    if (associatedRisks.length == 0) {
      if (taskIndex > -1) {
        let mitigatingActionDataset: any = {
          iD: this.tasks[taskIndex].actionID,
          riskID: this.tasks[taskIndex].riskID,
          clientID: this.globalService.clientID,
          entityID: this.globalService.entityID,
          category: 'Action Manager',
          description: this.tasks[taskIndex].description,
          assignedToID: this.tasks[taskIndex].assignedToID,
          assignedTo: this.tasks[taskIndex].assignedTo,
          dueDate: this.tasks[taskIndex].dueDate,
          percentCompleted: this.tasks[taskIndex].percentCompleted,
          status: this.tasks[taskIndex].status,
          priority: this.tasks[taskIndex].priority,
          createdByID: this.tasks[taskIndex].createdByID,
          createdBy: this.tasks[taskIndex].createdBy,
          creationDate: this.tasks[taskIndex].creationDate,
          editEnabled: this.tasks[taskIndex].editEnabled,
          deleteEnabled: true,
          statementType: "UPDATE",
        }
        this.taskDatasets.push(mitigatingActionDataset);
      }
    }
    this.tasks.splice(taskIndex, 1);

    let mitigatingActionRiskM2MDataset = {
      iD: e.data.iD,
      actionID: e.data.actionID,
      riskID: e.data.riskID,
      statementType: 'DELETE'
    }
    this.taskLinkingDatasets.push(mitigatingActionRiskM2MDataset);
  }

  deleteMitigatingAction(e: any) {
    let mitigatingActionDataset: any = {
      iD: e.data.iD,
      riskID: e.data.riskID,
      clientID: this.globalService.clientID,
      entityID: this.globalService.entityID,
      category: 'Action Manager',
      description: e.data.description,
      assignedToID: e.data.assignedToID,
      assignedTo: e.data.assignedTo,
      dueDate: e.data.dueDate,
      percentCompleted: e.data.percentCompleted,
      status: e.data.status,
      priority: e.data.priority,
      createdByID: e.data.createdByID,
      createdBy: e.data.createdBy,
      creationDate: e.data.creationDate,
      editEnabled: e.data.editEnabled,
      deleteEnabled: e.data.deleteEnabled,
      statementType: "DELETE",
    }
    this.taskDatasets.push(mitigatingActionDataset);

    if (e.data.associatedRisks && e.data.associatedRisks.length > 0) {
      let mitigatingActionRiskM2MDataset: any = {
        iD: e.data.associatedRisks[0].iD,
        actionID: e.data.iD,
        riskID: e.data.riskID,
        statementType: 'DELETE'
      }
      this.taskLinkingDatasets.push(mitigatingActionRiskM2MDataset);
    }
  }

  saveDialog() {
    this.taskDatasets.forEach((element: any) => {
      this.globalService.tasksDataset.data.push(element);
      this.globalService.tasksAuditTrailDataset = this.globalService.tasksAuditTrailDataset.concat(this.globalService.buildAuditTrail_0115({}, element, 'tblTask'));
    });
    this.taskLinkingDatasets.forEach((element: any) => {
      this.globalService.taskLinkingDataset.data.push(element);
      this.globalService.tasksMitigatingActionRiskM2MAuditTrailDataset = this.globalService.tasksMitigatingActionRiskM2MAuditTrailDataset.concat(this.globalService.buildAuditTrail_0115({}, element, 'tblMitigatingActionRiskM2M'));
    });
    this.globalService.tasks = cloneDeep(this.tasks);
    this.dialogRef.close({ taskDeleted: true });
  }

  goToRiskAnalysis_0466(event: any) {
    let row = event.data;

    let datasets: datasetGet[] = [];

    let teamDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        riskAssessmentID: row.riskAssessmentID
      },
      storedProcedure: "RiskAssessmentTeamMember"
    };
    let riskAssessment: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        riskAssessmentID: row.riskAssessmentID
      },
      storedProcedure: "RiskAssessment"
    };
    // DAM or Reg
    let risk: datasetGet = {
      parameters: {
        iD: null,
        clientID: this.globalService.clientID,
        riskAssessmentID: row.riskAssessmentID,
        riskID: null,
        userID: this.globalService.userID,
      },
      storedProcedure: "RiskRiskAssessmentM2O"
    };

    datasets.push(teamDataset, riskAssessment, risk);
    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Risk Record Data", datasets);

    this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      this.armService.teamDataSource.data = fromDB[0];
      this.armService.teamTableList = fromDB[0];
      this.armService.selectedRiskAssessment = fromDB[1][0];
      this.armService.selectedRiskAssessment["permissionName"] = row.permissionName;
      this.globalService.selectedRiskAssessment = fromDB[1][0];
      this.globalService.selectedRiskAssessment["permissionName"] = row.permissionName;
      this.registerService.selectedRiskForAnalysis = row;
      this.registerService.selectedRiskForAnalysisTeam = fromDB[0];
      this.registerService.navigateMitigatingActionsRegister = true;
      this.armService.selectedRiskAssessmentAssociatedRisks = fromDB[2];
      //Was using the linking table iD when setting the selected Risk
      let riskID = row.riskID;
      row.iD = riskID;
      this.armService.setGlobalSelectedRisk_0455(row);
      this.router.navigate(['/register/risk-record']);
      this.dialogRef.close();
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get data for Risk Record");
      window.alert(userErrMsg)
    });
  }
}
