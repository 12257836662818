import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class HeatmapService {

  risksList: any[] = [];
  initialRisksPerCell: any = [];
  initialRisksPerCell2: any[] = [];
  residualRisksPerCell: any = [];
  risksToDisplay: any = [];
  xRenderPos: any;
  yRenderPos: any;
  collisionPointsArray: any = [];
  tooltipVisible = false;
  selectedRiskTooltip: any[] = [];
  selectedRiskAssessmentTypeID: any;
  width: any;
  height: any;
  heatmapHeight: any;
  heatmapWidth: any;
  riskPointRadius: any;
  heatmapCellHeight: any;
  heatmapCellWidth: any;
  yCanvas: any;
  xCanvas: any
  canvasContext: any;
  context: any;
  initialRiskClick: boolean = false;
  heatmapContainerWidth: any;
  heatmapContainerHeight: any;
  displayInfoTooltip: boolean = false;
  alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  riskDisplayFormat: any[] = [
    [{ x: .5, y: .5 }],
    [{ x: .3, y: .5 }, { x: .7, y: .5 }],
    [{ x: .2, y: .5 }, { x: .5, y: .5 }, { x: .8, y: .5 }],
    [{ x: .3, y: .3 }, { x: .7, y: .3 }, { x: .3, y: .7 }, { x: .7, y: .7 }],
    [{ x: .3, y: .3 }, { x: .7, y: .3 }, { x: .5, y: .5 }, { x: .3, y: .7 }, { x: .7, y: .7 }],
    [{ x: .2, y: .3 }, { x: .5, y: .3 }, { x: .8, y: .3 }, { x: .2, y: .7 }, { x: .5, y: .7 }, { x: .8, y: .7 }],
    [{ x: .2, y: .2 }, { x: .5, y: .2 }, { x: .8, y: .2 }, { x: .5, y: .5 }, { x: .2, y: .8 }, { x: .5, y: .8 }, { x: .8, y: .8 }],
    [{ x: .2, y: .2 }, { x: .5, y: .2 }, { x: .8, y: .2 }, { x: .25, y: .5 }, { x: .65, y: .5 }, { x: .2, y: .8 }, { x: .5, y: .8 }, { x: .8, y: .8 }],
    [{ x: .2, y: .2 }, { x: .5, y: .2 }, { x: .8, y: .2 }, { x: .2, y: .5 }, { x: .5, y: .5 }, { x: .8, y: .5 }, { x: .2, y: .8 }, { x: .5, y: .8 }, { x: .8, y: .8 }]
  ];
  numberOfHorixontalLines: number = 5;
  yAxisTickArrayIndex: number = 0;
  yAxisTicks: any[] = [
    ['A', 'B', 'C', 'D', 'E'],
    ['1', '2', '3', '4', '5', '6', '8', '9', '10', '12', '15', '16', '20', '25'],
    ['1', '2', '3', '4', '5']
  ]
  showResidualRisks = true;
  showPlannedFutureState = false;
  dropdownDisplayValue: string = 'Risk Treatment Plan';
  displayHeatmapLegend: boolean = false;
  cellColours = [];
  dashboardView: boolean = false;

  constructor() {

  }


  tooltipPopulation(event: any, filteredRisksList: any[]) {
    let xMousePos = event.clientX - (this.xCanvas + this.heatmapContainerWidth * .1);
    let yMousePos = this.heatmapContainerHeight * .9 - (event.clientY - this.yCanvas);
    let x = [];
    let yUpperRange = (this.heatmapContainerHeight * .9) * .3;
    let yLowerRange = (this.heatmapContainerHeight * .9) * .7;
    let xLeftRange = (this.heatmapContainerWidth * -0.1);
    let xRightRange = (this.heatmapContainerWidth * -0.05);
    if ((yMousePos > yUpperRange && yMousePos < yLowerRange) && (xMousePos > xLeftRange && xMousePos < xRightRange) && (this.selectedRiskAssessmentTypeID > 1 && this.selectedRiskAssessmentTypeID < 5)) {
      this.displayInfoTooltip = true;
    } else {
      this.displayInfoTooltip = false;
    }

    let yUpperRangeLegend = (this.heatmapContainerHeight * .9) * .9;
    let yLowerRangeLegend = (this.heatmapContainerHeight * .9) * .98;
    if ((yMousePos > yUpperRangeLegend && yMousePos < yLowerRangeLegend) && (xMousePos > xLeftRange && xMousePos < xRightRange)) {
      this.displayHeatmapLegend = true;
    } else {
      this.displayHeatmapLegend = false;
    }

    this.tooltipVisible = false;
    for (var i = 0; i < this.collisionPointsArray.length; i++) {
      xLeftRange = (this.collisionPointsArray[i].x) - this.riskPointRadius;
      xRightRange = (this.collisionPointsArray[i].x) + this.riskPointRadius;
      yUpperRange = (this.collisionPointsArray[i].y) - this.riskPointRadius;
      yLowerRange = (this.collisionPointsArray[i].y) + this.riskPointRadius;

      if ((xMousePos >= xLeftRange && xMousePos <= xRightRange) && (yMousePos >= yUpperRange && yMousePos <= yLowerRange)) {
        this.tooltipVisible = true;
        x = filteredRisksList.filter(element => element.iD == this.collisionPointsArray[i].risk_ID);
        this.selectedRiskTooltip = x;
        this.xRenderPos = event.clientX + this.riskPointRadius;

        if (yMousePos > this.heatmapHeight * 0.5) {
          this.yRenderPos = event.clientY + this.riskPointRadius;
        } else {
          let heatmapRiskToolTipHeight = document.getElementById('heatmap-risk-tooltip')!.offsetHeight;
          this.yRenderPos = event.clientY - this.riskPointRadius - heatmapRiskToolTipHeight;
        }
        break;
      }
    }
  }

  drawHeatmap(riskAssessmentTypeID: number) {
    var fontSize = (this.heatmapWidth * 0.02 | 0) + "px Lato"
    this.numberOfHorixontalLines = 5;
    this.yAxisTickArrayIndex = 0;
    var colorArrayStartIndex = 0;
    var yAxisTickInterval = .2;
    var yAxisTickIntervalStart = -.9;
    var yaxisLabel = "OCCURRENCE x DETECTION MATRIX"
    this.selectedRiskAssessmentTypeID = riskAssessmentTypeID;
    if (riskAssessmentTypeID === 5) {
      this.numberOfHorixontalLines = 14;
      this.yAxisTickArrayIndex = 1;
      yAxisTickInterval = .071;
      yAxisTickIntervalStart = -.9643;
      colorArrayStartIndex = 5;
      yaxisLabel = "OCCURRENCE x DETECTION"
    } else if (riskAssessmentTypeID === 6 || riskAssessmentTypeID === 1) {
      yaxisLabel = "OCCURRENCE";
      this.yAxisTickArrayIndex = 1;
    }
    this.heatmapCellHeight = this.heatmapHeight * (1 / this.numberOfHorixontalLines);
    // Draw blank board
    this.context.clearRect(0, 0, this.heatmapContainerWidth, this.heatmapContainerHeight);
    this.context.fillStyle = "#EAEAEA";
    this.context.fillRect((this.heatmapContainerWidth * .05), 0, (this.heatmapContainerWidth * .05), (this.heatmapContainerHeight * .9));
    this.context.fillStyle = "#0c3c60";
    this.context.beginPath();
    this.context.font = fontSize;
    this.context.textBaseline = "middle";
    this.context.textAlign = 'center';
    this.context.fillStyle = '#0c3c60';
    if (this.dashboardView) {
      this.context.arc((this.heatmapContainerWidth * .02), (this.heatmapContainerHeight * .05), 10, 0, 2 * Math.PI, false);
      this.context.fill();
      this.context.fillStyle = '#ffffff';
      this.context.fillText('i', (this.heatmapContainerWidth * .02), (this.heatmapContainerHeight * .05));
      this.context.closePath();
    }
    this.context.font = fontSize;
    this.context.save();
    this.context.rotate(270 * Math.PI / 180);
    this.context.fillStyle = '#0c3c60';
    this.context.textAlign = 'center';
    this.context.fillText(yaxisLabel, this.heatmapHeight * -.5, (this.heatmapContainerWidth * 0.03));
    for (let i = 0; i < this.numberOfHorixontalLines; i++) {
      this.context.fillText(this.yAxisTicks[this.yAxisTickArrayIndex][i], this.heatmapHeight * (yAxisTickIntervalStart + (yAxisTickInterval * i)), (this.heatmapContainerWidth * .08));
    }

    this.context.restore();
    this.context.fillStyle = "#EAEAEA";
    this.context.fillRect((this.heatmapContainerWidth * .1), (this.heatmapContainerHeight * .9), this.heatmapWidth, (this.heatmapContainerHeight * .05));
    this.context.textAlign = 'center';
    this.context.fillStyle = "black";
    this.context.fillText("Negligible", (this.heatmapContainerWidth * 0.1) + this.heatmapWidth * .1, (this.heatmapContainerHeight * .93));
    this.context.fillText("Minor", (this.heatmapContainerWidth * 0.1) + this.heatmapWidth * .3, (this.heatmapContainerHeight * .93));
    this.context.fillText("Moderate", (this.heatmapContainerWidth * 0.1) + this.heatmapWidth * .5, (this.heatmapContainerHeight * .93));
    this.context.fillText("Major", (this.heatmapContainerWidth * 0.1) + this.heatmapWidth * .7, (this.heatmapContainerHeight * .93));
    this.context.fillText("Critical", (this.heatmapContainerWidth * 0.1) + this.heatmapWidth * .9, (this.heatmapContainerHeight * .93));
    this.context.fillStyle = '#0c3c60';
    this.context.fillText("SEVERITY", (this.heatmapContainerWidth * 0.1) + this.heatmapWidth * .5, (this.heatmapContainerHeight * .98));
    //shift the x-axis by the total amount of the y-axis labels
    this.context.transform(1, 0, 0, 1, (this.heatmapContainerWidth * .1), 0);

    for (let row = colorArrayStartIndex; row < (this.numberOfHorixontalLines + colorArrayStartIndex); row++) {
      let count = 0;
      for (let col in this.cellColours[row]) {
        let cellColor = this.cellColours[row][col];
        let x1 = this.heatmapCellWidth * count;
        let y1 = this.heatmapHeight / this.numberOfHorixontalLines * (row - colorArrayStartIndex);
        this.context.fillStyle = cellColor;
        this.context.fillRect(x1, y1, this.heatmapCellWidth, this.heatmapCellHeight);
        count++;
      }
    }
    for (let i = 1; i <= 5; i++) {
      //Vertical Grid Lines
      this.context.beginPath();
      this.context.setLineDash([0, 0]);
      this.context.moveTo((this.heatmapWidth / 5) * i, 0);
      this.context.lineTo((this.heatmapWidth / 5) * i, this.heatmapHeight);
      this.context.lineWidth = 1;
      this.context.strokeStyle = "white";
      this.context.closePath();
      this.context.stroke();
    }
    for (let i = 1; i <= this.numberOfHorixontalLines; i++) {
      //Horizontal Grid Lines
      this.context.beginPath();
      this.context.setLineDash([0, 0]);
      this.context.moveTo(0, (this.heatmapHeight / this.numberOfHorixontalLines) * i);
      this.context.lineTo(this.heatmapWidth, (this.heatmapHeight / this.numberOfHorixontalLines) * i);
      this.context.lineWidth = 1;
      this.context.strokeStyle = "white";
      this.context.closePath();
      this.context.stroke();
    }
    this.calculateRiskPoints(this.risksToDisplay);
    // The canvas must be transformed back to the original origin to allow the heatmap to be redrawn
    this.context.transform(1, 0, 0, 1, -(this.heatmapContainerWidth * .1), 0);
  }

  determineRisksPerCell(displayArray: any[]) {
    this.initialRisksPerCell = [];
    this.residualRisksPerCell = [];
    if (this.selectedRiskAssessmentTypeID === 5) {
      for (let row = 0; row < 14; row++) {
        for (let col = 1; col <= 5; col++) {
          this.initialRisksPerCell.push(displayArray.filter(risk => risk.occurrence * risk.detectability == parseInt(this.yAxisTicks[1][row]) && risk.severity == col));
          this.residualRisksPerCell.push(displayArray.filter(risk => risk.residualOccurrence * risk.residualDetectability == parseInt(this.yAxisTicks[1][row]) && risk.residualSeverity == col));
        }
      }
    } else if (this.selectedRiskAssessmentTypeID === 6 || this.selectedRiskAssessmentTypeID === 1) {
      for (let row = 1; row <= 5; row++) {
        for (let col = 1; col <= 5; col++) {
          this.initialRisksPerCell.push(displayArray.filter(risk => risk.occurrence == row && risk.severity == col));
          this.residualRisksPerCell.push(displayArray.filter(risk => risk.residualOccurrence == row && risk.residualSeverity == col));
        }
      }
    } else {
      for (let row = 1; row <= 5; row++) {
        for (let col = 1; col <= 5; col++) {
          this.initialRisksPerCell.push(displayArray.filter(risk => risk.initialOccurrenceVsDetectability === row && risk.severity === col));
          this.residualRisksPerCell.push(displayArray.filter(risk => risk.residualOccurrenceVsDetectability === row && risk.residualSeverity === col));
        }
      }
    }
  }

  calculateRiskPoints(risks: any[]) {
    this.determineRisksPerCell(risks);
    this.collisionPointsArray = [];
    let cellWidth = this.heatmapWidth * 0.2;
    let cellHeight = this.heatmapHeight * 0.2;
    let initialNumberOfRisksInCell, residualNumberOfRisksInCell, combinedNumberOfRisksInCell;
    let initialRiskFormat, residualRiskFormat, numberOfInitialRisksToPlot;
    let currentCell = 0;
    let x, y;
    let currentRiskInCell;
    let risk_ID, riskDisplayID;
    let riskType, riskStatus, numberOfOverdueMitigatingActions, riskDecision;
    let numberCellRows = 5;
    let maxNumberRiskPerCell = 9;
    if (this.selectedRiskAssessmentTypeID === 5) {
      numberCellRows = 14;
      maxNumberRiskPerCell = 3;
      cellHeight = this.heatmapHeight * 1 / 14;
    }
    for (let row = 0; row < numberCellRows; row++) {
      for (let column = 0; column < 5; column++) {
        initialNumberOfRisksInCell = this.initialRisksPerCell[currentCell].length;
        residualNumberOfRisksInCell = this.residualRisksPerCell[currentCell].length;
        combinedNumberOfRisksInCell = initialNumberOfRisksInCell + residualNumberOfRisksInCell;
        numberOfInitialRisksToPlot = 0;
        if (combinedNumberOfRisksInCell < maxNumberRiskPerCell) {
          residualRiskFormat = combinedNumberOfRisksInCell;
          initialRiskFormat = combinedNumberOfRisksInCell;
        } else {
          residualRiskFormat = maxNumberRiskPerCell;
          initialRiskFormat = maxNumberRiskPerCell;
        }
        if (initialNumberOfRisksInCell > maxNumberRiskPerCell) { initialNumberOfRisksInCell = maxNumberRiskPerCell };
        if (residualNumberOfRisksInCell > maxNumberRiskPerCell) { residualNumberOfRisksInCell = maxNumberRiskPerCell };

        if (initialNumberOfRisksInCell > 0) {
          riskType = "Initial";
          riskStatus = "NA"
          numberOfOverdueMitigatingActions = 0;
          for (currentRiskInCell = 0; (currentRiskInCell < initialNumberOfRisksInCell && (currentRiskInCell) < maxNumberRiskPerCell); currentRiskInCell++) {
            x = (cellWidth * column) + (cellWidth * this.riskDisplayFormat[initialRiskFormat - 1][currentRiskInCell].x);
            y = (cellHeight * row) + (cellHeight * this.riskDisplayFormat[initialRiskFormat - 1][currentRiskInCell].y);
            risk_ID = this.initialRisksPerCell[currentCell][currentRiskInCell].iD;
            riskDisplayID = this.initialRisksPerCell[currentCell][currentRiskInCell].displayID;
            riskDecision = this.initialRisksPerCell[currentCell][currentRiskInCell].riskDecision;
            riskStatus = this.initialRisksPerCell[currentCell][currentRiskInCell].riskStatus;
            numberOfOverdueMitigatingActions = this.initialRisksPerCell[currentCell][currentRiskInCell].overdueActions;
            this.collisionPointsArray.push({ risk_ID, riskDisplayID, x, y, riskType, riskStatus, numberOfOverdueMitigatingActions, riskDecision });
          }
          numberOfInitialRisksToPlot = currentRiskInCell;
          if (combinedNumberOfRisksInCell < maxNumberRiskPerCell) {
            numberOfInitialRisksToPlot = currentRiskInCell;
          } else {
            numberOfInitialRisksToPlot = 0;
          }
        }
        if (residualNumberOfRisksInCell > 0) {
          riskType = "Residual";
          for (currentRiskInCell = 0; (currentRiskInCell < residualNumberOfRisksInCell && (currentRiskInCell + numberOfInitialRisksToPlot) < maxNumberRiskPerCell); currentRiskInCell++) {
            x = (cellWidth * column) + (cellWidth * this.riskDisplayFormat[residualRiskFormat - 1][currentRiskInCell + numberOfInitialRisksToPlot].x);
            y = (cellHeight * row) + (cellHeight * this.riskDisplayFormat[residualRiskFormat - 1][currentRiskInCell + numberOfInitialRisksToPlot].y);
            risk_ID = this.residualRisksPerCell[currentCell][currentRiskInCell].iD;
            riskDisplayID = this.residualRisksPerCell[currentCell][currentRiskInCell].displayID;
            riskStatus = this.residualRisksPerCell[currentCell][currentRiskInCell].riskStatus;
            numberOfOverdueMitigatingActions = this.residualRisksPerCell[currentCell][currentRiskInCell].overdueActions;
            this.collisionPointsArray.push({ risk_ID, riskDisplayID, x, y, riskType, riskStatus, numberOfOverdueMitigatingActions });
          }
        }
        currentCell++;
      }
    }
    this.drawRiskPoints();
  }

  drawRiskPoints() {
    let riskID: number;
    let indexOfInitialRisk: number;
    let indexOfResidualRisk: number;
    let residualRiskArray: any[] = this.collisionPointsArray.filter((element: any) => element.riskType === 'Residual');
    let initialRiskArray: any[] = this.collisionPointsArray.filter((element: any) => element.riskType === 'Initial');
    for (var i = 0; i < residualRiskArray.length; i++) {
      if (this.dropdownDisplayValue == 'Risk Treatment Plan') {
        riskID = residualRiskArray[i].risk_ID;
        indexOfInitialRisk = this.collisionPointsArray.findIndex((element: any) => (element.risk_ID === riskID && element.riskType === 'Initial'))
        if (indexOfInitialRisk > 0) {
          this.context.beginPath();
          if (residualRiskArray[i].riskStatus === 'Managed') {
            this.context.setLineDash([0, 0]);
          } else {
            this.context.setLineDash([this.heatmapCellWidth / 12, this.heatmapCellWidth / 12]);
          }
          this.context.moveTo(residualRiskArray[i].x, this.heatmapHeight - residualRiskArray[i].y);
          this.context.lineTo(this.collisionPointsArray[indexOfInitialRisk].x, this.heatmapHeight - this.collisionPointsArray[indexOfInitialRisk].y);
          this.context.lineWidth = 1;

          if (residualRiskArray[i].numberOfOverdueMitigatingActions > 0) {
            this.context.strokeStyle = "red";
          } else {
            this.context.strokeStyle = "black";
          }
          this.context.closePath();

          this.context.stroke();
        }
      }
      if (this.dropdownDisplayValue == 'Planned Risk Status' || this.dropdownDisplayValue == 'Risk Treatment Plan' || (this.dropdownDisplayValue == 'Current Risk Status' && residualRiskArray[i].riskStatus == 'Managed')) {
        this.renderSingleRiskPoint(residualRiskArray[i].x, residualRiskArray[i].y, "Residual", residualRiskArray[i].riskDisplayID, residualRiskArray[i].riskStatus, "Residual Risk", 0);
      }
    }
    for (var i = 0; i < initialRiskArray.length; i++) {
      indexOfResidualRisk = residualRiskArray.findIndex((element: any) => element.risk_ID == initialRiskArray[i].risk_ID)
      if (indexOfResidualRisk != -1) {
        initialRiskArray[i].riskStatus = residualRiskArray[indexOfResidualRisk].riskStatus
      }
      if ((this.dropdownDisplayValue == 'Risk Treatment Plan' || (this.dropdownDisplayValue == 'Planned Risk Status' && ((initialRiskArray[i].riskStatus == 'Reduce' && initialRiskArray[i].riskStatus != 'Managed') || initialRiskArray[i].riskDecision === "Accept"))
        || (this.dropdownDisplayValue == 'Current Risk Status' && ((initialRiskArray[i].riskDecision === "Reduce" && initialRiskArray[i].riskStatus != 'Managed') || initialRiskArray[i].riskDecision === "Accept"))
      ) && !(initialRiskArray[i].riskDecision === 'Eliminate' && initialRiskArray[i].riskStatus === 'Managed')) {
        this.renderSingleRiskPoint(initialRiskArray[i].x, initialRiskArray[i].y, "Initial", initialRiskArray[i].riskDisplayID, initialRiskArray[i].riskStatus, initialRiskArray[i].riskDecision, initialRiskArray[i].numberOfOverdueMitigatingActions);
      }
    }
  }

  renderSingleRiskPoint(x: number, y: number, riskType: string, riskID: number, riskStatus: string, riskDecision: string, numberOfOverdueMitigatingActions: number) {
    let displayRiskPoint = true;
    var radius = this.riskPointRadius;
    var fontSize = (this.context.canvas.width * 0.018 | 0) + "px Lato";
    var riskPointBackground = "black";
    var riskPointColor = "white";
    if (riskType == "Initial" && riskStatus === "Managed" && riskDecision !== "Accept") {
      riskPointBackground = "#89CFF0";
      riskPointColor = "black";
    }
    if (riskType == "Residual" && riskStatus != "Managed") {
      riskPointBackground = "white";
      riskPointColor = "black";
    }
    if (riskType == "Initial" && riskStatus !== "Managed" && numberOfOverdueMitigatingActions > 0) {
      riskPointColor = "red";
    }
    if (displayRiskPoint) {
      this.context.beginPath();
      this.context.font = fontSize;
      this.context.textBaseline = "middle";
      this.context.textAlign = 'center';
      this.context.fillStyle = riskPointBackground;
      if (riskDecision !== "Eliminate") {
        this.context.arc(x, this.heatmapHeight - y, radius, 0, 2 * Math.PI, false);
        this.context.fill();
      } else {
        this.context.rect(x - radius, this.heatmapHeight - y - radius, radius * 2, radius * 2);
        this.context.fill();
        if (numberOfOverdueMitigatingActions > 0) {
          this.context.setLineDash([0, 0]);
          this.context.strokeStyle = "red";
          this.context.lineWidth = radius / 10;
          this.context.strokeRect(x - radius, this.heatmapHeight - y - radius, radius * 2, radius * 2);
        }
      }
      this.context.fillStyle = riskPointColor;
      this.context.fillText(riskID, x, this.heatmapHeight - y);
      this.context.closePath();
    }
  }

  selectRisksToDisplay(row: any, filteredRisksList: any) {
    this.tooltipVisible = false;
    this.selectedRiskTooltip = [];

    if (!this.initialRiskClick) {
      this.initialRiskClick = true;
      this.collisionPointsArray = [];
      this.tooltipVisible = false;
      this.risksToDisplay = [];
      row.clicked = true;
      this.risksToDisplay.push(row);
      this.drawHeatmap(this.selectedRiskAssessmentTypeID);
    } else {
      if (row.clicked && this.risksToDisplay.some((risk: any) => risk.iD === row.iD)) {
        row.clicked = false;
        for (var j = 0; j < this.risksToDisplay.length; j++) {
          if (this.risksToDisplay[j].iD === row.iD) {
            this.risksToDisplay.splice(j, 1);
            if (this.risksToDisplay.length === 0) {
              this.risksToDisplay = filteredRisksList;
              this.initialRiskClick = false;
            }
            this.collisionPointsArray = [];
            break;
          }
        }
        this.drawHeatmap(this.selectedRiskAssessmentTypeID);
      } else {
        row.clicked = true;
        this.risksToDisplay.push(row);
        this.collisionPointsArray = [];
        this.drawHeatmap(this.selectedRiskAssessmentTypeID);
      }
    }
  }

  getRiskIdLabel_XXXX(riskIndex: number) {
    let suffixIndex = 0;
    let prefix = ~~(riskIndex / 26);
    let riskIdLabel: string;

    if (prefix !== 0) {
      suffixIndex = riskIndex - (prefix * 26);
      riskIdLabel = this.alphabet[prefix - 1] + this.alphabet[suffixIndex];
    } else {
      riskIdLabel = this.alphabet[riskIndex];
    }
    return riskIdLabel;
  }
}
