<!-- <div class="risk-profile-dashboard" (click)="globalService.sidebarOpen = false;"> -->
<div class="dashboard">
    <div class="risk-dashboard-charts" *ngIf=" ((router.url !== '/dashboard/dashboard-heatmap') && (router.url !== '/dashboard/dashboard-risk-assessments'))">
        <div class="risk-profile-header">
            <h4 id="risk-profile-dashboard-header-title">Risk Dashboard Charts
            </h4>
        </div>
        <div class="risk-profile-filter-container">
            <div class="filter-item">
                <p>Severity Category</p>
                <dx-select-box [(value)]="selectedFilter.category" [items]="filterConditions.categories"
                    (onValueChanged)="updateDashboard()" [showClearButton]="true">
                </dx-select-box>
            </div>
            <div class="filter-item">
                <p>Entity</p>
                <dx-select-box [(value)]="selectedFilter.entity" [items]="filterConditions.entities"
                    (onValueChanged)="updateDashboard()" [showClearButton]="true">
                </dx-select-box>
            </div>
            <div class="filter-item">
                <p>Impacted Functional Area</p>
                <dx-select-box [(value)]="selectedFilter.impactedFunctionalArea"
                    [items]="filterConditions.impactedFuncationalAreas" (onValueChanged)="updateDashboard()"
                    [showClearButton]="true">
                </dx-select-box>
            </div>
            <div class="filter-item">
                <p>Business Process</p>
                <dx-select-box [(value)]="selectedFilter.impactedBusinessProcess"
                    [items]="filterConditions.impactedBusinessProcess" (onValueChanged)="updateDashboard()"
                    [showClearButton]="true">
                </dx-select-box>
            </div>
            <div class="filter-item">
                <p>Year</p>
                <dx-select-box [(value)]="selectedFilter.year" [items]="filterConditions.availableYears"
                    (onValueChanged)="updateDashboard()" [showClearButton]="true">
                </dx-select-box>
            </div>
        </div>
        <app-dashboard-risk-profile *ngIf="router.url === '/dashboard/dashboard-risk-profile'"
            [filteredRisks]="filteredRisks" [riskCategories]="riskCategories">
        </app-dashboard-risk-profile>
        <app-dashboard-risk *ngIf="router.url === '/dashboard/dashboard-risks'" [filteredRisks]="filteredRisks"
            [riskCategories]="riskCategories">
        </app-dashboard-risk>
        <app-dashboard-risk-assessment *ngIf="router.url === '/dashboard/dashboard-risk-assessments'"
            [filteredRisks]="filteredRisks" [riskCategories]="riskCategories">
        </app-dashboard-risk-assessment>
        <app-dashboard-mitigating-action *ngIf="router.url === '/dashboard/dashboard-mitigating-actions'"
            [filteredRisks]="filteredRisks" [riskCategories]="riskCategories">
        </app-dashboard-mitigating-action>
        <!-- <app-dashboard-resource *ngIf="router.url !== '/dashboard/dashboard-resources'" [filteredRisks]="filteredRisks"
            [riskCategories]="riskCategories">
        </app-dashboard-resource> -->
    </div>
    <div *ngIf="router.url === '/dashboard/dashboard-heatmap' || router.url === '/dashboard/dashboard-risk-assessments'">
        <router-outlet></router-outlet>
    </div>
</div>