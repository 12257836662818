<div *ngIf="auditTrailExists" class="audit-trail">
    <div class="transaction-container creation" *ngIf="creationList">
        <div
            *ngIf="creationList[creationTableNameKeys[0]][creationEntryID][0].reason !== 'View Only 06/09/2021'; else viewOnly">
            <div class="transaction-header">
                <h3>Created by {{creationList[creationTableNameKeys[0]][creationEntryID][0].completedBy}} &nbsp;</h3>
                <h3 class="transaction-date">{{creationList[creationTableNameKeys[0]][creationEntryID][0].dateCompleted
                    | date: 'MMMM d, y, h:mm:ss a' }}
                </h3>
            </div>
            <p><b>Justification: </b></p>
            <p *ngIf="creationList[creationTableNameKeys[0]][creationEntryID][0].reason">
                {{creationList[creationTableNameKeys[0]][creationEntryID][0].reason}}</p>
            <p *ngIf="!creationList[creationTableNameKeys[0]][creationEntryID][0].reason">
                No justification provided</p>
            <div class="transaction-content" *ngFor="let creationTableNameKey of creationTableNameKeys">
                <div class="audit-trail-table">
                    <div class="table-title">
                        <h3 class="uppercase">
                            {{lookupTable[creationTableNameKey][creationTableNameKey]}}
                        </h3>
                    </div>
                    <div class="table-content"
                        *ngFor="let entryIDkeys of creationList[creationTableNameKey] | keyvalue; let i = index">
                        <table class="table-container">
                            <tr class="audit-trail-entry">
                                <td class="field-id">
                                    <div class="statement-type"
                                        [ngClass]="{'insert': creationList[creationTableNameKey][entryIDkeys.key][0].statementType === 'INSERT',
                                        'update': creationList[creationTableNameKey][entryIDkeys.key][0].statementType === 'UPDATE',
                                        'delete': creationList[creationTableNameKey][entryIDkeys.key][0].statementType === 'DELETE'}">
                                        <span>{{creationList[creationTableNameKey][entryIDkeys.key][0].statementType}}</span>
                                    </div>
                                </td>
                                <td class="field-value">
                                    ID = {{entryIDkeys.key}}
                                </td>
                            </tr>
                            <tr class="audit-trail-entry"
                                *ngFor="let element of creationList[creationTableNameKey][entryIDkeys.key];">
                                <td class="field-id"
                                    [ngStyle]="{'display': (element.value !== null && element.value.length !== 0) ? 'flex' : 'none' }">
                                    {{lookupTable[element.tableName][element.fieldName]}}
                                </td>
                                <td class="field-value"
                                    [ngStyle]="{'display': (element.value !== null && element.value.length !== 0) ? 'flex' : 'none' }">
                                    {{element.value}}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #viewOnly>
            <div class="transaction-header">
                <h3>
                    {{creationList[creationTableNameKeys[0]][creationEntryID][0].value}}
                    &nbsp;
                </h3>
                <h3 class="transaction-date">
                    {{creationList[creationTableNameKeys[0]][creationEntryID][0].dateCompleted
                    | date: 'MMMM d, y, h:mm:ss a' }}
                </h3>
            </div>
        </ng-template>
    </div>
    <hr />
    <div *ngFor="let transactionIDKey of transactionIDKeys">
        <div class="transaction-container">
            <div *ngFor="let tableKey of groupedByTransactionAndTableName[transactionIDKey] | keyvalue; let j = index;">
                <div
                    *ngFor="let entryIDkey of groupedByTransactionAndTableName[transactionIDKey][tableKey.key] | keyvalue; let i = index;">
                    <div
                        *ngIf="groupedByTransactionAndTableName[transactionIDKey][tableKey.key][entryIDkey.key][0].reason !== 'View Only 06/09/2021'; else viewOnly">
                        <div class="transaction-header" *ngIf="j == 0 && i==0">
                            <h3>Modification made by
                                {{groupedByTransactionAndTableName[transactionIDKey][tableKey.key][entryIDkey.key][0].completedBy}}
                                &nbsp;
                            </h3>
                            <h3 class="transaction-date">
                                {{groupedByTransactionAndTableName[transactionIDKey][tableKey.key][entryIDkey.key][0].dateCompleted
                                | date: 'MMMM d, y, h:mm:ss a' }}
                            </h3>
                        </div>
                        <p *ngIf="j == 0 && i==0"><b>Justification: </b></p>
                        <p
                            *ngIf="j == 0 && i==0 && groupedByTransactionAndTableName[transactionIDKey][tableKey.key][entryIDkey.key][0].reason">
                            {{groupedByTransactionAndTableName[transactionIDKey][tableKey.key][entryIDkey.key][0].reason}}
                        </p>
                        <p
                            *ngIf="j == 0 && i==0 && !groupedByTransactionAndTableName[transactionIDKey][tableKey.key][entryIDkey.key][0].reason">
                            No justification provided
                        </p>
                        <div class="transaction-content">
                            <div class="audit-trail-table">
                                <div class="table-title" *ngIf="i == 0">
                                    <h3 class="uppercase">
                                        {{lookupTable[tableKey.key][tableKey.key]}}
                                    </h3>
                                </div>
                                <div class="table-content">
                                    <table class="table-container">
                                        <tr class="audit-trail-entry">
                                            <td class="field-id">
                                                <div class="statement-type"
                                                    [ngClass]="{'insert': groupedByTransactionAndTableName[transactionIDKey][tableKey.key][entryIDkey.key][0].statementType === 'INSERT',
                                                'update': groupedByTransactionAndTableName[transactionIDKey][tableKey.key][entryIDkey.key][0].statementType === 'UPDATE',
                                                'delete': groupedByTransactionAndTableName[transactionIDKey][tableKey.key][entryIDkey.key][0].statementType === 'DELETE'}">
                                                    <span>{{groupedByTransactionAndTableName[transactionIDKey][tableKey.key][entryIDkey.key][0].statementType}}</span>
                                                </div>
                                            </td>
                                            <td class="field-value">
                                                ID = {{entryIDkey.key}}
                                            </td>
                                        </tr>
                                        <tr class="audit-trail-entry"
                                            *ngFor="let element of groupedByTransactionAndTableName[transactionIDKey][tableKey.key][entryIDkey.key]; let j = index">
                                            <td class="field-id"
                                                *ngIf="element.value !== null && element.value.length !== 0">
                                                {{lookupTable[element.tableName][element.fieldName]}}
                                            </td>
                                            <td class="field-value"
                                                *ngIf="element.value !== null && element.value.length !== 0">
                                                {{element.value}}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-template #viewOnly>
                        <div class="transaction-header" *ngIf="j == 0 && i==0">
                            <h3>
                                {{groupedByTransactionAndTableName[transactionIDKey][tableKey.key][entryIDkey.key][0].value}}
                                &nbsp;
                            </h3>
                            <h3 class="transaction-date">
                                {{groupedByTransactionAndTableName[transactionIDKey][tableKey.key][entryIDkey.key][0].dateCompleted
                                | date: 'MMMM d, y, h:mm:ss a' }}
                            </h3>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <hr />
    </div>
</div>
<div *ngIf="!auditTrailExists">
    <div class="banner notification">
        <fa-icon [icon]="exclamationTriangle"></fa-icon>
        <h3 >Audit Trail doesn't exist</h3>
    </div>
</div>