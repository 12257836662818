import { ErrorHandler, Injectable } from '@angular/core';
import { LogService } from './log.service';
import { environment } from '../environments/environment';

@Injectable()
export class LogErrorHandler implements ErrorHandler {
	constructor(private logService: LogService) { }

	handleError(error: Error) {
		// your custom error handling logic
		console.error(error)
		this.logService.logToBackendError(environment.applicationFELogging +'LogError', error.stack);
	}
}