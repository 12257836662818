<div class="dialog">
    <div class="dialog-header">
        <div class="header-text center">
            <h2>Controls</h2>
        </div>
        <div class="align-items-start">
            <div class="header-icon">
                <button mat-button class="icon-btn dialog-close-btn" (click)="dialogRef.close()">
                    <div class="icon">
                        <fa-icon [icon]="times"></fa-icon>
                    </div>
                </button>
            </div>
        </div>
    </div>
    <div class="dialog-content">
        <form [formGroup]="formGroup" class="no-margin">
            <mat-form-field class="padding-between" floatLabel="always">
                <mat-label>Control</mat-label>
                <textarea rows="1" matInput type="text" formControlName="control"
                    id="control-dialog-control-text-box"></textarea>
            </mat-form-field>
            <mat-form-field class="padding-between" floatLabel="always">
                <mat-label>Control Reference</mat-label>
                <textarea rows="1" matInput type="text" formControlName="controlREF"
                    id="control-dialog-controlREF-text-box"></textarea>
            </mat-form-field>
            <mat-form-field class="padding-between" floatLabel="always">
                <mat-label>Control Description</mat-label>
                <textarea rows="3" matInput type="text" formControlName="controlDescription"
                    id="control-dialog-control-description-text-box"></textarea>
            </mat-form-field>
        </form>
        <div class="general-table-overflow" [hidden]="tableHidden" id="generalTableDialog">
            <dx-data-grid #grid id="generalTableDialog" class="controls-risk-table"
                [dataSource]="dataSource" keyExpr="iD" [showBorders]="true"
                [remoteOperations]="true" [allowColumnResizing]="true" [focusedRowEnabled]="true" 
                [height]="tableHeight" [width]="tableWidth" (onRowDblClick)="goToRiskAnalysisControlRiskDialog_0466($event)" >
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-paging [pageSize]="5"></dxo-paging>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 15, 20]" [showInfo]="true"
                    [showNavigationButtons]="true"></dxo-pager>
            </dx-data-grid>
            <div *ngIf="displayLoader" id="risk-register-mat-spinner" class="loader">
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </div>
    <div class="dialog-footer">
        <button mat-button class="btn done" (click)="dialogRef.close()" id="control-register-risks-dialog-close-btn">
            <p>Close</p>
        </button>
    </div>
</div>