import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { faCompressArrowsAlt, faDownload, faExclamationTriangle, faExpandArrows, faEye, faLink, faPlus, faTimesCircle, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { APIService, DialogService, GlobalService } from '@irlca/irlcore';
import { cloneDeep } from 'lodash';



@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent {

  @Input() selectedObject: any = {};
  @Input() editModeBool!: boolean;
  @Input() cancelChanges!: boolean;
  @Input() handleViewFile!: boolean;
  @Input() type!: string;
  @Input() expandedRichTextEditorBool!: boolean;
  @Output() openAttachmentsMasterDetail: EventEmitter<string> = new EventEmitter();
  @Output() getLatestAttachments: EventEmitter<string> = new EventEmitter();
  masterViewHidden: boolean = true;
  expandedImage: boolean = false;
  selectedAttachment: any;
  attachmentColumns: string[] = ['iD', 'Link'];
  editAttachment: string[] = [];
  selectedImage!: string;
  selectedExpandedImage!: string;
  attachmentsList: any[] = [];
  tempAttachments: any[] = [];
  objectKeys: any[] = [];
  tempKeys: any[] = [];
  selectedAttachmentTitle!: string;
  copyText: string = "Copy URL";
  pdf: any;
  downloadedFlag: boolean = false;
  expandable = false;

  // icons
  plus = faPlus
  expandArrows = faExpandArrows
  compressArrowsAlt = faCompressArrowsAlt
  timesCircle = faTimesCircle
  link = faLink
  eye = faEye
  download = faDownload
  trashAlt = faTrashAlt
  exclamationTriangle = faExclamationTriangle
  
  constructor(public globalService: GlobalService, public apiService: APIService, public router: Router, public dialogService: DialogService) { }

  ngOnInit(){
    this.globalService.$taskAttachmentViewToggle.subscribe((value) => {
      if(value){
        this.expandedRichTextEditorBool = false
      }
    })
  }


  ngOnChanges() {
    if (this.editModeBool) {
      this.globalService.tempAttachments = cloneDeep(this.globalService.attachmentsList);
    }
    else {
      for (let index = 0; index < this.globalService.attachmentsList.length; index++) {
        this.globalService.attachmentsList[index].editMode = false;
      }
    }
    if (this.cancelChanges) {
      this.cancelAttachmentChanges_0449();
    }
    if (this.handleViewFile) {
      this.handleViewFile_0460(0);
    }
    this.expandedImage = false;
  }

  openAttachmentInNewTab(element : any) {
    element = (element.indexOf('://') === -1) ? 'http://' + element : element;
    window.open(element, '_blank')!.focus();
  }

  expandAttachmentsMasterDetail_0457(index: number = 0) { //may change
    if(this.type == 'task') {
      this.globalService.tasksMasterViewExpanded = false;
    }
    this.openAttachmentsMasterDetail.emit();
    this.expandedRichTextEditorBool = !this.expandedRichTextEditorBool;
    if (index < this.globalService.attachmentsList.length && index >= 0) {
      if (this.expandedRichTextEditorBool == true && !this.expandedImage) {
        this.selectAttachment_0448(this.globalService.attachmentsList[index]);
      }
      else {
        this.selectAttachment_0448(this.selectedAttachment);
      }
    }
    this.expandedImage = false;
  }

  selectAttachment_0448(attachment: any) {
    if (attachment) {
      this.selectedAttachment = attachment;
      if (this.selectedAttachment.attachmentType == "image/png") {
        this.selectedExpandedImage = "data:image/png;base64," + this.selectedAttachment.key;
      }
      else if (this.selectedAttachment.attachmentType == "image/jpeg") {
        this.selectedExpandedImage = "data:image/jpeg;base64," + this.selectedAttachment.key;
      }
      else if (this.selectedAttachment.attachmentType == "application/pdf") {
        this.pdf = this.base64ToArrayBuffer_0139(this.selectedAttachment.key);
      }
    }
  }

  removeAttachment_0451(index: number) {
    if (index < this.globalService.attachmentsList.length && index >= 0 && this.editModeBool) {
      if (this.globalService.attachmentsList[index].iD > 0) {
        if (this.selectedAttachment == this.globalService.attachmentsList[index]) {
          this.selectAttachment_0448(0);
        }
        if (this.type == "task") {
          this.globalService.attachmentsToDelete.push({
            iD: this.globalService.attachmentsList[index].iD,
            clientID: this.globalService.clientID,
            attachmentID: this.globalService.attachmentsList[index].attachmentID,
            taskID: this.globalService.attachmentsList[index].taskID,
            statementType: "DELETE"
          });
        } else {
          this.globalService.attachmentsToDelete.push({
            iD: this.globalService.attachmentsList[index].iD,
            riskAssessmentID: this.selectedObject.iD,
            attachmentID: this.globalService.attachmentsList[index].attachmentID,
            statementType: "DELETE"
          });
        }
        this.globalService.attachmentKeys.splice(index, 1);
        this.globalService.attachmentsList.splice(index, 1);
        this.globalService.attachmentDescriptions.splice(index, 1);
        this.globalService.attachmentsList = [...this.globalService.attachmentsList];
      } else {
        let displayedIndex = this.globalService.attachmentsToAdd.findIndex((data : any) => data[0][0].data[0].attachmentDescription === this.globalService.attachmentsList[index].attachmentDescription);
        if (displayedIndex >= 0) {
          this.globalService.attachmentsToAdd.splice(displayedIndex, 1);
          this.globalService.attachmentKeys.splice(index, 1);
          this.globalService.attachmentsList.splice(index, 1);
          this.globalService.attachmentDescriptions.splice(index, 1);
          this.globalService.attachmentsList = [...this.globalService.attachmentsList];
        }
      }
    }
  }

  cancelAttachmentChanges_0449() {
    if (this.editModeBool) {
      this.globalService.attachmentsList = this.tempAttachments;
      this.objectKeys = this.tempKeys;
      for (let index = 0; index < this.globalService.attachmentsList.length; index++) {
        this.globalService.attachmentsList[index].editMode = false;
      }
    }
  }

  navigateToVersionControl_0409() {
    this.router.navigate(['/risk-assessment/risk-assessment-version-control']);
  }

  backToRiskAssessmentMasterDetail_XXXX() {
    this.router.navigate(['/risk-assessment']);
  }

  turnOnEditMode() {// may change 
    this.globalService.tempAttachments = cloneDeep(this.globalService.attachmentsList);
  }

  toggleEditAttachment_0452(index: number) {
    if (index < this.globalService.attachmentsList.length && index >= 0) {
      if (this.editModeBool) {
        if (!this.globalService.attachmentsList[index].editMode) {
          this.globalService.attachmentsList[index].editMode = !this.globalService.attachmentsList[index].editMode;
          this.globalService.attachmentDescriptions[index] = cloneDeep(this.globalService.attachmentsList[index].attachmentDescription);
        }
      }
    }
  }

  handleFileDownload_0456(attachment: any) { //awaiting approval
    this.downloadedFlag = false;
    if (attachment) {
      let postfix = '';
      let prefix = ''
      if (attachment.attachmentType == "application/pdf") {
        prefix = "data:application/pdf;base64,"
        postfix = ".pdf"
      }
      else if (attachment.attachmentType == "image/png") {
        prefix = "data:image/png;base64,"
        postfix = ".png"
      }
      else if (attachment.attachmentType == "image/jpeg") {
        prefix = "data:image/jpeg;base64,"
        postfix = ".jpeg"
      }
      else if (attachment.attachmentType == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        prefix = "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,"
        postfix = ".docx"
      }
      else if (attachment.attachmentType == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        prefix = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"
        postfix = ".xlsx"
      }
      else {
      }
      const source = prefix + attachment.key;
      const link = document.createElement("a");
      link.href = source;
      link.download = attachment.attachmentDescription + postfix
      link.click();
      this.downloadedFlag = true;
    }
  }

  copyURL_0461(index: number) {
    if (index < this.globalService.attachmentsList.length && index >= 0) {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = this.globalService.attachmentsList[index].key;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.copyText = "Copied!";
      setTimeout(() => {
        this.copyText = "Copy URL";
      }, 500);
    }
  }

  handleViewFile_0460(index: number) {
    if (index < this.globalService.attachmentsList.length && index >= 0) {
      this.selectedAttachment = this.globalService.attachmentsList[index];
      if (this.globalService.attachmentsList[index].attachmentType == "image/png") {
        this.expandedImage = !this.expandedImage;
        this.selectedAttachmentTitle = this.globalService.attachmentsList[index].attachmentDescription;
        this.selectedImage = "data:image/png;base64," + this.globalService.attachmentsList[index].key;
      }
      else if (this.globalService.attachmentsList[index].attachmentType == "image/jpeg") {
        this.expandedImage = !this.expandedImage;
        this.selectedAttachmentTitle = this.globalService.attachmentsList[index].attachmentDescription;
        this.selectedImage = "data:image/jpeg;base64," + this.globalService.attachmentsList[index].key;
      }
      else if (this.globalService.attachmentsList[index].attachmentType == "application/pdf") {
        this.expandedImage = !this.expandedImage;
        this.selectedAttachmentTitle = this.globalService.attachmentsList[index].attachmentDescription;
        this.pdf = this.base64ToArrayBuffer_0139(this.globalService.attachmentsList[index].key);
      }
    }
  }

  base64ToArrayBuffer_0139(base64 : string) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  openAddAttachmentDialog_0192() {
    let dialogConfigData: any = {
      panelClass: null,
      maxWidth: '40vh',
      minWidth: '40vw',
      data: {
        type: this.type,
        object: this.selectedObject
      }
    }
    const dialogRef = this.dialogService.openDialog_0088("AddAttachmentDialogComponent", dialogConfigData);
    dialogRef.afterClosed().subscribe((result : any)=> {
      if (result) {
        let isURL = false;
        let file = null;
        if (result[0].data[0].key != null) {
          isURL = true
        }
        if (result[2]) {
          file = result[2]
        }
        this.globalService.attachmentsToAdd.push([result, file]);
        this.globalService.attachmentsList.push({
          iD: -1,
          clientID: this.globalService.clientID,
          attachmentType: result[0].data[0].attachmentType,
          attachmentDescription: result[0].data[0].attachmentDescription,
          attachmentID: -1,
          editMode: false,
          isURL: isURL,
          key: result[0].data[0].key,
          keyID: null
        });

        this.globalService.attachmentDescriptions.push(result[0].data[0].attachmentDescription);
        this.globalService.attachmentsList = [...this.globalService.attachmentsList]
        //this.getLatestAttachments.emit();
      }
    })
  }
}
