import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APIService, datasetGet, DataToGet, GlobalService, IrlcoreAuthService } from '@irlca/irlcore';
import { Observable } from 'rxjs';
import { ArmService } from '../../../arm.service';
import { RiskAssessmentDialogComponent } from '../risk-assessment-dialog/risk-assessment-dialog.component';
import { map, startWith } from 'rxjs/operators';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-risk-dialog',
  templateUrl: './risk-dialog.component.html',
  styleUrls: ['./risk-dialog.component.scss']
})
export class RiskDialogComponent implements OnInit {

  riskForm!: FormGroup;
  newRiskAssessmentBool: boolean = false;
  currentDate: Date = new Date(Date.now());
  users: any[] = [];
  riskQuestions: any[] = [];
  functionalAreas: any[] = [];
  riskAssessmentTypes: any[] = [];
  entryID!: number;
  lastOrder!: number;
  itemGroups: any[] = [];
  filteredItemGroups!: Observable<any>;
  failureModes: any[] = [];
  filteredFailureModes!: Observable<any>;
  linkedFailureModes: any[] = [];
  riskAssessmentToolType: string = 'process';
  newFailureModeBool: boolean = false;
  newItemGroupBool: boolean = false;

  // icons
  times = faTimes;

  constructor(public dialogRef: MatDialogRef<RiskAssessmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder,
    public globalService: GlobalService, public auth: IrlcoreAuthService,
    public armService: ArmService, private apiService: APIService) { }


  async ngOnInit() {
    if (!this.globalService.configurableParameters.psfmInRiskTableRiskAssessment) {
      this.riskForm = this.createRiskFormGroupWithoutItemGroup_0410();
    } else {
      this.riskForm = this.createRiskFormGroupWithItemGroup_0410();
      this.prepareDataForGetRiskDialog_0190();
    }

    // DOC Comment: Removed until Risk Assessment Tool is used again 
    // if (this.armService.selectedRiskAssessment.riskAssessmentToolID) {
    //   this.riskAssessmentToolType = this.armService.determineRiskAssessmentTool_XXXX(this.armService.selectedRiskAssessment.riskAssessmentToolID);
    // } else {
    //   this.riskAssessmentToolType = 'process';
    // }

    this.entryID = this.data.entryID;
    this.lastOrder = this.data.lastOrder;
  }

  prepareDataForGetRiskDialog_0190() {
    let datasets: datasetGet[] = [];

    let itemGroupDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        riskAssessmentID: this.data.selectedRiskAssessmentID
      },
      storedProcedure: "ItemGroup"
    };

    let failureModeDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        riskAssessmentID: this.data.selectedRiskAssessmentID
      },
      storedProcedure: "FailureMode"
    };

    datasets.push(itemGroupDataset, failureModeDataset);
    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Risk Assessment Dialog", datasets);

    this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      this.itemGroups = fromDB[0];
      this.itemGroups = this.itemGroups.concat(this.data.newItemGroups);
      this.failureModes = fromDB[1];
      this.failureModes = this.failureModes.concat(this.data.newFailureModes);

      this.filteredItemGroups = this.riskForm.get('itemGroup')!.valueChanges
        .pipe(
          startWith(''),
          map(value => this.filterItemGroups_0411(value))
        );
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Risk Dialog Data");
      window.alert(userErrMsg)
    });
  }

  createRiskFormGroupWithoutItemGroup_0410() {
    if (this.data.selectedRisk) {
      return this.fb.group({
        failureCause: [this.data.selectedRisk.failureCause, Validators.required],
        failureEffect: [this.data.selectedRisk.failureEffect, Validators.required],
      });
    } else {
      return this.fb.group({
        failureCause: ['', Validators.required],
        failureEffect: ['', Validators.required],
      });
    }
  }

  createRiskFormGroupWithItemGroup_0410() {
    if (this.data.selectedRisk) {
      // set newEntry = this.data.selectedRisk

      return this.fb.group({
        failureCause: [this.data.selectedRisk.failureCause, Validators.required],
        failureEffect: [this.data.selectedRisk.failureEffect, Validators.required],
        itemGroup: [this.data.selectedRisk.itemGroupName, Validators.required],
        failureMode: [this.data.selectedRisk.failureMode, Validators.required],
      });
    } else {
      return this.fb.group({
        failureCause: ['', Validators.required],
        failureEffect: ['', Validators.required],
        itemGroup: ['', Validators.required],
        failureMode: ['', Validators.required],
      });
    }
  }

  prepareRiskForSave() {
    let failureCauseData: any;
    let failureEffectData: any;
    let riskData: any;
    let riskRiskAssessmentM2OData: any;
    let itemGroupData: any;
    let failureModeData: any;
    let failureModeID = null;
    let itemGroupID = null;
    let displayRisk = {};

    // DF Comment: Config Parameter - Are PS and FM required
    if (this.globalService.configurableParameters.psfmInRiskTableRiskAssessment) {
      let existingFailureModeID = this.getFailureModeID_0412(this.riskForm.value.failureMode);
      if (existingFailureModeID > 0) {
        failureModeID = existingFailureModeID;
      } else {
        if (this.data.dialogMode === 'EDIT' && this.data.selectedRisk.failureModeID < 0) {
          failureModeID = this.data.selectedRisk.failureModeID;
        } else {
          failureModeID = this.armService.failureModeEntryID;
        }

        this.newFailureModeBool = true;
      }

      let existingItemGroupID = this.getItemGroupID_0412(this.riskForm.value.itemGroup);
      if (existingItemGroupID > 0) {
        itemGroupID = existingItemGroupID;
      } else {
        if (this.data.dialogMode === 'EDIT' && this.data.selectedRisk.itemGroupID < 0) {
          itemGroupID = this.data.selectedRisk.itemGroupID;
        } else {
          itemGroupID = this.armService.itemGroupEntryID;
          this.lastOrder += 1;
        }

        this.newItemGroupBool = true;
      }
    }

    // DF Comment: If we have a new Failure Mode
    if (this.newFailureModeBool) {
      failureModeData = {
        iD: failureModeID,
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        itemGroupID: itemGroupID,
        itemGroupName: this.riskForm.value.itemGroup,
        failureMode: this.riskForm.value.failureMode,
        statementType: 'INSERT'
      }

      let failureModeIndex: number = this.armService.failureModeDataset.data.findIndex(failureMode => failureMode.iD === failureModeData.iD);
      if (failureModeIndex < 0) {
        this.armService.failureModeDataset.data.push(failureModeData);
        this.armService.failureModeEntryID -= 1;
      } else {
        this.armService.failureModeDataset.data[failureModeIndex].failureMode = this.riskForm.value.failureMode;
        this.armService.failureModeDataset.data[failureModeIndex].itemGroupID = itemGroupID;
        this.armService.failureModeDataset.data[failureModeIndex].itemGroupName = this.riskForm.value.itemGroup;
      }
    }

    // DF Comment: If we have a new Process Step
    if (this.newItemGroupBool) {
      itemGroupData = {
        iD: itemGroupID,
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        riskAssessmentID: this.data.selectedRiskAssessmentID,
        itemGroupName: this.riskForm.value.itemGroup,
        order: this.lastOrder,
        statementType: 'INSERT'
      }
      let itemGroupIndex: number = this.armService.itemGroupDataset.data.findIndex(itemGroup => itemGroup.iD === itemGroupData.iD);
      if (itemGroupIndex < 0) {
        this.armService.itemGroupDataset.data.push(itemGroupData);
        this.armService.itemGroupEntryID -= 1;
      } else {
        this.armService.itemGroupDataset.data[itemGroupIndex].itemGroupName = this.riskForm.value.itemGroup;
      }
    }

    if (this.data.dialogMode === 'ADD') {
      failureCauseData = {
        iD: this.entryID,
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        failureModeID: failureModeID,
        failureCause: this.riskForm.value.failureCause,
        occurrenceID: null,
        occurrenceJustification: null,
        detectabilityID: null,
        detectabilityJustification: null,
        residualOccurrenceID: null,
        residualOccurrenceJustification: null,
        residualDetectabilityID: null,
        residualDetectabilityJustification: null,
        statementType: "INSERT"
      };

      this.armService.failureCauseDataset.data.push(failureCauseData);

      failureEffectData = {
        iD: this.entryID,
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        failureModeID: failureModeID,
        failureEffect: this.riskForm.value.failureEffect,
        severityID: null,
        severityJustification: null,
        residualSeverityID: null,
        residualSeverityJustification: null,
        statementType: "INSERT"
      };

      this.armService.failureEffectDataset.data.push(failureEffectData);

      riskData = {
        iD: this.entryID,
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        riskREF: this.getRiskREF_XXXX()! + Math.abs(this.entryID),
        categoryID: null,
        failureCauseID: -201,
        failureCauseName: this.riskForm.value.failureCause,
        failureEffectID: -202,
        failureEffectName: this.riskForm.value.failureEffect,
        riskStatusID: 1,
        riskStatusName: 'For Analysis',
        riskDescription: "Description 1",
        riskOwnerID: null,
        isRejected: null,
        ownershipDate: null,
        periodicReviewFrequency: null,
        initialOccurrenceVsDetectability: null,
        initialRiskLevel: null,
        initialRPN: null,
        initialRS: null,
        riskDecision: null,
        riskDecisionJustification: null,
        residualOccurrenceVsDetectability: null,
        residualRiskLevel: null,
        residualRPN: null,
        residualRS: null,
        riskVersion: 1,
        isTemplate: false,
        approvedDate: null,
        itemGroupName: this.riskForm.value.itemGroup,
        failureMode: this.riskForm.value.failureMode,
        readyForApproval: false,
        managedDate: null,
        treatmentDecidedDate: null,
        statementType: "INSERT"
      };

      this.armService.riskDataset.data.push(riskData);

      riskRiskAssessmentM2OData = {
        iD: this.entryID,
        riskAssessmentID: this.data.selectedRiskAssessmentID,
        riskID: -99,
        statementType: "INSERT"
      };

      this.armService.riskRiskAssessmentM2ODataset.data.push(riskRiskAssessmentM2OData);

      displayRisk = {
        riskREF: "TBD",
        initialRiskLevel: null,
        failureEffect: this.riskForm.value.failureEffect,
        failureEffectID: failureEffectData.iD,
        failureCause: this.riskForm.value.failureCause,
        failureCauseID: failureCauseData.iD,
        itemGroupName: this.riskForm.value.itemGroup,
        itemGroupID: itemGroupID,
        failureMode: this.riskForm.value.failureMode,
        failureModeID: failureModeID,
        riskDecision: null,
        riskOwnerName: null,
        order: this.lastOrder,
      };
    } else {
      if (this.riskForm.controls['failureCause'].dirty || this.data.selectedRisk.failureModeID != failureModeID) {
        failureCauseData = {
          iD: this.data.selectedRisk.failureCauseID,
          clientID: this.globalService.clientID,
          entityID: this.globalService.entityID,
          failureModeID: failureModeID,
          failureCause: this.riskForm.value.failureCause,
          occurrenceID: this.data.selectedRisk.occurrenceID,
          occurrenceJustification: this.data.selectedRisk.occurrenceJustification,
          detectabilityID: this.data.selectedRisk.detectabilityID,
          detectabilityJustification: this.data.selectedRisk.detectabilityJustification,
          residualOccurrenceID: this.data.selectedRisk.residualOccurrenceID,
          residualOccurrenceJustification: this.data.selectedRisk.residualOccurrenceJustification,
          residualDetectabilityID: this.data.selectedRisk.residualDetectabilityID,
          residualDetectabilityJustification: this.data.selectedRisk.residualDetectabilityJustification,
          statementType: "UPDATE"
        };

        let failureCauseIndex: number = this.armService.failureCauseDataset.data.findIndex(failureCause => failureCause.iD === failureCauseData.iD);
        if (failureCauseIndex < 0) {
          this.armService.failureCauseDataset.data.push(failureCauseData);
        } else {
          this.armService.failureCauseDataset.data[failureCauseIndex].failureModeID = failureModeID;
          this.armService.failureCauseDataset.data[failureCauseIndex].failureCause = this.riskForm.value.failureCause;
        }
      }

      if (this.riskForm.controls['failureEffect'].dirty || this.data.selectedRisk.failureModeID != failureModeID) {
        failureEffectData = {
          iD: this.data.selectedRisk.failureEffectID,
          clientID: this.globalService.clientID,
          entityID: this.globalService.entityID,
          failureModeID: failureModeID,
          failureEffect: this.riskForm.value.failureEffect,
          severityID: this.data.selectedRisk.severityID,
          severityJustification: this.data.selectedRisk.severityJustification,
          residualSeverityID: this.data.selectedRisk.residualSeverityID,
          residualSeverityJustification: this.data.selectedRisk.residualSeverityJustification,
          statementType: "UPDATE"
        };

        let failureEffectIndex: number = this.armService.failureEffectDataset.data.findIndex(failureEffect => failureEffect.iD === failureEffectData.iD);
        if (failureEffectIndex < 0) {
          this.armService.failureEffectDataset.data.push(failureEffectData);
        } else {
          this.armService.failureEffectDataset.data[failureEffectIndex].failureModeID = failureModeID;
          this.armService.failureEffectDataset.data[failureEffectIndex].failureEffect = this.riskForm.value.failureEffect;
        }
      }

      displayRisk = {
        riskREF: this.data.selectedRisk.riskREF,
        initialRiskLevel: this.data.selectedRisk.initialRiskLevel,
        failureEffect: this.riskForm.value.failureEffect,
        failureEffectID: this.data.selectedRisk.failureEffectID,
        failureCause: this.riskForm.value.failureCause,
        failureCauseID: this.data.selectedRisk.failureCauseID,
        itemGroupName: this.riskForm.value.itemGroup,
        itemGroupID: itemGroupID,
        failureMode: this.riskForm.value.failureMode,
        failureModeID: failureModeID,
        riskDecision: this.data.selectedRisk.riskDecision,
        riskOwnerName: this.data.selectedRisk.riskOwnerName,
        order: this.lastOrder,
        currentRiskLevel: this.data.selectedRisk.currentRiskLevel,
        displayID: this.data.selectedRisk.displayID
      };
    }

    this.dialogRef.close({ displayRisk: displayRisk, newItemGroup: itemGroupData, newFailureMode: failureModeData });
  }

  //DF Comment: This needs to come from the database
  getRiskREF_XXXX() {
    let currentYear = new Date().getFullYear();
    switch (this.data.selectedRiskAssessmentTypeID) {
      case 1:
        return currentYear + '-' + 'CLIN';
      case 2:
        return currentYear + '-' + 'GNRL';
      case 3:
        return currentYear + '-' + 'PROD';
      case 4:
        return currentYear + '-' + 'VEND';
      case 5:
        return currentYear + '-' + 'GEND';
      case 6:
        return currentYear + '-' + 'GEWD';
      default:
        return;
    }
  }

  filterItemGroups_0411(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.itemGroups.filter(option => option.itemGroupName.toLowerCase().includes(filterValue));
  }

  filterFailureModes_0411(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.linkedFailureModes.filter(option => option.failureMode.toLowerCase().includes(filterValue));
  }

  getItemGroupID_0412(itemGroup: string): number {
    let itemGroupID = this.itemGroups.filter(itemGroupObj => itemGroupObj.itemGroupName === itemGroup);
    if (itemGroupID.length >= 1)
      return itemGroupID[0].iD;
    else
      return -1;
  }

  getFailureModeID_0412(failureMode: string): number {
    let failureModeID = this.failureModes.filter(failureModeObj => failureModeObj.failureMode === failureMode);
    if (failureModeID.length >= 1)
      return failureModeID[0].iD;
    else
      return -1;
  }

  getLinkedFailureModes_XXXX(itemGroupID: number) {
    this.linkedFailureModes = this.failureModes.filter(failureModeObj => failureModeObj.itemGroupID === itemGroupID);

    this.filteredFailureModes = this.riskForm.get('failureMode')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterFailureModes_0411(value))
      );
  }

  onFocusFailureMode() {
    let existingItemGroups = this.itemGroupExists();
    if (existingItemGroups.length > 0) {
      this.getLinkedFailureModes_XXXX(existingItemGroups[0].iD);
    } else {
      this.getLinkedFailureModes_XXXX(0);
    }
  }

  itemGroupExists(): any[] {
    return this.itemGroups.filter(itemGroup => this.riskForm.value.itemGroup.toLowerCase().trim() === itemGroup.itemGroupName.toLowerCase().trim())
  }
}
