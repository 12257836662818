import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { APIService, dataset, datasetGet, DataToGet, DataToPost, GlobalService } from '@irlca/irlcore';
import { ArmService } from '../../../arm.service';

@Component({
  selector: 'app-periodic-review-dialog',
  templateUrl: './periodic-review-dialog.component.html',
  styleUrls: ['./periodic-review-dialog.component.scss']
})
export class PeriodicReviewDialogComponent implements OnInit {

  form!: FormGroup;
  periodicReviewData: any;
  dataReceived: boolean = false;
  periodicReviewApprovers: any = [];
  tempDecision: any = null;
  tempUpdateRequired: any = null;
  versionControlAttachments: any[] = [];

  times = faTimes
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PeriodicReviewDialogComponent>, private fb: FormBuilder, public armService: ArmService,
    public globalService: GlobalService, public apiService: APIService) { }

  async ngOnInit() {
    await this.prepareDataForGetPeriodicReview();
    this.prepareDataForGetApprovers();
  }

  createFormGroup() {
    if (this.periodicReviewData) {
      this.tempDecision = this.periodicReviewData.periodicReviewDecision
      this.tempUpdateRequired = this.periodicReviewData.updateRequired
    }
    this.form = this.fb.group({
      periodicReviewDecision: [this.periodicReviewData.periodicReviewDecision],
      updateRequired: [this.periodicReviewData.updateRequired],
    });
    this.dataReceived = true;
  }

  async prepareDataForGetPeriodicReview() {
    let datasets: datasetGet[] = [];

    let periodicReview: datasetGet = {
      parameters: {
        riskAssessmentID: this.armService.selectedRiskAssessment.iD
      },
      storedProcedure: "PeriodicReview"
    };

    datasets.push(periodicReview);

    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Periodic Review", datasets);
    await this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      if (fromDB[0]) {
        this.periodicReviewData = fromDB[0][fromDB[0].length - 1];
      }
      this.createFormGroup();
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Periodic Review");
      window.alert(userErrMsg)
    });
  }

  async prepareDataForGetApprovers() {
    let datasets: datasetGet[] = [];

    let riskAssessmentTeamMemberDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        riskAssessmentID: this.armService.selectedRiskAssessment.iD
      },
      storedProcedure: 'RiskAssessmentTeamMember'
    }
    datasets.push(riskAssessmentTeamMemberDataset);

    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Risk Assessment Approvers ", datasets);
    await this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      this.periodicReviewApprovers = fromDB[0].filter((user: any) => user.isApprover);
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Risk Assessment Team Memebers");
      window.alert(userErrMsg)
    });
  }

  async prepareDataForPostPeriodicReview(iD: number = -1, isUpdate: boolean = true) {
    let datasets: dataset[] = [];
    let index: any;
    if (iD == -1) {
      if (this.periodicReviewData.attachmentID == null) {
        index = null;
      }
      else {
        index = this.periodicReviewData.attachmentID
      }
    }
    else {
      index = iD;
    }

    let periodicReviewDataset: dataset = {
      data: [{
        iD: this.periodicReviewData.iD,
        riskAssessmentID: this.armService.selectedRiskAssessment.iD,
        attachmentID: index,
        userID: this.globalService.userID,
        periodicReviewDecision: this.form.value.periodicReviewDecision,
        updateRequired: this.form.value.updateRequired,
        startDate: this.getCurrentDateTime_0516(),
        version: this.periodicReviewData.version,
        statementType: "UPDATE"
      }],
      dataset: "PeriodicReview"
    };

    let auditTrailList = this.globalService.buildAuditTrail_0115(
      {},
      periodicReviewDataset.data[0],
      "tblPeriodicReview"
    );
    datasets.push(periodicReviewDataset);
    let toDB = new DataToPost(this.globalService.clientID, this.globalService.userID, "Test reason", "Add Periodic Review", datasets, auditTrailList);
    await this.apiService.postDataToBackend_0051(this.apiService.urlForSinglePost, toDB).then((result: any) => {
      if (!isUpdate) {
        this.prepareDataForPostAttachmentESigs(index);
      }
      else {
        this.dialogRef.close(false);
      }
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Periodic Review");
      window.alert(userErrMsg)
    });
  }

  async savePDF() {
    // if(this.form.value.periodicReviewDecision!==this.tempDecision || this.form.value.updateRequired!==this.tempUpdateRequired){
    //   await this.prepareDataForPostPeriodicReview()
    // }
    let pdf = await this.armService.getPDF(this.armService.selectedRiskAssessment.riskAssessmentTitle, this.armService.selectedRiskAssessment.iD, this.armService.selectedRiskAssessment.riskAssessmentVersion);
    this.prepareDataForPostAttachment(pdf);
  }

  async prepareDataForPostAttachment(pdf: any) {
    let datasets: dataset[] = [];

    let attachmentDataset: dataset = {
      data: [{
        iD: -1,
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        attachmentType: "application/pdf",
        attachmentDescription: "Periodic Review for Risk Assessment " + this.armService.selectedRiskAssessment.iD + " PDF",
        key: null,
        statementType: "INSERT"
      }],
      dataset: "Attachment"
    };

    let auditTrailList = this.globalService.buildAuditTrail_0115(
      {},
      attachmentDataset.data[0],
      "tblAttachment"
    );
    var url = this.globalService.prepareServerURL("/values/saveReport");
    datasets.push(attachmentDataset);
    let toDB = new DataToPost(this.globalService.clientID, this.globalService.userID, "Test reason", "Add Attachment", datasets, auditTrailList, pdf);

    await this.apiService.postDataToBackend_0051(url, toDB).then((result: any) => {
      this.prepareDataForPostPeriodicReview(result.result[0], false);
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Attachment");
      window.alert(userErrMsg)
    });
  }

  async prepareDataForPostAttachmentESigs(index: number) {
    let datasets: dataset[] = [];

    let attachmentEsigsDataset: dataset = {
      data: [{
        iD: -1,
        clientID: this.globalService.clientID,
        attachmentID: index,
        Title: "Periodic Review for RA " + this.armService.selectedRiskAssessment.iD,
        Desc: "Periodic Review for Risk Assessment " + this.armService.selectedRiskAssessment.iD + " Description",
        status: "In Approval",
        statementType: "INSERT"
      }],
      dataset: "AttachmentElectronicSignaturesM2O"
    };

    let auditTrailList = this.globalService.buildAuditTrail_0115(
      {},
      attachmentEsigsDataset.data[0],
      "tblAttachmentElectronicSignaturesM2O"
    );
    let versionControlDataset: dataset = {
      data: [{
        iD: -1,
        clientID: this.globalService.clientID,
        attachmentID: index,
        riskAssessmentID: this.armService.selectedRiskAssessment.iD,
        riskAssessmentREF: this.armService.selectedRiskAssessment.riskAssessmentREF,
        version: this.armService.approvalRevisionHistory[this.armService.approvalRevisionHistory.length - 1].version,
        versionControlDescription: this.form.value.periodicReviewDecision,
        approvalInitiationDate: this.getCurrentDateTime_0516(),
        approvalCompletionDate: null,
        statementType: "INSERT"
      }],
      dataset: "VersionControlAttachmentM2O"
    };
    auditTrailList = auditTrailList.concat(this.globalService.buildAuditTrail_0115({}, versionControlDataset.data[0], "tblVersionControlAttachmentsM2O"));

    let signers: any[] = [];
    let entryID = -1;
    this.periodicReviewApprovers.forEach((element: any) => {
      let signer = {
        iD: entryID,
        clientID: this.globalService.clientID,
        userID: element.userID,
        attachmentID: index,
        signingMeaning: null,
        signed: false,
        comments: null,
        timestamp: null,
        signatureName: element.fullName,
        statementType: "INSERT"
      }
      signers.push(signer);
      auditTrailList = auditTrailList.concat(this.globalService.buildAuditTrail_0115({}, signer, "tblAttachmentSigners"));
      entryID--;
    });
    let attachmentSignersDataset: dataset = {
      data: signers,
      dataset: "AttachmentSigners"
    };

    datasets.push(attachmentEsigsDataset, versionControlDataset, attachmentSignersDataset);
    let toDB = new DataToPost(this.globalService.clientID, this.globalService.userID, "Test reason", "Add Periodic Review Approvers", datasets, auditTrailList, null);
    await this.apiService.postDataToBackend_0051(this.apiService.urlForSinglePost, toDB).then((result: any) => {
      this.updateToInApproval();
      this.prepareDataForPostAttachment_0450;
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Attachment Signers");
      window.alert(userErrMsg)
    });
  }

  base64ToArrayBufferVerionControl_0139(base64: string) {
    var binary_string = window.atob(decodeURIComponent(base64));
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  clearDialog() {
    this.form.reset();
  }

  getCurrentDateTime_0516() {
    var date = new Date();
    date.setHours(date.getHours());
    return date.toISOString().slice(0, 19).replace('T', ' ');
  }

  async updateToInApproval() {
    let datasets: dataset[] = [];
    let riskAssessmentDataset: dataset = {
      data: [{
        iD: this.armService.selectedRiskAssessment.iD,
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        riskAssessmentREF: this.armService.selectedRiskAssessment.riskAssessmentREF,
        riskAssessmentTitle: this.armService.selectedRiskAssessment.riskAssessmentTitle,
        riskAssessmentPurpose: this.armService.selectedRiskAssessment.riskAssessmentPurpose,
        riskAssessmentScope: this.armService.selectedRiskAssessment.riskAssessmentScope,
        riskAssessmentOutOfScope: this.armService.selectedRiskAssessment.riskAssessmentOutOfScope,
        riskAssessmentProximity: this.armService.selectedRiskAssessment.riskAssessmentProximity,
        riskAssessmentTriggerID: this.armService.selectedRiskAssessment.riskTriggerID,
        riskAssessmentTriggerName: this.armService.selectedRiskAssessment.riskTriggerDescription,
        riskAssessmentTypeID: this.armService.selectedRiskAssessment.riskAssessmentTypeID,
        riskAssessmentTypeName: this.armService.selectedRiskAssessment.riskAssessmentType,
        riskAssessmentStatusID: 2,
        riskAssessmentStatusName: "In Approval",
        riskAssessmentToolUsedID: this.armService.selectedRiskAssessment.riskAssessmentToolID,
        riskAssessmentToolUsedName: this.armService.selectedRiskAssessment.riskAssessmentTool,
        riskAssessmentLeadID: this.armService.selectedRiskAssessment.riskAssessmentLeadID,
        riskAssessmentLeadName: this.armService.selectedRiskAssessment.riskAssessmentLeadName,
        riskAssessmentEntityID: this.armService.selectedRiskAssessment.riskAssessmentEntityID,
        riskAssessmentEntityName: this.armService.selectedRiskAssessment.riskAssessmentEntityName,
        businessProcessID: this.armService.selectedRiskAssessment.businessProcessID,
        businessProcessName: this.armService.selectedRiskAssessment.businessProcess,
        functionalAreaID: this.armService.selectedRiskAssessment.functionalAreaID,
        functionalAreaName: this.armService.selectedRiskAssessment.functionalArea,
        riskInitiatorID: this.armService.selectedRiskAssessment.riskInitiatorID,
        riskInitiatorName: this.armService.selectedRiskAssessment.riskInitiatorName,
        creationDate: this.armService.selectedRiskAssessment.creationDate,
        periodicReviewFrequency: this.armService.selectedRiskAssessment.periodicReviewFrequency,
        periodicReviewDueDate: this.armService.selectedRiskAssessment.periodicReviewDueDate,
        riskAssessmentConclusion: this.armService.selectedRiskAssessment.riskAssessmentConclusion,
        riskAssessmentReevaluation: this.armService.selectedRiskAssessment.riskAssessmentReevaluation,
        approvedDate: null,
        riskAssessmentQualityRecord: this.armService.selectedRiskAssessment.riskAssessmentQualityRecord,
        riskAssessmentVersion: this.armService.selectedRiskAssessment.riskAssessmentVersion,
        isTemplate: this.armService.selectedRiskAssessment.isTemplate,
        tutorialStage: this.armService.selectedRiskAssessment.tutorialStage,
        riskMethodology: this.armService.selectedRiskAssessment.riskMethodology,
        periodicReviewComment: this.armService.selectedRiskAssessment.periodicReviewComment,
        isPeriodicReview: this.armService.selectedRiskAssessment.isPeriodicReview,
        statementType: 'UPDATE'
      }],
      dataset: "RiskAssessment",
    };

    let riskAssessmentAuditTrail = [{
      tableName: 'tblRiskAssessment',
      entryID: this.armService.selectedRiskAssessment.iD,
      fieldName: 'riskAssessmentStatusID',
      value: '2',
      statementType: 'UPDATE'
    },
    {
      tableName: 'tblRiskAssessment',
      entryID: this.armService.selectedRiskAssessment.iD,
      fieldName: 'riskAssessmentStatusName',
      value: 'In Approval',
      statementType: 'UPDATE'
    }];
    let auditTrail: any[] = [];
    datasets.push(riskAssessmentDataset);
    auditTrail = auditTrail.concat(riskAssessmentAuditTrail);

    let toDB = new DataToPost(this.globalService.clientID, this.globalService.userID, '', "Edit Risk Assessment Status", datasets, auditTrail);
    await this.apiService.postDataToBackend_0051(this.apiService.singlePostUrl, toDB).then((result: any) => {
      if (result) {
        this.armService.selectedRiskAssessment.riskAssessmentStatusID = 2;
        this.armService.selectedRiskAssessment.riskAssessmentStatus = 'In Approval';
      }
      this.dialogRef.close(false);
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Risk Assessment");
      window.alert(userErrMsg)
    });
  }

  async prepareDataForPostAttachment_0450() {
    let datasets: datasetGet[] = [];

    let versionControlAttachmentM2ODataset: datasetGet = {
      parameters: {
        iD: null,
        clientID: this.globalService.clientID,
        riskAssessmentID: this.armService.selectedRiskAssessment.iD,
        attachmentID: null,
        version: null,
      },
      storedProcedure: 'VersionControlAttachmentM2O'
    }
    datasets.push(versionControlAttachmentM2ODataset);

    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Risk Assessment Approvers ", datasets);
    await this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      if (fromDB) {
        this.versionControlAttachments = fromDB[0];

        let datasets: dataset[] = [];
        let auditTrailList: any[] = [];

        let reviewApprovalWorkflow: dataset = {
          data: [{
            iD: -1,
            clientID: this.globalService.clientID,
            riskAssessmentID: this.armService.selectedRiskAssessment.iD,
            versionID: this.versionControlAttachments[this.versionControlAttachments.length -1].iD,
            type: 'approval',
            stage: 1,
            isAccepted: null,
            initiatedDate: new Date(),
            completionDate: null,
            statementType: 'INSERT'
          }],
          dataset: "ReviewApprovalWorkflow",
        };
        datasets.push(reviewApprovalWorkflow);

        auditTrailList = this.globalService.buildAuditTrail_0115({}, reviewApprovalWorkflow.data[0], "tblReviewApprovalWorkflow")

        let toDB = new DataToPost(this.globalService.clientID, this.globalService.userID, '', "Add Revision", datasets, auditTrailList);
        this.apiService.postDataToBackend_0051(this.apiService.singlePostUrl, toDB).then((result: any) => {
        }).catch((err: any) => {
          console.error(err);
          let userErrMsg = this.armService.constructUserErrorMessage(err, "Risk Assessment");
          window.alert(userErrMsg)
        });
      }
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Risk Assessment Team Memebers");
      window.alert(userErrMsg)
    });
  }

  async updateRA() {
    if (this.form.value.periodicReviewDecision !== this.tempDecision || this.form.value.updateRequired !== this.tempUpdateRequired) {
      await this.prepareDataForPostPeriodicReview()
    }
    this.dialogRef.close(true);
  }
}
