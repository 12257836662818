// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { APIService, datasetGet, DataToGet, GlobalService, IrlcoreAuthService } from '@irlca/irlcore';
// import { cloneDeep } from 'lodash';
import { ArmService } from '../../../arm.service';

@Component({
  selector: 'app-dashboard-risk-profile',
  templateUrl: './dashboard-risk-profile.component.html',
  styleUrls: ['./dashboard-risk-profile.component.scss']
})
export class DashboardRiskProfileComponent implements OnInit {


  @Input() filteredRisks: any = {};
  @Input() riskCategories: any;

  riskProfileData: any = {};
  riskCategoriesGraphData: any[] = [];
  chartTitle: string = "Risks by Category";
  yAxisLabel: string = 'Number of Risks';
  // graphData: any = [];
    
  constructor(
    public router: Router,
    private globalService: GlobalService,
    private apiService: APIService,
    public auth: IrlcoreAuthService,
    private armService: ArmService,
  ) {
    // Object.assign(this, this.graphData)
  }

  ngOnInit() {
    this.updateDashboard();
  }

  ngOnChanges(){
    this.updateDashboard();
  }

  updateDashboard(){
    let analysedRiskProfileDashboard = this.armService.analyseRiskDataForRiskProfileDashboard( this.riskCategories, this.filteredRisks);
    this.riskProfileData = analysedRiskProfileDashboard.riskProfileData;
    this.riskCategoriesGraphData = analysedRiskProfileDashboard.riskCategoriesGraphData;
  }

  customizeTooltip(arg: any) {
    return {
      text: `${arg.seriesName} total: ${arg.valueText}`,
    };
  }

  legendClick(e: any) {
    const series = e.target;
    if (series.isVisible()) {
      series.hide();
    } else {
      series.show();
    }
  }

}


