import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class LogService {
	LogServerErrorURL = environment.applicationFELogging + "LogError";
	LogServerInfoURL = environment.applicationFELogging + "LogInfo";

	constructor(private http: HttpClient) { }

	logToBackendError(url: string, errorToLog: any) {
		const promise = new Promise((resolve, reject) => {
			const headers = new HttpHeaders({
				'Content-Type': 'application/json',
			});
			let postPayload = {
				"log": errorToLog
			}
			return this.http.post(url, postPayload,
				{ headers: headers }).toPromise().then((res: any) => {
					resolve(res);
				},
					err => {
						reject(err);
					}
				);
		});
		return promise;
	}

	logToBackendInfo(url: string, infoToLog: string) {
		const promise = new Promise((resolve, reject) => {
			const headers = new HttpHeaders({
				'Content-Type': 'application/json',
			});
			let postPayload = {
				"log": infoToLog
			}
			return this.http.post(url, postPayload,
				{ headers: headers }).toPromise().then((res: any) => {
					resolve(res);
				},
					err => {
						reject(err);
					}
				);
		});
		return promise;
	}
}