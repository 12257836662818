import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { faEye, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { APIService, dataset, datasetGet, DataToGet, GlobalService } from '@irlca/irlcore';
import { ArmService } from '../../../arm.service';

@Component({
  selector: 'app-risk-assessment-review-comments-dialog',
  templateUrl: './risk-assessment-review-comments-dialog.component.html',
  styleUrls: ['./risk-assessment-review-comments-dialog.component.scss']
})
export class RiskAssessmentReviewCommentsDialogComponent implements OnInit {

  comments: any[] = [[]];
  workflow: any[] = [];
  dataToDisplay: any[] = [];
  approvalToDisplay: any[] = [];
  reviews: any[] = [];
  approvals: any[] = [];
  version!: number;

  // icons
  times = faTimes
  eye = faEye
  constructor(public dialogRef: MatDialogRef<RiskAssessmentReviewCommentsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalService: GlobalService, private apiService: APIService, public armService: ArmService, public router: Router) { }

  ngOnInit() {
    this.version = this.data.version;
    this.prepareDataForGetReviewApprovalComments();
  }

  filterData(data: any) {
    this.createReviewArray(this.workflow);
    for (let i = 0; i < data.length; i++) {
      if (data[i].version == this.version && (data[i].type != 'approval' || (data[i].type != 'approval - periodic review'))) {
        this.dataToDisplay[data[i].stage - 1].push(data[i])
      }
      else if (data[i].version == this.version && data[i].type == 'approval') {
        this.approvalToDisplay[data[i].stage - 1].push(data[i])
      }
    }
  }

  createReviewArray(workflow: any) {
    for (let i = 0; i < workflow.length; i++) {
      if ((workflow[i].type != 'approval' ||  workflow[i].type != 'approval - periodic review')&& workflow[i].version == this.version) {
        this.dataToDisplay.push([]);
        this.reviews.push(workflow[i]);
      }
      else if (( workflow[i].type == 'approval' || workflow[i].type == 'approval - periodic review') && workflow[i].version == this.version) {
        this.approvalToDisplay.push([])
        this.approvals.push(workflow[i])
      }
    }
  }

  async prepareDataForGetReviewApprovalComments() {
    let datasets: datasetGet[] = [];
    let length = this.armService.workflow.length - 1;
    let ReviewApprovalCommentsDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        riskAssessmentID: this.armService.selectedRiskAssessment.iD
      },
      storedProcedure: "ReviewApprovalComments"
    };

    let ReviewApprovalWork: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        riskAssessmentID: this.armService.selectedRiskAssessment.iD
      },
      storedProcedure: "ReviewApprovalWorkflow"
    }
    let attachmentSignersDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        userID: null,
        riskAssessmentID: this.armService.selectedRiskAssessment.iD
      },
      storedProcedure: 'RiskAssessmentVersionSigners'
    }

    datasets.push(ReviewApprovalCommentsDataset, ReviewApprovalWork, attachmentSignersDataset);
    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Risk Assessment DAM", datasets)
    await this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      this.comments = fromDB[0];
      this.workflow = fromDB[1];
      this.filterData(this.comments)
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Review Approval Comments");
      window.alert(userErrMsg)
    });
  }

  navigateToPreviousRiskAssessmentReport(index: number) {
    this.armService.showPreviousRiskAssessmentReview = true;
    this.armService.fromApprovalsTab = false;
    this.armService.previousRiskAssessmentReviewAttachmentID = this.reviews[index].attachmentID;
    this.router.navigate(['/risk-assessment/risk-assessment-review'])
    this.dialogRef.close();
  }
  
  navigateToPreviousRiskAssessmentReportApproval(index:number) {
    this.armService.showPreviousRiskAssessmentReview = true;
    this.armService.fromApprovalsTab = false;
    this.armService.previousRiskAssessmentReviewAttachmentID = this.approvals[index].attachmentID;
    this.router.navigate(['/risk-assessment/risk-assessment-review'])
    this.dialogRef.close();
  }
}
