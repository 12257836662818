import { Injectable } from '@angular/core';
import { APIService, dataset, DataToPost, GlobalService } from '@irlca/irlcore';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ArmService {
  expandedView: any = false;
  isPeriodicReview: boolean = false;
  selectedPeriodicReview: number = -1;
  riskAssessmentStage: string = 'review';
  selectedAttachment: any;
  selectedRiskAssessment: any;
  selectedRisk: any;
  // DOC Comment: This array is duplicated - both are used
  selectedRiskAssessmentAssociatedRisks: any[] = [];
  indexOfSelectedRisk: number = 0;
  originalTeamData: any[] = [];
  teamTableList: any[] = [];
  teamAuditTrailList: any[] = [];
  teamEntryID: number = -1;
  teamDataSource: MatTableDataSource<any> = new MatTableDataSource<any[]>();
  teamTableDataset: dataset = {
    data: [],
    dataset: "RiskAssessmentTeamMember"
  };
  expandedTaskView: boolean = false;
  displayedRichTextData: any = {
    richTextDataID: 0,
    richTextData: ""
  }
  richTextDataDataset: dataset = {
    dataset: "RichTextData",
    data: []
  };
  richTextDataRiskAssessmentM2MDataset: dataset = {
    dataset: "RichTextDataRiskAssessmentM2M",
    data: []
  };
  selectedRiskAssessmentInitiateApproval: boolean = false;
  selectedRiskAssessmentInitiateReview: boolean = false;
  workflow: any = [];
  workflowInProgress!: boolean;
  selectedRiskInitiateApproval: boolean = false;
  supportingDataAuditTrailList: any[] = [];
  assessmentRichTextData: any[] = [];
  assessmentRichTextDataFromDB: any[] = [];
  supportingDataExpanded: boolean[] = [];
  entryIDSupportingData: number = -1;
  supportingDataDatasets: dataset[] = []
  taskReturn!: string;
  riskForReport!: any;
  riskReportAttachmentID!: number;

  taskExpandedView!: boolean;
  navigateFromRegister: boolean = false;
  selectedAssessmentReportID!: number;
  selectedRiskReportID!: any;
  assumptionsDataSource: MatTableDataSource<any> = new MatTableDataSource<any[]>();
  assumptionsFromDB: any[] = [];
  assumptionDataset: dataset = {
    dataset: "Assumptions",
    data: [],
  }
  approval: boolean = false;

  heatMapImage: any;


  assumptionsDatasets: dataset[] = [];
  entryIDAssumptions: number = -1;
  assumptionsAuditTrailList: any[] = [];

  riskDataset: dataset = {
    dataset: "Risk",
    data: []
  };
  riskRiskAssessmentM2ODataset: dataset = {
    dataset: "RiskRiskAssessmentM2O",
    data: []
  };
  failureCauseDataset: dataset = {
    dataset: "FailureCause",
    data: []
  };
  failureEffectDataset: dataset = {
    dataset: "FailureEffect",
    data: []
  };
  failureModeDataset: dataset = {
    dataset: "FailureMode",
    data: []
  };
  itemGroupDataset: dataset = {
    dataset: "ItemGroup",
    data: []
  };

  approvalRevisionHistoryDataset: dataset = {
    data: [],
    dataset: 'RevisionHistory'
  }
  approvalVersionControlAttachmentM2ODataset: dataset = {
    data: [],
    dataset: 'VersionControlAttachmentM2O'
  }
  approvalVersionControlAttachmentM2OAuditTrailList: any[] = [];
  approvalRevisionHistoryAuditTrailList: any[] = [];
  approvalAttachmentSignersDataset: dataset = {
    data: [],
    dataset: 'AttachmentSigners'
  }
  approvalAttachmentSignersAuditTrailList: any = [];
  approvalAttachmentElectronicSignatureM2ODataset: dataset = {
    data: [],
    dataset: 'AttachmentElectronicSignaturesM2O'
  }
  approvalAttachmentElectronicSignatureM2OAuditTrailList: any[] = [];
  latestVersionAttachment: any;
  approvalElectronicSignatureFromDB: any;
  approvalRevisionHistory: any = [];
  approvalAssignersAndApprovers: any = [];
  versionComments: any[] = [];
  approversDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  reviewersDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  riskDialogAuditTrailList: any[] = [];

  $riskAssessmentExpandedDetailSavePressed = new BehaviorSubject<boolean>(false);
  $pullRiskAssessmentExpandedDetailData = new BehaviorSubject<boolean>(false);
  $approvalDataRetrieved = new BehaviorSubject<boolean>(false);
  $approvalsComplete = new BehaviorSubject<boolean>(false);

  supportingDataSaveFunctionCalled: boolean = false;
  teamSaveFunctionCalled: boolean = false;
  assumptionSaveFunctionCalled: boolean = false;
  attachmentSaveFunctionCalled: boolean = false;
  approvalSaveFunctionCalled: boolean = false;

  auditTrailTableNames: string[] = [];
  auditTrailListView: any[] = [];
  primaryBlue: string = "#0850b2";
  highRiskColour: string = "#b22208";
  mediumRiskColour: string = "#e8c11c";
  lowRiskColour: string = "#27943c";
  backgroundColour: string = "#e8e8e8";
  dashboardColour: string = "#868383";
  uiElement: string = "#d8d8d8";
  appHeight: any;
  appWidth: any;
  navigateFromRiskRecord: boolean = false;
  refreshExpandedPage: boolean = false;
  applicationEnvironment = "TBD";
  applicationVersionNumber = "TBD"
  // DOC Comment: This array is duplicated - both are used
  selectedAssessmentRisks: any;
  itemGroupEntryID: number = -1;
  failureModeEntryID: number = -1;
  fromApprovalsTab: boolean = false;

  approvalWorkflow: boolean = false;
  riskProfileImage: any;
  showPreviousRiskAssessmentReview: boolean = false;
  previousRiskAssessmentReviewAttachmentID!: number;

  riskAssessmentToolDictionary: any = {
    use: {
      title: 'Use FMEA',
      itemGroup: 'User Step'
    },
    design: {
      title: 'Design FMEA',
      itemGroup: 'Design Requirement'
    },
    process: {
      title: 'Process FMEA',
      itemGroup: 'Process Step'
    }
  }

  instructions: any = {};

  monthOfYear: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

  constructor(
    public globalService: GlobalService,
    public apiService: APIService
  ) { }

  // DOC Comment: Should be moved to Irlcore Global Service
  getUserInitials_0042(fullName: string) {
    let matches = fullName.match(/\b(\w)/g);
    let initials = matches!.join('');
    return initials;
  }

  // DOC Comment: Will be covered by Class / Interface
  setGlobalSelectedRiskAssessment_0413(riskAssessment: any) {
    this.selectedRiskAssessment = riskAssessment;
  }

  // DOC Comment: Will be covered by Class / Interface
  setGlobalSelectedRisk_0455(risk: any) {
    this.selectedRisk = risk;
  }

  drawGauge_XXXX(x: number, y: number, startAngle: number, radius: number, strokeSize: number, progress: number, progressColor: string, ctx: any) {
    var StartAngleOffset = startAngle / 180 * Math.PI;

    ctx.beginPath();
    ctx.arc(x, y, radius, StartAngleOffset, StartAngleOffset + Math.PI, false);
    ctx.strokeStyle = "#c7cfca"
    ctx.lineWidth = strokeSize;
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(x, y, radius, StartAngleOffset, StartAngleOffset + (2 * Math.PI) * progress, false);
    ctx.strokeStyle = progressColor
    ctx.lineWidth = strokeSize;
    ctx.stroke();
  }

  getGaugeColour_XXXX(riskLevel: string): string {
    var riskColour: string;
    if (riskLevel === "High") { riskColour = this.highRiskColour }
    else if (riskLevel === "Medium") { riskColour = this.mediumRiskColour }
    else (riskColour = this.lowRiskColour)
    return riskColour;
  }

  async initiateRecordApproval() {
    let datasets: dataset[] = [];
    let auditTrail: any = [];

    let statusID: number = 2;
    let statusName: string = "In Approval";

    let riskReportAttachmentID = this.selectedAssessmentReportID;

    var entryID = -1;
    var newSignerStaticFields = {
      clientID: this.globalService.clientID,
      attachmentID: riskReportAttachmentID,
      signingMeaning: null,
      signed: 0,
      comments: null,
      timeStamp: null,
      statementType: "INSERT"
    }
    if (!this.approvalWorkflow) {
      statusID = 4;
      statusName = "In Review";
    }
    let temporarySigners = this.approvalAssignersAndApprovers;
    if (this.approvalWorkflow) {
      temporarySigners = this.approvalAssignersAndApprovers.filter((signer: any) => signer.isApprover == true)
    }

    for (var i = 0; i < temporarySigners.length; i++) {
      let newSigner: any = {};
      newSigner.iD = entryID;
      newSigner.userID = temporarySigners[i].userID;
      newSigner.roleName = temporarySigners[i].riskAssessmentRole;
      newSigner.signatureName = temporarySigners[i].fullName;
      this.approvalAttachmentSignersDataset.data.push({ ...newSignerStaticFields, ...newSigner });
      entryID--;
      // if (this.approvalAssignersAndApprovers[i].isReviewer) {
      //   statusID = 4;
      //   statusName = "In Review";

      // }
    }

    for (var i = 0; i < this.approvalAttachmentSignersDataset.data.length; i++) {
      this.approvalAttachmentSignersAuditTrailList = this.approvalAttachmentSignersAuditTrailList.concat(this.globalService.buildAuditTrail_0115({}, this.approvalAttachmentSignersDataset.data[i], "tblAttachmentSigners"));
    }

    let versionComment = null;

    if (this.workflow.length > 0) {
      if (this.workflow[this.workflow.length - 1].version == this.selectedRiskAssessment.riskAssessmentVersion) {
        versionComment = this.workflow[this.workflow.length - 1].versionControlDescription
      }
    }

    entryID = -1;
    let versionControl;
    if ((this.workflow.length > 0 && (!(this.workflow[this.workflow.length - 1].type == "approval" || !(this.workflow[this.workflow.length - 1].type == "approval - periodic review")) && this.workflow[this.workflow.length - 1].isAccepted))) {
      versionControl = {
        iD: entryID,
        clientID: this.globalService.clientID,
        attachmentID: riskReportAttachmentID,
        riskAssessmentID: this.selectedRiskAssessment.iD,
        version: this.selectedRiskAssessment.riskAssessmentVersion,
        versionControlDescription: versionComment,
        approvalInitiationDate: new Date(Date.now()),
        approvalCompletionDate: null,
        statementType: "INSERT"
      }
    }
    else {
      versionControl = {
        iD: entryID,
        clientID: this.globalService.clientID,
        attachmentID: riskReportAttachmentID,
        riskAssessmentID: this.selectedRiskAssessment.iD,
        version: this.selectedRiskAssessment.riskAssessmentVersion,
        versionControlDescription: versionComment,
        approvalInitiationDate: new Date(Date.now()),
        approvalCompletionDate: null,
        statementType: "INSERT"
      }
    }

    this.approvalVersionControlAttachmentM2ODataset.data.push(versionControl);
    this.approvalVersionControlAttachmentM2OAuditTrailList = this.approvalVersionControlAttachmentM2OAuditTrailList.concat(this.globalService.buildAuditTrail_0115({}, this.approvalVersionControlAttachmentM2ODataset.data[0], "tblVersionControlAttachmentM2O"));

    let newElectronicSignatureRecord = {
      iD: entryID,
      clientID: this.globalService.clientID,
      attachmentID: riskReportAttachmentID,
      Title: 'Version ' + this.selectedRiskAssessment.riskAssessmentVersion + ' for Risk Assessment' + this.selectedRiskAssessment.riskAssessmentREF,
      Desc: 'Test description',
      status: 'In Approval',
      statementType: 'INSERT'
    }

    this.approvalAttachmentElectronicSignatureM2ODataset.data.push(newElectronicSignatureRecord);
    this.approvalAttachmentElectronicSignatureM2OAuditTrailList = this.approvalAttachmentElectronicSignatureM2OAuditTrailList.concat(this.globalService.buildAuditTrail_0115({}, this.approvalAttachmentElectronicSignatureM2ODataset.data[0], "tblAttachmentElectronicSignaturesM2O"));

    let riskAssessmentDataset: dataset = {
      data: [{
        iD: this.selectedRiskAssessment.iD,
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        riskAssessmentREF: this.selectedRiskAssessment.riskAssessmentREF,
        riskAssessmentTitle: this.selectedRiskAssessment.riskAssessmentTitle,
        riskAssessmentPurpose: this.selectedRiskAssessment.riskAssessmentPurpose,
        riskAssessmentScope: this.selectedRiskAssessment.riskAssessmentScope,
        riskAssessmentOutOfScope: this.selectedRiskAssessment.riskAssessmentOutOfScope,
        riskAssessmentProximity: this.selectedRiskAssessment.riskAssessmentProximity,
        riskAssessmentTriggerID: this.selectedRiskAssessment.riskTriggerID,
        riskAssessmentTriggerName: this.selectedRiskAssessment.riskTriggerDescription,
        riskAssessmentTypeID: this.selectedRiskAssessment.riskAssessmentTypeID,
        riskAssessmentTypeName: this.selectedRiskAssessment.riskAssessmentType,
        riskAssessmentStatusID: statusID,
        riskAssessmentStatusName: statusName,
        riskAssessmentToolUsedID: this.selectedRiskAssessment.riskAssessmentToolID,
        riskAssessmentToolUsedName: this.selectedRiskAssessment.riskAssessmentTool,
        riskAssessmentLeadID: this.selectedRiskAssessment.riskAssessmentLeadID,
        riskAssessmentLeadName: this.selectedRiskAssessment.riskAssessmentLeadName,
        riskAssessmentEntityID: this.selectedRiskAssessment.riskAssessmentEntityID,
        riskAssessmentEntityName: this.selectedRiskAssessment.riskAssessmentEntityName,
        businessProcessID: this.selectedRiskAssessment.businessProcessID,
        businessProcessName: this.selectedRiskAssessment.businessProcess,
        functionalAreaID: this.selectedRiskAssessment.functionalAreaID,
        functionalAreaName: this.selectedRiskAssessment.functionalArea,
        riskInitiatorID: this.selectedRiskAssessment.riskInitiatorID,
        riskInitiatorName: this.selectedRiskAssessment.riskInitiatorName,
        creationDate: this.selectedRiskAssessment.creationDate,
        periodicReviewFrequency: this.selectedRiskAssessment.periodicReviewFrequency,
        periodicReviewDueDate: this.selectedRiskAssessment.periodicReviewDueDate,
        riskAssessmentConclusion: this.selectedRiskAssessment.riskAssessmentConclusion,
        riskAssessmentReevaluation: this.selectedRiskAssessment.riskAssessmentReevaluation,
        approvedDate: null,
        riskAssessmentQualityRecord: this.selectedRiskAssessment.riskAssessmentQualityRecord,
        riskAssessmentVersion: this.selectedRiskAssessment.riskAssessmentVersion,
        mitigatingActionsStatus: this.selectedRiskAssessment.mitigatingActionsStatus,
        isTemplate: this.selectedRiskAssessment.isTemplate,
        promotedFMEA: this.selectedRiskAssessment.promotedFMEA,
        tutorialStage: this.selectedRiskAssessment.tutorialStage,
        riskMethodology: this.selectedRiskAssessment.riskMethodology,
        periodicReviewComment: this.selectedRiskAssessment.periodicReviewComment,
        isPeriodicReview: this.selectedRiskAssessment.isPeriodicReview,
        statementType: 'UPDATE'
      }],
      dataset: "RiskAssessment",
    };
    let riskAssessmentAuditTrail = [{
      tableName: 'tblRiskAssessment',
      entryID: this.selectedRiskAssessment.iD,
      fieldName: 'riskAssessmentStatusID',
      value: statusID,
      statementType: 'UPDATE'
    },
    {
      tableName: 'tblRiskAssessment',
      entryID: this.selectedRiskAssessment.iD,
      fieldName: 'riskAssessmentStatusName',
      value: statusName,
      statementType: 'UPDATE'
    },
    {
      tableName: 'tblRiskAssessment',
      entryID: this.selectedRiskAssessment.iD,
      fieldName: 'riskAssessmentREF',
      value: this.selectedRiskAssessment.riskAssessmentREF,
      statementType: 'UPDATE'
    }];
    let workflowStageDataset!: dataset;
    let workflowAuditTrail;
    if (this.approvalWorkflow) {
      let approvals = this.workflow.filter((workflow: any) => (workflow.type == "approval" || workflow.type == "approval - periodic review") && this.selectedRiskAssessment.riskAssessmentVersion == workflow.version);
      let type = 'approval';
      if (this.selectedRiskAssessment.isPeriodicReview) {
        type = "approval - periodic review"
      }
      let stageInt = 0;
      if (approvals.length == 0) {
        stageInt = 1;
      }
      else {
        stageInt = approvals[approvals.length - 1].stage + 1;
      }

      workflowStageDataset = {
        data: [{
          iD: -1,
          clientID: this.globalService.clientID,
          riskAssessmentID: this.selectedRiskAssessment.iD,
          versionID: -99,
          type: type,
          stage: stageInt,
          isAccepted: null,
          initiatedDate: new Date(),
          completionDate: null,
          statementType: "INSERT"
        }],
        dataset: "ReviewApprovalWorkflow"
      };
      workflowAuditTrail = this.globalService.buildAuditTrail_0115({}, workflowStageDataset.data[0], "tblReviewApprovalWorkflow");
    }
    else {
      let stageInt = 0;
      let approvals = this.workflow.filter((workflow: any) => workflow.type == "approval" || workflow.type == "approval - periodic review")
      let reviews = this.workflow.filter((workflow: any) => workflow.type == "review" || workflow.type == "review - periodic review")
      if (reviews.length == 0) {
        stageInt = 1;
      }
      else if (approvals.length == 0) {
        stageInt = reviews[reviews.length - 1].stage + 1;
      }
      else {
        let lengthApprovals = approvals.length - 1;
        let lengthReviews = reviews.length - 1;
        if (approvals[lengthApprovals].isAccepted && reviews[lengthReviews].version == approvals[lengthApprovals].version) {
          stageInt = 1;
        }
        else {
          stageInt = reviews[lengthReviews].stage + 1;
        }
      }
      let type = "review"
      if (this.selectedRiskAssessment.isPeriodicReview) {
        type = "review - periodic review"
      }

      workflowStageDataset = {
        data: [{
          iD: -1,
          clientID: this.globalService.clientID,
          riskAssessmentID: this.selectedRiskAssessment.iD,
          versionID: -99,
          type: type,
          stage: stageInt,
          isAccepted: null,
          initiatedDate: new Date(),
          completionDate: null,
          statementType: "INSERT"
        }],
        dataset: "ReviewApprovalWorkflow"
      };
      workflowAuditTrail = this.globalService.buildAuditTrail_0115({}, workflowStageDataset.data[0], "tblReviewApprovalWorkflow");
    }

    datasets.push(this.approvalAttachmentSignersDataset, this.approvalVersionControlAttachmentM2ODataset, workflowStageDataset, this.approvalAttachmentElectronicSignatureM2ODataset, riskAssessmentDataset);

    auditTrail = auditTrail.concat(this.approvalAttachmentSignersAuditTrailList, this.approvalVersionControlAttachmentM2OAuditTrailList, workflowAuditTrail, this.approvalAttachmentElectronicSignatureM2OAuditTrailList, riskAssessmentAuditTrail);

    let toDB = new DataToPost(this.globalService.clientID, this.globalService.userID, '', "Initiate Approval", datasets, auditTrail);
    this.apiService.postDataToBackend_0051(this.apiService.singlePostUrl, toDB).then((result: any) => {
      if (result) {
        this.selectedRiskAssessment.riskAssessmentStatusID = statusID;
        this.workflowInProgress = true;
      }
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.constructUserErrorMessage(err, "Record Approval");
      window.alert(userErrMsg)
    })
  }

  async getPDF(riskAssessmentTitle: any, riskAssessmentID: any, versionNumber: any) {
    var head = "<head> <meta name='description' content='' charset='UTF-8'/> <link rel='stylesheet' type='text/css' href='C:\\styles\\risk-assessmentReport.css'> </head>"
    var element = $(".div-for-pdf")[0];
    head = head.concat(element.outerHTML)
    var url = this.globalService.serverBaseURL() + "/riskRiskAssessment/generateRiskAssessmentReport";
    let toDB = new DataToPost(this.globalService.clientID, this.globalService.userID, "Generate Report", "Generate Report",
      [{ data: [{ riskAssessmentTitle: riskAssessmentTitle, riskAssessmentID: riskAssessmentID.toString(), versionNumber: versionNumber, reportHeaderText: this.globalService.configurableParameters.reportHeaderText, reportFooterText: this.globalService.configurableParameters.reportFooterText }], dataset: "" }],
      [], btoa(unescape(encodeURIComponent(head))));
    var pdf;
    await this.apiService.postDataToBackend_0051(url, toDB).then((fromDB: any) => {
      pdf = fromDB.result.PDF;
      return pdf;
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.constructUserErrorMessage(err, "Risk Assessment Report");
      window.alert(userErrMsg)
    });
    return pdf;
  }

  determineRiskAssessmentTool_XXXX(riskAssessmentToolID: number) {
    switch (riskAssessmentToolID) {
      case 1:
        return 'process';
      case 2:
        return 'design';
      case 3:
        return 'use';
      case 4:
        return 'process';
      default:
        return 'process';
    }
  }

  constructUserErrorMessage(error: any, action: string): string {
    let errMsg!: string;
    switch (error.status) {
      case 500: {
        if (error.error['ExceptionMessage']) {
          errMsg = this.status500ErrorMsg(error.error.ExceptionMessage, action);
        } else if (error.error['Message']) {
          errMsg = this.status500ErrorMsg(error.error.Message, action);
        }
        return errMsg;
      }
      case 503: {
        return "Irlca services are currently down for maintenance. Sorry for any inconveniences caused";
      }
      case 408: {
        return "The server timed out waiting for the request. Please try again and if problem persists contact your system administrator.";
      }
    }

    return 'Unknown error occurred please try again and if problem persists contact your system administrator.'
  }

  status500ErrorMsg(exceptionMsg: string, action: string): string {
    function extract([beg, end]: string[]) { // this function produces an array of substrings that are between the two given characters
      const matcher = new RegExp(`${beg}(.*?)${end}`, 'gm');
      const normalise = (str: string) => str.slice(beg.length, end.length * -1);
      return function (str: string) {
        return str.match(matcher)!.map(normalise);
      }
    }

    if (exceptionMsg.toLowerCase().includes('unique key')) {
      const stringExtractor = extract(["'", "'"]); // the substrings between '<<Substring>>' 
      let temp = stringExtractor(exceptionMsg)
      let table = temp[0].split("_"); // the error message contains 'UC_<<tableName>>' table[1] = <<tableName>>

      return "There was a problem with your request: There is already a " + action + " with the same key values. " + this.uniqueMsgMap.get(table[1]) + " Please make changes to the " + action + " and try again."
    }
    return "Something went wrong during your request. Please try again and contact your system administrator if problem persists."
  }

  uniqueMsgMap = new Map([ // List of all Unique key constraints and there associated error message, disregarding linking tables
    ["Assumptions", "The Assumptions Description needs to be unique. There is already an entry with that value."],
    ["BusinessProcess", "The Business Process needs to be unique. There is already an entry with that value."],
    ["Category", "The Category Description needs to be unique. There is already an entry with that value."],
    ["ClinicalTrial", "The Clinical Trail Reference needs to be unique. There is already an entry with that value."],
    ["Control", "Combination of all values needs to be unique. There is already an entry with those value."],
    ["ControlCategory", "The Control Category needs to be unique. There is already an entry with that value."],
    ["FunctionalArea", "The Functional Area needs to be unique. There is already an entry with that value."],
    ["NoticeTriggers", "There can only be one Notice Trigger per Functional aArea. There is already a Notice Trigger for that Functional Area."],
    ["PeriodicReview", "Cannot start another Periodic Review with the same start date as another. There is already an entry with that start date."],
    ["RevisionHistory", "There can only be one Revision History entry per version. There is already a Revision History for this verison."],
    ["Risk", "There can only be one Risk with the same Risk Reference and Version Number. There is already a Risk that has the same Risk Reference and Version Number combo."],
    ["RiskAssessment", "The Risk Assessment Title needs to be unique. There is already an entry with that value."],
    ["RiskAssessmentMandatoryOptionalRole", "There cant be multiple of the same Role per Risk Assessment Type."],
    ["RiskAssessmentStatus", "The combination of the Risk Assessment Status and Risk Assessment Sub-Status needs to be unique. There is already an entry with those value."],
    ["RiskAssessmentTeamMember", "A user cannnot be assigned multiple Roles for the current Risk Assessment. The user already has been assigned a role."],
    ["RiskAssessmentTool", "The Risk Assessment Tool needs to be unique. There is already an entry with that value."],
    ["RiskAssessmentTrigger", "The Risk Assessment Trigger Description needs to be unique. There is already an entry with that value."],
    ["RiskAssessmentType", "The Risk Assessment Type needs to be unique. There is already an entry with that value."],
    ["RiskLevel", "The can only be Risk Level entry per SOD Category. There is already an entry for that SOD Category."],
    ["RiskQuestion", "The Risk Question needs to be unique. There is already an entry with that value."],
    ["RiskStatus", "The Risk Status needs to be unique. There is already an entry with that value."],
    ["SODCategory", "There cant be multiples of the same SOD Category for each SOD Type."],
    ["SODDescription", "There cant be multiples of the same SOD Rating for each SOD Category."],
    ["Attachment", "The combination of the Attachment Description and storage Key needs to be unique. There is already an entry with those value."],
    ["ConfigurableList", "There cant be multiples of the same Value for each ParamID."],
    ["Enitiy", "The Entity Name needs to be unique. There is already an entry with that value."],
    ["ExtendedMitigatingAction", "There cannot be multiple Extention Dates per Mitigating Action."],
    ["MitigatingActionStatus", "The Mitigating Action Status needs to be unique. There is already an entry with that value."],
    ["Role", "There cant be multiples of the same Role Name for each Role Type."],
  ]);

  filterRiskDashboardDataset(selectedFilter: any, filteredRisks: any): any {
    let updatedFilterConditions: any = {};
    if (selectedFilter.category) {
      filteredRisks = filteredRisks.filter((risk: any) => risk.SODCategory == selectedFilter.category);
    }
    if (selectedFilter.entity) {
      filteredRisks = filteredRisks.filter((risk: any) => risk.entityName == selectedFilter.entity)
    }
    if (selectedFilter.impactedFunctionalArea) {
      filteredRisks = filteredRisks.filter((risk: any) => risk.functionalArea == selectedFilter.impactedFunctionalArea,)
    }
    if (selectedFilter.impactedBusinessProcess) {
      filteredRisks = filteredRisks.filter((risk: any) => risk.businessProcess == selectedFilter.impactedBusinessProcess)
    }
    if (selectedFilter.year) {
      filteredRisks = filteredRisks.filter((risk: any) => risk.creationDate.getFullYear() == selectedFilter.year)
    }
    // let analysedRiskProfileDashboard = this.analyseRiskDataForDashboard(riskCategories, dataForAnalysis);
    updatedFilterConditions.categories = Array.from(new Set(filteredRisks.map((item: any) => item.SODCategory)));
    updatedFilterConditions.entities = Array.from(new Set(filteredRisks.map((item: any) => item.entityName)));
    updatedFilterConditions.impactedFuncationalAreas = Array.from(new Set(filteredRisks.map((item: any) => item.functionalArea)));
    updatedFilterConditions.impactedBusinessProcess = Array.from(new Set(filteredRisks.map((item: any) => item.businessProcess)));
    updatedFilterConditions.availableYears = Array.from(new Set(filteredRisks.map((item: any) => item.creationDate.getFullYear())));
    let updatedFilteredRiskDataPackage = {
      filteredRisks: filteredRisks,
      filterConditions: updatedFilterConditions
    }
    return updatedFilteredRiskDataPackage;
  }

  analyseRiskDataForRiskProfileDashboard(riskCategories: any[], dataForAnalysis: any[]): any {
    let analysedRiskDataPackage: any = {};
    let riskProfileData: any = {
      currentHighRisks: 0,
      totalHighRisks: 0,
      acceptedHighRisks: 0,
      reducedHighRisks: 0,
      avoidedHighRisks: 0,
      pendingHighRisks: 0,
      reducedHighToHighRisks: 0,
      reducingHighToHighRisks: 0,
      reducedHighToMediumRisks: 0,
      reducingHighToMediumRisks: 0,
      reducedHighToLowRisks: 0,
      reducingHighToLowRisks: 0,
      eliminatedHighRisks: 0,
      eliminatingHighRisks: 0,
      currentMediumRisks: 0,
      totalMediumRisks: 0,
      acceptedMediumRisks: 0,
      reducedMediumRisks: 0,
      avoidedMediumRisks: 0,
      pendingMediumRisks: 0,
      reducedMediumToHighRisks: 0,
      reducingMediumToHighRisks: 0,
      reducedMediumToMediumRisks: 0,
      reducingMediumToMediumRisks: 0,
      reducedMediumToLowRisks: 0,
      reducingMediumToLowRisks: 0,
      eliminatedMediumRisks: 0,
      eliminatingMediumRisks: 0,
      currentLowRisks: 0,
      totalLowRisks: 0,
      acceptedLowRisks: 0,
      reducedLowRisks: 0,
      avoidedLowRisks: 0,
      pendingLowRisks: 0,
      reducedLowToHighRisks: 0,
      reducingLowToHighRisks: 0,
      reducedLowToMediumRisks: 0,
      reducingLowToMediumRisks: 0,
      reducedLowToLowRisks: 0,
      reducingLowToLowRisks: 0,
      eliminatedLowRisks: 0,
      eliminatingLowRisks: 0
    };
    let riskCategoriesGraphData: any[] = [];
    for (let i = 0; i < riskCategories.length; i++) {
      riskCategoriesGraphData.push({
        name: riskCategories[i].SODCategory,
        totalRisks: 0,
        high: 0,
        medium: 0,
        low: 0
      })
    }
    let analysedData = dataForAnalysis;
    for (let i = 0; i < analysedData.length; i++) {
      // Analyse Data for High, Medium and Low distribution both mitigated and non mitigated   
      if (analysedData[i].initialRiskLevel === "High") {
        riskProfileData.totalHighRisks++;
        if (analysedData[i].riskDecision === "Accept") {
          riskProfileData.acceptedHighRisks++;
        } else if (analysedData[i].riskDecision === "Reduce") {
          riskProfileData.reducedHighRisks++;
        } else if (analysedData[i].riskDecision === "Eliminate") {
          riskProfileData.avoidedHighRisks++;
        } else {
          riskProfileData.pendingHighRisks++;
        }
        if (analysedData[i].riskDecision === "Eliminate" && analysedData[i].riskStatus === "Managed") {
          riskProfileData.eliminatedHighRisks++;
        } else if (analysedData[i].riskDecision === "Eliminate" && analysedData[i].riskStatus != "Managed") {
          riskProfileData.eliminatingHighRisks++;
        }
        if (analysedData[i].riskStatus === "Managed" && analysedData[i].residualRiskLevel) {
          if (analysedData[i].residualRiskLevel === "High") {
            riskProfileData.reducedHighToHighRisks++;
          } else if (analysedData[i].residualRiskLevel === "Medium") {
            riskProfileData.reducedHighToMediumRisks++;
          } else if (analysedData[i].residualRiskLevel === "Low") {
            riskProfileData.reducedHighToLowRisks++;
          }
        }
        if (analysedData[i].riskStatus != "Managed" && analysedData[i].residualRiskLevel) {
          if (analysedData[i].residualRiskLevel === "High") {
            riskProfileData.reducingHighToHighRisks++;
          } else if (analysedData[i].residualRiskLevel === "Medium") {
            riskProfileData.reducingHighToMediumRisks++;
          } else if (analysedData[i].residualRiskLevel === "Low") {
            riskProfileData.reducingHighToLowRisks++;
          }
        }
      } else if (analysedData[i].initialRiskLevel === "Medium") {
        riskProfileData.totalMediumRisks++;
        if (analysedData[i].riskDecision === "Accept") {
          riskProfileData.acceptedMediumRisks++;
        } else if (analysedData[i].riskDecision === "Reduce") {
          riskProfileData.reducedMediumRisks++;
        } else if (analysedData[i].riskDecision === "Eliminate") {
          riskProfileData.avoidedMediumRisks++;
        } else {
          riskProfileData.pendingMediumRisks++;
        }
        if (analysedData[i].riskDecision === "Eliminate" && analysedData[i].riskStatus === "Managed") {
          riskProfileData.eliminatedMediumRisks++;
        } else if (analysedData[i].riskDecision === "Eliminate" && analysedData[i].riskStatus != "Managed") {
          riskProfileData.eliminatingMediumRisks++;
        }
        if (analysedData[i].riskStatus === "Managed" && analysedData[i].residualRiskLevel) {
          if (analysedData[i].residualRiskLevel === "High") {
            riskProfileData.reducedMediumToHighRisks++;
          } else if (analysedData[i].residualRiskLevel === "Medium") {
            riskProfileData.reducedMediumToMediumRisks++;
          } else if (analysedData[i].residualRiskLevel === "Low") {
            riskProfileData.reducedMediumToLowRisks++;
          }
        }

        if (analysedData[i].riskStatus != "Managed" && analysedData[i].residualRiskLevel) {
          if (analysedData[i].residualRiskLevel === "High") {
            riskProfileData.reducingMediumToHighRisks++;
          } else if (analysedData[i].residualRiskLevel === "Medium") {
            riskProfileData.reducingMediumToMediumRisks++;
          } else if (analysedData[i].residualRiskLevel === "Low") {
            riskProfileData.reducingMediumToLowRisks++;
          }
        }
      } else if (analysedData[i].initialRiskLevel === "Low") {
        riskProfileData.totalLowRisks++;
        if (analysedData[i].riskDecision === "Accept") {
          riskProfileData.acceptedLowRisks++;
        } else if (analysedData[i].riskDecision === "Reduce") {
          riskProfileData.reducedLowRisks++;
        } else if (analysedData[i].riskDecision === "Eliminate") {
          riskProfileData.avoidedLowRisks++;
        } else {
          riskProfileData.pendingLowRisks++;
        }
        if (analysedData[i].riskDecision === "Eliminate" && analysedData[i].riskStatus === "Managed") {
          riskProfileData.eliminatedLowRisks++;
        } else if (analysedData[i].riskDecision === "Eliminate" && analysedData[i].riskStatus != "Managed") {
          riskProfileData.eliminatingLowRisks++;
        }
        if (analysedData[i].riskStatus === "Managed" && analysedData[i].residualRiskLevel) {
          if (analysedData[i].residualRiskLevel === "High") {
            riskProfileData.reducedLowToHighRisks++;
          } else if (analysedData[i].residualRiskLevel === "Medium") {
            riskProfileData.reducedLowToMediumRisks++;
          } else if (analysedData[i].residualRiskLevel === "Low") {
            riskProfileData.reducedLowToLowRisks++;
          }
        }

        if (analysedData[i].riskStatus != "Managed" && analysedData[i].residualRiskLevel) {
          if (analysedData[i].residualRiskLevel === "High") {
            riskProfileData.reducingLowToHighRisks++;
          } else if (analysedData[i].residualRiskLevel === "Medium") {
            riskProfileData.reducingLowToMediumRisks++;
          } else if (analysedData[i].residualRiskLevel === "Low") {
            riskProfileData.reducingLowToLowRisks++;
          }
        }
      }
      // Analyse Data for Risk per Severity Category     
      for (let j = 0; j < riskCategoriesGraphData.length; j++) {
        if (analysedData[i].SODCategory == riskCategoriesGraphData[j].name && analysedData[i].riskDecision != "Avoid") {
          riskCategoriesGraphData[j].totalRisks++;
          if (analysedData[i].riskStatus === "Managed" && analysedData[i].riskDecision === "Reduce") {
            if (analysedData[i].residualRiskLevel === "High") {
              riskCategoriesGraphData[j].high++;
            } else if (analysedData[i].residualRiskLevel === "Medium") {
              riskCategoriesGraphData[j].medium++;
            } else if (analysedData[i].residualRiskLevel === "Low") {
              riskCategoriesGraphData[j].low++;
            }
          } else {
            if (analysedData[i].initialRiskLevel === "High") {
              riskCategoriesGraphData[j].high++;
            } else if (analysedData[i].initialRiskLevel === "Medium") {
              riskCategoriesGraphData[j].medium++;
            } else if (analysedData[i].initialRiskLevel === "Low") {
              riskCategoriesGraphData[j].low++;
            }
          }
        }
      }
    }
    riskCategoriesGraphData = riskCategoriesGraphData.filter((category: any) => category.totalRisks > 0);
    riskProfileData.currentHighRisks = riskProfileData.totalHighRisks + riskProfileData.reducedMediumToHighRisks + riskProfileData.reducedLowToHighRisks - (riskProfileData.reducedHighToMediumRisks + riskProfileData.reducedHighToLowRisks + riskProfileData.eliminatedHighRisks);
    riskProfileData.currentMediumRisks = riskProfileData.totalMediumRisks + riskProfileData.reducedHighToMediumRisks + riskProfileData.reducedLowToMediumRisks - (riskProfileData.reducedMediumToLowRisks + riskProfileData.reducedMediumToHighRisks + riskProfileData.eliminatedMediumRisks);
    riskProfileData.currentLowRisks = riskProfileData.totalLowRisks + riskProfileData.reducedHighToLowRisks + riskProfileData.reducedMediumToLowRisks - (riskProfileData.reducedLowToMediumRisks + riskProfileData.reducedLowToHighRisks + riskProfileData.eliminatedLowRisks);
    analysedRiskDataPackage = {
      riskProfileData: riskProfileData,
      riskCategoriesGraphData: riskCategoriesGraphData
    }
    return analysedRiskDataPackage;
  }


  calculateRSandRPN_XXXX(severity: number, occurrence: number, detectability: number) {
    let calculatedRSandRPN: any = { RS: null, RPN: null };
    if (severity && occurrence) {
      calculatedRSandRPN.RS = severity * occurrence;
      if (detectability)
        calculatedRSandRPN.RPN = severity * occurrence * detectability;
    }
    return calculatedRSandRPN;
  }

  calculateRiskLevelMedicalDevice_XXXX(severity: number, occurrence: number, detectability: number, detectabilityMandatory: boolean): any {
    let riskLevel = null;
    if (!severity || !occurrence) {
      riskLevel = null;
    } else {
      if (detectabilityMandatory) {
        let occurrenceXDetectability = this.calculateOccurrenceVsDetectabilityMedicalDevice_XXXX(occurrence, detectability);
        if (severity == 1 && (occurrenceXDetectability == 4 || occurrenceXDetectability == 5)) {
          riskLevel = 'Medium';
        } else if (severity == 1 && (occurrenceXDetectability == 1 || occurrenceXDetectability == 2 || occurrenceXDetectability == 3)) {
          riskLevel = 'Low';
        } else if (severity == 2 && (occurrenceXDetectability == 1 || occurrenceXDetectability == 2)) {
          riskLevel = 'Low';
        } else if (severity == 2 && (occurrenceXDetectability == 3 || occurrenceXDetectability == 4 || occurrenceXDetectability == 5)) {
          riskLevel = 'Medium';
        } else if (severity == 3 && (occurrenceXDetectability == 1)) {
          riskLevel = 'Low';
        } else if (severity == 3 && (occurrenceXDetectability == 2 || occurrenceXDetectability == 3 || occurrenceXDetectability == 4)) {
          riskLevel = 'Medium';
        } else if (severity == 3 && (occurrenceXDetectability == 5)) {
          riskLevel = 'High';
        } else if (severity == 4 && (occurrenceXDetectability == 1 || occurrenceXDetectability == 2 || occurrenceXDetectability == 3)) {
          riskLevel = 'Medium';
        } else if (severity == 4 && (occurrenceXDetectability == 4 || occurrenceXDetectability == 5)) {
          riskLevel = 'High';
        } else if (severity == 5 && (occurrenceXDetectability == 1)) {
          riskLevel = 'Medium';
        } else if (severity == 5 && (occurrenceXDetectability == 2 || occurrenceXDetectability == 3 || occurrenceXDetectability == 4 || occurrenceXDetectability == 5)) {
          riskLevel = 'High';
        }
      } else {
        // DF Comment: Possible duplication with function calculateRiskLevelGeneralWithoutDetection(severity: number, occurrence: number)
        if (severity == 1 && (occurrence == 1 || occurrence == 2 || occurrence == 3)) { riskLevel = 'Low' }
        else if (severity == 2 && (occurrence == 2 || occurrence == 1)) { riskLevel = 'Low' }
        else if (severity == 3 && occurrence == 1) { riskLevel = 'Low' }

        else if (severity == 1 && (occurrence == 4 || occurrence == 5)) { riskLevel = 'Medium' }
        else if (severity == 2 && (occurrence == 3 || occurrence == 4 || occurrence == 5)) { riskLevel = 'Medium' }
        else if (severity == 3 && (occurrence == 2 || occurrence == 3 || occurrence == 4)) { riskLevel = 'Medium' }
        else if (severity == 4 && (occurrence == 1 || occurrence == 2 || occurrence == 3)) { riskLevel = 'Medium' }
        else if (severity == 5 && (occurrence == 1)) { riskLevel = 'Medium' }

        else if (severity == 3 && (occurrence == 5)) { riskLevel = 'High' }
        else if (severity == 4 && (occurrence == 4 || occurrence == 5)) { riskLevel = 'High' }
        else if (severity == 5 && (occurrence == 2 || occurrence == 3 || occurrence == 4 || occurrence == 5)) { riskLevel = 'High' }
      }
    }
    return riskLevel;
  }

  calculateOccurrenceVsDetectabilityMedicalDevice_XXXX(occurrence: number, detectability: number): any {
    let occurrenceXdetectability;

    if (detectability == 1) { occurrenceXdetectability = 1 }
    else if (detectability == 2 && (occurrence == 2 || occurrence == 1)) { occurrenceXdetectability = 1 }
    else if (detectability == 3 && occurrence == 1) { occurrenceXdetectability = 1 }
    else if (detectability == 4 && occurrence == 1) { occurrenceXdetectability = 1 }

    else if (detectability == 2 && (occurrence != 2 && occurrence != 1)) { occurrenceXdetectability = 2 }
    else if (detectability == 3 && (occurrence == 3 || occurrence == 2)) { occurrenceXdetectability = 2 }
    else if (detectability == 4 && (occurrence == 2)) { occurrenceXdetectability = 2 }
    else if (detectability == 5 && (occurrence == 1 || occurrence == 2)) { occurrenceXdetectability = 2 }


    else if (detectability == 3 && (occurrence == 5 || occurrence == 4)) { occurrenceXdetectability = 3 }
    else if (detectability == 4 && (occurrence == 3)) { occurrenceXdetectability = 3 }
    else if (detectability == 5 && (occurrence == 3)) { occurrenceXdetectability = 3 }

    else if (detectability == 4 && (occurrence == 4 || occurrence == 5)) { occurrenceXdetectability = 4 }
    else if (detectability == 5 && (occurrence == 4)) { occurrenceXdetectability = 4 }

    else if (detectability == 5 && (occurrence == 5)) { occurrenceXdetectability = 5 }

    if (occurrenceXdetectability)
      return occurrenceXdetectability;
    else return null;
  }


  calculateRiskLevelGeneralWithDetection(severity: number, occurrence: number, detectability: number): string {
    let occurrenceDetectability = occurrence * detectability;
    let riskLevel: string = "";
    if ((occurrenceDetectability < 16 && severity === 1) || (occurrenceDetectability < 12 && severity === 2) || (occurrenceDetectability < 5 && severity === 3)) { riskLevel = "Low" }

    else if ((occurrenceDetectability > 15 && severity === 1) || (occurrenceDetectability > 10 && severity === 2) || (occurrenceDetectability > 4 && occurrenceDetectability < 25 && severity === 3)
      || (occurrenceDetectability < 16 && severity === 4) || (occurrenceDetectability < 5 && severity === 5)) { riskLevel = "Medium" }

    else if ((occurrenceDetectability > 20 && severity === 3) || (occurrenceDetectability > 15 && severity === 4) || (occurrenceDetectability > 4 && severity === 5)) { riskLevel = "High" }

    return riskLevel;

  }

  calculateRiskLevelGeneralWithoutDetection(severity: number, occurrence: number): string {
    let riskLevel: string = "";
    if ((occurrence < 4 && severity === 1) || (occurrence < 3 && severity === 2) || (occurrence < 2 && severity === 3)) { riskLevel = "Low" }

    else if ((occurrence > 3 && severity === 1) || (occurrence > 2 && severity === 2) || (occurrence > 1 && occurrence < 5 && severity === 3)
      || (occurrence < 4 && severity === 4) || (occurrence < 2 && severity === 5)) { riskLevel = "Medium" }

    else if ((occurrence > 4 && severity === 3) || (occurrence > 3 && severity === 4) || (occurrence > 1 && severity === 5)) { riskLevel = "High" }

    return riskLevel;

  }

  monthDifference(startDate: Date, endDate: Date): number {
    return (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth())
  }

  createGraphTemplate(dataForAnalysis: any): any {
    let graphTemplate: any = {};
    let currentDate: Date;
    currentDate = new Date();
    let oldestRiskDate = new Date();
    for (let i = 0; i < dataForAnalysis.length; i++) {
      if (dataForAnalysis[i].creationDate < oldestRiskDate) {
        oldestRiskDate = dataForAnalysis[i].creationDate
      }
    }
    let numberOfMonthDataPoints: number = this.monthDifference(oldestRiskDate, currentDate) + 1;
    let graphForMonth: any[] = [];
    let monthCounter = oldestRiskDate.getMonth();
    let yearCounter = oldestRiskDate.getFullYear() - 2000; // to convert to 2 digit year subtract 2000, watch out in year 3000 :)
    for (let i = 0; i < numberOfMonthDataPoints; i++) {
      graphForMonth.push({
        high: 0,
        medium: 0,
        low: 0,
        accept: 0,
        reduce: 0,
        eliminate: 0,
        created: 0,
        approved: 0,
        pending: i + 2,
        name: this.monthOfYear[monthCounter] + yearCounter.toString()
      })
      monthCounter++;
      if (monthCounter > 11) {
        monthCounter = 0;
        yearCounter++;
      }
    }
    graphTemplate = {
      graphForMonth: graphForMonth,
      oldestRiskDate: oldestRiskDate
    }
    return graphTemplate

  }

  getPeriodicReviewStatusColor(status: string, daysRemaining: number): string {
    let periodicReviewColour: string = "#0850b2";
    if (status == 'Periodic Review') {
      periodicReviewColour = this.globalService.configurableParameters.periodicReviewCountdownSecondaryColour; //Countdown
    }
    else if (status == "Periodic Review Due") {
      // DH-Comment : retrived value is in Months -> curently multiply this value by 30 to get # of days -> ammend to get exact days 
      if (daysRemaining > Number(this.globalService.configurableParameters.warningNoticePeriodicReview) * 30 && daysRemaining <= Number(this.globalService.configurableParameters.advancedNoticePeriodicReview) * 30) { //Advanced Notice
        periodicReviewColour = this.globalService.configurableParameters.advancedNoticePeriodicReviewSecondaryColour;
      } else if (daysRemaining > 0 && daysRemaining <= Number(this.globalService.configurableParameters.warningNoticePeriodicReview) * 30) { //Due Notice
        periodicReviewColour = this.globalService.configurableParameters.warningNoticePeriodicReviewSecondaryColour;
      }
    } else if (status == "Periodic Review Overdue") {
      periodicReviewColour = this.globalService.configurableParameters.periodicReviewOverdueSecondaryColour; //Overdue
    }
    return periodicReviewColour
  }

  calculateDaysRemainingPeriodicReview(): any {
    let todaysDate = moment();
    let periodicReviewDueDate = moment(this.selectedRiskAssessment.periodicReviewDueDate);
    let periodicReviewDaysRemaining = periodicReviewDueDate.diff(todaysDate, 'days');
    let periodicReviewDueDisplayedText;
    let periodicReviewDisplayedColor;
    if (periodicReviewDaysRemaining > 0) { //checks if Overdue, negative number indicates Overdue 
      if (periodicReviewDaysRemaining < Number(this.globalService.configurableParameters.advancedNoticePeriodicReview) * 30) {
        periodicReviewDueDisplayedText = "Periodic Review Due"; //changed displayed text from 'Periodic Review'
        periodicReviewDisplayedColor = this.getPeriodicReviewStatusColor(periodicReviewDueDisplayedText, periodicReviewDaysRemaining) //choice of 2 configurable colors when 'DUE'
      } else {
        periodicReviewDueDisplayedText = "Periodic Review";
        periodicReviewDisplayedColor = this.getPeriodicReviewStatusColor(periodicReviewDueDisplayedText, periodicReviewDaysRemaining) //1 configurable color when > 90
      }
    } else {
      periodicReviewDueDisplayedText = "Periodic Review Overdue";
      periodicReviewDaysRemaining = periodicReviewDaysRemaining * -1; //makes positive number for display purposes
      periodicReviewDisplayedColor = this.getPeriodicReviewStatusColor(periodicReviewDueDisplayedText, periodicReviewDaysRemaining) //1 configurable color when 'OVERDUE'
    }
    let returnObject = {
      daysRemaining: periodicReviewDaysRemaining,
      periodicReviewDueDisplayedText: periodicReviewDueDisplayedText,
      periodicReviewDisplayedColor: periodicReviewDisplayedColor,
    }
    return returnObject;
  }
}