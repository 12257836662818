<div class="dialog">
    <div class="dialog-header">
        <div class="header-text center">
            Approvals Dialog
        </div>
        <div class="align-items-start">
            <div class="header-icon">
                <button mat-button class="icon-btn dialog-close-btn" (click)="dialogRef.close({ closeType: 'cancel' })"
                    id="Approvals-DialogCompenent-closeDialogIcon">
                    <div class="icon">
                        <fa-icon [icon]="times"></fa-icon>
                    </div>
                </button>
            </div>
        </div>
    </div>
    <div class="dialog-content justify-content-center flex-row">
        <div class="checklist">
            <div class="checklist-item">
                <p>Risk Assessment not in edit mode
                </p>
                <div class="icon timesbox" *ngIf="data.editMode">
                    <fa-icon [icon]="timesSquare"></fa-icon>
                </div>
                <div class="icon checkmarkbox" *ngIf="!data.editMode">
                    <fa-icon [icon]="checkSquare"></fa-icon>
                </div>
            </div>
            <div class="checklist-item">
                <p>Logged in user is risk assessment lead
                </p>
                <div class="icon timesbox" *ngIf="data.riskAssessmentLead != globalService.userID">
                    <fa-icon [icon]="timesSquare"></fa-icon>
                </div>
                <div class="icon checkmarkbox" *ngIf="data.riskAssessmentLead == globalService.userID">
                    <fa-icon [icon]="checkSquare"></fa-icon>
                </div>
            </div>
            <div class="checklist-item">
                <p>Risk assessment has risks
                </p>
                <div class="icon timesbox" *ngIf="armService.selectedAssessmentRisks.length == 0">
                    <fa-icon [icon]="timesSquare"></fa-icon>
                </div>
                <div class="icon checkmarkbox" *ngIf="armService.selectedAssessmentRisks.length > 0">
                    <fa-icon [icon]="checkSquare"></fa-icon>
                </div>
            </div>
            <div class="checklist-item" *ngIf="armService.selectedAssessmentRisks.length > 0">
                <p>All risks are complete
                </p>
                <div class="icon timesbox" *ngIf="!data.risksComplete">
                    <fa-icon [icon]="timesSquare"></fa-icon>
                </div>
                <div class="icon checkmarkbox" *ngIf="data.risksComplete">
                    <fa-icon [icon]="checkSquare"></fa-icon>
                </div>
            </div>
            <div class="checklist-item">
                <p>Conclusion inputted
                </p>
                <div class="icon timesbox" *ngIf="!data.riskAssessmentConclusion">
                    <fa-icon [icon]="timesSquare"></fa-icon>
                </div>
                <div class="icon checkmarkbox" *ngIf="data.riskAssessmentConclusion">
                    <fa-icon [icon]="checkSquare"></fa-icon>
                </div>
            </div>
            <div class="checklist-item">
                <p>Team Complete
                </p>
                <div class="icon timesbox" *ngIf="!data.teamComplete">
                    <fa-icon [icon]="timesSquare"></fa-icon>
                </div>
                <div class="icon checkmarkbox" *ngIf="data.teamComplete">
                    <fa-icon [icon]="checkSquare"></fa-icon>
                </div>
            </div>
            <div class="checklist-item" *ngIf="armService.selectedRiskAssessment.isPeriodicReview">
                <p>Periodic Review inputted
                </p>
                <div class="icon timesbox" *ngIf="!armService.selectedRiskAssessment.periodicReviewComment">
                    <fa-icon [icon]="timesSquare"></fa-icon>
                </div>
                <div class="icon checkmarkbox" *ngIf="armService.selectedRiskAssessment.periodicReviewComment">
                    <fa-icon [icon]="checkSquare"></fa-icon>
                </div>
            </div>
            <div class="checklist-item" *ngIf="data.type == 'Approval'">
                <p>Review Complete
                </p>
                <div class="icon timesbox" *ngIf="data.reviewsComplete.length == 0">
                    <fa-icon [icon]="timesSquare"></fa-icon>
                </div>
                <div class="icon checkmarkbox" *ngIf="data.reviewsComplete.length > 0">
                    <fa-icon [icon]="checkSquare"></fa-icon>
                </div>
            </div>
        </div>
    </div>
    <div class="dialog-footer">
        <div class="only-buttons">
            <button mat-button class="btn done" (click)="dialogRef.close()" id="control-register-risks-dialog-close-btn">
                <p>Close</p>
            </button>
        </div>
    </div>


</div>