<div class="risk-record-option" *ngIf="!viewAuditTrailBool">
    <div class="return-button-container">
        <button class="return-button" [ngClass]="{'disabled': editModeBool}"
            (click)="navigateToExpandedDetailView_0409()">
            <fa-icon class="white-icon" [icon]="arrowLeft"></fa-icon>
        </button>
        <div class="return-button-margin">
        </div>
    </div>
    <div class="risk-record-scroll-container">
        <div class="risk-record-container">
            <div class="risk-record-details-container">
                <div class="risk-record-details"
                    [ngClass]="{'managed': (selectedRisk.riskStatus == 'Managed' && selectedRisk.isActionEliminate), 
                'low': selectedRisk.currentRiskLevel == 'Low' && !(selectedRisk.riskStatus == 'Managed' && selectedRisk.isActionEliminate),
                'medium': selectedRisk.currentRiskLevel == 'Medium' &&!(selectedRisk.riskStatus == 'Managed' && selectedRisk.isActionEliminate),
                'high': selectedRisk.currentRiskLevel == 'High' && !(selectedRisk.riskStatus == 'Managed' && selectedRisk.isActionEliminate)}">
                    <div class="risk-record-details-header-container">
                        <div class="risk-record-group-box-title">
                            <h1 id="risk-record-iD">RISK DESCRIPTION</h1>
                        </div>
                        <div class="risk-record-reference">
                            <button class="btn icon-btn icon-btn-white" (click)="nextPrevRiskRecord(false)"
                                [disabled]="editModeBool">
                                <div class="icon">
                                    <fa-icon [icon]="arrowLeft"></fa-icon>
                                </div>
                            </button>
                            <h1 id="risk-record-REF">ID: {{selectedRisk.riskRef}}</h1>
                            <button class="btn icon-btn icon-btn-white" (click)="nextPrevRiskRecord(true)"
                                [disabled]="editModeBool">
                                <div class="icon">
                                    <fa-icon [icon]="arrowRight"></fa-icon>
                                </div>
                            </button>
                        </div>
                        <div class="risk-record-version">
                            <h1>Version: {{selectedRisk.riskVersion}}.0</h1>
                        </div>
                    </div>
                    <div class="risk-description-container"
                        [ngClass]="{'innerManaged': (selectedRisk.riskStatus == 'Managed' && selectedRisk.isActionEliminate), 
                    'innerLow':  selectedRisk.currentRiskLevel == 'Low' && !(selectedRisk.riskStatus == 'Managed' && selectedRisk.isActionEliminate),
                    'innerMedium': selectedRisk.currentRiskLevel == 'Medium' && !(selectedRisk.riskStatus == 'Managed' && selectedRisk.isActionEliminate),
                    'innerHigh': selectedRisk.currentRiskLevel == 'High' && !(selectedRisk.riskStatus == 'Managed' && selectedRisk.isActionEliminate)}">
                        <h3 class="process-step">Process Step: {{selectedRisk.itemGroupName}}</h3>
                        <div class="risk-record-details-content-container">
                            <div class="failure-cause-effect-container">
                                <div class="failure-cause-effect-header effect">
                                    <p>{{globalService.configurableParameters.riskEffect}}</p>
                                </div>
                                <div class="failure-cause-effect-content" *ngIf="selectedRisk.failureEffect">
                                    {{selectedRisk.failureEffect}}
                                </div>
                            </div>
                            <div class="failure-cause-effect-container">
                                <div class="failure-cause-effect-header failure-mode">
                                    <p>Failure Mode / Hazard</p>
                                </div>
                                <div class="failure-cause-effect-content" *ngIf="selectedRisk.failureMode">
                                    {{selectedRisk.failureMode}}
                                </div>
                            </div>
                            <div class="failure-cause-effect-container">
                                <div class="failure-cause-effect-header cause">
                                    <p>{{globalService.configurableParameters.riskCause}}</p>
                                </div>
                                <div class="failure-cause-effect-content" *ngIf="selectedRisk.failureCause">
                                    {{selectedRisk.failureCause}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="risk-record-dashboard-container">
                    <div class="risk-record-dashboard-header">
                        <div class="risk-record-dashboard-title">
                            <h1>DASHBOARD</h1>
                        </div>
                        <div class="risk-record-ellipsis">
                            <button mat-button class="icon-btn icon-btn-white" id="riskRecord-ellipsis-btn"
                                [matMenuTriggerFor]="menu" [disabled]="editModeBool">
                                <div class="icon">
                                    <fa-icon [icon]="ellipsisHorizontal"></fa-icon>
                                </div>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="editRiskRecord()" id="riskRecord-ellipsis-edit"
                                    *ngIf="(riskPermission == 'Editor' || riskPermission == 'Owner') && 
                                        armService.selectedRiskAssessment.riskAssessmentStatus == 'Draft' && !armService.selectedRisk.approvedDate">
                                    <p>Edit</p>
                                </button>
                                <button mat-menu-item
                                    *ngIf="riskPermission == 'Owner' && !globalService.configurableParameters.turnOffDAM"
                                    id="riskRecord-ellipsis-protectAccess"
                                    (click)="openDataAccessManagementDialog_0192()">
                                    <p>Protect Access</p>
                                </button>
                                <button mat-menu-item id="riskRecord-ellipsis-initiateRevision"
                                    [disabled]="armService.selectedRiskAssessment.approvedDate || !armService.selectedRisk.approvedDate ||
                                            (armService.selectedRiskAssessment.riskAssessmentVersion === 1 || armService.selectedRisk.riskVersion >= armService.selectedRiskAssessment.riskAssessmentVersion)"
                                    *ngIf="(armService.selectedRisk.permissionName == 'Editor' || armService.selectedRisk.permissionName == 'Owner') && 
                                            (!armService.selectedRiskAssessment.approvedDate && armService.selectedRisk.approvedDate  &&
                                            (armService.selectedRiskAssessment.riskAssessmentVersion != 1 && armService.selectedRisk.riskVersion <= armService.selectedRiskAssessment.riskAssessmentVersion))"
                                    (click)="createNewRiskReport_XXXX()">
                                    <p>Initiate a Revision</p>
                                </button>
                                <button mat-menu-item id="riskRecord-ellipsis-previewReport"
                                    (click)="previewRiskAssessmentReport()">
                                    <p>Preview Report</p>
                                </button>
                                <button mat-menu-item (click)="navigateToRiskVersionControl_0409()"
                                    id="riskRecord-ellipsis-previousVersion"
                                    [disabled]="selectedRisk.riskVersion === 1">
                                    <p>View Previous Version</p>
                                </button>
                                <button mat-menu-item id="riskRecord-ellipsis-viewAuditTrail"
                                    (click)="prepareDataForGetAuditTrail()">
                                    <p>View Audit Trail</p>
                                </button>
                            </mat-menu>
                        </div>


                    </div>
                    <div class="risk-record-dashboard-content">
                        <!-- <div class="risk-record-dashboard-proximity"
                            *ngIf="globalService.configurableParameters.showProximity">
                            <div class="proximity-container">
                                <div [ngClass]="{'live': proximityPercentage >= 100 }">Live</div>
                                <div class="proximity-bar">
                                    <div class="proximity-bar-fill" [style.height.%]="proximityPercentage"></div>
                                </div>
                                <div>Proximity</div>
                            </div>
                        </div> -->
                        <div class="risk-record-dashboard-gauges">
                            <div id="risk-record-dashboard-canvas" class="risk-record-dashboard-canvas">
                                <canvas #InitialRiskGauge></canvas>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="risk-record-navigation-container" id="risk-record-navigation-container">
                <div class="menu-items" *ngIf="riskRecordDisplayObject">
                    <div class="menu-item" id="riskRecord-initialRiskAnalysis-menuItem"
                        (click)="selectedSection = sections.initialRiskAnalysis; setRiskRecordDisplayObject_XXXX(true); "
                        [ngClass]="{'selected': selectedSection.route === sections.initialRiskAnalysis.route}">
                        <p>Initial Risk Analysis</p>
                    </div>
                    <div class="menu-item" id="riskRecord-riskControl-menuItem"
                        (click)="selectedSection = sections.riskControl; this.updateTaskPull = !this.updateTaskPull;"
                        [ngClass]="{'selected': selectedSection.route === sections.riskControl.route,
                    'disabled': !selectedRisk.initialRS}">
                        <p>Risk Control</p>
                    </div>
                    <div class="menu-item" id="riskRecord-residualRiskAnalysis-menuItem"
                        (click)="selectedSection = sections.residualRiskAnalysis; setRiskRecordDisplayObject_XXXX(false);"
                        [ngClass]="{'selected': selectedSection.route === sections.residualRiskAnalysis.route,
                'disabled': ((riskRecordForm.value.riskDecision === 'Action' && riskRecordForm.value.riskEliminate) || riskRecordForm.value.riskDecision === 'Accept' || riskRecordForm.value.riskDecision == null)}">
                        <p>Residual Risk Analysis</p>
                    </div>
                    <div class="menu-item" id="riskRecord-details-menuItem" (click)="selectedSection = sections.details"
                        [ngClass]="{'selected': selectedSection.route === sections.details.route}">
                        <p>Details</p>
                    </div>
                    <div class="menu-item" id="riskRecord-team-menuItem" (click)="selectedSection = sections.team"
                        [ngClass]="{'selected': selectedSection.route === sections.team.route}">
                        <p>Team</p>
                    </div>
                </div>
                <div class="record-complete-indicator" *ngIf="riskRecordDisplayObject">
                    <div class="record-complete-block" id="riskRecord-readyForApproval"
                        [ngClass]="{'complete': selectedRisk.readyForApproval, 'incomplete': !selectedRisk.readyForApproval}">
                        <p id="riskRecord-recordComplete" class="record-complete" *ngIf="selectedRisk.readyForApproval">
                            <fa-icon [icon]="checkCircle"></fa-icon>
                            Record Complete
                        </p>
                        <p id="riskRecord-recordIncomplete" class="record-incomplete"
                            *ngIf="!selectedRisk.readyForApproval">
                            <fa-icon [icon]="timesCircle"></fa-icon>
                            Record Incomplete
                        </p>
                        <div class="record-complete-tooltip">
                            <div class="no-risk" *ngIf="riskRecordForm.value.riskStatusID === 2">
                                <div class="risk-record-todo-list">
                                    <div>Risk Identified as No Risk</div>
                                </div>
                            </div>
                            <div class="decision-eliminate"
                                *ngIf="riskRecordForm.value.riskDecision === 'Action' && riskRecordForm.value.riskEliminate">
                                <div class="risk-record-todo-list">
                                    <div class="todo-list-item">
                                        <fa-icon [icon]="checkCircle" *ngIf="selectedRisk.initialRiskLevel"
                                            class="complete"></fa-icon>
                                        <fa-icon [icon]="timesCircle" *ngIf="!selectedRisk.initialRiskLevel"
                                            class="incomplete"></fa-icon>
                                        <p>Initial Risk Level determined</p>
                                    </div>
                                    <div class="todo-list-item">
                                        <fa-icon [icon]="checkCircle" *ngIf="riskRecordForm.value.riskDecision"
                                            class="complete"></fa-icon>
                                        <fa-icon [icon]="timesCircle" *ngIf="!riskRecordForm.value.riskDecision"
                                            class="incomplete"></fa-icon>
                                        <p>Risk Treatment decided</p>
                                    </div>
                                    <div class="todo-list-item">
                                        <fa-icon [icon]="checkCircle"
                                            *ngIf="riskRecordForm.value.riskDecisionJustification" class="complete">
                                        </fa-icon>
                                        <fa-icon [icon]="timesCircle"
                                            *ngIf="!riskRecordForm.value.riskDecisionJustification" class="incomplete">
                                        </fa-icon>
                                        <p>Risk Treatment Justification provided</p>
                                    </div>
                                    <div class="todo-list-item">
                                        <fa-icon [icon]="checkCircle" *ngIf="globalService.tasks.length > 0"
                                            class="complete"></fa-icon>
                                        <fa-icon [icon]="timesCircle" *ngIf="globalService.tasks.length === 0"
                                            class="incomplete"></fa-icon>
                                        <p>Mitigating Actions entered</p>
                                    </div>
                                </div>
                            </div>
                            <div class="decision-reduce"
                                *ngIf="riskRecordForm.value.riskDecision === 'Action' && !riskRecordForm.value.riskEliminate">
                                <div class="risk-record-todo-list">
                                    <div class="todo-list-item">
                                        <fa-icon [icon]="checkCircle" *ngIf="selectedRisk.initialRiskLevel"
                                            class="complete"></fa-icon>
                                        <fa-icon [icon]="timesCircle" *ngIf="!selectedRisk.initialRiskLevel"
                                            class="incomplete"></fa-icon>
                                        <p>Initial Risk Level determined</p>
                                    </div>
                                    <div class="todo-list-item">
                                        <fa-icon [icon]="checkCircle" *ngIf="riskRecordForm.value.riskDecision"
                                            class="complete"></fa-icon>
                                        <fa-icon [icon]="timesCircle" *ngIf="!riskRecordForm.value.riskDecision"
                                            class="incomplete"></fa-icon>
                                        <p>Risk Treatment decided</p>
                                    </div>
                                    <div class="todo-list-item">
                                        <fa-icon [icon]="checkCircle" *ngIf="globalService.tasks.length > 0"
                                            class="complete"></fa-icon>
                                        <fa-icon [icon]="timesCircle" *ngIf="globalService.tasks.length === 0"
                                            class="incomplete"></fa-icon>
                                        <p>Mitigating Actions entered</p>
                                    </div>
                                    <div class="todo-list-item">
                                        <fa-icon [icon]="checkCircle" *ngIf="selectedRisk.residualRiskLevel"
                                            class="complete"></fa-icon>
                                        <fa-icon [icon]="timesCircle" *ngIf="!selectedRisk.residualRiskLevel"
                                            class="incomplete"></fa-icon>
                                        <p>Residual Risk Level determined</p>
                                    </div>
                                </div>
                            </div>
                            <div class="decision-accept" *ngIf="riskRecordForm.value.riskDecision === 'Accept'">
                                <div class="risk-record-todo-list">
                                    <div class="todo-list-item">
                                        <fa-icon [icon]="checkCircle" *ngIf="selectedRisk.initialRiskLevel"
                                            class="complete"></fa-icon>
                                        <fa-icon [icon]="timesCircle" *ngIf="!selectedRisk.initialRiskLevel"
                                            class="incomplete"></fa-icon>
                                        <p>Initial Risk Level determined</p>
                                    </div>
                                    <div class="todo-list-item">
                                        <fa-icon [icon]="checkCircle" *ngIf="riskRecordForm.value.riskDecision"
                                            class="complete"></fa-icon>
                                        <fa-icon [icon]="timesCircle" *ngIf="!riskRecordForm.value.riskDecision"
                                            class="incomplete"></fa-icon>
                                        <p>Risk Treatment decided</p>
                                    </div>
                                    <div class="todo-list-item">
                                        <fa-icon [icon]="checkCircle"
                                            *ngIf="riskRecordForm.value.riskDecisionJustification" class="complete">
                                        </fa-icon>
                                        <fa-icon [icon]="timesCircle"
                                            *ngIf="!riskRecordForm.value.riskDecisionJustification" class="incomplete">
                                        </fa-icon>
                                        <p>Risk Treatment Justification provided</p>
                                    </div>
                                </div>
                            </div>
                            <div class="decision-not-made"
                                *ngIf="!riskRecordForm.value.riskDecision && riskRecordForm.value.riskStatusID !== 2">
                                <div class="risk-record-todo-list">
                                    <div class="todo-list-item">
                                        <fa-icon [icon]="checkCircle" *ngIf="selectedRisk.initialRiskLevel"
                                            class="complete"></fa-icon>
                                        <fa-icon [icon]="timesCircle" *ngIf="!selectedRisk.initialRiskLevel"
                                            class="incomplete"></fa-icon>
                                        <p>Initial Risk Level determined</p>
                                    </div>
                                    <div class="todo-list-item">
                                        <fa-icon [icon]="timesCircle" class="incomplete"></fa-icon>
                                        <p>Risk Treatment decided</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="risk-analysis-evaluation" *ngIf="riskRecordDisplayObject">
                <form [formGroup]="riskRecordForm">
                    <div class="SOD-analysis-container"
                        *ngIf="selectedSection.route === 'initialRiskAnalysis' || selectedSection.route === 'residualRiskAnalysis'">
                        <div class="SOD-failure-cause-effect-container failure-effect">
                            <div class="cause-effect-header">
                                <h1>
                                    {{globalService.configurableParameters.riskEffect}}
                                </h1>
                            </div>
                            <div class="cause-effect-content">
                                <div class="SOD-header">
                                    <div class="SOD-title">
                                        <div>
                                            <div class="SOD-Label">SEVERITY</div>
                                        </div>
                                        <span class="required-asterisk">*</span>
                                    </div>
                                    <div class="SOD-rating">
                                        <div class="SOD-number">
                                            <div id="riskRecord-initialSeverity"
                                                *ngIf="riskRecordDisplayObject.severity">
                                                <div class="SOD-Label">{{riskRecordDisplayObject.severity}}</div>
                                            </div>
                                            <div id="riskRecord-initialSeverity-TBD"
                                                *ngIf="!riskRecordDisplayObject.severity">
                                                <h1>...</h1>
                                            </div>
                                        </div>
                                        <div class="SOD-classification">
                                            <div class="level"
                                                [style.backgroundColor]="riskRecordDisplayObject.severityClassificationColour"
                                                [style.color]="riskRecordDisplayObject.severityClassificationTextColour"
                                                id="riskRecord-initialSeverityClassification"
                                                *ngIf="riskRecordDisplayObject.severityClassification">
                                                <h3>{{riskRecordDisplayObject.severityClassification}}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="riskRecordDisplayObject.severity || globalService.configurableParameters.turnOffTutorial"
                                    class="SOD-container">
                                    <div class="SOD-content-header effect">
                                        <div class="mandatory-label">
                                            <p class="uppercase">RATING</p>
                                            <span class="required-asterisk">*</span>
                                        </div>
                                        <button mat-button class="icon-btn icon-btn-white"
                                            id="riskRecord-initial-failureEffect-edit-btn"
                                            *ngIf="editModeBool && selectedSection.route === 'initialRiskAnalysis'"
                                            (click)="openRatingDialog_0192(true, 'severity')">
                                            <div class="icon">
                                                <fa-icon [icon]="edit"></fa-icon>
                                            </div>
                                        </button>
                                        <button mat-button class="icon-btn icon-btn-white"
                                            id="riskRecord-residual-severity-edit-btn"
                                            *ngIf="editModeBool && selectedSection.route === 'residualRiskAnalysis'"
                                            (click)="openRatingDialog_0192(false, 'severity')">
                                            <div class="icon">
                                                <fa-icon [icon]="edit"></fa-icon>
                                            </div>
                                        </button>
                                    </div>
                                    <div class="SOD-rating-content-container">
                                        <div class="category">
                                            <h3>Category</h3>
                                            <p>{{riskRecordDisplayObject.severityCategoryName}}</p>
                                        </div>

                                        <div class="category-description">
                                            <h3>Category Description</h3>
                                            <p>{{riskRecordDisplayObject.severityDescription}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="( editModeBool && ( selectedSection.route === 'initialRiskAnalysis' && (displayInstructions.instructionSets[0].instructionInitialComplete || displayInstructions.instructionSets[0].guidanceSteps[1].guidanceStepInitialComplete))
                                                || (selectedSection.route === 'residualRiskAnalysis' && (displayInstructions.instructionSets[0].instructionResidualComplete || displayInstructions.instructionSets[0].guidanceSteps[1].guidanceStepResidualComplete)))
                                                || ( !editModeBool && (selectedSection.route === 'initialRiskAnalysis' && displayInstructions.instructionSets[0].instructionInitialComplete)|| (selectedSection.route === 'residualRiskAnalysis' && displayInstructions.instructionSets[0].instructionResidualComplete))
                                                || globalService.configurableParameters.turnOffTutorial"
                                    class="SOD-container">
                                    <div class="SOD-content-header effect">
                                        <p class="uppercase">SEVERITY COMMENT</p>

                                    </div>
                                    <div *ngIf="!editModeBool && riskRecordDisplayObject.severityJustification"
                                        class="SOD-content" id="riskRecord-initialSeverityJustification">
                                        {{riskRecordDisplayObject.severityJustification}}
                                    </div>
                                    <div *ngIf="!editModeBool && !riskRecordDisplayObject.severityJustification && ( (globalService.configurableParameters.turnOffTutorial) || ((selectedSection.route == 'initialRiskAnalysis' && displayInstructions.instructionSets[0].instructionInitialComplete) || (selectedSection.route == 'residualRiskAnalysis' && displayInstructions.instructionSets[0].instructionResidualComplete)))"
                                        class="SOD-content" id="riskRecord-initialSeverityJustification">
                                        <div class="banner notification">
                                            <fa-icon [icon]="exclamationTriangle"></fa-icon>
                                            <h3>There is no Severity Comment.</h3>
                                        </div>
                                    </div>
                                    <div *ngIf="editModeBool && (selectedSection.route == 'initialRiskAnalysis' && ((globalService.configurableParameters.turnOffTutorial) || (displayInstructions.instructionSets[0].instructionInitialComplete || selectedSection.route == 'initialRiskAnalysis' && displayInstructions.instructionSets[0].guidanceSteps[1].guidanceStepInitialComplete)))"
                                        class="" id="TBD">
                                        <textarea rows="5" matInput type="text"
                                            formControlName="initialSeverityJustification"
                                            placeholder="Severity Rating Comment..." id="TBD" maxlength="2000">
                                        </textarea>
                                    </div>
                                    <div *ngIf="editModeBool && ((selectedSection.route == 'residualRiskAnalysis' && ( (globalService.configurableParameters.turnOffTutorial) || displayInstructions.instructionSets[0].instructionResidualComplete || selectedSection.route == 'residualRiskAnalysis' && displayInstructions.instructionSets[0].guidanceSteps[1].guidanceStepResidualComplete)))"
                                        class="" id="TBD">
                                        <textarea rows="5" matInput type="text"
                                            formControlName="residualSeverityJustification"
                                            placeholder="Severity Rating Comment..." id="TBD" maxlength="2000">
                                        </textarea>
                                    </div>
                                </div>
                                <div class="SOD-container"
                                    *ngIf="globalService.configurableParameters.addRiskPreventionControls">
                                    <div class="SOD-content-header effect">
                                        <p class="uppercase">Risk Prevention Control</p>
                                    </div>
                                    <div class="SOD-content">
                                        <div *ngIf="riskPreventionControlDataSource.data.length === 0">
                                            <div class="banner notification">
                                                <fa-icon [icon]="exclamationTriangle"></fa-icon>
                                                <h3>There is no Risk Prevention Controls.</h3>
                                            </div>
                                        </div>
                                        <div *ngIf="riskPreventionControlDataSource.data.length > 0"
                                            class="table-container">
                                            <table mat-table [dataSource]="riskPreventionControlDataSource">
                                                <ng-container matColumnDef="controlID">
                                                    <th mat-header-cell *matHeaderCellDef>
                                                        <p>ID</p>
                                                    </th>
                                                    <td mat-cell *matCellDef="let element">
                                                        <p> {{element.controlID}}</p>
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="control">
                                                    <th mat-header-cell *matHeaderCellDef>
                                                        <p>Control</p>
                                                    </th>
                                                    <td mat-cell *matCellDef="let element">
                                                        <p>{{element.control}}</p>
                                                    </td>
                                                </ng-container>

                                                <tr mat-header-row
                                                    *matHeaderRowDef="controlDisplayedColumns; sticky: true">
                                                </tr>
                                                <tr mat-row *matRowDef="let row; columns: controlDisplayedColumns;">
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <!-- && !globalService.configurableParameters.turnOffTutorial && globalService.tutorialEnabled -->
                                <div *ngIf="!globalService.configurableParameters.turnOffTutorial && ( 
                                            (selectedSection.route === 'initialRiskAnalysis'&& !displayInstructions.instructionSets[0].instructionInitialComplete)
                                            ||
                                                (selectedSection.route === 'residualRiskAnalysis'&& !displayInstructions.instructionSets[0].instructionResidualComplete)
                                            ) " class="SOD-container instructions">
                                    <div class="instructions-title">
                                        <p>GUIDANCE</p>
                                    </div>
                                    <div
                                        *ngFor="let guidanceStep of displayInstructions.instructionSets[0].guidanceSteps; let i=index">
                                        <div class="instruction"
                                            [ngClass]="{'disabled': ((selectedSection.route === 'initialRiskAnalysis' && !guidanceStep.stepInitialEnabled) 
                                            || (selectedSection.route === 'residualRiskAnalysis' && !guidanceStep.stepResidualEnabled))}">
                                            <div class="step"
                                                (click)="selectInstructionAction(guidanceStep.guidanceREF)">
                                                <p>{{i+1}}</p>
                                            </div>
                                            <div class="step-instruction"
                                                [ngClass]="{'step-instruction-strikeout': guidanceStepStrikeout(guidanceStep)}"
                                                (click)="selectInstructionAction(guidanceStep.guidanceREF)">
                                                <p>{{guidanceStep.guidanceDescription}}</p>
                                            </div>
                                            <div *ngIf="(selectedSection.route === 'initialRiskAnalysis' && guidanceStep.guidanceStepInitialComplete) ||
                                            (selectedSection.route === 'residualRiskAnalysis' && guidanceStep.guidanceStepResidualComplete)"
                                                class="step-complete">
                                                <fa-icon [icon]="checkCircle"></fa-icon>
                                            </div>
                                            <div *ngIf="((selectedSection.route === 'initialRiskAnalysis' && 
                                                            !guidanceStep.guidanceStepInitialComplete && 
                                                            guidanceStep.guidanceOptional) || 
                                                            (selectedSection.route === 'residualRiskAnalysis' && 
                                                            !guidanceStep.guidanceStepResidualComplete && 
                                                            guidanceStep.guidanceOptional) )" class="step-cancel"
                                                (click)="cancelOptionalGuidance(0, i)">
                                                <p>Skip</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="SOD-failure-cause-effect-container failure-cause">
                            <div class="cause-effect-header">
                                <h1>
                                    {{globalService.configurableParameters.riskCause}}
                                </h1>

                            </div>
                            <div class="effect-content-container">
                                <div class="cause-effect-content">
                                    <div class="SOD-header">
                                        <div class="SOD-title">
                                            <div>
                                                <div class="SOD-Label">OCCURRENCE</div>
                                            </div>
                                            <span class="required-asterisk">*</span>
                                        </div>
                                        <div class="SOD-rating">
                                            <div class="SOD-number">
                                                <div id="riskRecord-initialOccurrence"
                                                    *ngIf="riskRecordDisplayObject.occurrence">
                                                    <div class="SOD-Label">{{riskRecordDisplayObject.occurrence}}</div>
                                                </div>

                                                <div id="riskRecord-initialOccurrence-TBD"
                                                    *ngIf="!riskRecordDisplayObject.occurrence">
                                                    <h1>...</h1>
                                                </div>
                                            </div>
                                            <div class="SOD-classification">
                                                <div class="level"
                                                    [style.backgroundColor]="riskRecordDisplayObject.occurrenceClassificationColour"
                                                    [style.color]="riskRecordDisplayObject.occurrenceClassificationTextColour"
                                                    id="riskRecord-initialOccurrenceClassification"
                                                    *ngIf="riskRecordDisplayObject.occurrenceClassification">
                                                    <h3>{{riskRecordDisplayObject.occurrenceClassification}}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="SOD-container" *ngIf="( editModeBool && ( selectedSection.route === 'initialRiskAnalysis' && (displayInstructions.instructionSets[1].instructionInitialComplete || displayInstructions.instructionSets[1].guidanceSteps[0].guidanceStepInitialComplete))
                                    || (selectedSection.route === 'residualRiskAnalysis' && (displayInstructions.instructionSets[1].instructionResidualComplete || displayInstructions.instructionSets[1].guidanceSteps[0].guidanceStepResidualComplete)))
                                    || ( !editModeBool && (selectedSection.route === 'initialRiskAnalysis' && displayInstructions.instructionSets[1].instructionInitialComplete)|| (selectedSection.route === 'residualRiskAnalysis' && displayInstructions.instructionSets[1].instructionResidualComplete))
                                    || globalService.configurableParameters.turnOffTutorial">
                                        <div class="SOD-content-header effect">
                                            <p class="uppercase">Prevention Control</p>
                                            <button mat-button class="icon-btn icon-btn-white"
                                                id="riskRecord-initial-failureCause-edit-btn"
                                                *ngIf="editModeBool && selectedSection.route === 'initialRiskAnalysis'"
                                                (click)="openControlDialog_0192(true, 'occurrence')">
                                                <div class="icon">
                                                    <fa-icon [icon]="edit"></fa-icon>
                                                </div>
                                            </button>
                                            <button mat-button class="icon-btn icon-btn-white"
                                                id="riskRecord-initial-failureCause-edit-btn"
                                                *ngIf="editModeBool && selectedSection.route === 'residualRiskAnalysis'"
                                                (click)="openControlDialog_0192(false, 'occurrence')">
                                                <div class="icon">
                                                    <fa-icon [icon]="edit"></fa-icon>
                                                </div>
                                            </button>
                                        </div>
                                        <div class="SOD-content">
                                            <div *ngIf="causePreventionControlDataSource.data.length === 0">
                                                <div class="banner notification">
                                                    <fa-icon [icon]="exclamationTriangle"></fa-icon>
                                                    <h3>There are no Prevention Controls.</h3>
                                                </div>
                                            </div>
                                            <div *ngIf="causePreventionControlDataSource.data.length > 0"
                                                class="table-container">
                                                <table mat-table [dataSource]="causePreventionControlDataSource">
                                                    <ng-container matColumnDef="controlID">
                                                        <th mat-header-cell *matHeaderCellDef>
                                                            <p>ID</p>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <p> {{element.controlID}}</p>
                                                        </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="control">
                                                        <th mat-header-cell *matHeaderCellDef>
                                                            <p>Control</p>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <p>{{element.control}}</p>
                                                        </td>
                                                    </ng-container>

                                                    <tr mat-header-row
                                                        *matHeaderRowDef="controlDisplayedColumns; sticky: true">
                                                    </tr>
                                                    <tr mat-row *matRowDef="let row; columns: controlDisplayedColumns;">
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="riskRecordDisplayObject.occurrence || globalService.configurableParameters.turnOffTutorial"
                                        class="SOD-container">
                                        <div class="SOD-content-header effect">
                                            <div class="mandatory-label">
                                                <p class="uppercase">RATING</p>
                                                <span class="required-asterisk">*</span>
                                            </div>
                                            <button mat-button class="icon-btn icon-btn-white"
                                                id="riskRecord-initial-failureEffect-edit-btn"
                                                *ngIf="editModeBool && selectedSection.route === 'initialRiskAnalysis'"
                                                (click)="openRatingDialog_0192(true, 'occurrence')">
                                                <div class="icon">
                                                    <fa-icon [icon]="edit"></fa-icon>
                                                </div>
                                            </button>
                                            <button mat-button class="icon-btn icon-btn-white"
                                                id="riskRecord-initial-failureEffect-edit-btn"
                                                *ngIf="editModeBool && selectedSection.route === 'residualRiskAnalysis'"
                                                (click)="openRatingDialog_0192(false, 'occurrence')">
                                                <div class="icon">
                                                    <fa-icon [icon]="edit"></fa-icon>
                                                </div>
                                            </button>
                                        </div>
                                        <div class="SOD-rating-content-container">
                                            <div class="category">
                                                <h3>Category</h3>
                                                <p>{{riskRecordDisplayObject.occurrenceCategoryName}}</p>
                                            </div>

                                            <div class="category-description">
                                                <h3>Category Description</h3>
                                                <p>{{riskRecordDisplayObject.occurrenceDescription}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="( editModeBool && ( selectedSection.route === 'initialRiskAnalysis' && (displayInstructions.instructionSets[1].instructionInitialComplete || displayInstructions.instructionSets[1].guidanceSteps[2].guidanceStepInitialComplete))
                                                || (selectedSection.route === 'residualRiskAnalysis' && (displayInstructions.instructionSets[1].instructionResidualComplete || displayInstructions.instructionSets[1].guidanceSteps[2].guidanceStepResidualComplete)))
                                                || ( !editModeBool && (selectedSection.route === 'initialRiskAnalysis' && displayInstructions.instructionSets[1].instructionInitialComplete)|| (selectedSection.route === 'residualRiskAnalysis' && displayInstructions.instructionSets[1].instructionResidualComplete))
                                                || globalService.configurableParameters.turnOffTutorial"
                                        class="SOD-container">
                                        <div class="SOD-content-header effect">
                                            <p class="uppercase">OCCURRENCE COMMENT</p>
                                        </div>
                                        <div *ngIf="!editModeBool && riskRecordDisplayObject.occurrenceJustification"
                                            class="SOD-content" id="riskRecord-initialOccurrenceJustification">
                                            {{riskRecordDisplayObject.occurrenceJustification}}
                                        </div>
                                        <div *ngIf="!editModeBool && !riskRecordDisplayObject.occurrenceJustification && ( (globalService.configurableParameters.turnOffTutorial) || ((selectedSection.route == 'initialRiskAnalysis' && displayInstructions.instructionSets[1].instructionInitialComplete) || (selectedSection.route == 'residualRiskAnalysis' && displayInstructions.instructionSets[1].instructionResidualComplete))) "
                                            class="SOD-content" id="riskRecord-initialOccurrenceJustification">
                                            <div class="banner notification">
                                                <fa-icon [icon]="exclamationTriangle"></fa-icon>
                                                <h3>There is no Occurrence Comment.</h3>
                                            </div>
                                        </div>
                                        <div *ngIf="editModeBool && (selectedSection.route == 'initialRiskAnalysis' && ((globalService.configurableParameters.turnOffTutorial) || (displayInstructions.instructionSets[1].instructionInitialComplete ||  displayInstructions.instructionSets[1].guidanceSteps[2].guidanceStepInitialComplete)))"
                                            class="" id="TBD">
                                            <textarea rows="5" matInput type="text"
                                                formControlName="initialOccurrenceJustification"
                                                placeholder="Occurrence Rating Comment..." id="TBD" maxlength="2000">
                                        </textarea>
                                        </div>
                                        <div *ngIf="editModeBool && (selectedSection.route == 'residualRiskAnalysis' && ((globalService.configurableParameters.turnOffTutorial) || (displayInstructions.instructionSets[1].instructionResidualComplete ||  displayInstructions.instructionSets[1].guidanceSteps[2].guidanceStepResidualComplete)))"
                                            class="" id="TBD">
                                            <textarea rows="5" matInput type="text"
                                                formControlName="residualOccurrenceJustification"
                                                placeholder="Occurrence Rating Comment..." id="TBD" maxlength="2000">
                                    </textarea>
                                        </div>
                                    </div>
                                    <!-- && !globalService.configurableParameters.turnOffTutorial && globalService.tutorialEnabled -->
                                    <div *ngIf="!globalService.configurableParameters.turnOffTutorial && ( 
                                        (selectedSection.route === 'initialRiskAnalysis'&& !displayInstructions.instructionSets[1].instructionInitialComplete)
                                    ||
                                        (selectedSection.route === 'residualRiskAnalysis'&& !displayInstructions.instructionSets[1].instructionResidualComplete)
                                    ) " class="SOD-container instructions">
                                        <div class="instructions-title">
                                            <p>GUIDANCE</p>
                                        </div>
                                        <div
                                            *ngFor="let guidanceStep of displayInstructions.instructionSets[1].guidanceSteps; let i=index">
                                            <div class="instruction"
                                                [ngClass]="{'disabled': ((selectedSection.route === 'initialRiskAnalysis' && !guidanceStep.stepInitialEnabled) 
                                            || (selectedSection.route === 'residualRiskAnalysis' && !guidanceStep.stepResidualEnabled))}">
                                                <div class="step"
                                                    (click)="selectInstructionAction(guidanceStep.guidanceREF)">
                                                    <p>{{i+1}}</p>
                                                </div>
                                                <div class="step-instruction"
                                                    [ngClass]="{'step-instruction-strikeout': guidanceStepStrikeout(guidanceStep)}"
                                                    (click)="selectInstructionAction(guidanceStep.guidanceREF)">
                                                    <p>{{guidanceStep.guidanceDescription}}</p>
                                                </div>
                                                <div *ngIf="(selectedSection.route === 'initialRiskAnalysis' && guidanceStep.guidanceStepInitialComplete) ||
                                            (selectedSection.route === 'residualRiskAnalysis' && guidanceStep.guidanceStepResidualComplete)"
                                                    class="step-complete">
                                                    <fa-icon [icon]="checkCircle"></fa-icon>
                                                </div>
                                                <div *ngIf="((selectedSection.route === 'initialRiskAnalysis' && 
                                                !guidanceStep.guidanceStepInitialComplete && 
                                                guidanceStep.guidanceOptional) || 
                                                (selectedSection.route === 'residualRiskAnalysis' && 
                                                !guidanceStep.guidanceStepResidualComplete && 
                                                guidanceStep.guidanceOptional) )" class="step-cancel"
                                                    (click)="cancelOptionalGuidance(1, i)">
                                                    <p>Skip</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cause-effect-content">
                                    <div class="SOD-header">
                                        <div class="SOD-title">
                                            <div>
                                                <div class="SOD-Label">DETECTION</div>
                                            </div>
                                            <span class="required-asterisk"
                                                *ngIf="armService.selectedRiskAssessment.detectabilityMandatory">*</span>
                                        </div>
                                        <div class="SOD-rating"
                                            *ngIf="armService.selectedRiskAssessment.detectabilityMandatory">
                                            <div class="SOD-number">
                                                <div id="riskRecord-initialDetectability"
                                                    *ngIf="riskRecordDisplayObject.detectability">
                                                    <div class="SOD-Label">{{riskRecordDisplayObject.detectability}}
                                                    </div>
                                                </div>

                                                <div id="riskRecord-initialDetectability-TBD"
                                                    *ngIf="!riskRecordDisplayObject.detectability">
                                                    <h1>...</h1>
                                                </div>
                                            </div>
                                            <div class="SOD-classification">
                                                <div class="level"
                                                    [style.backgroundColor]="riskRecordDisplayObject.detectabilityClassificationColour"
                                                    [style.color]="riskRecordDisplayObject.detectabilityClassificationTextColour"
                                                    id="riskRecord-initialDetectabilityClassification"
                                                    *ngIf="riskRecordDisplayObject.detectabilityClassification">
                                                    <h3>{{riskRecordDisplayObject.detectabilityClassification}}</h3>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div *ngIf="!armService.selectedRiskAssessment.detectabilityMandatory">
                                        <div class="banner notification">
                                            <fa-icon [icon]="exclamationTriangle"></fa-icon>
                                            <h3>Detection Not Mandatory</h3>
                                        </div>
                                    </div>

                                    <div class="SOD-container"
                                        *ngIf="( armService.selectedRiskAssessment.detectabilityMandatory && editModeBool && ( selectedSection.route === 'initialRiskAnalysis' && (displayInstructions.instructionSets[2].instructionInitialComplete || displayInstructions.instructionSets[2].guidanceSteps[0].guidanceStepInitialComplete))
                                    || (selectedSection.route === 'residualRiskAnalysis' && (displayInstructions.instructionSets[2].instructionResidualComplete || displayInstructions.instructionSets[2].guidanceSteps[0].guidanceStepResidualComplete)))
                                    || (armService.selectedRiskAssessment.detectabilityMandatory && !editModeBool && (selectedSection.route === 'initialRiskAnalysis' && displayInstructions.instructionSets[2].instructionInitialComplete)|| (selectedSection.route === 'residualRiskAnalysis' && displayInstructions.instructionSets[2].instructionResidualComplete))
                                    || (armService.selectedRiskAssessment.detectabilityMandatory && globalService.configurableParameters.turnOffTutorial)">
                                        <div class="SOD-content-header effect">
                                            <p class="uppercase">Detection Controls</p>
                                            <button mat-button class="icon-btn icon-btn-white"
                                                id="riskRecord-initial-failureCause-edit-btn"
                                                *ngIf="editModeBool && selectedSection.route === 'initialRiskAnalysis'"
                                                (click)="openControlDialog_0192(true, 'detectability')">
                                                <div class="icon">
                                                    <fa-icon [icon]="edit"></fa-icon>
                                                </div>
                                            </button>
                                            <button mat-button class="icon-btn icon-btn-white"
                                                id="riskRecord-initial-failureCause-edit-btn"
                                                *ngIf="editModeBool && selectedSection.route === 'residualRiskAnalysis'"
                                                (click)="openControlDialog_0192(false, 'detectability')">
                                                <div class="icon">
                                                    <fa-icon [icon]="edit"></fa-icon>
                                                </div>
                                            </button>
                                        </div>
                                        <div class="SOD-content">
                                            <div *ngIf="detectionControlDataSource.data.length === 0">
                                                <div class="banner notification">
                                                    <fa-icon [icon]="exclamationTriangle"></fa-icon>
                                                    <h3>There are no Detection Controls.</h3>
                                                </div>
                                            </div>
                                            <div *ngIf="detectionControlDataSource.data.length > 0"
                                                class="table-container">
                                                <table mat-table [dataSource]="detectionControlDataSource">
                                                    <ng-container matColumnDef="controlID">
                                                        <th mat-header-cell *matHeaderCellDef>
                                                            <p>ID</p>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <p> {{element.controlID}}</p>
                                                        </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="control">
                                                        <th mat-header-cell *matHeaderCellDef>
                                                            <p>Control</p>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <p>{{element.control}}</p>
                                                        </td>
                                                    </ng-container>

                                                    <tr mat-header-row
                                                        *matHeaderRowDef="controlDisplayedColumns; sticky: true">
                                                    </tr>
                                                    <tr mat-row *matRowDef="let row; columns: controlDisplayedColumns;">
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="armService.selectedRiskAssessment.detectabilityMandatory && riskRecordDisplayObject.detectability ||( armService.selectedRiskAssessment.detectabilityMandatory && globalService.configurableParameters.turnOffTutorial)"
                                        class="SOD-container">
                                        <div class="SOD-content-header effect">
                                            <div class="mandatory-label">
                                                <p class="uppercase">RATING</p>
                                                <span class="required-asterisk">*</span>
                                            </div>
                                            <button mat-button class="icon-btn icon-btn-white"
                                                id="riskRecord-initial-failureEffect-edit-btn"
                                                *ngIf="editModeBool && selectedSection.route === 'initialRiskAnalysis'"
                                                (click)="openRatingDialog_0192(true, 'detectability')">
                                                <div class="icon">
                                                    <fa-icon [icon]="edit"></fa-icon>
                                                </div>
                                            </button>
                                            <button mat-button class="icon-btn icon-btn-white"
                                                id="riskRecord-initial-failureEffect-edit-btn"
                                                *ngIf="editModeBool && selectedSection.route === 'residualRiskAnalysis'"
                                                (click)="openRatingDialog_0192(false, 'detectability')">
                                                <div class="icon">
                                                    <fa-icon [icon]="edit"></fa-icon>
                                                </div>
                                            </button>
                                        </div>
                                        <div class="SOD-rating-content-container">
                                            <div class="category">
                                                <h3>Category</h3>
                                                <p>{{riskRecordDisplayObject.detectabilityCategoryName}}</p>
                                            </div>

                                            <div class="category-description">
                                                <h3>Category Description</h3>
                                                <p>{{riskRecordDisplayObject.detectabilityDescription}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="( armService.selectedRiskAssessment.detectabilityMandatory && editModeBool && ( selectedSection.route === 'initialRiskAnalysis' && (displayInstructions.instructionSets[2].instructionInitialComplete || displayInstructions.instructionSets[2].guidanceSteps[2].guidanceStepInitialComplete))
                                                || (selectedSection.route === 'residualRiskAnalysis' && (displayInstructions.instructionSets[2].instructionResidualComplete || displayInstructions.instructionSets[2].guidanceSteps[2].guidanceStepResidualComplete)))
                                                || ( !editModeBool && (selectedSection.route === 'initialRiskAnalysis' && displayInstructions.instructionSets[2].instructionInitialComplete)|| (selectedSection.route === 'residualRiskAnalysis' && displayInstructions.instructionSets[2].instructionResidualComplete))
                                                || ( armService.selectedRiskAssessment.detectabilityMandatory && globalService.configurableParameters.turnOffTutorial)"
                                        class="SOD-container">
                                        <div class="SOD-content-header effect">
                                            <p class="uppercase">DETECTION COMMENT</p>
                                        </div>
                                        <div *ngIf="!editModeBool && riskRecordDisplayObject.detectabilityJustification"
                                            class="SOD-content" id="riskRecord-initialDetectabilityJustification">
                                            {{riskRecordDisplayObject.detectabilityJustification}}
                                        </div>
                                        <div *ngIf="!editModeBool && !riskRecordDisplayObject.detectabilityJustification  && ( (globalService.configurableParameters.turnOffTutorial) || ((selectedSection.route == 'initialRiskAnalysis' && displayInstructions.instructionSets[2].instructionInitialComplete) || (selectedSection.route == 'residualRiskAnalysis' && displayInstructions.instructionSets[2].instructionResidualComplete)))"
                                            class="SOD-content" id="riskRecord-initialDetectabilityJustification">
                                            <div class="banner notification">
                                                <fa-icon [icon]="exclamationTriangle"></fa-icon>
                                                <h3>There is no Detection Comment.</h3>
                                            </div>
                                        </div>
                                        <div *ngIf="editModeBool && (selectedSection.route == 'initialRiskAnalysis' && ((globalService.configurableParameters.turnOffTutorial) || (displayInstructions.instructionSets[2].instructionInitialComplete || selectedSection.route == 'initialRiskAnalysis' && displayInstructions.instructionSets[2].guidanceSteps[2].guidanceStepInitialComplete)))"
                                            class="" id="TBD">
                                            <textarea rows="5" matInput type="text"
                                                formControlName="initialDetectabilityJustification"
                                                placeholder="Detectability Rating Comment..." id="TBD" maxlength="2000">
                                        </textarea>
                                        </div>
                                        <div *ngIf="editModeBool && (selectedSection.route == 'residualRiskAnalysis' && ((globalService.configurableParameters.turnOffTutorial) || (displayInstructions.instructionSets[2].instructionResidualComplete || selectedSection.route == 'residualRiskAnalysis' && displayInstructions.instructionSets[2].guidanceSteps[2].guidanceStepResidualComplete)))"
                                            class="" id="TBD">
                                            <textarea rows="5" matInput type="text"
                                                formControlName="residualDetectabilityJustification"
                                                placeholder="Detectability Rating Comment..." id="TBD" maxlength="2000">
                                    </textarea>
                                        </div>
                                    </div>

                                    <!-- && !globalService.configurableParameters.turnOffTutorial && globalService.tutorialEnabled -->
                                    <div *ngIf="armService.selectedRiskAssessment.detectabilityMandatory && !globalService.configurableParameters.turnOffTutorial && ( 
                                        (selectedSection.route === 'initialRiskAnalysis'&& !displayInstructions.instructionSets[2].instructionInitialComplete)
                                    ||
                                        (selectedSection.route === 'residualRiskAnalysis'&& !displayInstructions.instructionSets[2].instructionResidualComplete)
                                    ) " class="SOD-container instructions">
                                        <div class="instructions-title">
                                            <p>GUIDANCE</p>
                                        </div>
                                        <div
                                            *ngFor="let guidanceStep of displayInstructions.instructionSets[2].guidanceSteps; let i=index">
                                            <div class="instruction"
                                                [ngClass]="{'disabled': ((selectedSection.route === 'initialRiskAnalysis' && !guidanceStep.stepInitialEnabled) 
                                            || (selectedSection.route === 'residualRiskAnalysis' && !guidanceStep.stepResidualEnabled))}">
                                                <div class="step"
                                                    (click)="selectInstructionAction(guidanceStep.guidanceREF)">
                                                    <p>{{i+1}}</p>
                                                </div>
                                                <div class="step-instruction"
                                                    [ngClass]="{'step-instruction-strikeout': guidanceStepStrikeout(guidanceStep)}"
                                                    (click)="selectInstructionAction(guidanceStep.guidanceREF)">
                                                    <p>{{guidanceStep.guidanceDescription}}</p>
                                                </div>
                                                <div *ngIf="(selectedSection.route === 'initialRiskAnalysis' && guidanceStep.guidanceStepInitialComplete) ||
                                                (selectedSection.route === 'residualRiskAnalysis' && guidanceStep.guidanceStepResidualComplete)"
                                                    class="step-complete">
                                                    <fa-icon [icon]="checkCircle"></fa-icon>
                                                </div>
                                                <div *ngIf="((selectedSection.route === 'initialRiskAnalysis' && 
                                                            !guidanceStep.guidanceInitialComplete && 
                                                            guidanceStep.guidanceOptional) ||   
                                                            (selectedSection.route === 'residualRiskAnalysis' && 
                                                            !guidanceStep.guidanceResidualComplete && 
                                                            guidanceStep.guidanceOptional) )" class="step-cancel"
                                                    (click)="cancelOptionalGuidance(2, i)">
                                                    <p>Skip</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="risk-control"
                    [ngClass]="{'no-display': selectedSection.route !== sections.riskControl.route}">
                    <form [formGroup]="riskRecordForm">
                        <div class="risk-decision-justification-container" *ngIf="editModeBool">
                            <div class="risk-decision-container" id="riskRecord-riskDecision">
                                <!-- <div class="risk-decision"> -->



                                <div class="mandatory-label">
                                    <label class="bold-label">Risk Decision</label><br />
                                    <span class="required-asterisk">*</span>
                                </div>

                                <mat-select formControlName="riskDecision" placeholder="Select Risk Decision..."
                                    id="riskRecord-riskControl-riskDecision">
                                    <mat-option *ngFor="let riskDecision of riskDecisions" [value]="riskDecision"
                                        (click)="selectedRisk.riskDecision = riskDecision; renderRiskRecordDashboard_XXXX(initialRiskContext);">
                                        <p>{{riskDecision}}</p>
                                    </mat-option>
                                </mat-select>
                                <!-- </div> -->
                                <div class="action-decision" *ngIf="selectedRisk.riskDecision === 'Action'">
                                    <mat-checkbox class="example-margin" formControlName="riskEliminate">Action
                                        Eliminates the Risk</mat-checkbox>
                                </div>
                            </div>
                            <div class="risk-decision-justification" id="riskRecord-riskDecisionJustification">
                                <label class="bold-label">Risk Decision Justification
                                    <span class="required-asterisk"
                                        *ngIf="(riskRecordForm.value.riskDecision && !(riskRecordForm.value.riskDecision ==='Action' && !this.riskRecordForm.value.riskEliminate))">*</span>
                                </label>
                                <textarea rows="5" matInput type="text" formControlName="riskDecisionJustification"
                                    placeholder="Enter Risk Decision Justification..."
                                    id="riskRecord-riskControl-riskDecisionJustification" maxlength="2000">
                                    </textarea>
                            </div>
                        </div>
                        <div class="risk-decision-justification-container" *ngIf="!editModeBool">
                            <div class="risk-decision-container" id="riskRecord-riskDecision">
                                <div class="risk-decision">
                                    <label class="bold-label">Risk Decision</label><br />
                                    <input matInput formControlName="riskDecision" placeholder="Pending..."
                                        id="riskRecord-riskControl-riskDecision" readonly>
                                </div>
                                <div class="action-decision" *ngIf="selectedRisk.riskDecision === 'Action'">
                                    <mat-checkbox class="example-margin" formControlName="riskEliminate"
                                        [disabled]="true">Action
                                        Eliminates the Risk</mat-checkbox>
                                </div>
                            </div>
                            <div class="risk-decision-justification" id="riskRecord-riskDecisionJustification">
                                <label class="bold-label">Risk Decision Justification</label>
                                <textarea rows="5" matInput type="text" formControlName="riskDecisionJustification"
                                    placeholder="Risk Decision Justification..."
                                    id="riskRecord-riskControl-riskDecisionJustification" readonly>
                                    </textarea>
                            </div>
                        </div>
                        <div class="risk-record-mitigation-container" *ngIf="selectedRisk.riskDecision === 'Action'">
                            <div class="risk-record-mitigation-header">
                                <div class="mitigating-action-label">
                                    <h4 class="uppercase">Mitigating Actions</h4>
                                    <span class="required-asterisk">*</span>
                                </div>
                                <div class="button-gap view-associated-risks" *ngIf="editModeBool">
                                    <p>View Associated Risks</p>
                                    <button mat-button class="icon-btn icon-btn-white" id="riskRecord-ellipsis-btn"
                                        (click)="openAssociatedRisksDialog_0192()">
                                        <div class="icon">
                                            <fa-icon [icon]="eye"></fa-icon>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div class="risk-record-mitigation-content">
                                <irlcore-tasks-master *ngIf="selectedRisk.riskDecision === 'Action' && !editModeBool"
                                    [masterDetail]="false" [filteredView]="true" [riskID]="armService.selectedRisk.iD"
                                    [addTask]="editModeBool" [tasksDeleted]="tasksDeleted" [updatePull]="updateTaskPull"
                                    [tableHeight]="'40vh'"
                                    (expandedView)="armService.expandedView = $event; armService.taskReturn = 'risk-record'; navigateToMitigatingActionRegister_0409()">

                                </irlcore-tasks-master>
                                <irlcore-tasks-master *ngIf="selectedRisk.riskDecision === 'Action' && editModeBool"
                                    [masterDetail]="false" [filteredView]="true" [riskID]="armService.selectedRisk.iD"
                                    [addTask]="editModeBool" [tasksDeleted]="tasksDeleted" [tableHeight]="'40vh'"
                                    (expandedView)="armService.expandedView = $event; armService.taskReturn = 'risk-record'; navigateToMitigatingActionRegister_0409()">

                                </irlcore-tasks-master>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="risk-record-details" *ngIf="selectedSection.route === 'details'">
                    <form [formGroup]="riskRecordForm">
                        <div class="risk-details-container col-6">
                            <div class="risk-detail">
                                <div *ngIf="!editModeBool" class="d-flex justify-content-between">
                                    <div>
                                        <label class="bold-label">Risk Owner</label><br />
                                        <p id="riskRecord-details-riskOwner" *ngIf="selectedRisk.riskOwnerName">
                                            {{selectedRisk.riskOwnerName}}</p>
                                        <p id="riskRecord-details-riskOwner-TBD" *ngIf="!selectedRisk.riskOwnerName">TBD
                                        </p>
                                    </div>
                                    <div>
                                        <label class="bold-label">Risk Ownership Confirmation</label><br />
                                        <p id="riskRecord-details-riskOwnershipConfirmation"
                                            class="risk-ownership-btn confirm"
                                            *ngIf="selectedRisk.ownershipDate && !selectedRisk.isRejected">
                                            <fa-icon [icon]="userCheck"></fa-icon>
                                            Confirmed
                                        </p>
                                        <p id="riskRecord-details-riskOwnershipConfirmation"
                                            class="risk-ownership-btn decline" *ngIf="selectedRisk.isRejected">
                                            <fa-icon [icon]="userTimes"></fa-icon>
                                            Declined
                                        </p>
                                        <p id="riskRecord-details-riskOwner-TBD"
                                            *ngIf="!selectedRisk.ownershipDate && !selectedRisk.isRejected"
                                            class="risk-ownership-btn pending">
                                            <fa-icon [icon]="userClock"></fa-icon>
                                            Pending
                                        </p>
                                    </div>
                                </div>
                                <div *ngIf="editModeBool" class="d-flex justify-content-between">
                                    <div class="col-5">
                                        <label class="bold-label">Risk Owner</label><br />
                                        <input type="text" placeholder="Risk Owner" matInput
                                            id="riskRecord-details-edit-riskOwner" formControlName="riskOwnerName"
                                            [matAutocomplete]="auto" (keyup)="riskOwnerUpdated = false">
                                        <mat-autocomplete #auto="matAutocomplete">
                                            <mat-option *ngFor="let user of filteredUsers | async; let i = index;"
                                                value="{{user.fullName}}" id="riskRecord-details-riskOwner-option-{{i}}"
                                                (click)="riskOwnerUpdated = true">
                                                <p>{{user.fullName}} (<span class="email">{{user.email}}</span>)</p>
                                            </mat-option>
                                        </mat-autocomplete>
                                        <span class="form-field-message" *ngIf="(filteredUsers | async).length == 0">
                                            Invalid Selection. Does not exist in list of Users </span>
                                    </div>
                                    <div>
                                        <label class="bold-label">Risk Ownership Confirmation</label><br />
                                        <div class="button-gap" *ngIf="getUserID_0412(riskRecordForm.value.riskOwnerName) == globalService.userID 
                                                    && !riskConfirmedDeniedBool">
                                            <button mat-button class="btn risk-ownership-btn confirm"
                                                (click)="confirmRiskOwnership_0500(true)">
                                                <fa-icon [icon]="userCheck"></fa-icon>
                                                Confirm
                                            </button>
                                            <button mat-button class="btn risk-ownership-btn decline"
                                                (click)="confirmRiskOwnership_0500(false)">
                                                <fa-icon [icon]="userTimes"></fa-icon>
                                                Decline
                                            </button>
                                        </div>
                                        <div
                                            *ngIf="getUserID_0412(riskRecordForm.value.riskOwnerName) != globalService.userID || riskConfirmedDeniedBool">
                                            <p id="riskRecord-details-riskOwnershipConfirmation"
                                                class="risk-ownership-btn confirm"
                                                *ngIf="selectedRisk.ownershipDate && !selectedRisk.isRejected">
                                                <fa-icon [icon]="userCheck"></fa-icon>
                                                Confirmed
                                            </p>
                                            <p id="riskRecord-details-riskOwnershipConfirmation"
                                                class="risk-ownership-btn decline" *ngIf="selectedRisk.isRejected">
                                                <fa-icon [icon]="userTimes"></fa-icon>
                                                Declined
                                            </p>
                                            <p id="riskRecord-details-riskOwner-TBD"
                                                *ngIf="!selectedRisk.isRejected && !selectedRisk.ownershipDate"
                                                class="risk-ownership-btn pending">
                                                <fa-icon [icon]="userClock"></fa-icon>
                                                Pending
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="risk-detail">
                                <label class="bold-label">Risk Assessment ID</label><br />
                                <p id="riskRecord-details-riskAssessmentID">
                                    {{armService.selectedRiskAssessment.riskAssessmentREF}}</p>
                            </div>
                            <div class="risk-detail">
                                <label class="bold-label">Risk Assessment Type</label><br />
                                <p id="riskRecord-details-riskAssessmentType">
                                    {{armService.selectedRiskAssessment.riskAssessmentType}}</p>
                            </div>
                            <div class="risk-detail">
                                <label class="bold-label">Risk Question</label><br />
                                <p id="riskRecord-details-riskQuestion">
                                    {{armService.selectedRisk.riskQuestion}}</p>
                            </div>
                            <div class="risk-detail" *ngIf="globalService.configurableParameters.showProximity">
                                <label class="bold-label">Risk Proximity</label><br />
                                <p id="riskRecord-details-riskProximity">
                                    {{armService.selectedRisk.proximity}} months</p>
                            </div>
                            <div class="risk-detail">
                                <label class="bold-label">Risk Status</label><br />
                                <div *ngIf="!editModeBool">
                                    <p id="riskRecord-details-riskStatus">
                                        {{selectedRisk.riskStatus}}</p>
                                </div>
                                <div *ngIf="editModeBool && selectedRisk.riskStatusID > 2">
                                    <p id="riskRecord-details-riskStatus">
                                        {{selectedRisk.riskStatus}}</p>
                                </div>
                                <div *ngIf="editModeBool && selectedRisk.riskStatusID <= 2" class="col-6">
                                    <mat-select formControlName="riskStatusID" placeholder="Select Risk Status"
                                        (selectionChange)="riskStatusUpdated = true"
                                        id="riskRecord-details-edit-riskStatus">
                                        <mat-option *ngFor="let riskStatus of displayRiskStatuses"
                                            [value]="riskStatus.iD">
                                            <p>{{riskStatus.riskStatus}}</p>
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div class="risk-detail" *ngIf="!globalService.configurableParameters.turnOffFMEA">
                                <label class="bold-label">Risk Assessment Tool</label>
                                <button
                                    *ngIf="armService.selectedRisk.riskAssessmentTool && !editModeBool && armService.selectedRiskAssessment.riskAssessmentToolID !== 4"
                                    id="riskRecord-riskAssessmentTool-btn"
                                    class="btn icon-btn icon-btn-blue risk-assessment-tool-arrow"
                                    (click)="navigateToRiskAssessmentTool_0409()">
                                    <fa-icon [icon]="arrowCirlceRight"></fa-icon>
                                </button><br />
                                <p id="riskRecord-details-riskAssessmentTool"
                                    *ngIf="armService.selectedRisk.riskAssessmentTool">
                                    {{armService.selectedRisk.riskAssessmentTool}}</p>
                            </div>
                            <!-- <div class="risk-detail"
                                *ngIf="globalService.configurableParameters.psfmInRiskTableRiskAssessment">
                                <label class="bold-label">
                                    {{globalService.configurableParameters.itemGroup}}</label>
                                <br />
                                <p id="riskRecord-details-processStep">
                                    {{selectedRisk.itemGroupName}}</p>
                            </div>
                            <div class="risk-detail"
                                *ngIf="globalService.configurableParameters.psfmInRiskTableRiskAssessment &&selectedRisk.riskAssessmentToolID !== 4">
                                <label
                                    class="bold-label">{{globalService.configurableParameters.riskMode}}</label><br />
                                <p id="riskRecord-details-failureMode">
                                    {{selectedRisk.failureMode}}</p>
                            </div> -->
                            <div class="risk-category-row">
                                <div class="risk-detail">
                                    <label class="bold-label">Severity Category</label><br />
                                    <p id="riskRecord-details-severityCategory"
                                        *ngIf="selectedRisk.occurrenceCategoryName">
                                        {{selectedRisk.severityCategoryName}}</p>
                                    <p id="riskRecord-details-severityCategory"
                                        *ngIf="!selectedRisk.occurrenceCategoryName">
                                        TBD</p>
                                </div>
                                <div class="risk-detail">
                                    <label class="bold-label">Occurrence Category</label><br />
                                    <p id="riskRecord-details-occurrenceCategory"
                                        *ngIf="selectedRisk.occurrenceCategoryName">
                                        {{selectedRisk.occurrenceCategoryName}}</p>
                                    <p id="riskRecord-details-occurrenceCategory"
                                        *ngIf="!selectedRisk.occurrenceCategoryName">
                                        TBD</p>
                                </div>
                                <div class="risk-detail"
                                    *ngIf="armService.selectedRiskAssessment.detectabilityMandatory">
                                    <label class="bold-label">Detection Category</label><br />
                                    <p id="riskRecord-details-detectabilityCategory"
                                        *ngIf="selectedRisk.detectabilityCategoryName">
                                        {{selectedRisk.detectabilityCategoryName}}</p>
                                    <p id="riskRecord-details-detectabilityCategory"
                                        *ngIf="!selectedRisk.detectabilityCategoryName">
                                        TBD</p>
                                </div>
                            </div>
                            <div class="risk-category-row"
                                *ngIf="selectedRisk.riskDecision === 'Action' && !riskRecordForm.value.riskEliminate">
                                <div class="risk-detail">
                                    <label class="bold-label">Residual Severity Category</label><br />
                                    <p id="riskRecord-details-residualSeverityCategory"
                                        *ngIf="selectedRisk.residualSeverityCategoryName">
                                        {{selectedRisk.residualSeverityCategoryName}}</p>
                                    <p id="riskRecord-details-residualSeverityCategory"
                                        *ngIf="!selectedRisk.residualSeverityCategoryName && selectedRisk.riskDecision === 'Action'">
                                        TBD</p>
                                    <!-- <p id="riskRecord-details-residualSeverityCategory"
                                        *ngIf="!selectedRisk.residualSeverityCategoryName && selectedRisk.riskDecision !== 'Action'">
                                        N/A</p> -->
                                </div>
                                <div class="risk-detail">
                                    <label class="bold-label">Residual Occurrence Category</label><br />
                                    <p id="riskRecord-details-residualOccurrenceCategory"
                                        *ngIf="selectedRisk.residualOccurrenceCategoryName">
                                        {{selectedRisk.residualOccurrenceCategoryName}}</p>
                                    <p id="riskRecord-details-residualOccurrenceCategory"
                                        *ngIf="!selectedRisk.residualOccurrenceCategoryName && selectedRisk.riskDecision === 'Action'">
                                        TBD</p>
                                    <!-- <p id="riskRecord-details-residualOccurrenceCategory"
                                        *ngIf="!selectedRisk.residualOccurrenceCategoryName && selectedRisk.riskDecision !== 'Action'">
                                        N/A</p> -->
                                </div>
                                <div class="risk-detail"
                                    *ngIf="armService.selectedRiskAssessment.detectabilityMandatory">
                                    <label class="bold-label">Residual Detection Category</label><br />
                                    <p id="riskRecord-details-residualDetectabilityCategory"
                                        *ngIf="selectedRisk.residualDetectabilityCategoryName">
                                        {{selectedRisk.residualDetectabilityCategoryName}}</p>
                                    <p id="riskRecord-details-residualDetectabilityCategory"
                                        *ngIf="!selectedRisk.residualDetectabilityCategoryName && selectedRisk.riskDecision === 'Action'">
                                        TBD</p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div *ngIf="selectedSection.route === 'team'" class="risk-record-team">
                    <app-risk-assessment-team [editMode]="false"></app-risk-assessment-team>
                </div>
            </div>
        </div>
    </div>
    <div class="edit-mode-footer" *ngIf="editModeBool">
        <div class="button-gap">
            <div>
                <p>Edit Mode - Please Save or Cancel all actions</p>
            </div>
            <button mat-button class="btn cancel" (click)="cancelChanges_XXXX()">
                <p>Cancel</p>
            </button>
            <button mat-button class="btn primary" appDebounceClick
                (debounceClick)="prepareDataForPostRiskRecord_0450()" [debounceTime]="200" [disabled]="(!riskStatusUpdated 
                && !riskConfirmedDeniedBool && !selectedSODRatingFailureEffectUpdated && !selectedSODRatingFailureCauseUpdated 
                && !failureEffectControlsUpdated && !failureCauseControlsUpdated && !severityUpdated 
                && !occurrenceUpdated && !detectabilityUpdated
                && !riskRecordForm.dirty
                && !riskOwnerUpdated
                && (!globalService.tasksDataset.data || globalService.tasksDataset.data.length === 0) 
                && (!globalService.taskLinkingDataset.data || globalService.taskLinkingDataset.data.length === 0))">
                <p>Save</p>
            </button>
        </div>
    </div>
</div>
<div class="audit-trail-container" *ngIf="viewAuditTrailBool">
    <div class="page-card-back-btn-container">
        <button class="back-btn" id="audit-trail-back-button" (click)="closeAuditTrail()">
            <fa-icon class="white-icon" [icon]="arrowLeft"></fa-icon>
        </button>
        <div class="return-button-margin">
        </div>
    </div>
    <div class="page-card back-btn audit-trail">
        <div class="page-card-header">
            <div style="justify-content: space-between;">
                <div class="reference-title">
                    <h3 id="risk-title" class="title uppercase">Risk Record</h3>
                    <h3 class="separator">&nbsp;</h3>
                    <h3 id="risk-identification-detail-ref" class="reference uppercase">
                        {{selectedRisk.riskRef}}
                    </h3>
                </div>
            </div>
            <div class="export-button-group">
                <label class="bold-label">Export To PDF</label>
                &nbsp;
                <button mat-button (click)="genReport_XXXX();" id="risk-adit-trail-to-pdf-button"
                    class="btn icon-btn icon-btn-white">
                    <div class="icon">
                        <fa-icon [icon]="fileExport"></fa-icon>
                    </div>
                </button>
            </div>
        </div>
        <div class="page-card-body" *ngIf="!displayLoader">
            <app-audit-trail [auditTrailListView]="armService.auditTrailListView"
                [tableNames]="armService.auditTrailTableNames">
            </app-audit-trail>
        </div>
        <mat-spinner *ngIf="displayLoader" class="loader"></mat-spinner>
    </div>
</div>