import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { APIService, dataset, datasetGet, DataToGet, DataToPost, GlobalService, IrlcoreAuthService } from '@irlca/irlcore';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ArmService } from '../../../arm.service';

@Component({
  selector: 'app-risk-assessment-dialog',
  templateUrl: './risk-assessment-dialog.component.html',
  styleUrls: ['./risk-assessment-dialog.component.scss']
})
export class RiskAssessmentDialogComponent implements OnInit {

  riskAssessmentForm!: FormGroup;
  newRiskAssessmentBool: boolean = true;
  currentDate: Date = new Date(Date.now());
  users: any[] = [];
  riskQuestions: any[] = [];
  functionalAreas: any[] = [];
  businessProcesses: any[] = [];
  riskAssessmentTypes: any[] = [];
  products: any[] = [];
  vendors: any[] = [];
  filteredUsers!: Observable<any>;
  filteredRiskQuestions!: Observable<any>;
  mandatoryOptionalRoles: any[] = [];
  userData: any;
  existingRiskQuestionSelected: boolean = false;
  riskAssessmentLeadSelected: boolean = false;

  businessProcessName!: string;
  functionalAreaName!: string;
  riskAssessmentTypeName!: string;
  riskAssessmentEntityName!: string;

  // icons
  times = faTimes;

  constructor(public dialogRef: MatDialogRef<RiskAssessmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder,
    private apiService: APIService, private globalService: GlobalService,
    private armService: ArmService,
    public auth: IrlcoreAuthService) { }

  async ngOnInit() {
    this.riskAssessmentForm = this.createRiskAssessmentFormGroup_0410();
    this.userData = await this.auth.getUserTokenData_0437();
    this.prepareDataForGetRiskAssessmentDialog_0190();
    this.setRiskAssessmentFormValidators_0525()
  }

  prepareDataForGetRiskAssessmentDialog_0190() {
    let datasets: datasetGet[] = [];

    let userDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        departmentID: null,
        searchValue: null,
      },
      storedProcedure: "User"
    };

    let riskQuestionDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
      },
      storedProcedure: "RiskQuestion"
    };

    let impactedFunctionalAreaDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
      },
      storedProcedure: "FunctionalArea"
    };

    let impactedBusinessProcessDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
      },
      storedProcedure: "BusinessProcess"
    };

    let riskAssessmentTypeDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
      },
      storedProcedure: "RiskAssessmentType"
    };

    let entityDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
      },
      storedProcedure: "Entity"
    };

    let productDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
      },
      storedProcedure: "Product"
    };

    datasets.push(userDataset, riskQuestionDataset, impactedFunctionalAreaDataset, impactedBusinessProcessDataset, riskAssessmentTypeDataset, entityDataset, productDataset);
    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Risk Assessment Dialog", datasets);

    this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      this.users = fromDB[0];
      this.riskQuestions = fromDB[1];
      this.functionalAreas = fromDB[2];
      this.businessProcesses = fromDB[3];
      this.riskAssessmentTypes = fromDB[4];
      this.vendors = fromDB[5];
      this.products = fromDB[6];

      this.filteredUsers = this.riskAssessmentForm.get('riskAssessmentLeadName')!.valueChanges
        .pipe(
          startWith(''),
          map(value => this.filterUsers_0411(value))
        );
      this.filteredRiskQuestions = this.riskAssessmentForm.get('riskQuestion')!.valueChanges
        .pipe(
          startWith(''),
          map(value => this.filterRiskQuestions_0411(value))
        );
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Risk Assessment Dialog Data");
      window.alert(userErrMsg)
    });
  }

  createRiskAssessmentFormGroup_0410() {
    return this.fb.group({
      riskAssessmentTypeID: ['', Validators.required],
      vendorID: ['', Validators.required],
      riskAssessmentEntityID: ['', Validators.required],
      productID: ['', Validators.required],
      clinicalTrialRef: ['', Validators.required],
      riskAssessmentTitle: ['', Validators.required],
      riskQuestion: ['', Validators.required],
      newRiskQuestionBool: [true, Validators.required],
      riskAssessmentLeadName: new FormControl(''),
      impactedFunctionalArea: ['', Validators.required],
      impactedBusinessProcess: ['', Validators.required],
    });
  }

  setRiskAssessmentFormValidators_0525() {
    const vendorControl: any = this.riskAssessmentForm.get('vendorID');
    const productControl: any = this.riskAssessmentForm.get('productID');
    const clinicalTrialControl: any = this.riskAssessmentForm.get('clinicalTrialRef');

    this.riskAssessmentForm.get('riskAssessmentTypeID')!.valueChanges
      .subscribe(riskAssessmentTypeID => {

        if (riskAssessmentTypeID === 1) {
          clinicalTrialControl.setValidators([Validators.required]);
          vendorControl.setValidators(null);
          productControl.setValidators(null);
        }
        else if (riskAssessmentTypeID === 2 || riskAssessmentTypeID === 5 || riskAssessmentTypeID === 6) {

          clinicalTrialControl.setValidators(null);
          vendorControl.setValidators(null);
          productControl.setValidators(null);
        }
        else if (riskAssessmentTypeID === 3) {
          productControl.setValidators([Validators.required]);
          clinicalTrialControl.setValidators(null);
          vendorControl.setValidators(null);
        }
        else if (riskAssessmentTypeID === 4) {
          vendorControl.setValidators([Validators.required]);
          clinicalTrialControl.setValidators(null);
          productControl.setValidators(null);
        }

        vendorControl.updateValueAndValidity();
        clinicalTrialControl.updateValueAndValidity();
        productControl.updateValueAndValidity();
      });
  }

  saveRiskAssessment_0415() {
    let entryID = -1;
    let datasets: any[] = [];
    let riskAssessmentDataset!: dataset;
    let riskAssessmentRiskQuestionM2ODataset;
    let riskQuestionDataset;
    let clinicalTrialDataset;
    let riskAssessmentClinicalTrialM2ODataset;
    let riskAssessmentProductM2ODataset;
    let riskAssessmentVendorM2ODataset;

    riskAssessmentDataset = {
      data: [{
        iD: entryID,
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        riskAssessmentREF: entryID,
        riskAssessmentTitle: this.riskAssessmentForm.value.riskAssessmentTitle,
        riskAssessmentPurpose: null,
        riskAssessmentScope: null,
        riskAssessmentOutOfScope: null,
        riskAssessmentProximity: null,
        riskAssessmentTriggerID: null,
        riskAssessmentTriggerName: null,
        riskAssessmentTypeID: this.riskAssessmentForm.value.riskAssessmentTypeID,
        riskAssessmentTypeName: this.riskAssessmentTypes[this.riskAssessmentTypes.findIndex(type => type.iD == this.riskAssessmentForm.value.riskAssessmentTypeID)].riskAssessmentType,
        riskAssessmentStatusID: 1,
        riskAssessmentStatusName: 'Risk Question Identified',
        riskAssessmentToolUsedID: null,
        riskAssessmentLeadID: this.getUserID_0412(this.riskAssessmentForm.value.riskAssessmentLeadName),
        riskAssessmentLeadName: this.riskAssessmentForm.value.riskAssessmentLeadName,
        riskAssessmentEntityID: this.riskAssessmentForm.value.riskAssessmentEntityID,
        riskAssessmentEntityName: this.riskAssessmentEntityName,
        businessProcessID: this.riskAssessmentForm.value.impactedBusinessProcess,
        businessProcessName: this.businessProcessName,
        functionalAreaID: this.riskAssessmentForm.value.impactedFunctionalArea,
        functionalAreaName: this.functionalAreaName,
        riskInitiatorID: this.globalService.userID,
        riskInitiatorName: this.globalService.userName,
        creationDate: new Date(Date.now()),
        riskAssessmentConclusion: null,
        riskAssessmentReevaluation: null,
        approvedDate: null,
        riskAssessmentQualityRecord: null,
        riskAssessmentVersion: 1,
        isTemplate: false,
        tutorialStage: 1,
        riskMethodology: null,
        periodicReviewComment: null,
        isPeriodicReview: 0,
        statementType: 'INSERT'
      }],
      dataset: "RiskAssessment",
    };

    let auditTrail = this.globalService.buildAuditTrail_0115({}, riskAssessmentDataset.data[0], 'tblRiskAssessment');
    datasets.push(riskAssessmentDataset);

    if (this.riskAssessmentForm.value.newRiskQuestionBool) {
      riskQuestionDataset = {
        data: [{
          iD: entryID,
          clientID: this.globalService.clientID,
          entityID: this.globalService.entityID,
          riskQuestion: this.riskAssessmentForm.value.riskQuestion,
          statementType: 'INSERT'
        }],
        dataset: "RiskQuestion",
      }

      let riskQuestionAuditTrail = this.globalService.buildAuditTrail_0115({}, riskQuestionDataset.data[0], 'tblRiskQuestion');
      auditTrail = auditTrail.concat(riskQuestionAuditTrail);
      datasets.push(riskQuestionDataset);

      riskAssessmentRiskQuestionM2ODataset = {
        data: [{
          iD: entryID,
          riskQuestionID: -202,
          riskAssessmentID: -201,
          statementType: 'INSERT'
        }],
        dataset: "RiskAssessmentRiskQuestionM2O",
      }
    } else {
      riskAssessmentRiskQuestionM2ODataset = {
        data: [{
          iD: entryID,
          riskQuestionID: this.getRiskQuestionID_0412(this.riskAssessmentForm.value.riskQuestion),
          riskAssessmentID: -201,
          statementType: 'INSERT'
        }],
        dataset: "RiskAssessmentRiskQuestionM2O",
      }
    }

    let riskAssessmentRiskQuestionM2OAuditTrail = this.globalService.buildAuditTrail_0115({}, riskAssessmentRiskQuestionM2ODataset.data[0], 'tblRiskAssessmentRiskQuestionM2O');
    auditTrail = auditTrail.concat(riskAssessmentRiskQuestionM2OAuditTrail);
    datasets.push(riskAssessmentRiskQuestionM2ODataset);

    if (this.riskAssessmentForm.value.riskAssessmentTypeID === 1) {
      clinicalTrialDataset = {
        data: [{
          iD: entryID,
          clientID: this.globalService.clientID,
          entityID: this.globalService.entityID,
          clinicalTrialREF: this.riskAssessmentForm.value.clinicalTrialRef,
          statementType: 'INSERT'
        }],
        dataset: "ClinicalTrial",
      }

      let clinicalTrialAuditTrail = this.globalService.buildAuditTrail_0115({}, clinicalTrialDataset.data[0], 'tblClinicalTrial');
      auditTrail = auditTrail.concat(clinicalTrialAuditTrail);

      riskAssessmentClinicalTrialM2ODataset = {
        data: [{
          iD: entryID,
          clinicalTrialID: this.globalService.dependantValue,
          riskAssessmentID: -201,
          statementType: 'INSERT'
        }],
        dataset: "RiskAssessmentClinicalTrialM2O",
      }

      let riskAssessmentClinicalTrialM2OAuditTrail = this.globalService.buildAuditTrail_0115({}, riskAssessmentClinicalTrialM2ODataset.data[0], 'tblRiskAssessmentClinicalTrialM2O');
      auditTrail = auditTrail.concat(riskAssessmentClinicalTrialM2OAuditTrail);

      datasets.push(clinicalTrialDataset, riskAssessmentClinicalTrialM2ODataset);
    }

    if (this.riskAssessmentForm.value.riskAssessmentTypeID === 3) {
      riskAssessmentProductM2ODataset = {
        data: [{
          iD: entryID,
          productID: this.riskAssessmentForm.value.productID,
          productName: this.products[this.products.findIndex(prod => prod.iD == this.riskAssessmentForm.value.productID)].product,
          riskAssessmentID: -201,
          statementType: 'INSERT'
        }],
        dataset: "RiskAssessmentProductM2O",
      }

      let riskAssessmentProductM2OAuditTrail = this.globalService.buildAuditTrail_0115({}, riskAssessmentProductM2ODataset.data[0], 'tblRiskAssessmentProductM2O');
      auditTrail = auditTrail.concat(riskAssessmentProductM2OAuditTrail);

      datasets.push(riskAssessmentProductM2ODataset);
    }

    if (this.riskAssessmentForm.value.riskAssessmentTypeID === 4) {
      riskAssessmentVendorM2ODataset = {
        data: [{
          iD: entryID,
          vendorID: this.riskAssessmentForm.value.vendorID,
          vendorName: this.vendors[this.vendors.findIndex(vend => vend.iD == this.riskAssessmentForm.value.vendorID)].entityName,
          riskAssessmentID: -201,
          statementType: 'INSERT'
        }],
        dataset: "RiskAssessmentVendorM2O",
      }

      let riskAssessmentVendortM2OAuditTrail = this.globalService.buildAuditTrail_0115({}, riskAssessmentVendorM2ODataset.data[0], 'tblRiskAssessmentVendorM2O');
      auditTrail = auditTrail.concat(riskAssessmentVendortM2OAuditTrail);

      datasets.push(riskAssessmentVendorM2ODataset);
    }

    if (this.getUserID_0412(this.riskAssessmentForm.value.riskAssessmentLeadName) != this.globalService.userID && this.getUserID_0412(this.riskAssessmentForm.value.riskAssessmentLeadName) != 1) {
      let tablePermissionDatasetRALead = {
        data: [{
          iD: entryID,
          systemID: 1,
          userID: this.userData.userID,
          groupID: null,
          functionID: null,
          permissionID: 50,
          tableName: 'tblRiskAssessment',
          tableRowID: -201,
          createdByID: this.userData.userID,
          creationDate: this.getCurrentDateTime_0516(),
          statementType: "INSERT"
        }, {
          iD: entryID,
          systemID: 1,
          userID: this.getUserID_0412(this.riskAssessmentForm.value.riskAssessmentLeadName),
          groupID: null,
          functionID: null,
          permissionID: 50,
          tableName: 'tblRiskAssessment',
          tableRowID: -201,
          createdByID: this.userData.userID,
          creationDate: this.getCurrentDateTime_0516(),
          statementType: "INSERT"
        }],
        dataset: "SysUserTablePermission",
      }
      let tablePermissionDatasetAuditTrailRA = this.globalService.buildAuditTrail_0115({}, tablePermissionDatasetRALead.data[0], 'tblSysUserTablePermission');
      tablePermissionDatasetAuditTrailRA = tablePermissionDatasetAuditTrailRA.concat(this.globalService.buildAuditTrail_0115({}, tablePermissionDatasetRALead.data[1], 'tblSysUserTablePermission'))
      auditTrail = auditTrail.concat(tablePermissionDatasetAuditTrailRA);
      datasets.push(tablePermissionDatasetRALead);
    }
    else {
      let tablePermissionDataset = {
        data: [{
          iD: entryID,
          systemID: 1,
          userID: this.userData.userID,
          groupID: null,
          functionID: null,
          permissionID: 50,
          tableName: 'tblRiskAssessment',
          tableRowID: -201,
          createdByID: this.userData.userID,
          creationDate: this.getCurrentDateTime_0516(),
          statementType: "INSERT"
        }],
        dataset: "SysUserTablePermission",
      }
      let tablePermissionDatasetAuditTrail = this.globalService.buildAuditTrail_0115({}, tablePermissionDataset.data[0], 'tblSysUserTablePermission');
      auditTrail = auditTrail.concat(tablePermissionDatasetAuditTrail);
      datasets.push(tablePermissionDataset);
    }

    let toDB = new DataToPost(this.globalService.clientID, this.globalService.userID, "Test reason", "Add Risk Assessment", datasets, auditTrail);

    this.apiService.postDataToBackend_0051(this.apiService.urlForPostRiskAssessment, toDB).then((data: any) => {
      if (data) {
        this.dialogRef.close({ closeType: "SUCCESS" });
      }
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Risk Assessment");
      window.alert(userErrMsg)
    });
  }

  getCurrentDateTime_0516() {
    var date = new Date();
    date.setHours(date.getHours());
    return date.toISOString().slice(0, 19).replace('T', ' ');
  }

  filterUsers_0411(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.users.filter(option => option.fullName.toLowerCase().includes(filterValue));
  }

  filterRiskQuestions_0411(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.riskQuestions.filter(option => option.riskQuestion.toLowerCase().includes(filterValue));
  }

  getUserID_0412(userFullName: string): number {
    let user = this.users.filter(user => user.fullName === userFullName);
    if (user.length >= 1)
      return user[0].userID;
    else
      return 1;
  }

  getRiskQuestionID_0412(riskQuestion: string): number {
    let riskQuestionID = this.riskQuestions.filter(riskQuestionObj => riskQuestionObj.riskQuestion === riskQuestion);
    if (riskQuestionID.length >= 1)
      return riskQuestionID[0].iD;
    else
      return -1;
  }

  riskAssessmentFormValid_XXXX() {
    if (((!this.riskAssessmentForm.value.newRiskQuestionBool && this.existingRiskQuestionSelected) || (this.riskAssessmentForm.value.newRiskQuestionBool && !this.riskQuestionExists_XXXX(this.riskAssessmentForm.value.riskQuestion)))
      && ((this.riskAssessmentForm.value.riskAssessmentLeadName && this.riskAssessmentLeadSelected) || !this.riskAssessmentForm.value.riskAssessmentLeadName)
      && this.riskAssessmentForm.valid && this.riskAssessmentForm.dirty)
      return false;
    else return true;
  }

  riskQuestionExists_XXXX(riskQuestion: string) {
    if (!this.riskQuestions.some(element => element.riskQuestion === riskQuestion))
      return false;
    else return true;
  }

  riskAssessmentTemplates = [
    {
      iD: 1,
      riskAssessmentTitle: 'Risk Assessment Template 1'
    },
    {
      iD: 2,
      riskAssessmentTitle: 'Risk Assessment Template 2'
    },
    {
      iD: 3,
      riskAssessmentTitle: 'Risk Assessment Template 3'
    },
    {
      iD: 4,
      riskAssessmentTitle: 'Risk Assessment Template 4'
    },
    {
      iD: 5,
      riskAssessmentTitle: 'Risk Assessment Template 5'
    },
    {
      iD: 6,
      riskAssessmentTitle: 'Risk Assessment Template 6'
    },
    {
      iD: 2,
      riskAssessmentTitle: 'Risk Assessment Template 2'
    },
    {
      iD: 3,
      riskAssessmentTitle: 'Risk Assessment Template 3'
    },
    {
      iD: 4,
      riskAssessmentTitle: 'Risk Assessment Template 4'
    },
    {
      iD: 5,
      riskAssessmentTitle: 'Risk Assessment Template 5'
    },
    {
      iD: 6,
      riskAssessmentTitle: 'Risk Assessment Template 6'
    },
    {
      iD: 2,
      riskAssessmentTitle: 'Risk Assessment Template 2'
    },
    {
      iD: 3,
      riskAssessmentTitle: 'Risk Assessment Template 3'
    },
    {
      iD: 4,
      riskAssessmentTitle: 'Risk Assessment Template 4'
    },
    {
      iD: 5,
      riskAssessmentTitle: 'Risk Assessment Template 5'
    },
    {
      iD: 6,
      riskAssessmentTitle: 'Risk Assessment Template 6'
    },
    {
      iD: 2,
      riskAssessmentTitle: 'Risk Assessment Template 2'
    },
    {
      iD: 3,
      riskAssessmentTitle: 'Risk Assessment Template 3'
    },
    {
      iD: 4,
      riskAssessmentTitle: 'Risk Assessment Template 4'
    },
    {
      iD: 5,
      riskAssessmentTitle: 'Risk Assessment Template 5'
    },
    {
      iD: 6,
      riskAssessmentTitle: 'Risk Assessment Template 6'
    },
  ]

}
