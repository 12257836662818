import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'riskAssessmentMasterSearch'
})
export class FMEAPipe implements PipeTransform {

    transform(failureModes: any[], searchedTerm: string): any[] {
        let returnList = [];
        if (searchedTerm === "" || searchedTerm === undefined || failureModes.length === 0) {
            return failureModes;
        }
        else {
            let keys = Object.keys(failureModes[0]);
            let filterArray: boolean[] = [];
            returnList = failureModes.filter(failureMode => {
                filterArray = [];
                for (let key of keys) {
                    if (typeof (failureMode[key]) === "string") {
                        filterArray.push(failureMode[key].toLocaleLowerCase().includes(searchedTerm.toLocaleLowerCase()));
                    }
                }
                return filterArray.includes(true);
            });
            return returnList;
        }
    }
}