import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FilterService {

    constructor() { }
    public searchedFilterObjects: any = { riskAssessmentREF: [], riskAssessmentType: [], businessProcess: [], functionalArea: [], initialRS: [], initialRPN: [], initialRiskLevel: [], riskDecision: [], residualRS: [], residualRPN: [], residualRiskLevel: [], riskStatus: [], riskAssessmentProximity: [], itemGroupName: [], failureMode: [], riskTeamMember: [] };
    public searchedFilterControlObjects: any = { category: [] };

    getSearchedFilterObjectsRisk_0478(): any {
        return this.searchedFilterObjects;
    }

    getSearchedFilterObjectsControl_0478(): any {
        return this.searchedFilterControlObjects;
    }

    setSearchedFilterObjectsRisk_0479(searchedFilterObjects: any[], key: string): void {
        this.searchedFilterObjects[key] = searchedFilterObjects;
    }

    setSearchedFilterObjectsControl_0479(searchedFilterControlObjects: any[], key: string): void {
        this.searchedFilterControlObjects[key] = searchedFilterControlObjects;
    }

    getFilterOptionsRisk_0477(listOfRisks: any[], listOfRiskTeamMembers: any[]): any[] {
        let filterLists: any = { riskAssessmentREF: [], riskAssessmentType: [], businessProcess: [], functionalArea: [], initialRS: [], initialRPN: [], initialRiskLevel: [], riskDecision: [], residualRS: [], residualRPN: [], residualRiskLevel: [], riskStatus: [], riskAssessmentProximity: [], itemGroupName: [], failureMode: [], riskTeamMember: [] };
        const keys = Object.keys(filterLists);

        listOfRisks.forEach(risk => {
            keys.forEach(key => {
                if (key != 'riskTeamMember') {
                    let tempKeys: any[] = this.getFilterObjectKeysRisk_0476(key);
                    let validIndex = filterLists[key].findIndex((item: any) => (item.filterValue === risk[tempKeys[0]]));
                    if (validIndex === -1 && risk[tempKeys[0]] != null && risk[tempKeys[0]] != " ") {
                        let tempObject: any = { filterValue: risk[tempKeys[0]], visible: true, checked: false };
                        filterLists[key].push(tempObject);
                    }
                }
            });
        });

        listOfRiskTeamMembers.forEach(element => {
            let tempKeys = this.getFilterObjectKeysRisk_0476('riskTeamMember');
            let validIndex = filterLists['riskTeamMember'].findIndex((item: any) => (item.filterValue === element[tempKeys[0]]));
            if (validIndex === -1) {
                if (listOfRisks.some(risk => element.riskAssessmentID === risk.riskAssessmentID)) {
                    let tempObject: any = { filterValue: element[tempKeys[0]], visible: true, checked: false }
                    filterLists['riskTeamMember'].push(tempObject);
                }
            }
        });

        return filterLists;
    }

    getFilterOptionsControl_0477(listOfControls: any) {
        let filterLists: any = { category: [] };
        const keys = Object.keys(filterLists);

        listOfControls.forEach((control: any) => {
            keys.forEach(key => {
                let tempKeys: any[] = this.getFilterObjectKeysControl_0476(key);
                let validIndex = filterLists[key].findIndex((item: any) => (item.filterValue === control[tempKeys[0]]));
                if (validIndex === -1 && control[tempKeys[0]] != null && control[tempKeys[0]] != " ") {
                    let tempObject: any = { filterValue: control[tempKeys[0]], visible: true, checked: false };
                    filterLists[key].push(tempObject);
                }
            });
        });

        return filterLists;
    }

    // Revisit
    getFilterObjectKeysRisk_0476(key: string): any | null {
        switch (key) {
            case 'riskAssessmentType':
                return ['riskAssessmentType'];
            case 'businessProcess':
                return ['businessProcess'];
            case 'functionalArea':
                return ['functionalArea'];
            case 'initialRS':
                return ['initialRS'];
            case 'initialRPN':
                return ['initialRPN'];
            case 'initialRiskLevel':
                return ['initialRiskLevel'];
            case 'riskDecision':
                return ['riskDecision'];
            case 'residualRS':
                return ['residualRS'];
            case 'residualRPN':
                return ['residualRPN'];
            case 'residualRiskLevel':
                return ['residualRiskLevel'];
            case 'riskStatus':
                return ['riskStatus'];
            case 'riskAssessmentProximity':
                return ['riskAssessmentProximity'];
            case 'riskAssessmentREF':
                return ['riskAssessmentREF'];
            case 'itemGroupName':
                return ['itemGroupName'];
            case 'failureMode':
                return ['failureMode'];
            case 'riskTeamMember':
                return ['fullName'];
            default:
                break;
        }
    }

    getFilterObjectKeysControl_0476(key: string): any | null {
        switch (key) {
            case 'category':
                return ['category'];
            default:
                break;
        }
    }

    filterRisks_0475(listOfRisks: any[], selectedFilterOptions: any[], listOfRiskTeamMembers: any[]) {
        let stageOneFilter: any[];
        let stageTwoFilter: any[];
        let stageThreeFilter: any[];
        let stageFourFilter: any[];
        let stageFiveFilter: any[];
        let stageSixFilter: any[];
        let stageSevenFilter: any[];
        let stageEightFilter: any[];
        let stageNineFilter: any[];
        let stageTenFilter: any[];
        let stageElevenFilter: any[];
        let stageTwelveFilter: any[];
        let stageThirteenFilter: any[];
        let stageFourteenFilter: any[];
        let stageFifteenFilter: any[];
        let listOfFilteredRisks: any[];
        let tempListOfRiskTeamMembers: any[] = [];

        if (selectedFilterOptions.some(filterOption => filterOption.type === 'riskAssessmentREF')) {
            stageOneFilter = listOfRisks.filter((el) => {
                return selectedFilterOptions.some((selectedFilterOption) => {
                    return selectedFilterOption.filterValue === el.riskAssessmentREF;
                });
            });
        } else {
            stageOneFilter = listOfRisks;
        }

        if (selectedFilterOptions.some(filterOption => filterOption.type === 'riskAssessmentType')) {
            stageTwoFilter = stageOneFilter.filter((el) => {
                return selectedFilterOptions.some((selectedFilterOption) => {
                    return selectedFilterOption.filterValue === el.riskAssessmentType;
                });
            });
        } else {
            stageTwoFilter = stageOneFilter;
        }

        if (selectedFilterOptions.some(filterOption => filterOption.type === 'functionalArea')) {
            stageThreeFilter = stageTwoFilter.filter((el) => {
                return selectedFilterOptions.some((selectedFilterOption) => {
                    return selectedFilterOption.filterValue === el.functionalArea;
                });
            });
        } else {
            stageThreeFilter = stageTwoFilter;
        }

        if (selectedFilterOptions.some(filterOption => filterOption.type === 'businessProcess')) {
            stageFourFilter = stageThreeFilter.filter((el) => {
                return selectedFilterOptions.some((selectedFilterOption) => {
                    return selectedFilterOption.filterValue === el.businessProcess;
                });
            });
        } else {
            stageFourFilter = stageThreeFilter;
        }

        if (selectedFilterOptions.some(filterOption => filterOption.type === 'initialRS')) {
            stageFiveFilter = stageFourFilter.filter((el) => {
                return selectedFilterOptions.some((selectedFilterOption) => {
                    return selectedFilterOption.filterValue === el.initialRS;
                });
            });
        } else {
            stageFiveFilter = stageFourFilter;
        }

        if (selectedFilterOptions.some(filterOption => filterOption.type === 'initialRPN')) {
            stageSixFilter = stageFiveFilter.filter((el) => {
                return selectedFilterOptions.some((selectedFilterOption) => {
                    return selectedFilterOption.filterValue === el.initialRPN;
                });
            });
        } else {
            stageSixFilter = stageFiveFilter;
        }

        if (selectedFilterOptions.some(filterOption => filterOption.type === 'initialRiskLevel')) {
            stageSevenFilter = stageSixFilter.filter((el) => {
                return selectedFilterOptions.some((selectedFilterOption) => {
                    return selectedFilterOption.filterValue === el.initialRiskLevel;
                });
            });
        } else {
            stageSevenFilter = stageSixFilter;
        }

        if (selectedFilterOptions.some(filterOption => filterOption.type === 'riskDecision')) {
            stageEightFilter = stageSevenFilter.filter((el) => {
                return selectedFilterOptions.some((selectedFilterOption) => {
                    return selectedFilterOption.filterValue === el.riskDecision;
                });
            });
        } else {
            stageEightFilter = stageSevenFilter;
        }

        if (selectedFilterOptions.some(filterOption => filterOption.type === 'residualRS')) {
            stageNineFilter = stageEightFilter.filter((el) => {
                return selectedFilterOptions.some((selectedFilterOption) => {
                    return selectedFilterOption.filterValue === el.residualRS;
                });
            });
        } else {
            stageNineFilter = stageEightFilter;
        }

        if (selectedFilterOptions.some(filterOption => filterOption.type === 'residualRPN')) {
            stageTenFilter = stageNineFilter.filter((el) => {
                return selectedFilterOptions.some((selectedFilterOption) => {
                    return selectedFilterOption.filterValue === el.residualRPN;
                });
            });
        } else {
            stageTenFilter = stageNineFilter;
        }

        if (selectedFilterOptions.some(filterOption => filterOption.type === 'residualRiskLevel')) {
            stageElevenFilter = stageTenFilter.filter((el) => {
                return selectedFilterOptions.some((selectedFilterOption) => {
                    return selectedFilterOption.filterValue === el.residualRiskLevel;
                });
            });
        } else {
            stageElevenFilter = stageTenFilter;
        }

        if (selectedFilterOptions.some(filterOption => filterOption.type === 'riskStatus')) {
            stageTwelveFilter = stageElevenFilter.filter((el) => {
                return selectedFilterOptions.some((selectedFilterOption) => {
                    return selectedFilterOption.filterValue === el.riskStatus;
                });
            });
        } else {
            stageTwelveFilter = stageElevenFilter;
        }

        if (selectedFilterOptions.some(filterOption => filterOption.type === 'riskAssessmentProximity')) {
            stageThirteenFilter = stageTwelveFilter.filter((el) => {
                return selectedFilterOptions.some((selectedFilterOption) => {
                    return selectedFilterOption.filterValue === el.riskAssessmentProximity;
                });
            });
        } else {
            stageThirteenFilter = stageTwelveFilter;
        }

        if (selectedFilterOptions.some(filterOption => filterOption.type === 'itemGroupName')) {
            stageFourteenFilter = stageThirteenFilter.filter((el) => {
                return selectedFilterOptions.some((selectedFilterOption) => {
                    return selectedFilterOption.filterValue === el.itemGroupName;
                });
            });
        } else {
            stageFourteenFilter = stageThirteenFilter;
        }

        if (selectedFilterOptions.some(filterOption => filterOption.type === 'failureMode')) {
            stageFifteenFilter = stageFourteenFilter.filter((el) => {
                return selectedFilterOptions.some((selectedFilterOption) => {
                    return selectedFilterOption.filterValue === el.failureMode;
                });
            });
        } else {
            stageFifteenFilter = stageFourteenFilter;
        }

        if (selectedFilterOptions.some(filterOption => filterOption.type === 'riskTeamMember')) {
            let listOfRiskTeamMemberRisks: any[] = [];
            let listOfSelectedRiskTeamMemberIDs = selectedFilterOptions.filter(filterOption => filterOption.type === 'riskTeamMember');

            listOfSelectedRiskTeamMemberIDs.forEach(inspectorID => {
                let tempArray = listOfRiskTeamMembers.filter(riskTeamMember => riskTeamMember.fullName == inspectorID.filterValue);
                tempListOfRiskTeamMembers = tempListOfRiskTeamMembers.concat(tempArray);
            });

            tempListOfRiskTeamMembers.forEach(riskTeamMember => {
                let tempArray = stageFifteenFilter.filter(risk => risk.riskAssessmentID == riskTeamMember.riskAssessmentID);
                listOfRiskTeamMemberRisks = listOfRiskTeamMemberRisks.concat(tempArray);
            });

            listOfFilteredRisks = listOfRiskTeamMemberRisks;
        } else {
            listOfFilteredRisks = stageFifteenFilter;
        }

        return listOfFilteredRisks;
    }

    filterControls_0559(listOfControls: any, selectedFilterControlOptions: any) {
        let stageOneFilter;
        let listOfFilteredControls;

        if (selectedFilterControlOptions.some((filterOption: any) => filterOption.type === 'category')) {
            stageOneFilter = listOfControls.filter((el: any) => {
                return selectedFilterControlOptions.some((selectedFilterOption: any) => {
                    return selectedFilterOption.filterValue === el.category;
                });
            });
        } else {
            stageOneFilter = listOfControls;
        }

        listOfFilteredControls = stageOneFilter;

        return listOfFilteredControls;
    }
}
