export const environment = {
    production: false,
    applicationDisplayName: 'Active-Risk-Management Dev Environment',

    // THE CONFIGURATION SERVER IS RUNS ON PORT 44311. THIS SERVER IS USED FOR LOGING
    configBaseURL: "https://10.18.64.52:44311/",

    // THE BACKEND SERVER IS CONFIGURED TO RUN ON PORT 44301

    // TO CONNECT TO THE BACKEND RUNNING ON THE IRLCA DEV SERVER @ 10.18.64.52
    applicationBaseURL: 'https://10.18.64.52:44301',

    // TO CONNECT TO YOUR LOCAL BACKEND SERVER BACKEND
    // applicationBaseURL: 'https://localhost:44301/',


    // TO CONNECT TO THE BACKEND RUNNING ON ARM DEV SERVER ON AWS
    // applicationBaseURL: 'https://armdev.irlca.com:44301/',

    // THE LOGGING SERVER RUNS ON PORT 1099
    applicationFELogging: 'https://10.18.64.52:1099/api/Log/'
}