<div class="flow-diagram-container">
    <div class="page-card-back-btn-container no-margin" [ngClass]="{'disabled': editModeBool}">
        <button class="back-btn" id="risk-assessment-expanded-detail-close-button"
            (click)="navigateToExpandedDetailView_0409()">
            <div> <fa-icon class="white-icon" [icon]="arrowLeft"></fa-icon> </div>
        </button>
    </div>
    <div class="page-card right">
        <div class="page-card-header">
            <div class="reference-title">
                <h3 id="risk-assessment-expanded-detail-title" class="title uppercase" >Risk Assessment Flow Diagram
                </h3>
                <h3 class="separator">&nbsp;</h3>
                <h3 id="risk-assessment-expanded-detail-ref" class="reference uppercase">
                    {{armService.selectedRiskAssessment.riskAssessmentREF}}
                </h3>
            </div>
            <button mat-button class="icon-btn icon-btn-white" id="risk-assessment-ellipsis-btn"
                [matMenuTriggerFor]="menu"
                *ngIf="!editModeBool && (armService.selectedRiskAssessment.permissionName == 'Editor' || armService.selectedRiskAssessment.permissionName == 'Owner')">
                <div class="icon"><fa-icon [icon] = "ellipsisHorizontal"></fa-icon>
                </div>
            </button>
        </div>
        <div class="page-card-body">
            <!-- Dx-Diagram contains the DEV Extreme Diagram component -->
            <dx-diagram #diagram id="diagram">
            </dx-diagram>
        </div>
        <div class="edit-mode-footer-diagram" *ngIf="editModeBool">
            <div class="button-gap">
                <p>Edit Mode - Please Save or Cancel all actions</p>
                <button mat-button class="btn cancel" (click)="turnOffEditMode();">
                    <p>Cancel</p>
                </button>
                <button mat-button class="btn primary" appDebounceClick (debounceClick)="prepareDataForPostFlowDiagram()" [debounceTime]="200" >
                    <p>Save</p>
                </button>
            </div>
        </div>
    </div>
</div>
<!-- Menu for Ellipsis -->
<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="turnOnEditMode()" id="flow-diagram-ellipsis-edit" *ngIf="!editModeBool"
        [disabled]="inWorkflow || armService.selectedRiskAssessment.approvedDate">
        <p>Edit</p>
    </button>
</mat-menu>
<!-- End of Menu For Ellipsis -->