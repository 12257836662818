<div class="dialog">
    <div class="dialog-header">
        <div class="header-text center">
            <h2>Associated Risks</h2>
        </div>
        <div class="align-items-start">
            <div class="header-icon">
                <button mat-button class="icon-btn dialog-close-btn" (click)="dialogRef.close()">
                    <div class="icon">
                        <fa-icon [icon]="times"></fa-icon>
                    </div>
                </button>
            </div>
        </div>
    </div>
    <div class="dialog-content" *ngIf="associatedRisksAssigned">
        <div class="associated-risks-table">
            <dx-data-grid id="gridContainer" [dataSource]="tasks" keyExpr="iD" [showBorders]="true"
            (onCellPrepared)="onCellPreparedActionTable($event)" (onRowRemoved)="deleteMitigatingAction($event)">
            <dxi-column dataField="iD" [width]="70" caption="ID"></dxi-column>
            <dxi-column dataField="description"></dxi-column>
            <dxi-column dataField="status"></dxi-column>
            <dxo-editing mode="row" [allowDeleting]="allowDeleting">
            </dxo-editing>
            <dxo-master-detail [enabled]="true" template="detail" [autoExpandAll]="!allowDeleting"></dxo-master-detail>
            <div *dxTemplate="let associatedRisk of 'detail'">
                <dx-data-grid [dataSource]="associatedRisk.data.associatedRisks" keyExpr="riskID" [showBorders]="true"
                    (onCellPrepared)="onCellPreparedAssociatedRisk($event)"
                    (onRowDblClick)="goToRiskAnalysis_0466($event)"
                    (onRowRemoved)="removeAssociation($event, associatedRisk.data.associatedRisks)">
                    <dxi-column dataField="riskREF" caption="Risk REF"></dxi-column>
                    <dxi-column dataField="failureCause"></dxi-column>
                    <dxi-column dataField="failureEffect"></dxi-column>
                    <dxo-editing mode="row" [allowDeleting]="allowDeleting">
                        <dxo-texts deleteRow="Remove">
                        </dxo-texts>
                    </dxo-editing>
                </dx-data-grid>
            </div>
        </dx-data-grid>
        </div>
    </div>
    <div class="dialog-footer">
        <div class="only-buttons" *ngIf="data.mode === 'riskRecord'">
            <button mat-button class="btn cancel" (click)="dialogRef.close()">
                <p>Cancel</p>
            </button>
            <button mat-button class="btn done" (click)="saveDialog()">
                <p>Done</p>
            </button>
        </div>
        <div class="only-buttons" *ngIf="data.mode === 'register'">
            <button mat-button class="btn done" (click)="dialogRef.close()">
                <p>Done</p>
            </button>
        </div>
    </div>
</div>