import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RiskAssessmentDialogComponent } from './risk-assessment-dialog/risk-assessment-dialog.component';
import { RiskDialogComponent } from './risk-dialog/risk-dialog.component';

import { RiskAssessmentTeamDialogComponent } from '../../shared/dialog/risk-assessment-team-dialog/risk-assessment-team-dialog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { RiskRegisterFilterDialogComponent } from './risk-register-filter-dialog/risk-register-filter-dialog.component';
import { RiskAssessmentLeadDialogComponent } from './risk-assessment-lead-dialog/risk-assessment-lead-dialog.component';
import { ControlDialogComponent } from './control-dialog/control-dialog.component';
import { ControlRisksDialogComponent } from './control-risks-dialog/control-risks-dialog.component';
import { ControlRegisterFilterDialogComponent } from './control-register-filter-dialog/control-register-filter-dialog.component';
import { PeriodicReviewDialogComponent } from './periodic-review-dialog/periodic-review-dialog.component';
import { InactiveTimeoutDialogComponent } from './inactive-timeout-dialog/inactive-timeout-dialog.component';
import { RiskAssessmentReviewDialogComponent } from './risk-assessment-review-dialog/risk-assessment-review-dialog.component';
import { RiskAssessmentReviewCommentsDialogComponent } from './risk-assessment-review-comments-dialog/risk-assessment-review-comments-dialog.component';
import { ItemGroupOrderDialogComponent } from './item-group-order-dialog/item-group-order-dialog.component';
import { SODRatingDialogComponent } from './sod-rating-dialog/sod-rating-dialog.component';
import { SODControlDialogComponent } from './sod-control-dialog/sod-control-dialog.component';
import { TaskRisksDialogComponent } from './task-risks-dialog/task-risks-dialog.component';
import { ApprovalsDialogComponent } from './approvals-dialog/approvals-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class ARMDialogService {

    constructor(public dialog: MatDialog) { }

    openDialog_0088(componentName: string, configData: any): any {
        let component: any;
        switch (componentName) {
            case 'RiskAssessmentDialogComponent': {
                component = RiskAssessmentDialogComponent;
                break;
            }
            case 'AddTeamMemberDialogComponent': {
                component = RiskAssessmentTeamDialogComponent;
                break;
            }
            case 'ConfirmationDialogComponent': {
                component = ConfirmationDialogComponent;
                break;
            }
            case 'RiskDialogComponent': {
                component = RiskDialogComponent;
                break;
            }
            case 'RiskRegisterFilterDialogComponent': {
                component = RiskRegisterFilterDialogComponent;
                break;
            }
            case 'SODRatingDialogComponent': {
                component = SODRatingDialogComponent;
                break;
            }
            case 'SODControlDialogComponent': {
                component = SODControlDialogComponent;
                break;
            }
            case 'RiskAssessmentLeadDialogComponent': {
                component = RiskAssessmentLeadDialogComponent;
                break;
            }
            case 'ControlDialogComponent': {
                component = ControlDialogComponent;
                break;
            }
            case 'ControlRisksDialogComponent': {
                component = ControlRisksDialogComponent;
                break;
            }
            case 'ControlRegisterFilterDialogComponent': {
                component = ControlRegisterFilterDialogComponent;
                break;
            }
            case 'PeriodicReviewDialogComponent': {
                component = PeriodicReviewDialogComponent;
                break;
            }
            case 'InactiveTimeoutDialogComponent': {
                component = InactiveTimeoutDialogComponent;
                break;
            }
            case 'RiskAssessmentReviewDialogComponent': {
                component = RiskAssessmentReviewDialogComponent;
                break;
            }
            case 'RiskAssessmentReviewCommentsDialogComponent': {
                component = RiskAssessmentReviewCommentsDialogComponent;
                break;
            }
            case 'ItemGroupOrderDialogComponent': {
                component = ItemGroupOrderDialogComponent;
                break;
            }
            case 'TaskRisksDialogComponent': {
                component = TaskRisksDialogComponent;
                break;
            }
            case 'ApprovalsDialogComponent': {
                component = ApprovalsDialogComponent;
                break;
            }
            default: {
                break;
            }
        }

        if (component !== undefined && configData !== undefined) {
            const dialogRef = this.dialog.open(component, {
                width: configData.width,
                height: configData.height,
                maxHeight: configData.maxHeight,
                minHeight: configData.minHeight,
                panelClass: configData.panelClass,
                maxWidth: configData.maxWidth,
                minWidth: configData.minWidth,
                data: configData.data,
                disableClose: true
            });
            return dialogRef;
        } else {
            console.error('Opening dialog failed because of invalid inputs');
            return null;
        }
    }
}
