import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-communications',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.scss']
})
export class CommunicationsComponent implements OnInit {

  @ViewChild('InitialRiskGauge', { static: false })
  canvas!: ElementRef<HTMLCanvasElement>;

  constructor() { }

  ngOnInit() {

    // this.renderICH_Q9_Figure1(this.figureContext);

  }

  // @HostListener('window:resize', ['$event'])
  // onResize() {
  //   this.renderICH_Q9_Figure1(this.figureContext);
  // }

  renderICH_Q9_Figure1(ctx: any) {

    let riskDashboardWidth: number = 500;
    let riskDashboardHeight: number = 1000;

    ctx = this.canvas.nativeElement.getContext('2d');

    ctx.canvas.width = riskDashboardWidth;
    ctx.canvas.height = riskDashboardHeight;
    ctx.clearRect(0, 0, riskDashboardWidth, riskDashboardHeight);

    var x = 200;
    var y = 100

    ctx.textAlign = "center";
    ctx.font = "12px Lato";

    ctx.beginPath();
    ctx.rect(x, y - 90, 200, 45);
    ctx.stroke();
    ctx.fillText("Initiate", x + 100, y - 70);
    ctx.fillText("Quality Risk Management Process", x + 100, y - 57);

    ctx.beginPath();
    ctx.rect(x, y, 200, 30);
    ctx.stroke();

    ctx.fillText("Risk Identidfication", x + 100, y + 20);

    ctx.beginPath();
    ctx.rect(x, y + 50, 200, 30);
    ctx.stroke();
    ctx.fillText("Risk Analysis", x + 100, y + 70);

    ctx.beginPath();
    ctx.rect(x, y + 100, 200, 30);
    ctx.stroke();
    ctx.fillText("Risk Evaluation", x + 100, y + 120);

    ctx.beginPath();
    ctx.rect(x, y + 200, 200, 30);
    ctx.stroke();
    ctx.fillText("Risk Reduction", x + 100, y + 220);

    ctx.beginPath();
    ctx.rect(x, y + 250, 200, 30);
    ctx.stroke();
    ctx.fillText("Risk Acceptance", x + 100, y + 270);

    ctx.beginPath();
    ctx.rect(x, y + 320, 200, 45);
    ctx.stroke();
    ctx.fillText("Output / Result of the", x + 100, y + 340);
    ctx.fillText("Quality Risk Management Process", x + 100, y + 353);

    ctx.beginPath();
    ctx.rect(x, y + 410, 200, 30);
    ctx.stroke();
    ctx.fillText("Review Events", x + 100, y + 430);

  }

}
