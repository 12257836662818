import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { IrlcoreAuthService, GlobalService } from '@irlca/irlcore';
import { ArmService } from '../../../../arm.service';
import { ARMDialogService } from '../../../../shared/dialog/dialog.service';
import { cloneDeep } from 'lodash';
import { dataset } from '@irlca/irlcore';
import { APIService } from '@irlca/irlcore';
import { faCheck, faEllipsisH, faPlus } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-risk-assessment-team',
  templateUrl: './risk-assessment-team.component.html',
  styleUrls: ['./risk-assessment-team.component.scss']
})
export class RiskAssessmentTeamComponent implements OnInit {

  @Input() editMode: any;
  @Input() savePressed: any;

  displayedTeamColumns: string[] = ['fullName', 'riskAssessmentRole', 'approver', 'reviewer', 'department'];
  auditTrailList: any[] = [];
  tableData: any[] = [];
  tableList: any[] = [];
  pageSizeInRows!: number;
  currentPage = 0;
  datasets: dataset[] = [];
  confirmationMode!: string;
  riskAssessmentExpandedDetailSavePressedCalled: boolean = false;
  counter: number = 0;
  emptyRow = {
    value: null, fullName: null, contactNumber: null, email: null,
    departmentName: null, entityName: null, location: null,
  };

  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    this.armService.teamDataSource.sort = value;
  }

  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    this.armService.teamDataSource.paginator = value;
  }

  // icons
  plus = faPlus
  ellipsisHorizontal = faEllipsisH
  check = faCheck
  
  constructor(
    public auth: IrlcoreAuthService,
    public armService: ArmService,
    public globalService: GlobalService,
    public dialogService: ARMDialogService,
    public apiService: APIService
  ) {
  }

  ngOnInit() {
    this.addDeletedField_0043();
  }

  ngOnChanges() {
    if (this.savePressed && !this.riskAssessmentExpandedDetailSavePressedCalled)
      this.riskAssessmentExpandedDetailSavePressed_XXXX();
  }


  removeDeletion_0045(row: any) {
    if (row.statementType == 'INSERT') {
      const dataToPost = {
        iD: row.iD,
        // clientID: data.clientID,
        riskAssessmentID: this.armService.selectedRiskAssessment.iD,
        userID: row.userID,
        roleID: row.riskAssessmentRoleID,
        isReviewer: row.selectedReviewer,
        isApprover: row.selectedApprover,
        statementType: 'INSERT',
      };
      this.armService.teamTableDataset.data.push(dataToPost);
    } else if (row.statementType == 'UPDATE') {
      const dataToPost = {
        iD: row.iD,
        // clientID: data.clientID,
        riskAssessmentID: this.armService.selectedRiskAssessment.iD,
        userID: row.userID,
        roleID: row.riskAssessmentRoleID,
        isReviewer: row.selectedReviewer,
        isApprover: row.selectedApprover,
        statementType: 'UPDATE',
      };
      this.armService.teamTableDataset.data.push(dataToPost);
      const index = this.armService.teamTableDataset.data.findIndex((data: any) => (data.iD == row.iD && data.statementType == 'DELETE'));
      this.armService.teamTableDataset.data.splice(index, 1);
    } else {
      const index = this.armService.teamTableDataset.data.findIndex((data: any) => (data.iD == row.iD && data.statementType == 'DELETE'));
      this.armService.teamTableDataset.data.splice(index, 1);
    }

    for (let i = 0; i < this.armService.teamTableDataset.data.length; i++) {
      if (this.armService.teamTableDataset.data[i].iD === row.iD && this.armService.teamTableDataset.data[i].statementType == 'DELETE') {
        this.armService.teamTableDataset.data.splice(i, 1);
      }
    }
  }

  addDeletedField_0043() {
    this.armService.teamTableList.forEach(entry => {
      entry['deleted'] = false;
    });
    this.armService.teamDataSource.data = this.armService.teamTableList;
  }

  deleteTableEntry_0244(row: any): void {
    let insertFlag = false;
    let dataToPost = {
      iD: row.iD,
      // clientID: data.clientID,
      riskAssessmentID: this.armService.selectedRiskAssessment.iD,
      userID: row.userID,
      roleID: row.roleID,
      isReviewer: row.isReviewer,
      isApprover: row.isApprover,

      statementType: 'DELETE',
    };
    this.armService.teamTableDataset.data.forEach((element: any, index: number) => {
      if (element.iD === row.iD) {
        if (this.armService.teamTableDataset.data[index].statementType == 'INSERT') {
          this.armService.teamTableDataset.data.splice(index, 1);
          insertFlag = true;
        } else {
          this.armService.teamTableDataset.data.splice(index, 1);
        }
      }
    });

    if (insertFlag) {
      return;
    } else {
      this.armService.teamTableDataset.data.push(dataToPost);
      let deletedRowAuditTrail = this.globalService.buildAuditTrail_0115({}, dataToPost, "tblRiskAssessmentTeamMember");
      deletedRowAuditTrail.forEach((element: any) => {
        this.auditTrailList.push(element);
      });
      this.armService.teamAuditTrailList = this.armService.teamAuditTrailList.concat(this.auditTrailList)
    }
  }

  openAddTeamMemberDialog_0303(row: any, state: string, add: any) {
    const dialogConfigData = {
      maxWidth: '39.7vw',
      minWidth: '39.7vw',
      data: {
        table: this.armService.teamTableList,
        row,
        state,
        addMember: add,
        clientID: this.globalService.clientID,
        entryID: this.armService.teamEntryID
      }
    };

    const dialogRef = this.dialogService.openDialog_0088('AddTeamMemberDialogComponent', dialogConfigData);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result.closeType === 'save') {
          if (result.dataToPost[1].statementType == 'INSERT' && this.armService.teamTableList.findIndex(data => data.iD == result.dataToPost[1].iD) < 0) {
            this.armService.teamTableList.push(result.dataToPost[1]);
            this.armService.teamDataSource.data = this.armService.teamTableList;

            this.armService.teamTableDataset.data.push(result.dataToPost[0]);
            const tempAuditTrailList = cloneDeep(this.auditTrailList);
            result.dataToPost[2].forEach((element: any) => {
              this.auditTrailList = this.globalService.addToAuditTrailList_0038(element, tempAuditTrailList);
            });
            this.armService.teamEntryID -= 1;
          } else if (this.armService.teamTableDataset.data.findIndex((data: any) => data.iD == result.dataToPost[0].iD) >= 0) {
            let index = this.armService.teamTableList.findIndex(data => data.iD == result.dataToPost[1].iD);
            this.armService.teamTableList[index] = result.dataToPost[1];
            this.armService.teamDataSource.data = this.armService.teamTableList;
            index = this.armService.teamTableDataset.data.findIndex((data: any) => data.iD == result.dataToPost[0].iD);
            this.armService.teamTableDataset.data[index] = result.dataToPost[0];

          } else {

            const index = this.armService.teamTableList.findIndex((data: any) => data.iD == result.dataToPost[1].iD);
            this.armService.teamTableList[index] = result.dataToPost[1];
            this.armService.teamDataSource.data = this.armService.teamTableList;
            this.armService.teamTableDataset.data.push(result.dataToPost[0]);

          }
        }
      }
    });
  }

  riskAssessmentExpandedDetailSavePressed_XXXX() {
    this.riskAssessmentExpandedDetailSavePressedCalled = true;
    this.armService.teamSaveFunctionCalled = true;
    this.armService.$riskAssessmentExpandedDetailSavePressed.next(true);
    this.riskAssessmentExpandedDetailSavePressedCalled = false;
  }

  onEditMember(member: any, type: string) {
    if (type === 'approver') {
      if (member.isApprover) {
        member.isApprover = false;
      } else {
        member.isApprover = true;
      }
    } else {
      if (member.isReviewer) {
        member.isReviewer = false;
      } else {
        member.isReviewer = true;
      }
    }
    if (this.armService.teamTableDataset.data.length === 0 || (this.armService.teamTableDataset.data.findIndex((data: any) => data.iD == member.iD) < 0) || (this.armService.teamTableDataset.data.findIndex((data: any) => data.roleID === 353)>= 0)) {
      let editIndex = this.armService.teamTableDataset.data.findIndex((data: any) => data.roleID === 353);
      if(editIndex >= 0) {
        this.armService.teamTableDataset.data[editIndex].isReviewer = member.isReviewer;
        this.armService.teamTableDataset.data[editIndex].isApprover = member.isApprover;
      } else {
        let dataToPost = {
          iD: member.iD,
          // clientID: data.clientID,
          riskAssessmentID: this.armService.selectedRiskAssessment.iD,
          userID: member.userID,
          fullName: member.fullName,
          roleID: member.roleID,
          isReviewer: member.isReviewer,
          isApprover: member.isApprover,
  
          statementType: 'UPDATE',
        };
        this.armService.teamTableDataset.data.push(dataToPost);
      }

    } else {
      if (this.armService.teamTableDataset.data.findIndex((data: any) => data.iD === member.iD) >= 0) {
        let index = this.armService.teamTableDataset.data.findIndex((data: any) => data.iD == member.iD);

        this.armService.teamTableDataset.data[index].isReviewer = member.isReviewer;
        this.armService.teamTableDataset.data[index].isApprover = member.isApprover;

      }
    }
  }
}

