import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class RegisterService {

    navigateRiskRegister: boolean = false;
    navigateControlRegister: boolean = false;
    navigateMitigatingActionsRegister: boolean = false;
    filteredRisks: any = [];
    filteredControls: any = [];
    selectedFilterOptions: any = [];
    allRisks: any = [];
    allControls: any = [];
    riskRegisterFilterValue: any;
    controlRegisterFilterValue: any;
    riskRegisterTeamMembers: any = [];
    selectedRiskForAnalysis: any;
    selectedRiskForAnalysisTeam: any;
    risksFilteredApplied: boolean = false;
    controlsFilterApplied: boolean = false;
    sidebarOpen: boolean = false;
    pageSizeInRows!: number;
    currentPage = 0;
   
    constructor() { }

    openCloseSidebarRiskService_0044() {
        this.sidebarOpen = !this.sidebarOpen;
    }

    calcPageSizeInRows_0129() {
        let rowSize = null;
        let headerSize = null;
        let paginatorSize = null;
        let divSize = document.getElementById('generalTable')!.clientHeight;
        
        if (document.getElementById('risk-register-table-row') != null) {            
            rowSize = document.getElementById('risk-register-table-row')!.clientHeight;
            headerSize = document.getElementById('risk-register-table-header-row')!.clientHeight;
            paginatorSize = document.getElementById('risk-register-table-paginator')!.clientHeight;            
        } else {
            rowSize = 48;
            headerSize = 57;
            paginatorSize = 74;
        }
        this.pageSizeInRows = Math.floor((divSize - headerSize - paginatorSize) / rowSize);
        if (this.pageSizeInRows < 0) {
            this.pageSizeInRows = 0;
        }
    }
}