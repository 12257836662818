<div class="footer">
    <p *ngIf="globalService.postFlag"
        [ngClass]="(globalService.postStatus == 'Successfully Posted') ? 'success' : 'fail'">
        {{globalService.postStatus}}</p>
    <p *ngIf="auth.postFlag" [ngClass]="(auth.postStatus == 'Successfully Posted') ? 'success' : 'fail'">
        {{auth.postStatus}}</p>

    <div class="app-environment">
        <h6>Environment: {{globalService.configurableParameters.applicationEnvironment}}</h6>
    </div>    
    <div class="app-version">
        <h5>Version: {{globalService.configurableParameters.applicationVersion}}</h5>
    </div>
</div>