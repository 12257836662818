<div class="risk-assessment-dashboard">
    <div class="risk-profile-filter-container">
        <div class="filter-item">
            <p>Entity</p>
            <dx-select-box [(value)]="selectedFilter.entity" [items]="filterConditions.entities"
                (onValueChanged)="updateDashboard()" [showClearButton]="true">
            </dx-select-box>
        </div>
        <div class="filter-item">
            <p>Impacted Functional Area</p>
            <dx-select-box [(value)]="selectedFilter.impactedFunctionalArea"
                [items]="filterConditions.impactedFuncationalAreas" (onValueChanged)="updateDashboard()"
                [showClearButton]="true">
            </dx-select-box>
        </div>
        <div class="filter-item">
            <p>Business Process</p>
            <dx-select-box [(value)]="selectedFilter.impactedBusinessProcess"
                [items]="filterConditions.impactedBusinessProcess" (onValueChanged)="updateDashboard()"
                [showClearButton]="true">
            </dx-select-box>
        </div>
        <div class="filter-item">
            <p>Year</p>
            <dx-select-box [(value)]="selectedFilter.year" [items]="filterConditions.availableYears"
                (onValueChanged)="updateDashboard()" [showClearButton]="true">
            </dx-select-box>
        </div>
    </div>
    <div class="chart-row">
        <div class="chart-container">
            <dx-chart id="chart" title="Risk Assessments" [dataSource]="riskAssessmentGraph" [rotated]="false"
                (onLegendClick)="legendClick($event)">
                <dxi-series valueField="created" name="Created" color="#0850b2"></dxi-series>
                <dxi-series valueField="approved" name="Approved" color="#5AA454"></dxi-series>
                <!-- <dxi-series valueField="pending" name="Pending >3 months" color="#E44D25"></dxi-series> -->
                <dxi-value-axis>
                    <dxo-title text={{yAxisLabel}}></dxo-title>
                </dxi-value-axis>
                <dxo-common-series-settings argumentField="name" type="line">
                    <dxo-label [visible]="false" [format]="{
                      type: 'fixedPoint',
                      precision: '0'
                    }">
                    </dxo-label>
                </dxo-common-series-settings>
                <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="left">
                </dxo-legend>
                <dxo-tooltip [enabled]="true" location="edge" [customizeTooltip]="customizeTooltip">
                </dxo-tooltip>
            </dx-chart>

        </div>
        <div class="chart-container">
            <dx-chart id="chart" title="Periodic Review" [dataSource]="periodicReviewGraph" [rotated]="false"
                (onLegendClick)="legendClick($event)">
                <dxi-series valueField="periodicReviewDue" name="Due" color="#0850b2"></dxi-series>
                <!-- <dxi-series valueField="periodicReviewOverdueDue" name="Overdue" color="#FFA500"></dxi-series> -->
                <dxi-value-axis>
                    <dxo-title text={{yAxisLabel}}></dxo-title>
                </dxi-value-axis>
                <dxo-argument-axis>
                    <dxi-constant-line value='Jun' [width]="4" color="#E44D25">
                        <dxo-label text="NOW"></dxo-label>
                    </dxi-constant-line>
                </dxo-argument-axis>
                <dxo-common-series-settings argumentField="name" type="bar">
                    <dxo-label [visible]="true" [format]="{
                      type: 'fixedPoint',
                      precision: '0'
                    }">
                    </dxo-label>
                </dxo-common-series-settings>
                <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="left">
                </dxo-legend>
                <dxo-tooltip [enabled]="true" location="edge" [customizeTooltip]="customizeTooltip">
                </dxo-tooltip>
            </dx-chart>
        </div>
    </div>
</div>