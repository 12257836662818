<div class="dialog">
    <div class="dialog-header">
        <div class="header-text center" >
            Review Risk Assessment Report
        </div>
        <div class="align-items-start">
            <div class="header-icon">
                <button mat-button class="icon-btn dialog-close-btn" (click)="dialogRef.close({ closeType: 'cancel' })"
                    id="riskAssessmentReviewDialogCompenent-closeDialogIcon">
                    <div class="icon"><fa-icon [icon] = "times"></fa-icon></div>
                </button>
            </div>
        </div>
    </div>
    <div class="dialog-content justify-content-center">
        <form [formGroup]="riskAssessmentReviewForm" class="riskAssessmentReviewFG">

            <div class="row">
                <!-- <div class="col"> -->
                <label>Review Response</label>
                <mat-select class="profile-card" formControlName="reason">
                    <mat-option *ngFor="let Reason of reviewDropDownOptions; let i = index;" [value]="Reason"><p>{{Reason}}</p>
                    </mat-option>
                </mat-select>
                <!-- </div> -->
            </div>
            <div class="row text-area" *ngIf="riskAssessmentReviewForm.value.reason == 'Review With Comments'">
                <label class="esig-textarea">Comments</label>
                <textarea  matInput
                    type="text" placeholder="Enter Comments" id="riskAssessmentReview-dialog-comments-input"
                    class="text-box" formControlName="comment"></textarea>
            </div>
        </form>

    </div>
    <div class="dialog dialog-footer-justification">
        <div>
            <div class="only-buttons">
                <button mat-button class="btn cancel" (click)="dialogRef.close({ closeType: 'cancel' })"
                    id="ConfirmationDialogCompenent-cancel" ><p>Cancel</p></button>
                <button mat-button class="btn primary" appDebounceClick (debounceClick)="prepareDataForPostReviewApprovalComments()"
                    [debounceTime]="200" id="team-TeamMemberDialogComponent-confirm"><p>Confirm</p></button>
            </div>
        </div>
    </div>
</div>