import { Component, Input, OnInit } from '@angular/core';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import * as _ from 'lodash';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.scss']
})
export class AuditTrailComponent implements OnInit {
  @Input() auditTrailListView: any = [];
  @Input() tableNames: string[] = [];
  creationList: { [tableName: string]: { [entryID: string]: any[] } } = {};
  groupedByTransactionAndTableName: { [transactionID: number]: { [tableName: string]: { [entryID: string]: any[] } } } = {};
  creationTableNameKeys: any = [];
  transactionIDKeys: any = [];
  creationTransactionID!: any;
  creationEntryID: any;
  creationEntryIDKeys: any[] = [];
  auditTrailExists: boolean = false;

  // icons
  exclamationTriangle = faExclamationTriangle

  constructor() { }

  ngOnInit() {
    this.auditTrailListView.forEach((element: any) => {
      this.groupByTransactionAndTableName_0386(element);
    });

    this.auditTrailExists = Object.keys(this.groupedByTransactionAndTableName).length > 0;
    if (this.auditTrailExists) {
      this.prepareCreationTransaction_0387();
    }
  }

  groupByTransactionAndTableName_0386(auditTrailList: any[]) {
    auditTrailList.forEach((element: any) => {
      if (element.transactionID in this.groupedByTransactionAndTableName) {
        if (element.tableName in this.groupedByTransactionAndTableName[element.transactionID]) {
          if (element.entryID in this.groupedByTransactionAndTableName[element.transactionID][element.tableName]) {
            this.groupedByTransactionAndTableName[element.transactionID][element.tableName][element.entryID].push(element);
          } else {
            this.groupedByTransactionAndTableName[element.transactionID][element.tableName][element.entryID] = [];
            this.groupedByTransactionAndTableName[element.transactionID][element.tableName][element.entryID].push(element);
          }
        } else {
          this.groupedByTransactionAndTableName[element.transactionID][element.tableName] = {};
          this.groupedByTransactionAndTableName[element.transactionID][element.tableName][element.entryID] = [];
          this.groupedByTransactionAndTableName[element.transactionID][element.tableName][element.entryID].push(element);
        }
      } else {
        this.groupedByTransactionAndTableName[element.transactionID] = {};
        this.groupedByTransactionAndTableName[element.transactionID][element.tableName] = {};
        this.groupedByTransactionAndTableName[element.transactionID][element.tableName][element.entryID] = [];
        this.groupedByTransactionAndTableName[element.transactionID][element.tableName][element.entryID].push(element);
      }
    });
  }

  prepareCreationTransaction_0387() {
    this.creationTransactionID = Object.keys(this.groupedByTransactionAndTableName)[0];
    this.creationList = this.groupedByTransactionAndTableName[this.creationTransactionID];
    this.creationTableNameKeys = Object.keys(this.creationList);
    this.creationEntryIDKeys = Object.keys(this.groupedByTransactionAndTableName[this.creationTransactionID][this.creationTableNameKeys[0]]);
    this.creationEntryID = Object.keys(this.groupedByTransactionAndTableName[this.creationTransactionID][this.creationTableNameKeys[0]])[0];
    delete this.groupedByTransactionAndTableName[this.creationTransactionID];
    this.transactionIDKeys = Object.keys(this.groupedByTransactionAndTableName);
  }

  lookupTable: { [key: string]: any } = { //Will need to be done for every table in the database
    tblRiskAssessment: {
      tblRiskAssessment: 'Risk Assessment Data',
      entryID: 'ID',
      clientID: 'Client ID',
      entityID: 'Entity ID',
      riskAssessmentREF: 'Risk Assessment Reference',
      riskAssessmentTitle: 'Title',
      riskAssessmentPurpose: 'Purpose',
      riskAssessmentScope: 'Scope',
      riskAssessmentOutOfScope: 'Out of Scope',
      riskAssessmentProximity: 'Proximity',
      riskAssessmentTriggerID: 'Trigger ID',
      riskAssessmentTriggerName: 'Trigger',
      riskAssessmentTypeID: 'Type ID',
      riskAssessmentTypeName: 'Type',
      riskAssessmentStatusID: 'Status ID',
      riskAssessmentStatusName: 'Status',
      riskAssessmentToolUsedID: 'Tool ID',
      riskAssessmentToolUsedName: 'Tool',
      riskAssessmentLeadID: 'Lead ID',
      riskAssessmentLeadName: 'Lead',
      riskAssessmentEntityID: 'Risk Assessment Entity ID',
      riskAssessmentEntityName: 'Risk Assessment Entity Name',
      businessProcessID: 'Business Process ID',
      businessProcessName: 'Business Process',
      functionalAreaID: 'Functional Area ID',
      functionalAreaName: 'Functional Area',
      riskInitiatorID: 'Risk Initiator ID',
      riskInitiatorName: 'Risk Initiator',
      creationDate: 'Creation Date',
      periodicReviewFrequency: 'Periodic Review Frequency',
      periodicReviewDueDate: 'Periodic Review Due Date',
      riskAssessmentConclusion: 'Conclusion',
      riskAssessmentReevaluation: 'Re-evaluation',
      approvedDate: 'Approved Date',
      riskAssessmentQualityRecord: 'Quality Record',
      riskAssessmentVersion: 'Version',
      mitigatingActionsStatus: 'Mitigating Actions Status',
      isTemplate: 'Is a template',
      promotedFMEA: 'Promoted From FMEA',
      tutorialStage: 'Tutorial Stage',
    },
    tblAttachmentSigners: {
      tblAttachmentSigners: 'Attachment Signers Data',
      clientID: 'Client ID',
      userID: 'User ID',
      fullName: 'Full Name',
      roleName: 'Role Name',
      attachmentID: 'Attachment ID',
      attachmentName: 'Attachment Title',
      signingMeaning: 'Signing Meaning',
      signed: 'Is signed',
      comments: 'Comments',
      timeStamp: 'Time stamp',
      signatureName: 'Signature Name'
    },
    tblAttachmentElectronicSignaturesM2O: {
      tblAttachmentElectronicSignaturesM2O: 'Attachment Electronic Signature Data',
      clientID: 'Client ID',
      attachmentID: 'Attachment ID',
      Title: 'Title',
      Desc: 'Description',
      status: 'Status',
    },
    tblAttachmentRiskAssessmentM2M: {
      tblAttachmentRiskAssessmentM2M: 'Attachment Risk Assessment Data',
      riskAssessmentID: 'Risk Assessment ID',
      riskAssessmentName: 'Risk Assessment',
      attachmentID: 'Attachment ID',
      attachmentName: 'Attachment Title',
    },
    tblPeriodicReview: {
      tblPeriodicReview: 'Periodic Review Data',
      riskAssessmentID: 'Risk Assessment ID',
      attachmentID: 'Attachment ID',
      periodicReviewDescription: 'Periodic Review Description',
      updateRequired: 'Update Required',
      startDate: 'Start Date'
    },
    tblVersionControlAttachmentM2O: {
      tblVersionControlAttachmentM2O: 'Version Control Attachment Data',
      clientID: 'Client ID',
      clientName: 'Client',
      attachmentID: 'Attatchment ID',
      attachmentName: 'Attatchment',
      riskAssessmentID: 'Risk Assessment ID',
      riskAssessmentName: 'Risk Assessment',
      version: 'Version',
      versionControlDescription: 'Version Control Description ',
      approvalInitiationDate: 'Approval Initiation Date',
      approvalCompletionDate: 'Approval Completion Date'
    },
    tblRevisionHistory: {
      tblRevisionHistory: 'Revision History Data',
      riskAssessmentID: 'Risk Assessment ID',
      riskAssessmentName: 'Risk Assessment',
      versionNumber: 'Version Number',
      changeType: 'Change Type',
      revisionSummary: 'Revision Summary',
      justification: 'Justification',
      approvedByID: 'Approved By ID',
      approvedByName: 'Approved By Name'
    },
    tblRisk: {
      tblRisk: 'Risk Data',
      entryID: 'ID',
      clientID: 'Client ID',
      entityID: 'Entity ID',
      riskREF: 'Risk Reference',
      categoryID: 'Category ID',
      categoryName: 'Category',
      failureCauseID: 'Failure Cause ID',
      failureCauseName: 'Failure Cause',
      failureEffectID: 'Failure Effect ID',
      failureEffectName: 'Failure Effect',
      riskStatusID: 'Risk Status ID',
      riskStatusName: 'Risk Status',
      riskDescription: 'Risk Description',
      riskOwnerID: 'Risk Owner ID',
      riskOwnerName: 'Risk Owner',
      isRejected: 'Is rejected',
      ownershipDate: 'Ownership Date',
      initialOccurrenceVsDetectability: 'Initial Occurrence vs. Detection',
      initialRiskLevel: 'Initial Risk Level',
      initialRPN: 'Initial Risk Priority Number',
      initialRS: 'Initial Risk Score',
      riskDecision: 'Risk Decision',
      riskDecisionJustification: 'Risk Decision Justification',
      residualOccurrenceVsDetectability: 'Residual Occurrence vs. Detection',
      residualRiskLevel: 'Residual Risk Level',
      residualRPN: 'Residual Risk Priority Number',
      residualRS: 'Residual Risk Score',
      riskVersion: 'Risk Version',
      isTemplate: 'Is a template',
      approvedDate: 'Approved Date',
      itemGroupName: 'Item Group',
      failureMode: 'Failure Mode'
    },
    tblAssumptions: {
      tblAssumptions: 'Assumptions',
      entryID: 'ID',
      clientID: 'Client ID',
      entityID: 'Entity ID',
      riskAssessmentID: 'Risk Assessment ID',
      riskAssessmentREFA: 'Risk Assessment Reference',
      riskAssessmentName: 'Risk Assessment Title',
      assumptionDescription: 'Assumption Description',
    },
    tblRiskAssessmentTeamMember: {
      tblRiskAssessmentTeamMember: 'Team members',
      entryID: 'ID',
      riskAssessmentID: 'Risk Assessment ID',
      riskAssessmentREFA: 'Risk Assessment Reference',
      userID: 'User ID',
      userName: 'Full Name',
      fullName: 'Full Name',
      roleID: 'Role ID',
      riskAssessmentRole: 'Role Name',
      roleName: 'Role Name',
      isReviewer: 'Is a reviewer',
      isApprover: 'Is an approver'
    },
    tblRichTextData: {
      tblRichTextData: 'Supporting Data',
      clientID: 'Client ID',
      entityID: 'Entity ID',
      richTextData: 'Rich Text Data',
    },
    tblControlFailureEffectM2M: {
      tblControlFailureEffectM2M: 'Failure Effect Control Data',
      controlID: 'Control ID',
      failureEffectID: 'Failure Effect ID'
    },
    tblControlFailureCauseM2M: {
      tblControlFailureCauseM2M: 'Failure Cause Control Data',
      controlID: 'Control ID',
      failureCauseID: 'Failure Cause ID',
      isOccurrenceControl: 'Is occurrence control'
    },
    tblMitigatingActionRiskM2M: {
      tblMitigatingActionRiskM2M: 'Risk Mitigating Actions',
      riskID: 'Risk ID',
      actionID: 'Mitigating Action ID'
    },
    tblFailureEffect: {
      tblFailureEffect: 'Failure Effect Data',
      clientID: 'Client ID',
      entityID: 'Entity ID',
      failureModeID: 'Failure Mode ID',
      failureEffect: 'Failure Effect',
      severityID: 'Severity ID',
      severityJustification: 'Severity Justification',
      residualSeverityID: 'Residual Severity ID',
      residualSeverityJustification: 'Residual Severity Justification'
    },
    tblFailureCause: {
      tblFailureCause: 'Failure Cause Data',
      clientID: 'Client ID',
      entityID: 'Entity ID',
      failureModeID: 'Failure Mode ID',
      failureCause: 'Failure Cause',
      occurrenceID: 'Occurrence ID',
      occurrenceJustification: 'Occurrence Justification',
      detectabilityID: 'Detection ID',
      detectabilityJustification: 'Detection Justification',
      residualOccurrenceID: 'Residual Occurrence ID',
      residualOccurrenceJustification: 'Reidual Occurrence Justification',
      residualDetectabilityID: 'Residual Detection ID',
      residualDetectabilityJustification: 'Residual Detection Justification',
    },
    tblAttachment: {
      tblAttachment: 'Attachment Data',
      attachmentType: 'Attachment Type',
      attachmentDescription: 'Attachment Description',
      key: 'Storage Key',
    },
    tblSysUserTablePermission: {
      tblSysUserTablePermission: 'User Table Permissions Data',
      systemID: 'System ID',
      userID: 'User ID',
      userName: 'Full Name',
      groupID: 'Group ID',
      groupName: 'Group Name',
      functionID: 'Function ID',
      functionName: 'Function Name',
      permissionID: 'Permission ID',
      permissionName: 'Permission Name',
      tableName: 'Table Name',
      tableRowID: 'Table Row ID',
      createdByID: 'Created By',
      createdByName: 'Created By Name',
      creationDate: 'Creation Date'
    },
    tblRiskQuestion: {
      tblRiskQuestion: 'Risk Question Data',
      riskQuestion: 'Risk Question'
    },
    tblTask: {
      tblTask: 'Task Data',
      actionID: 'Action ID',
      clientID: 'Client ID',
      inspectionID: 'Inspection ID',
      category: 'Category',
      description: 'Description',
      assignedToID: 'Assigned To ID',
      assignedToName: 'Assigned To',
      assignedTo: 'Assigned To',
      dueDate: 'Due Date',
      percentCompleted: 'Percentage Complete',
      status: 'Status',
      priority: 'Priority',
      createdByID: 'Created By ID',
      createdByName: 'Created By Name',
      createdBy: 'Created By Name',
      creationDate: 'Creation Date',
      riskID: 'Risk ID',
      editEnabled: 'Edit Enabled',
      deleteEnabled: 'Delete Enabled',
      completionDate: 'Completion Date'
    },
  }
}