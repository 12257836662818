<div class="page-card">
    <div class="page-card-header">
        <div class="reference-title">
            <h3 class="title uppercase" >Mitigating Actions Dashboard</h3>
        </div>
        <div class="yearly">
            <div class="year-button-container left">
                <button mat-button class="year-button" [disabled]="currentYearIndex === 0"
                    (click)="updateCurrentYear_XXXX(-1)">
                    <fa-icon [icon]="caretLeft"></fa-icon>

                </button>
            </div>
            <div class="year-text">
                <h2>{{yearsArray[currentYearIndex]}}</h2>
            </div>
            <div class="year-button-container right">
                <button mat-button class="year-button" [disabled]="currentYearIndex === yearsArray.length-1"
                    (click)="updateCurrentYear_XXXX(1)">
                    <fa-icon [icon]="caretRight" class="year-button"></fa-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="page-card-body">
        <div class="dashboard-well">
            <div class="dashboard-graph">
                <div class="chart-with-title">
                    <p class="chart-title"> Mitigating Actions Overview </p>
                    <div>
                        <!-- <ngx-charts-line-chart [view]="view" [scheme]="RAGcolorScheme" [legend]="legend"
                            [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="xAxis"
                            [yAxis]="yAxis" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [timeline]="timeline"
                            [results]="actionsOverViewData[yearsArray[currentYearIndex]]" (select)="onSelect($event)"
                            [xAxisTickFormatting]="formatDate">
                        </ngx-charts-line-chart> -->
                    </div>
                </div>
            </div>
            <div class="dashboard-graph">
                <div class="chart-with-title">
                    <p class="chart-title"> Action Status </p>
                    <div>
                        <!-- <ngx-charts-line-chart [view]="view" [scheme]="statusColorScheme" [legend]="legend"
                            [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="xAxis"
                            [yAxis]="yAxis" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [timeline]="timeline"
                            [results]="actionStatusData[yearsArray[currentYearIndex]]" (select)="onSelect($event)"
                            [xAxisTickFormatting]="formatDate">
                        </ngx-charts-line-chart> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>