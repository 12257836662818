import { Component, Input, OnInit } from '@angular/core';
import { APIService, datasetGet, DataToGet, GlobalService, IrlcoreAuthService } from '@irlca/irlcore';
import { ArmService } from '../../../arm.service';

@Component({
  selector: 'app-dashboard-risk-assessment',
  templateUrl: './dashboard-risk-assessment.component.html',
  styleUrls: ['./dashboard-risk-assessment.component.scss']
})
export class DashboardRiskAssessmentComponent implements OnInit {

  // Component Variables
  chartTitle: string = "Risk Levels";
  yAxisLabel: string = 'Number of Risk Assessments';
  monthOfYear: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  riskAssessmentGraph: any[] = [];
  periodicReviewGraph: any[] = [];
  loadingRiskAssessments: boolean = false;
  allRiskAssessmentDataDB: any = {};
  filteredRiskAssessments: any = {};
  riskCategories: any = [];
  filterConditions: any = {
    categories: [],
    entities: [],
    impactedFunctionalAreas: [],
    impactedBusinessProcesses: [],
    availableYears: []
  }
  selectedFilter: any = {};

  constructor(public globalService: GlobalService,
    private apiService: APIService,
    public armService: ArmService,
    public auth: IrlcoreAuthService,
  ) { }

  ngOnInit() {
    this.prepareDataForGetRiskAssessment_0190();
  }

  prepareDataForGetRiskAssessment_0190() {
    let datasets: datasetGet[] = [];

    let riskAssessment: datasetGet = {
      parameters: {
        iD: null,
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        userID: this.globalService.userID
      },
      storedProcedure: "DAMRiskAssessment"
    };

    datasets.push(riskAssessment);
    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Risk Assessment", datasets);
    this.loadingRiskAssessments = true;
    this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      this.allRiskAssessmentDataDB = fromDB[0];
      for (let i = 0; i < this.allRiskAssessmentDataDB.length; i++) {
        this.allRiskAssessmentDataDB[i].creationDate = new Date(this.allRiskAssessmentDataDB[i].creationDate);
        this.allRiskAssessmentDataDB[i].approvedDate = new Date(this.allRiskAssessmentDataDB[i].approvedDate);
        this.allRiskAssessmentDataDB[i].periodicReviewDueDate = new Date(this.allRiskAssessmentDataDB[i].periodicReviewDueDate);
      };
      this.updateDashboard()

      this.loadingRiskAssessments = false;
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Risk Assessment");
      window.alert(userErrMsg)
    });
  }

  updateDashboard() {
    let filteredRiskData = this.filterRiskAssessmentDashboardDataset(this.selectedFilter, this.allRiskAssessmentDataDB);
    this.filteredRiskAssessments = filteredRiskData.filteredRiskAssessments;
    this.filterConditions = filteredRiskData.filterConditions;
    this.updateRiskAssessmentCharts();
  }

  updateRiskAssessmentCharts() {
    let graphTemplate: any = {};
    let currentDate: Date;
    currentDate = new Date();
    graphTemplate = this.armService.createGraphTemplate(this.filteredRiskAssessments);
    let periodicReviewGraph = this.createPeriodicReviewTemplate();
    let riskAssessmentLevelForMonth: any[] = graphTemplate.graphForMonth;
    let oldestRiskDate = graphTemplate.oldestRiskDate;
    let periodicReviewMonthDifference: number;
    for (let i = 0; i < this.filteredRiskAssessments.length; i++) {
      riskAssessmentLevelForMonth[this.armService.monthDifference(oldestRiskDate, this.filteredRiskAssessments[i].creationDate)].created += 1
      if (this.filteredRiskAssessments[i].approvedDate.getFullYear() > 2000) {
        riskAssessmentLevelForMonth[this.armService.monthDifference(oldestRiskDate, this.filteredRiskAssessments[i].approvedDate)].approved += 1
      }
      if (this.filteredRiskAssessments[i].periodicReviewDueDate.getFullYear() > 2000) {
        periodicReviewMonthDifference = this.armService.monthDifference(currentDate, this.filteredRiskAssessments[i].periodicReviewDueDate);
        if (periodicReviewMonthDifference >= -6 && periodicReviewMonthDifference <= 6) {
          periodicReviewGraph[periodicReviewMonthDifference + 6].periodicReviewDue += 1
        }
      }
    }
    this.riskAssessmentGraph = riskAssessmentLevelForMonth;
    this.periodicReviewGraph = periodicReviewGraph;
  }

  customizeTooltip(arg: any) {
    return {
      text: `${arg.seriesName} total: ${arg.valueText}`,
    };
  }

  legendClick(e: any) {
    const series = e.target;
    if (series.isVisible()) {
      series.hide();
    } else {
      series.show();
    }
  }

  createPeriodicReviewTemplate(): any {
    let startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 6);
    let graphForMonth: any[] = [];
    let monthCounter = startDate.getMonth();
    let yearCounter = startDate.getFullYear() - 2000; // to convert to 2 digit year subtract 2000, watch out in year 3000 :)
    for (let i = 0; i <= 12; i++) {
      graphForMonth.push({
        periodicReviewDue: 0,
        periodicReviewOverdueDue: 0,
        name: this.monthOfYear[monthCounter] + yearCounter.toString()
      })
      monthCounter++;
      if (monthCounter > 11) {
        monthCounter = 0;
        yearCounter++;
      }
    }
    return graphForMonth;
  }

  filterRiskAssessmentDashboardDataset(selectedFilter: any, filteredRiskAssessments: any): any {
    let updatedFilterConditions: any = {};
    if (selectedFilter.entity) {
      filteredRiskAssessments = filteredRiskAssessments.filter((riskAssessment: any) => riskAssessment.riskAssessmentEntityName == selectedFilter.entity)
    }
    if (selectedFilter.impactedFunctionalArea) {
      filteredRiskAssessments = filteredRiskAssessments.filter((riskAssessment: any) => riskAssessment.functionalArea == selectedFilter.impactedFunctionalArea,)
    }
    if (selectedFilter.impactedBusinessProcess) {
      filteredRiskAssessments = filteredRiskAssessments.filter((riskAssessment: any) => riskAssessment.businessProcess == selectedFilter.impactedBusinessProcess)
    }
    if (selectedFilter.year) {
      filteredRiskAssessments = filteredRiskAssessments.filter((riskAssessment: any) => riskAssessment.creationDate.getFullYear() == selectedFilter.year)
    }
    updatedFilterConditions.entities = Array.from(new Set(filteredRiskAssessments.map((item: any) => item.riskAssessmentEntityName)));
    updatedFilterConditions.impactedFuncationalAreas = Array.from(new Set(filteredRiskAssessments.map((item: any) => item.functionalArea)));
    updatedFilterConditions.impactedBusinessProcess = Array.from(new Set(filteredRiskAssessments.map((item: any) => item.businessProcess)));
    updatedFilterConditions.availableYears = Array.from(new Set(filteredRiskAssessments.map((item: any) => item.creationDate.getFullYear())));
    let updatedFilteredRiskDataPackage = {
      filteredRiskAssessments: filteredRiskAssessments,
      filterConditions: updatedFilterConditions
    }
    return updatedFilteredRiskDataPackage;
  }

}
