<div class="container">
    <div class="d-flex flex-row justify-content-between attachment-header-container"
        id="ra-attachments-header-container">
        <div class="d-flex flew-row" id="ra-attachments-header-row">
            <div class="align-self-center header-text" id="ra-attachments-header-text">ATTACHMENTS </div>
            <div class="align-self-start add-attachment" *ngIf="editModeBool" (click)="openAddAttachmentDialog_0192()"
                id="ra-attachments-header">
                <fa-icon [icon]="plus" class="add-attachment-button" id="ra-add-attachment-button"> </fa-icon>
            </div>
        </div>
        <div class="d-flex justify-content-end" id="ra-attachments-actions">
            <div class="attachment-download-button-expanded" (click)="handleFileDownload_0456(selectedAttachment)"
                *ngIf="selectedAttachment && selectedAttachment.attachmentType!='URL' && expandedRichTextEditorBool"
                id="ra-attachments-download-button-expanded">
                <fa-icon [icon]="download" class="file-download-icon2"></fa-icon>
            </div>
            <button mat-button class="btn icon-btn open-link-btn" id="ra-attachments-expand-compress-buttons"
                *ngIf="globalService.attachmentsList.length!=0" (click)="expandAttachmentsMasterDetail_0457()">
                <div class="icon">
                    <fa-icon *ngIf="!expandedRichTextEditorBool" [icon]="expandArrows" id="ra-attachment-expand">
                    </fa-icon>
                    <fa-icon *ngIf="expandedRichTextEditorBool" [icon]="compressArrowsAlt" id="ra-attachment-compress">
                    </fa-icon>
                </div>

            </button>
        </div>
    </div>
    <div *ngIf="expandedImage" class="expanded-attachment-container" id="ra-attachment-expanded-container">
        <div class="d-flex flex-row justify-content-between expanded-attachment-title-container"
            id="ra-attachment-expanded-header">
            <div class="expanded-attachment-title" id="ra-attachment-expanded-title">
                {{selectedAttachmentTitle}}
            </div>
            <button (click)="expandedImage=false" class="align-self-center close-image-button"
                id="ra-attachment-expanded-close">
                <fa-icon [icon]="timesCircle" class="close-expanded-image-icon" id="ra-attachment-expanded-close-icon">
                </fa-icon>
                Close
            </button>
        </div>
        <div *ngIf="selectedAttachment.attachmentType=='image/png' || selectedAttachment.attachmentType=='image/jpeg'"
            id="ra-attachment-expanded-image-container">
            <img src="{{selectedImage}}" alt="Red dot" width="100%" height="100%" id="ra-attachment-expanded-image" />
        </div>
        <div *ngIf="selectedAttachment.attachmentType=='application/pdf'" id="ra-attachment-expanded-pdf-container">
            <app-pdf-viewer [src]="pdf" [title]="selectedAttachment.attachmentDescription"
                [(masterViewHidden)]="masterViewHidden" *ngIf="pdf != null" [esig]="false" [expandable]="expandable"
                id="ra-attachment-pdf">
            </app-pdf-viewer>
        </div>
    </div>
    <div class="table-container attachments-table" *ngIf="!expandedImage && !expandedRichTextEditorBool"
        id="ra-attachments-table-container">
        <table mat-table [dataSource]="globalService.attachmentsList" class="attachments-table-element"
            id="ra-attachments-table" *ngIf="globalService.attachmentsList && globalService.attachmentsList.length>0">
            <ng-container matColumnDef="iD" id="ra-attachments-table-id-col">
                <th mat-header-cell *matHeaderCellDef id="ra-attachments-table-id-header"> ID </th>
                <td mat-cell *matCellDef="let element; let i = index" class="attachment-id-column"
                    id="ra-attachments-table-id"> {{i+1}} </td>
            </ng-container>
            <ng-container matColumnDef="Link" id="ra-attachments-table-link-col">
                <th mat-header-cell *matHeaderCellDef id="ra-attachments-table-link-header"> Link </th>
                <td mat-cell *matCellDef="let element; let i = index" (dblclick)="expandAttachmentsMasterDetail_0457(i)"
                    id="ra-attachments-table-link">
                    <div class="d-flex flex-row justify-content-between align-items-center attachment-links-container"
                        id="ra-attachments-table-link-container">
                        <div class="d-flex flex-row attachment-text-id" id="ra-attachments-table-link-text-container">
                            <div (click)="toggleEditAttachment_0452(i)" *ngIf="!element.editMode && !element.isURL"
                                id="ra-attachments-table-link-text-noedit">{{element.attachmentDescription}}</div>
                            <div *ngIf="!editModeBool && element.isURL"><a (click)="openAttachmentInNewTab(element.key)"
                                    id="ra-attachments-table-link-url-noedit">{{element.attachmentDescription}}</a>
                            </div>
                            <div (click)="toggleEditAttachment_0452(i)"
                                *ngIf="editModeBool && element.isURL && !element.editMode"
                                id="ra-attachments-table-link-text-edit">{{element.attachmentDescription}}</div>
                            <input #attachmentInput type="text" *ngIf="element.editMode && editModeBool"
                                [(ngModel)]="globalService.attachmentDescriptions[i]" class="attachment-name-change"
                                id="ra-attachments-table-link-text-input">
                            <div *ngIf="element.isURL && !element.editMode" #tooltip="matTooltip"
                                matTooltip="{{copyText}}" class="d-flex justify-content-center url-icon-container"
                                (click)="copyURL_0461(i)" id="ra-attachments-table-url-copy-btn">
                                <fa-icon [icon]="link" class="align-self-center url-icon"
                                    id="ra-attachments-table-url-icon"></fa-icon>

                            </div>
                        </div>
                        <div class="d-flex flex-row" *ngIf="!editModeBool"
                            id="ra-attachments-table-view-download-container">
                            <div class="attachment-expand-button" (click)="handleViewFile_0460(i)"
                                *ngIf="element.attachmentType=='image/jpeg' || element.attachmentType=='image/png' || element.attachmentType=='application/pdf'"
                                id="ra-attachments-table-view-btn">
                                <fa-icon [icon]="eye" class="external-link-icon" id="ra-attachments-table-view-icon">
                                </fa-icon>
                            </div>
                            <div class="attachment-download-button"
                                (click)="handleFileDownload_0456(globalService.attachmentsList[i])"
                                *ngIf="element.attachmentType!='URL'" id="ra-attachments-table-download-btn">
                                <fa-icon [icon]="download" class="file-download-icon"
                                    id="ra-attachments-table-download-icon">
                                </fa-icon>
                            </div>
                        </div>
                        <div class="attachment-buttons-container" *ngIf="editModeBool"
                            id="ra-attachments-table-remove-container">
                            <div class="attachment-expand-button" (click)="removeAttachment_0451(i)"
                                id="ra-attachments-table-remove-btn">
                                <fa-icon [icon]="trashAlt" class="delete-icon" id="ra-attachments-table-remove-icon">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="attachmentColumns; sticky: true" id="ra-attachments-table-row-header">
            </tr>
            <tr mat-row *matRowDef="let row; columns: attachmentColumns;" class="attachment-row"
                id="ra-attachments-table-row"></tr>
        </table>
        <div *ngIf="globalService.attachmentsList && globalService.attachmentsList.length==0"
            class="d-flex align-items-center justify-content-center" id="no-attachments-container">
            <div class="banner notification">
                <fa-icon [icon]="exclamationTriangle"></fa-icon>
                <h3>There are no Attachments at this time. Enable Edit Mode to add Attachments as required.</h3>
            </div>
        </div>
    </div>
    <div class="master-container" *ngIf="expandedRichTextEditorBool" id="ra-attachments-master-container">
        <div class="master col-3" id="ra-attachments-master">
            <div class="master-list expanded-tab-master" id="ra-attachments-master-list">
                <div *ngFor="let attachment of globalService.attachmentsList; let i = index;" class="expanded-tab-item"
                    id="ra-attachments-master-item">
                    <mat-card class="expanded-master-card" (click)="selectAttachment_0448(attachment)"
                        [ngClass]="{'selected-card': attachment.iD === selectedAttachment.iD }"
                        id="ra-attachments-master-card">
                        <div class="colour-strip" id="ra-attachments-colour-strip"></div>
                        <div class="master-card-content d-flex row justify-content-between expanded-tab-item-text"
                            id="ra-attachments-master-card-content">
                            <div id="ra-attachments-master-card-content-{{i}}-attachmentDescription">
                                <h4>{{attachment.attachmentDescription}}</h4>
                            </div>
                            <div (click)="removeAttachment_0451(i)" class="delete-button-div" *ngIf="editModeBool"
                                id="ra-attachments-master-remove-btn">
                                <fa-icon [icon]="trashAlt" class="delete-icon" id="ra-attachments-master-remove-icon">
                                </fa-icon>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
        <div class="col-9 expanded-tab-detail-container" id="ra-attachments-detail-container">
            <div class="justify-content-center expanded-tab-detail" id="ra-attachments-detail">
                <div class="pdf-container" *ngIf="selectedAttachment.attachmentType=='application/pdf'"
                    id="ra-attachments-detail-pdf-container">
                    <app-pdf-viewer [src]="pdf" [title]="selectedAttachment.attachmentDescription"
                        [(masterViewHidden)]="masterViewHidden" *ngIf="pdf != null" [esig]="false"
                        [expandable]="expandable" id="ra-attachments-detail">
                    </app-pdf-viewer>
                </div>
                <div *ngIf="selectedAttachment.attachmentType=='image/png' || selectedAttachment.attachmentType=='image/jpeg'"
                    id="ra-attachments-detail-image-container">
                    <div class="expanded-attachment-container" id="ra-attachments-detail-image-expanded">
                        <img src="{{selectedExpandedImage}}" width="100%" height="100%"
                            id="ra-attachments-detail-image" />
                    </div>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-center"
                    *ngIf="selectedAttachment.attachmentType!='image/png' && selectedAttachment.attachmentType!='image/jpeg' && selectedAttachment.attachmentType!='application/pdf' && selectedAttachment.attachmentType!='URL'"
                    id="ra-attachments-detail-no-display-container">
                    <h2 id="ra-attachments-detail-no-display">Cannot display attachment.</h2>
                </div>
                <div *ngIf="selectedAttachment.attachmentType=='URL'"
                    class="d-flex flex-column justify-content-center align-items-center"
                    id="ra-attachments-detail-no-display-url">
                    <h2 id="ra-attachments-detail-no-display-url-header">Cannot display URL link.</h2>
                    <!-- <iframe src="selectedAttachment.key"></iframe> -->
                    <a (click)="openAttachmentInNewTab(selectedAttachment.key)"
                        id="ra-attachments-detail-no-display-url-href">Click to open link in new tab!</a>
                </div>
            </div>
        </div>
    </div>
</div>