import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faInfoCircle, faTimes } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
    justificationNeeded: boolean = false;
    justification!: string;
    textAreaForm!: FormGroup;

    // icons
    times = faTimes
    infoCirlce = faInfoCircle
    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder) { }

    ngOnInit() {
        this.justificationNeeded = this.data.justificationNeeded;

        this.textAreaForm = this.fb.group({
            textArea: [null, Validators.required]
        });
    }
}
