<div class="page-card team">
    <div class="page-card-header">
        <div class="reference-title">
            <h3 class="title uppercase" >Team</h3>
        </div>
        <div class="d-flex">
            <div *ngIf="editMode" class="edit-mode-buttons">
                <button mat-button class="btn icon-btn icon-btn-white"
                    (click)="openAddTeamMemberDialog_0303(emptyRow,'EDIT', true)"
                    id="team-RiskAssessmentTeamComponent-addTeam-button">
                    <div class="icon"><fa-icon [icon]="plus"></fa-icon></div>
                </button>
            </div>
        </div>

    </div>
    <div class="page-card-body table">
        <div class="general-table risk-record-team-table" id="riskAssessmentTeam-table">
            <mat-table [dataSource]="armService.teamDataSource" class="table table-striped team-table" matSort matSortDisableClear>
                <ng-container matColumnDef="fullName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        <p>Name</p>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let member">
                        <div [ngClass]="{'team-member-ellipsis' : editMode}">
                            <button *ngIf="editMode && member.roleID != 353" mat-button class="icon-btn icon-btn-white"
                                id="risk-assessment-ellipsis-btn" [matMenuTriggerFor]="teamMenu">
                                <div class="icon"><fa-icon [icon] = "ellipsisHorizontal"></fa-icon></div>
                            </button>

                            <mat-menu #teamMenu="matMenu">
                                <button mat-menu-item *ngIf="!member.deleted; else deleted"
                                    (click)="deleteTableEntry_0244(member); member.deleted = true;"
                                    [disabled]="member.designation == 'Mandatory'">Delete</button>
                                <ng-template #deleted>
                                    <button mat-menu-item
                                        (click)="removeDeletion_0045(member); member.deleted = false;">Undo
                                        Deletion</button>
                                </ng-template>
                                <button mat-menu-item (click)="openAddTeamMemberDialog_0303(member,'EDIT', false);"
                                    [disabled]="member.deleted">Edit</button>
                            </mat-menu>
                        </div>
                        <div class="team-member-image">
                            <img class="profile-pic" *ngIf="member.profilePicture"
                                src="data:image/png;base64,{{member.profilePicture}}" alt="profile-photo">
                            <div [ngStyle]="{'margin-right': '5px'}" class="profile-pic" *ngIf="!member.profilePicture"
                                [style.backgroundColor]="auth.stringToHslColor(member.fullName , 30, 80)">
                                <p>{{armService.getUserInitials_0042(member.fullName) | slice:0:2}}</p>
                            </div>
                        </div>
                        <div class="team-member-name">
                            <p class="truncate-text-1-line" #tooltip="matTooltip"
                            [matTooltip]="member.fullName">{{member.fullName}}</p>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="riskAssessmentRole">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        <p>Risk Assessment Role</p>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let member; let i = index" class="d-flex flex-row">
                        <div *ngIf="member.designation  != null ; then showIcon else dontShowIcon">
                        </div>
                        <ng-template #showIcon>
                            <div *ngIf="member.designation  === 'Mandatory' ; else optional">
                                <div class="mandatory-indicator">
                                    <div class="indicator mandatory">
                                        <p class="uppercase">M</p>
                                    </div>
                                    <p class="truncate-text-1-line"  #tooltip="matTooltip"
                                    [matTooltip]="member.riskAssessmentRole">{{member.riskAssessmentRole }}</p>
                                </div>
                            </div>
                            <ng-template #optional>
                                <div class="mandatory-indicator">
                                    <div class="indicator optional">
                                        <p class="uppercase">O</p>
                                    </div>
                                    <p class="truncate-text-1-line" #tooltip="matTooltip"
                                    [matTooltip]="member.riskAssessmentRole">{{member.riskAssessmentRole}}</p>
                                </div>
                            </ng-template>
                        </ng-template>
                        <ng-template #dontShowIcon>
                            <div class="mandatory-indicator">
                                <div class="indicator none">
                                </div>
                            </div>
                            <p class="truncate-text-1-line" #tooltip="matTooltip"
                            [matTooltip]="member.riskAssessmentRole">{{member.riskAssessmentRole}}</p>
                        </ng-template>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="approver">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        <p>Approver</p>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let member">
                        <div class="d-flex justify-content-end" *ngIf="!editMode">
                            <div *ngIf="member.isApprover" class="read-only-checkmark checked">
                                <fa-icon [icon]="check"></fa-icon>
                            </div>
                            <div *ngIf="!member.isApprover" class="read-only-checkmark unchecked">
                            </div>
                        </div>
                        <div class="d-flex justify-content-end" *ngIf="editMode">
                            <div *ngIf="member.isApprover && !member.approverDesignation && !member.isReviewer"
                                class="read-only-checkmark checked" (click)="onEditMember(member, 'approver')">
                                <fa-icon [icon]="check"></fa-icon>
                            </div>
                            <div *ngIf="!member.isApprover && !member.approverDesignation && !member.isReviewer"
                                class="read-only-checkmark unchecked" (click)="onEditMember(member, 'approver')">
                            </div>
                            <div *ngIf="member.approverDesignation"
                                class="read-only-checkmark checked">
                                <fa-icon [icon]="check"></fa-icon>
                            </div>
                            <div *ngIf="member.isReviewer" class="read-only-checkmark unchecked">
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="reviewer">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        <p>Reviewer</p>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let member" class="justify-content-between">
                        <div class="d-flex justify-content-end" *ngIf="!editMode">
                            <div *ngIf="member.isReviewer" class="read-only-checkmark checked">
                                <fa-icon [icon]="check"></fa-icon>
                            </div>
                            <div *ngIf="!member.isReviewer" class="read-only-checkmark unchecked">
                            </div>
                        </div>
                        <div class="d-flex justify-content-end" *ngIf="editMode">
                            <div *ngIf="member.isReviewer && !member.isApprover" class="read-only-checkmark checked"
                                (click)="onEditMember(member, 'reviewer')">
                                <fa-icon [icon]="check"></fa-icon>
                            </div>
                            <div *ngIf="!member.isReviewer && !member.isApprover" class="read-only-checkmark unchecked"
                                (click)="onEditMember(member, 'reviewer')">
                            </div>
                            <div *ngIf="member.isApprover" class="read-only-checkmark unchecked">
                            </div>

                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="department">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        <p>Department</p>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let member" >
                        <p class="truncate-text-1-line" #tooltip="matTooltip"
                        [matTooltip]="member.department">{{member.department}}</p>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedTeamColumns; sticky: true" id="riskAssessmentTeam-headerRow">
                </mat-header-row>
                <mat-row [ngClass]="{'deleted': row.deleted}"
                    *matRowDef="let row; columns: displayedTeamColumns; let i = index;" id="riskAssessmentTeam-row">
                </mat-row>
            </mat-table>
        </div>
    </div>
    <div class="page-card-footer justify-content-end">
        <!-- <div class="table-paginator">
            <mat-paginator id="team-riskAssessmentTeamComponent-paginator" [pageSize]="pageSizeInRows"
                [pageSizeOptions]="[pageSizeInRows, pageSizeInRows*2, 
                    pageSizeInRows*3, pageSizeInRows*4]" showFirstLastButtons [pageIndex]="currentPage"
                (page)="handlePaginator_0304($event)"
                [ngStyle]="{ 'display': (tableList.length > pageSizeInRows || currentPage > 0) ? 'flex' : 'none'}">
            </mat-paginator>
        </div> -->
    </div>
</div>