import { PipeTransform, Pipe } from '@angular/core';
import { FilterService } from '../../../filter.service';

@Pipe({
    name: 'riskRegisterFilterSearch'
})
export class DialogFilterPipe implements PipeTransform {

    constructor(private filterService: FilterService) { }

    transform(listOfFilters: any[], searchedTerm: string, key: string, controlFlag: boolean) {
        let returnList = listOfFilters;
        for (let index = 0; index < listOfFilters.length; index++) {
            const element = listOfFilters[index];
            if (!element.filterValue || !searchedTerm || searchedTerm == '') {
                return returnList;
            }
        }
        returnList = listOfFilters.filter(item =>
            (item.filterValue.toString().toLowerCase().indexOf(searchedTerm.toLowerCase()) !== -1)
            || (item.clicked)
        );
        if (controlFlag) {
            this.filterService.setSearchedFilterObjectsControl_0479(returnList, key);
        } else {
            this.filterService.setSearchedFilterObjectsRisk_0479(returnList, key);
        }
        
        return returnList;
    }
}
