import { Component, OnInit, ViewChild, Input, EventEmitter, Output, AfterViewInit, ElementRef, } from '@angular/core';
import WebViewer from "@pdftron/webviewer";
import { HttpClient } from '@angular/common/http';
import { IrlcoreAuthService, APIService, GlobalService } from '@irlca/irlcore';
import  getInstance  from '@pdftron/webviewer'
import { async } from '@angular/core/testing';


@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements AfterViewInit {
  @ViewChild(PdfViewerComponent, { static: false }) public pdfViewer: any;
  @ViewChild('pdfViewer', { static: false }) viewerREF!: ElementRef;
  constructor(private http: HttpClient, public auth: IrlcoreAuthService, public apiService: APIService, public globalService: GlobalService) {

  }
  @Input()
  title!: string;
  @Input()
  src!: any;
  @Input() masterViewHidden!: boolean;
  @Input() expandable: boolean = true;
  @Output() masterViewHiddenChange = new EventEmitter<boolean>();
  @Output() masterViewUnhidden: EventEmitter<Boolean> = new EventEmitter();
  @Input() esig: boolean = false;
  @Input() signed: boolean = false;
  @Input() signatureStatus: string = "";

  currentPage: number = 1;
  currentZoom: number = 1;
  currentZoomDup: number = 1;
  displayZoom: number = this.currentZoom * 100;
  pdfYCords: number = 0;
  pageZooms: any[] = [];
  pageScrolls: any[] = [];
  totalPages!: number;
  timeSpentOnPage!: number;
  timeSpentOnZoom!: number;
  timeSpentOnScroll!: number;
  fitToPage: boolean = false;
  timingInterval: any;
  index!: number;
  scrollHeight: number = 50;
  DocumentTracking: boolean = false;
  private scrollContainer: any;
  loading!: boolean;
  private pdfComponent!: PdfViewerComponent;
  DocumentAnalysis: any[] = [];
  pdfChanged : boolean = false;
  zooms: any[] = [
    { value: .25, viewValue: '25%' },
    { value: .5, viewValue: '50%' },
    { value: .75, viewValue: '75%' },
    { value: 1, viewValue: '100%' },
    { value: 1.25, viewValue: '125%' },
    { value: 1.5, viewValue: '150%' },
    { value: 1.75, viewValue: '175%' },
    { value: 2, viewValue: '200%' },
  ];

   ngOnChanges(){
    this.pdfChanged = true;
   }


  ngAfterViewInit(): void {
    let disbaledEles = [
      'toolbarGroup-Shapes',
      'toolbarGroup-Insert',
      'toolbarGroup-FillAndSign',
      'toolbarGroup-Edit',
      'toolbarGroup-Forms',
      'toolbarGroup-Annotate',
      'toolsHeader',
      'pageManipulationOverlay',
      'selectToolButton',
      'thumbDelete',
      'pageManipulationOverlayButton',
      'notePopup',
      'contextMenuPopup',
      'annotationPopup',
      'toggleNotesButton'
    ];
    WebViewer({
      path: '../../../assets/lib',
      fullAPI: true,
      disabledElements: disbaledEles
    }, this.viewerREF.nativeElement).then(async (instance: any) => {
      await instance.UI.loadDocument(this.src, { filename: this.title });

      const { documentViewer } = instance.Core;
      const { annotationManager } = instance.Core;
      annotationManager.setCurrentUser(this.globalService.currentUser.Email);
      annotationManager.enableReadOnlyMode();

           
    });
  }
  base64ToBlob(base64: any) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], { type: 'application/pdf' });
  };

  async ngOnInit() {
    // this.src = this.base64ToBlob(this.src);
  }


  toggleExpandCollapse_0493() {
    this.masterViewHidden = !this.masterViewHidden;
    this.masterViewHiddenChange.emit(this.masterViewHidden)
  }

  showSpinner_0494(event: { loaded: number, total: number }) {
    this.loading = event.loaded < event.total;
  }
}
