import { AfterViewInit, Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import DataSource from 'devextreme/data/data_source';
import { APIService, datasetGet, DataToGet, GlobalService, IrlcoreAuthService } from '@irlca/irlcore';
import { FormBuilder, FormGroup } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { RegisterService } from '../../../content/register/register.service';
import { RiskAssessmentDialogComponent } from '../risk-assessment-dialog/risk-assessment-dialog.component';
import { Router } from '@angular/router';
import { ArmService } from '../../../arm.service';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'app-control-risks-dialog',
    templateUrl: './control-risks-dialog.component.html',
    styleUrls: ['./control-risks-dialog.component.scss']
})
export class ControlRisksDialogComponent implements OnInit, AfterViewInit {
    columnsToDisplay: string[] = ["riskREF", "failureEffect", "failureCause"];
    risks: any[] = [];
    controlRisks: any[] = [];
    selectedControlCategory: any;
    dataSource!: DataSource;
    currentPage!: number;
    displayLoader: boolean = true;
    pageSizeInRows: number = 8;
    formGroup: FormGroup;
    tableHidden = false;
    input = { value: "" }
    tableHeight: any;
    tableWidth: any;
    // icons 
    times = faTimes

    constructor(
        public dialogRef: MatDialogRef<RiskAssessmentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public globalService: GlobalService,
        public auth: IrlcoreAuthService,
        public apiService: APIService,
        public registerService: RegisterService,
        public armService: ArmService,
        private router: Router,
        private fb: FormBuilder,
    ) {
        this.formGroup = this.fb.group({
            control: [{ value: this.data.row.control, disabled: true }],
            controlREF: [{ value: this.data.row.controlREF, disabled: true }],
            controlDescription: [{ value: this.data.row.controlDescription, disabled: true }],
        });
    }

    async ngOnInit() {
        await this.prepareDataForGetControlRisks_0190();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.registerService.calcPageSizeInRows_0129();
        });
    }

    async prepareDataForGetControlRisks_0190() {
        let datasets: datasetGet[] = [];

        let control: datasetGet = {
            parameters: {
                iD: this.data.row.iD,
                clientID: this.globalService.clientID,
                entityID: this.globalService.entityID,
                controlCategoryID: null,
            },
            storedProcedure: "ControlsAssociatedRisks"
        };

        datasets.push(control);
        let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Control Risks", datasets);

        await this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
            this.controlRisks = fromDB[0];
            setTimeout(() => {
                this.displayLoader = false;
                this.dataSource = new DataSource({
                    store: {
                        type: "array",
                        key: "iD",
                        data: this.controlRisks
                    }
                });
            });
        }).catch((err: any) => {
            console.error(err);
            let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Controls Associated Risks");
            window.alert(userErrMsg)
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.tableHeight = window.innerHeight * 0.83;
        this.tableWidth = document.getElementById('generalTableDialog')!.offsetWidth;
    }

    goToRiskAnalysisControlRiskDialog_0466(event: any) {
        let row = event.data;
        let datasets: datasetGet[] = [];

        let teamDataset: datasetGet = {
            parameters: {
                clientID: this.globalService.clientID,
                entityID: this.globalService.entityID,
                riskAssessmentID: row.riskAssessmentID
            },
            storedProcedure: "RiskAssessmentTeamMember"
        };

        let riskAssessment: datasetGet = {
            parameters: {
                clientID: this.globalService.clientID,
                entityID: this.globalService.entityID,
                riskAssessmentID: row.riskAssessmentID
            },
            storedProcedure: "RiskAssessment"
        };

        let riskAssessmentPermission: datasetGet = {
            parameters: {
                iD: null,
                clientID: this.globalService.clientID,
                riskAssessmentID: row.riskAssessmentID,
                riskID: row.iD,
                userID: this.globalService.userID,
            },
            storedProcedure: "DAMRiskRiskAssessmentM2O"
        };

        datasets.push(teamDataset, riskAssessment, riskAssessmentPermission);
        let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Risk Record Data", datasets);

        this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
            this.registerService.selectedRiskForAnalysisTeam = fromDB[0];
            this.armService.selectedRiskAssessment = fromDB[1][0];
            this.armService.selectedRiskAssessment.iD = this.armService.selectedRiskAssessment.riskAssessmentID;
            this.armService.selectedRiskAssessment['permissionName'] = fromDB[2][0].permissionName;
            this.globalService.selectedRiskAssessment = fromDB[1][0];
            this.globalService.selectedRiskAssessment.iD = this.globalService.selectedRiskAssessment.riskAssessmentID;
            this.armService.teamDataSource.data = fromDB[0];
            this.armService.teamTableList = fromDB[0];
            this.armService.setGlobalSelectedRisk_0455(row);
            this.armService.selectedRisk["riskID"] = row.iD;
            this.armService.selectedRisk['permissionName'] = fromDB[2][0].permissionName;
            this.registerService.navigateControlRegister = true;
            this.registerService.controlRegisterFilterValue = this.data.filterValue;
            this.registerService.filteredControls = this.data.filteredControls;
            this.registerService.selectedFilterOptions = this.data.selectedFilterOptions;
            this.registerService.allControls = this.data.controls;
            this.registerService.controlsFilterApplied = this.data.filterApplied;
            this.router.navigate(['/register/risk-record']);
            this.dialogRef.close();
        }).catch((err: any) => {
            console.error(err);
            let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Risk Record Data");
            window.alert(userErrMsg)
        });
    }

    calcPageSizeInRows_0129() {
        // this.pageSizeInRows = 10;
        let rowSize = null;
        let headerSize = null;
        let divSize = document.getElementById('generalTableDialog')!.clientHeight;

        if (document.getElementById('row') != null) {
            rowSize = document.getElementById('row')!.clientHeight;
            headerSize = document.getElementById('headerRow')!.clientHeight;
        } else {
            rowSize = 48;
            headerSize = 57;
        }
        this.pageSizeInRows = Math.floor((divSize - headerSize) / rowSize);
        if (this.pageSizeInRows < 0) {
            this.pageSizeInRows = 0;
        }
    }
}
