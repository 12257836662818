import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { DashboardRiskProfileComponent } from './dashboard-risk-profile/dashboard-risk-profile.component';
import { DashboardRiskAssessmentComponent } from './dashboard-risk-assessment/dashboard-risk-assessment.component';
import { DashboardRiskComponent } from './dashboard-risk/dashboard-risk.component';
import { DashboardMitigatingActionComponent } from './dashboard-mitigating-action/dashboard-mitigating-action.component';
import { DashboardHeatmapComponent } from './dashboard-heatmap/dashboard-heatmap.component';
import { DashboardResourceComponent } from './dashboard-resource/dashboard-resource.component';
import { SharedModule } from 'src/app/shared/shared.module';
// import { NgxChartsModule } from '@swimlane/ngx-charts';
import { IrlcoreAuthGuard } from '@irlca/irlcore';
import { DxCheckBoxModule, DxDataGridModule, DxSelectBoxModule, DxTemplateModule, DxTooltipModule, DxChartModule } from 'devextreme-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard-risk-profile'
      },
      {
        path: 'dashboard-risk-profile',
        component: DashboardRiskProfileComponent,
      },
      {
        path: "dashboard-risk-assessments",
        component: DashboardRiskAssessmentComponent,
        canActivate: [IrlcoreAuthGuard], data: { featureName: ['View Dashboard Risk Assessment'], guardedRedirect: "irlcore/securitymanagement/forbidden" },
      },
      {
        path: "dashboard-risks",
        component: DashboardRiskComponent,
        canActivate: [IrlcoreAuthGuard], data: { featureName: ['View Dashboard Risks'], guardedRedirect: "irlcore/securitymanagement/forbidden" },
      },
      {
        path: "dashboard-mitigating-actions",
        component: DashboardMitigatingActionComponent,
        canActivate: [IrlcoreAuthGuard], data: { featureName: ['View Dashboard Mitigating Actions'], guardedRedirect: "irlcore/securitymanagement/forbidden" },
      },
      {
        path: "dashboard-heatmap",
        component: DashboardHeatmapComponent,
        canActivate: [IrlcoreAuthGuard], data: { featureName: ['View Dashboard Heatmap'], guardedRedirect: "irlcore/securitymanagement/forbidden" },
      }
    ]
  },

];



@NgModule({
  declarations: [DashboardComponent, DashboardRiskAssessmentComponent, DashboardRiskComponent, DashboardMitigatingActionComponent, DashboardHeatmapComponent, DashboardResourceComponent, DashboardRiskComponent, DashboardRiskProfileComponent],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    // NgxChartsModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxTemplateModule,
    DxSelectBoxModule,
    DxTooltipModule,
    DxChartModule,
    FontAwesomeModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports: []
})
export class DashboardModule { }
