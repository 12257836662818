import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-risk-profile',
  templateUrl: './risk-profile.component.html',
  styleUrls: ['./risk-profile.component.scss']
})
export class RiskProfileComponent implements OnInit {

  @Input() riskProfileData: any = {};
  @Input() isHorizontal: any;

  constructor() { }

  ngOnInit() {
 
  }
}
