<div class="dialog" id="team-RiskAssessmentTeamComponent-addTeam-dialog" *ngIf="userList.length > 0">
    <div class="dialog-header">
        <div class="header-text center">
            <h2 >Team Member</h2>
        </div>
        <div class="align-items-start">
            <div class="headers-icon">
                <button mat-button class="icon-btn dialog-close-btn" (click)="closeDialog_0118(dialogRef)"
                    id="team-TeamMemberDialogComponent-closeDialogIcon">
                    <div class="icon"><fa-icon [icon]="times"></fa-icon></div>
                </button>
            </div>
        </div>
    </div>
    <div class="dialog-content">
        <div class="required-items"><i>Required Items</i>
            <span class="required-asterisk">*</span>
        </div>
        <form [formGroup]="memberForm" *ngIf="userList.length > 0">
            <div class="row col-6" id="role">
                <mat-form-field floatLabel="always">
                    <mat-label >Risk Assessment Role
                    </mat-label>
                    <mat-select disableOptionCentering formControlName="riskAssessmentRole"
                        placeholder="Select Risk Assessment Role..." id="team-TeamMemberDialogComponent-role"
                        (selectionChange)="selectedRiskAssessmentRole = memberForm.get('riskAssessmentRole')!.value;"
                        [disabled]="data.row.designation === 'Mandatory' || data.row.designation === 'Optional' || data.addMember === 'false' || data.row.roleID === 353">
                        <mat-option *ngFor="let role of riskAssessmentRoles"
                            id="{{'selectedRiskAssessmentRole-'+ role.iD}}" [value]="role.roleName"
                            (click)="selectedRiskAssessmentRoleID = role.iD" >
                            <p>{{role.roleName}}</p>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <hr>
            <div class="row">
                <div class="col-12" id="name">
                    <mat-form-field floatLabel="always">
                        <mat-label >Name
                        </mat-label>
                        <input type="text" placeholder="Select User..." matInput
                            id="team-TeamMemberDialogComponent-user" formControlName="user" [matAutocomplete]="auto"
                            (keyup)="teamMemberSelected = false">
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let user of filteredUsers | async; let i = index;"
                                value="{{user.fullName}}" id="team-TeamMemberDialogComponent-{{i}}"
                                (onSelectionChange)="onEnterUser_0247($event, user, memberForm)"
                                (click)="teamMemberSelected = true;" [disabled]="memberIDValues.includes(user.userID)">
                                <p>{{user.fullName}} (<span class="email">{{user.email}}</span>)</p>
                            </mat-option>
                        </mat-autocomplete>
                        <span class="form-field-message" *ngIf="(filteredUsers | async).length == 0">
                            Invalid Selection. Does not exist in list of Users </span>
                    </mat-form-field>
                </div>
            </div>
            <div class="role-type">
                <!-- <div class="row justify-content-between"> -->
                <div class="role-type-container">
                    <label class="col-4"> Reviewer </label>
                    <div *ngIf="!selectedReviewer && !selectedApprover" class="read-only-checkmark unchecked"
                        (click)="selectedReviewer = true; checkboxChanged = true;"
                        id="add-team-member-dialog-reviewer-checkbox">
                    </div>
                    <div *ngIf="selectedReviewer" class="read-only-checkmark checked"
                        (click)="selectedReviewer = false; checkboxChanged = true;"
                        id="add-team-member-dialog-reviewer-checkbox">
                        <fa-icon [icon]="check"></fa-icon>
                    </div>
                    <div *ngIf="selectedApprover" class="read-only-checkmark unchecked"
                        id="add-team-member-dialog-reviewer-checkbox">
                    </div>
                </div>
                <br>
                <div class="role-type-container">
                    <label class="col-4"> Approver </label>
                    <div *ngIf="!selectedApprover && !data.row.approverDesignation && !selectedReviewer"
                        id="add-team-member-dialog-approver-checkbox" class="read-only-checkmark unchecked"
                        (click)="selectedApprover = true; checkboxChanged = true;">
                    </div>
                    <div *ngIf="selectedApprover && !data.row.approverDesignation"
                        id="add-team-member-dialog-approver-checkbox" class="read-only-checkmark checked"
                        (click)="selectedApprover = false; checkboxChanged = true;">
                        <fa-icon [icon]="check"></fa-icon>
                    </div>
                    <div *ngIf="data.row.approverDesignation" id="add-team-member-dialog-approver-checkbox"
                        class="read-only-checkmark checked">
                        <fa-icon [icon]="check"></fa-icon>
                    </div>
                    <div *ngIf="selectedReviewer" class="read-only-checkmark unchecked"
                        id="add-team-member-dialog-reviewer-checkbox">
                    </div>

                </div>
                <!-- </div> -->
            </div>
            <div class="read-only-area">
                <div class="row">
                    <div class="col-6 team-member-info" id="email">
                        <mat-form-field floatLabel="always">
                            <mat-label >Email</mat-label>
                            <textarea matInput name="emailTextArea" formControlName="email" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="1" readonly
                                id="team-TeamMemberDialogComponent-email"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-6 team-member-info" id="contact-number">
                        <mat-form-field floatLabel="always">
                            <mat-label >Contact Number</mat-label>
                            <textarea matInput name="contactNoTextArea" formControlName="contactNumber"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="1" readonly
                                id="team-TeamMemberDialogComponent-contactNumber"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 team-member-info" id="organisational-role">
                        <mat-form-field floatLabel="always">
                            <mat-label >Organisational Role</mat-label>
                            <textarea matInput name="entityTextArea" formControlName="organisationalRole"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="1" readonly
                                id="team-TeamMemberDialogComponent-organisationalRole"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-6 team-member-info" id="department-read-only">
                        <mat-form-field floatLabel="always">
                            <mat-label >Department</mat-label>
                            <textarea matInput name="contactNoTextArea" formControlName="departmentTextArea"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="1" readonly
                                id="team-TeamMemberDialogComponent-departmentReadOnly"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="dialog-footer">
        <div class="only-buttons" *ngIf="addMember; else update">
            <button mat-button class="btn cancel" (click)="closeDialog_0118(dialogRef)"
                id="team-TeamMemberDialogComponent-cancel" >
                <p>Cancel</p>
            </button>
            <button mat-button class="btn done" [disabled]="!memberForm.valid || !teamMemberSelected"
                appDebounceClick (debounceClick)="postMemberEntry_0246('INSERT', data, 'Team', dialogRef)"
                [debounceTime]="200" id="team-TeamMemberDialogComponent-done" >
                <p>Done</p>
            </button>
        </div>
        <ng-template #update>
            <div class="only-buttons">
                <button mat-button class="btn cancel" (click)="closeDialog_0118(dialogRef)"
                    id="team-TeamMemberDialogComponent-cancel" >
                    <p>Cancel</p>
                </button>
                <button mat-button class="btn done" [disabled]="teamMemberFormValid_XXXX()"
                    appDebounceClick (debounceClick)="postMemberEntry_0246('UPDATE', data, 'Team', dialogRef)"
                    [debounceTime]="200" id="team-TeamMemberDialogComponent-done" >
                    <p>Done</p>
                </button>
            </div>
        </ng-template>
    </div>
</div>