<nav *ngIf="userAuthenticated" class="justify-content-between"  id="app-header">
    <a href="">
        <img src="../../assets/images/ARM-Logo-icon-v5@2x.png" alt="irlca-logo" class="logo">
        <div class="branding" >
           <h3> Active Risk Management</h3>
        </div>
    </a>
    <!-- <div class="search">
        <input placeholder="Search...">
    </div> -->
    <div class="user">
        <div class="userDropDown" [matMenuTriggerFor]="menu">
            <label *ngIf="globalService.displayName">{{globalService.currentUser.fullName}}</label>
            <div *ngIf="globalService.displayName">
                <img class="profile-pic" *ngIf="globalService.currentUser.profilePicture != null"
                    src="data:image/png;base64,{{globalService.currentUser.profilePicture}}" alt="profile-photo">
                <div class="profile-pic" *ngIf="globalService.currentUser.profilePicture == null"
                    [style.backgroundColor]="auth.stringToHslColor(globalService.currentUser.fullName , 30, 80)">
                    {{armService.getUserInitials_0042(globalService.currentUser.fullName) | slice:0:2}}
                </div>
            </div>
        </div>
        <mat-menu #menu="matMenu">
            <button mat-menu-item routerLink="/help-portal" >Help</button>
            <button mat-menu-item routerLink="/user-profile" >Profile</button>
            <button  mat-menu-item (click)="logout()" >Logout</button>
        </mat-menu>
    </div>
</nav>