<div class="dialog">
    <div class="dialog-header">
        <div class="header-text center">
            <h2 >Filter Risks</h2>
        </div>
        <div class="align-items-start">
            <div class="header-icon">
                <button mat-button class="icon-btn dialog-close-btn" id="riskRegister-filteringDialog-close-button"
                    (click)="dialogRef.close()">
                    <div class="icon"><fa-icon [icon]="times"></fa-icon></div>
                </button>
            </div>
        </div>
    </div>
    <div class="dialog-content filter">
        <div class="row filter-search-container justify-content-center ">
            <div class="col-6 d-flex justify-content-center search-box">
                <input matInput type="text" class="search-options-input white-background"
                    placeholder="Search filter options..." [(ngModel)]="searchedTerm"
                    id="riskRegister-filteringDialog-search-input">
                <button mat-button mat-icon-button aria-label="Clear" (click)="searchedTerm = '';"
                    [ngClass]="{'hidden': searchedTerm == ''}" id="riskRegister-filteringDialog-search-input-clear">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        <div class="scrollable-content">
            <div class="filter-section">
                <div *ngFor="let title of filterTitles" id="riskRegister-filteringDialog-{{title.key}}-title-div"
                    class="{{title.class}} sorting-row" (click)="sortFilterOptionsRisk_0472(title.key, false)">
                    <div id="riskRegister-filteringDialog-{{title.key}}-title-text" >
                        {{title.name}}
                    </div>
                    <div id="risk-register-filteringDialog-sort-{{title.key}}">
                        <button *ngIf="!filterListIsSorted[title.key]"
                            id="riskRegister-filteringDialog-sort-DESC-{{title.key}}"><fa-icon [icon]="longArrowAltUp"></fa-icon>
                        </button>
                        <button *ngIf="filterListIsSorted[title.key]"
                            id="riskRegister-filteringDialog-sort-ASC-{{title.key}}"><fa-icon [icon]="longArrowAltDown"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="container-search-body-scrollable">
                <div class="col-1 filter-list border" id="risk-register-filter-dialog-filter-list-risk-assessment-ref">
                    <div *ngFor="let riskAssessmentREF of displayFilterObjects.riskAssessmentREF | riskRegisterFilterSearch: searchedTerm: 'riskAssessmentREF': false; let i = index;"
                        class="filter-item" [hidden]="!riskAssessmentREF.visible"
                        id="filter-item-residual-risk-assessment-ref-{{i}}">
                        <label class="filter-value-container" *ngIf="riskAssessmentREF.visible"
                            >{{riskAssessmentREF.filterValue}}
                            <input type="checkbox"
                                id="riskRegister-filteringDialog-riskAssessmentREFCheckboxInput-{{i}}"
                                (click)="toggleFilterOptionListRisk_0482('riskAssessmentREF', riskAssessmentREF.filterValue, $event);"
                                [checked]="riskAssessmentREF.checked">
                            <span id="riskRegister-filteringDialog-riskAssessmentREFCheckboxSpan-{{i}}"
                                [ngClass]="{'checkmark-visible': riskAssessmentREF.checked }" class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-2 filter-list border" id="risk-register-filter-dialog-filter-list-ID">
                    <div *ngFor="let riskAssessmentType of displayFilterObjects.riskAssessmentType | riskRegisterFilterSearch: searchedTerm: 'riskAssessmentType': false; let i=index;"
                        class="filter-item" [hidden]="!riskAssessmentType.visible"
                        id="filter-item-risk-assessment-type-{{i}}">
                        <label class="filter-value-container" *ngIf="riskAssessmentType.visible"
                            >{{riskAssessmentType.filterValue}}
                            <input type="checkbox"
                                id="riskRegister-filteringDialog-riskAssessmentTypeCheckboxInput-{{i}}"
                                (click)="toggleFilterOptionListRisk_0482('riskAssessmentType', riskAssessmentType.filterValue, $event);"
                                [checked]="riskAssessmentType.checked">
                            <span id="riskRegister-filteringDialog-riskAssessmentTypeCheckboxSpan-{{i}}"
                                [ngClass]="{'checkmark-visible': riskAssessmentType.checked }" class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-2 filter-list border" id="risk-register-filter-dialog-filter-list-functional-area">
                    <div *ngFor="let functionalArea of displayFilterObjects.functionalArea | riskRegisterFilterSearch: searchedTerm: 'functionalArea': false; let i=index;"
                        class="filter-item" [hidden]="!functionalArea.visible" id="filter-item-functional-area-{{i}}">
                        <label class="filter-value-container" *ngIf="functionalArea.visible"
                            >{{functionalArea.filterValue}}
                            <input type="checkbox" id="riskRegister-filteringDialog-functionalArea-CheckboxInput-{{i}}"
                                (click)="toggleFilterOptionListRisk_0482('functionalArea', functionalArea.filterValue, $event);"
                                [checked]="functionalArea.checked">
                            <span id="riskRegister-filteringDialog-functionalArea-CheckboxSpan-{{i}}"
                                [ngClass]="{'checkmark-visible': functionalArea.checked }" class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-2 filter-list border"
                    id="risk-register-filter-dialog-filter-list-impacted-business-process">
                    <div *ngFor="let businessProcess of displayFilterObjects.businessProcess | riskRegisterFilterSearch: searchedTerm: 'businessProcess': false; let i=index;"
                        class="filter-item" [hidden]="!businessProcess.visible"
                        id="filter-item-impacted-business-preocess-{{i}}">
                        <label class="filter-value-container" *ngIf="businessProcess.visible"
                            >{{businessProcess.filterValue}}
                            <input type="checkbox" id="riskRegister-filteringDialog-businessProcess-CheckboxInput-{{i}}"
                                (click)="toggleFilterOptionListRisk_0482('businessProcess', businessProcess.filterValue, $event);"
                                [checked]="businessProcess.checked">
                            <span id="riskRegister-filteringDialog-businessProcess-CheckboxSpan-{{i}}"
                                [ngClass]="{'checkmark-visible': businessProcess.checked }" class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-1 filter-list border" id="risk-register-filter-dialog-filter-list-initial-risk-score">
                    <div *ngFor="let initialRS of displayFilterObjects.initialRS | riskRegisterFilterSearch: searchedTerm: 'initialRS': false; let i = index;"
                        class="filter-item" [hidden]="!initialRS.visible" id="filter-item-initial-risk-score-{{i}}">
                        <label class="filter-value-container" *ngIf="initialRS.visible" >{{initialRS.filterValue}}
                            <input type="checkbox" id="riskRegister-filteringDialog-initialRS-CheckboxInput-{{i}}"
                                (click)="toggleFilterOptionListRisk_0482('initialRS', initialRS.filterValue, $event);"
                                [checked]="initialRS.checked">
                            <span id="riskRegister-filteringDialog-initialRS-CheckboxSpan-{{i}}"
                                [ngClass]="{'checkmark-visible': initialRS.checked }" class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-1 filter-list border" id="risk-register-filter-dialog-filter-list-initial-RPN">
                    <div *ngFor="let initialRPN of displayFilterObjects.initialRPN | riskRegisterFilterSearch: searchedTerm: 'initialRPN': false; let i = index;"
                        class="filter-item" [hidden]="!initialRPN.visible" id="filter-item-initial-RPN-{{i}}">
                        <label class="filter-value-container" *ngIf="initialRPN.visible" >{{initialRPN.filterValue}}
                            <input type="checkbox" id="riskRegister-filteringDialog-initialRPNCheckboxInput-{{i}}"
                                (click)="toggleFilterOptionListRisk_0482('initialRPN', initialRPN.filterValue, $event);"
                                [checked]="initialRPN.checked">
                            <span id="riskRegister-filteringDialog-initialRPNCheckboxSpan-{{i}}"
                                [ngClass]="{'checkmark-visible': initialRPN.checked }" class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-1 filter-list border" id="risk-register-filter-dialog-filter-list-initial-risk-level">
                    <div *ngFor="let initialRiskLevel of displayFilterObjects.initialRiskLevel | riskRegisterFilterSearch: searchedTerm: 'initialRiskLevel': false; let i = index;"
                        class="filter-item" [hidden]="!initialRiskLevel.visible"
                        id="filter-item-initial-risk-level-{{i}}">
                        <label class="filter-value-container" *ngIf="initialRiskLevel.visible"
                            >{{initialRiskLevel.filterValue}}
                            <input type="checkbox" id="riskRegister-filteringDialog-initialRiskLevelCheckboxInput-{{i}}"
                                (click)="toggleFilterOptionListRisk_0482('initialRiskLevel', initialRiskLevel.filterValue, $event);"
                                [checked]="initialRiskLevel.checked">
                            <span id="riskRegister-filteringDialog-initialRiskLevelCheckboxSpan-{{i}}"
                                [ngClass]="{'checkmark-visible': initialRiskLevel.checked }" class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-1 filter-list border" id="risk-register-filter-dialog-filter-list-risk-decision">
                    <div *ngFor="let riskDecision of displayFilterObjects.riskDecision | riskRegisterFilterSearch: searchedTerm: 'riskDecision': false; let i = index;"
                        class="filter-item" [hidden]="!riskDecision.visible" id="filter-item-risk-decision-{{i}}">
                        <label class="filter-value-container" *ngIf="riskDecision.visible"
                            >{{riskDecision.filterValue}}
                            <input type="checkbox" id="riskRegister-filteringDialog-riskDecisionCheckboxInput-{{i}}"
                                (click)="toggleFilterOptionListRisk_0482('riskDecision', riskDecision.filterValue, $event);"
                                [checked]="riskDecision.checked">
                            <span id="riskRegister-filteringDialog-riskDecisionCheckboxSpan-{{i}}"
                                [ngClass]="{'checkmark-visible': riskDecision.checked }" class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-1 filter-list border" id="risk-register-filter-dialog-filter-list-residual-risk-score">
                    <div *ngFor="let residualRS of displayFilterObjects.residualRS | riskRegisterFilterSearch: searchedTerm: 'residualRS': false; let i = index;"
                        class="filter-item" [hidden]="!residualRS.visible" id="filter-item-residual-risk-score-{{i}}">
                        <label class="filter-value-container" *ngIf="residualRS.visible" >{{residualRS.filterValue}}
                            <input type="checkbox" id="riskRegister-filteringDialog-residualRS-CheckboxInput-{{i}}"
                                (click)="toggleFilterOptionListRisk_0482('residualRS', residualRS.filterValue, $event);"
                                [checked]="residualRS.checked">
                            <span id="riskRegister-filteringDialog-residualRS-CheckboxSpan-{{i}}"
                                [ngClass]="{'checkmark-visible': residualRS.checked }" class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-1 filter-list border" id="risk-register-filter-dialog-filter-list-residual-RPN">
                    <div *ngFor="let residualRPN of displayFilterObjects.residualRPN | riskRegisterFilterSearch: searchedTerm: 'residualRPN': false; let i = index;"
                        class="filter-item" [hidden]="!residualRPN.visible"
                        id="filter-item-residual-residual-RPN-{{i}}">
                        <label class="filter-value-container" *ngIf="residualRPN.visible"
                            >{{residualRPN.filterValue}}
                            <input type="checkbox" id="riskRegister-filteringDialog-residualRPNCheckboxInput-{{i}}"
                                (click)="toggleFilterOptionListRisk_0482('residualRPN', residualRPN.filterValue, $event);"
                                [checked]="residualRPN.checked">
                            <span id="riskRegister-filteringDialog-residualRPNCheckboxSpan-{{i}}"
                                [ngClass]="{'checkmark-visible': residualRPN.checked }" class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-1 filter-list border" id="risk-register-filter-dialog-filter-list-residual-risk-level">
                    <div *ngFor="let residualRiskLevel of displayFilterObjects.residualRiskLevel | riskRegisterFilterSearch: searchedTerm: 'residualRiskLevel': false; let i = index;"
                        class="filter-item" [hidden]="!residualRiskLevel.visible"
                        id="filter-item-residual-residual-risk-level-{{i}}">
                        <label class="filter-value-container" *ngIf="residualRiskLevel.visible"
                            >{{residualRiskLevel.filterValue}}
                            <input type="checkbox"
                                id="riskRegister-filteringDialog-residualRiskLevelCheckboxInput-{{i}}"
                                (click)="toggleFilterOptionListRisk_0482('residualRiskLevel', residualRiskLevel.filterValue, $event);"
                                [checked]="residualRiskLevel.checked">
                            <span id="riskRegister-filteringDialog-residualRiskLevelCheckboxSpan-{{i}}"
                                [ngClass]="{'checkmark-visible': residualRiskLevel.checked }" class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-2 filter-list border" id="risk-register-filter-dialog-filter-list-risk-status">
                    <div *ngFor="let riskStatus of displayFilterObjects.riskStatus | riskRegisterFilterSearch: searchedTerm: 'riskStatus': false; let i = index;"
                        class="filter-item" [hidden]="!riskStatus.visible" id="filter-item-residual-risk-status-{{i}}">
                        <label class="filter-value-container" *ngIf="riskStatus.visible" >{{riskStatus.filterValue}}
                            <input type="checkbox" id="riskRegister-filteringDialog-riskStatusCheckboxInput-{{i}}"
                                (click)="toggleFilterOptionListRisk_0482('riskStatus', riskStatus.filterValue, $event);"
                                [checked]="riskStatus.checked">
                            <span id="riskRegister-filteringDialog-riskStatusCheckboxSpan-{{i}}"
                                [ngClass]="{'checkmark-visible': riskStatus.checked }" class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-1 filter-list border"
                    id="risk-register-filter-dialog-filter-list-risk-assessment-proximity">
                    <div *ngFor="let riskAssessmentProximity of displayFilterObjects.riskAssessmentProximity | riskRegisterFilterSearch: searchedTerm: 'riskAssessmentProximity': false; let i = index;"
                        class="filter-item" [hidden]="!riskAssessmentProximity.visible"
                        id="filter-item-residual-risk-assessment-proximity-{{i}}">
                        <label class="filter-value-container" *ngIf="riskAssessmentProximity.visible"
                            >{{riskAssessmentProximity.filterValue}}
                            <input type="checkbox"
                                id="riskRegister-filteringDialog-riskAssessmentProximityCheckboxInput-{{i}}"
                                (click)="toggleFilterOptionListRisk_0482('riskAssessmentProximity', riskAssessmentProximity.filterValue, $event);"
                                [checked]="riskAssessmentProximity.checked">
                            <span id="riskRegister-filteringDialog-riskAssessmentProximityCheckboxSpan-{{i}}"
                                [ngClass]="{'checkmark-visible': riskAssessmentProximity.checked }"
                                class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-2 filter-list border"
                    id="risk-register-filter-dialog-filter-list-risk-assessment-proximity">
                    <div *ngFor="let itemGroupName of displayFilterObjects.itemGroupName | riskRegisterFilterSearch: searchedTerm: 'itemGroupName': false; let i = index;"
                        class="filter-item" [hidden]="!itemGroupName.visible"
                        id="filter-item-residual-risk-assessment-proximity-{{i}}">
                        <label class="filter-value-container" *ngIf="itemGroupName.visible"
                            >{{itemGroupName.filterValue}}
                            <input type="checkbox" id="riskRegister-filteringDialog-itemGroupNameCheckboxInput-{{i}}"
                                (click)="toggleFilterOptionListRisk_0482('itemGroupName', itemGroupName.filterValue, $event);"
                                [checked]="itemGroupName.checked">
                            <span id="riskRegister-filteringDialog-itemGroupNameCheckboxSpan-{{i}}"
                                [ngClass]="{'checkmark-visible': itemGroupName.checked }" class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-2 filter-list border"
                    id="risk-register-filter-dialog-filter-list-risk-assessment-proximity">
                    <div *ngFor="let failureMode of displayFilterObjects.failureMode | riskRegisterFilterSearch: searchedTerm: 'failureMode': false; let i = index;"
                        class="filter-item" [hidden]="!failureMode.visible"
                        id="filter-item-residual-risk-assessment-proximity-{{i}}">
                        <label class="filter-value-container" *ngIf="failureMode.visible"
                            >{{failureMode.filterValue}}
                            <input type="checkbox" id="riskRegister-filteringDialog-failureModeCheckboxInput-{{i}}"
                                (click)="toggleFilterOptionListRisk_0482('failureMode', failureMode.filterValue, $event);"
                                [checked]="failureMode.checked">
                            <span id="riskRegister-filteringDialog-failureModeCheckboxSpan-{{i}}"
                                [ngClass]="{'checkmark-visible': failureMode.checked }" class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-2 filter-list border" id="risk-register-filter-dialog-filter-list-risk-team-members">
                    <div *ngFor="let riskTeamMember of displayFilterObjects.riskTeamMember | riskRegisterFilterSearch: searchedTerm: 'riskTeamMember': false; let i = index;"
                        class="filter-item" [hidden]="!riskTeamMember.visible"
                        id="filter-item-residual-risk-team-members-{{i}}">
                        <label class="filter-value-container" *ngIf="riskTeamMember.visible"
                            >{{riskTeamMember.filterValue}}
                            <input type="checkbox" id="riskRegister-filteringDialog-riskTeamMemberCheckboxInput-{{i}}"
                                (click)="toggleFilterOptionListRisk_0482('riskTeamMember', riskTeamMember.filterValue, $event);"
                                [checked]="riskTeamMember.checked">
                            <span id="riskRegister-filteringDialog-riskTeamMemberCheckboxSpan-{{i}}"
                                [ngClass]="{'checkmark-visible': riskTeamMember.checked }" class="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="dialog-footer">
        <div id="action-group">
            <div class="only-buttons">
                <button mat-button id="riskRegister-filteringDialog-cancel-button" class="btn cancel"
                    (click)="closeRiskFilterDialog_0118(dialogRef)" >
                    <p>Cancel</p>
                </button>
                <button mat-button id="riskRegister-filteringDialog-clear-button" class="btn secondary"
                    (click)="clearSelectedRiskFilters_0481()" [disabled]="disabledFlagClearAll" >
                    <p>Clear All</p>
                </button>
                <button mat-button id="riskRegister-filteringDialog-apply-button" class="btn done"
                    (click)="exportFilterDialogObjectRiskRegisterFilterDialog_0484()" [disabled]="disabledFlagApply"
                    >
                    <p>Apply</p>
                </button>
            </div>
        </div>
    </div>
</div>