import { Component, Input, OnInit } from '@angular/core';
import { ArmService } from '../../../arm.service';

@Component({
  selector: 'app-dashboard-risk',
  templateUrl: './dashboard-risk.component.html',
  styleUrls: ['./dashboard-risk.component.scss']
})
export class DashboardRiskComponent implements OnInit {

  @Input() filteredRisks: any = {};
  @Input() riskCategories: any;

  riskProfileData: any = {};
  riskCategoriesGraphData: any[] = [];
  chartTitle: string = "Risk Levels";
  yAxisLabel: string = 'Number of Risks';


  riskGraph: any[] = []

  // overdueRiskGraph = [
  //   {
  //     high: 10,
  //     medium: 4,
  //     low: 12,
  //     name: 'Jan'
  //   },
  //   {
  //     high: 10,
  //     medium: 4,
  //     low: 12,
  //     name: 'Feb'
  //   },
  //   {
  //     high: 10,
  //     medium: 4,
  //     low: 12,
  //     name: 'Mar'
  //   },
  //   {
  //     high: 10,
  //     medium: 4,
  //     low: 12,
  //     name: 'Apr'
  //   },
  //   {
  //     high: 10,
  //     medium: 4,
  //     low: 12,
  //     name: 'May'
  //   },
  //   {
  //     high: 10,
  //     medium: 4,
  //     low: 12,
  //     name: 'Jun'
  //   },
  //   {
  //     high: 10,
  //     medium: 4,
  //     low: 12,
  //     name: 'Jul'
  //   },
  //   {
  //     high: 10,
  //     medium: 4,
  //     low: 12,
  //     name: 'Aug'
  //   },
  //   {
  //     high: 10,
  //     medium: 4,
  //     low: 12,
  //     name: 'Sep'
  //   }, {
  //     high: 10,
  //     medium: 4,
  //     low: 12,
  //     name: 'Oct'
  //   }, {
  //     high: 10,
  //     medium: 4,
  //     low: 12,
  //     name: 'Nov'
  //   }, {
  //     high: 10,
  //     medium: 4,
  //     low: 12,
  //     name: 'Dec'
  //   }
  // ]

  constructor(
    private armService: ArmService,
  ) {
    // Object.assign(this, this.graphData)
  }

  ngOnInit() {
  }

  ngOnChanges() {
    let graphTemplate: any = {};
    let currentDate: Date;
    currentDate = new Date();
    graphTemplate = this.armService.createGraphTemplate(this.filteredRisks);
    let riskLevelForMonth: any[] = graphTemplate.graphForMonth;
    let oldestRiskDate = graphTemplate.oldestRiskDate;
    for (let i = 0; i < this.filteredRisks.length; i++) {
      if (this.filteredRisks[i].riskDecision === "Accept") {
        riskLevelForMonth = this.updateMonthlyRiskLevels(oldestRiskDate, "Accept", this.filteredRisks[i].creationDate, currentDate, this.filteredRisks[i].initialRiskLevel, riskLevelForMonth);
      } else if (this.filteredRisks[i].riskDecision === "Eliminate") {
        riskLevelForMonth = this.updateMonthlyRiskLevels(oldestRiskDate, "Eliminate", this.filteredRisks[i].creationDate, this.filteredRisks[i].managedDate, this.filteredRisks[i].initialRiskLevel, riskLevelForMonth);
      } else if (this.filteredRisks[i].riskDecision === "Reduce") {
        if (this.filteredRisks[i].managedDate.getFullYear() < 2000) {
          riskLevelForMonth = this.updateMonthlyRiskLevels(oldestRiskDate, "Reduce", this.filteredRisks[i].creationDate, currentDate, this.filteredRisks[i].initialRiskLevel, riskLevelForMonth);
        } else {
          riskLevelForMonth = this.updateMonthlyRiskLevels(oldestRiskDate, "Reduce", this.filteredRisks[i].creationDate, this.filteredRisks[i].managedDate, this.filteredRisks[i].initialRiskLevel, riskLevelForMonth);
          riskLevelForMonth = this.updateMonthlyRiskLevels(oldestRiskDate, "No Update", this.filteredRisks[i].managedDate, currentDate, this.filteredRisks[i].residualRiskLevel, riskLevelForMonth);
        }
      }
    }
    this.riskGraph = riskLevelForMonth;
  }

  updateMonthlyRiskLevels(oldestRiskDate: Date, riskDecision: string, startDate: Date, endDate: Date, riskLevel: string, RiskLevelForMonth: any) {
    let startMonth: number = this.armService.monthDifference(oldestRiskDate, startDate);
    let endMonth: number;
    if (endDate.getFullYear() < 2000) {
      endMonth = RiskLevelForMonth.length - 1;
    } else {
      endMonth = this.armService.monthDifference(oldestRiskDate, endDate);
    }
    let updatedRiskLevelForMonth: any[] = RiskLevelForMonth;
    // Update Risk Level
    for (let i = startMonth; i <= endMonth; i++) {
      if (riskLevel === "High") {
        updatedRiskLevelForMonth[i].high += 1;
      } else if (riskLevel === "Medium") {
        updatedRiskLevelForMonth[i].medium += 1;
      } else if (riskLevel === "Low") {
        updatedRiskLevelForMonth[i].low += 1;
      }
    }
    // Update High Risk Decision
    if (riskLevel === "High") {
      if (riskDecision === "Accept") {
        updatedRiskLevelForMonth[startMonth].accept += 1;
      } else if (riskDecision === "Reduce") {
        updatedRiskLevelForMonth[startMonth].reduce += 1;
      } else if (riskDecision === "Eliminate") {
        updatedRiskLevelForMonth[startMonth].eliminate += 1;
      }
    }
    return updatedRiskLevelForMonth;
  }

  customizeTooltip(arg: any) {
    return {
      text: `${arg.seriesName} total: ${arg.valueText}`,
    };
  }

  legendClick(e: any) {
    const series = e.target;
    if (series.isVisible()) {
      series.hide();
    } else {
      series.show();
    }
  }

}
