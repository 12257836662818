<div class="dialog" *ngIf="dataReceived">
    <div class="dialog-header">
        <div class="header-text center">
            <h2>Periodic Review</h2>
        </div>
        <div class="align-items-start">
            <div class="header-icon">
                <button mat-button class="icon-btn dialog-close-btn" (click)="dialogRef.close()"
                    id="dialog-config-list-item-close-btn">
                    <div class="icon"><fa-icon [icon]="times"></fa-icon></div>
                </button>
            </div>
        </div>
    </div>
    <div class="dialog-content">
        <div class="row read-only-background">
            <div class="col-6 d-flex flex-column">
                <label>Periodic Review Frequency</label>
                <p>{{armService.selectedRiskAssessment.periodicReviewFrequency}} Months</p>
            </div>
            <div class="col-6 d-flex flex-column">
                <label>Periodic Review Status</label>
                <p>Red</p>
            </div>
        </div>
        <form [formGroup]="form">
            <div class="row">
                <div class="col-10">
                    <mat-form-field floatLabel="always">
                        <mat-label id="add-config-list-item-trigger-label">Periodic Review Decision</mat-label>
                        <textarea matInput formControlName="periodicReviewDecision" type="text" id="add-periodic-review-decision-input" class="heightVH" placeholder="Enter Periodic Review Decision..."></textarea>               
                    </mat-form-field>
                </div>
            </div>
            <div class="row top-20">
                <div class="col-6">
                    <mat-form-field floatLabel="always">
                        <mat-label id="add-config-list-item-trigger-label">Risk Assessment Update Required</mat-label>
                        <mat-select formControlName="updateRequired" placeholder="Select Update Required Status..">
                            <mat-option [value]="true" id="add-updateRequired-yes"><p>Yes</p></mat-option>
                            <mat-option [value]="false" id="add-updateRequired-no"><p>No</p></mat-option> 
                        </mat-select>                             
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
    <div class="periodic-review-dialog-footer">
        <button class="btn secondary" mat-button *ngIf="form.value.updateRequired == true" (click)="updateRA()"
            id="add-config-list-item-cancel-button">Initiate Update of Risk Assessment</button>
        <button class="btn secondary" mat-button *ngIf="form.value.updateRequired == false" (click)="savePDF()"
            id="add-config-list-item-cancel-button">Initiate Periodic Review Decision Approval</button>
        <div>
            <button class="btn cancel" mat-button id="add-config-list-item-clear-button" (click)="dialogRef.close()"><p>Cancel</p></button>
            <button class="btn done" mat-button appDebounceClick (debounceClick)="prepareDataForPostPeriodicReview()" [debounceTime]="200"
                id="add-config-list-item-apply-button"><p>Done</p></button>
        </div>
    </div>
</div>
<div class="div-for-pdf" *ngIf="dataReceived">
    <h1>Periodic Review Decision</h1>
        <p>{{form.value.periodicReviewDecision}}</p>
    <h1>Risk Assessment Update Required?</h1>
        <p *ngIf="form.value.updateRequired">Yes</p>
        <p *ngIf="!form.value.updateRequired">No</p>
</div>
