<div class="control-register">
    <div class="control-register-header">
        <div class="reference-title big-start">
            <h3 id="control-register-header-title" class="title uppercase control-title">Controls</h3>
            <button mat-button id="control-register-open-add-control-dialog-button" class="btn icon-btn icon-btn-white"
                (click)="openAddControlDialog_0192(emptyRow, 'ADD', true);"
                *ngIf="auth.featurePermitted_0151(['Add Control'])">
                <div class="icon">
                    <fa-icon [icon]="plus"></fa-icon>
                </div>
            </button>
        </div>
        <!-- <div class="control-register-filter-container">
            <div *ngIf="filterApplied" class="risk-register-filter-options-container">
                <div class="control-register-filter-option-pills">
                    <div class="filter-option-cards" *ngFor="let filter of selectedFilterOptions; let i = index;">
                        <mat-card id="control-register-flattenedAppliedFilter-{{i}}" class="material-card">
                            <div class="material-card-content">
                                <div class="material-card-text">
                                    {{filter.filterValue}}
                                </div>
                                <div id="control-register-delete-single-card-filter"
                                    (click)="removeFilterOptionControls_0560(filter);" class="cancel-filter-option icon"
                                    *ngIf="!quickFilterIsSelected">
                                    <fa-icon [icon]="times" id="control-register-remove-filter-option-btn"></fa-icon>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </div>
                <div class="control-register-filter-option-cancel">
                    <button *ngIf="filterApplied" mat-button id="control-register-clear-filters-button"
                        (click)="clearFiltersControlRegister_0470()" class="btn icon-btn icon-btn-blue full-height">
                        <div class="icon">
                            <fa-icon [icon]="trash"></fa-icon>
                        </div>
                    </button>
                </div>
            </div>
        </div> -->
    </div>
    <div class="" [hidden]="tableHidden" id="generalTable">
        <div class="">
            <dx-data-grid id="gridContainer" [dataSource]="dataSource.data" [showBorders]="true" [wordWrapEnabled]="false"
                [remoteOperations]="true" [allowColumnResizing]="true" [focusedRowEnabled]="true" columnResizingMode="widget" 
                (onExporting)="onExporting($event)" keyExpr="controlID" height="87vh">
                <dxi-column alignment="center" dataField="controlID" caption="Control ID"
                     [width]="100">
                </dxi-column>
                <dxi-column alignment="center" dataField="controlREF" caption="Control Reference"
                     [width]="250">
                </dxi-column>
                <dxi-column alignment="center" dataField="category" caption="Control Category"
                     [width]="200">
                </dxi-column>
                <dxi-column alignment="left" dataField="control" caption="Control" 
                     [width]="300">
                </dxi-column>
                <dxi-column alignment="left" dataField="controlDescription" caption="Control Description" >
                </dxi-column>
                <dxi-column alignment="left" dataField="Ellipsis" caption="Options"
                    cellTemplate="optionsTemplate"  [width]="75" [allowFiltering]="false">
                </dxi-column>
                <dxo-header-filter [visible]="true"></dxo-header-filter>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-search-panel [visible]="true" placeholder="Search..." [width]="400"></dxo-search-panel>
                <dxo-export [enabled]="true"></dxo-export>
                <dxo-paging [enabled]="false"></dxo-paging>
                <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
                <!-- <div class="truncate-text-3-lines" *dxTemplate="let d of 'controlCellTemplate'">
                    {{d.value}}
                </div> -->
                <div class="truncate-text-3-lines description-cell" *dxTemplate="let d of 'optionsTemplate'">
                    <div class="edit-buttons" id="control-register-editButtons">
                        <button id="control-register-ellipsis-button" mat-button
                            class="icon-btn icon-btn-white edit-mode-btn-register" [matMenuTriggerFor]="menu"
                            #menuTrigger="matMenuTrigger" (click)="menuTrigger.openMenu()" [overlapTrigger]="false">
                            <div>
                                <fa-icon [icon]="ellipsisVertical"></fa-icon>
                            </div>
                        </button>
                        <mat-menu [hidden]="true" #menu="matMenu" xPosition="before" [overlapTrigger]="false"
                            class="dropdown-menu">
                            <button class="menu-button neat" id="control-register-associated-risks-button" mat-menu-item
                                (click)="openControlRisksDialog_0192(d.data);" [disabled]="d.data.riskCount === 0">
                                <div>
                                    <fa-icon [icon]="exclamationTriangle"></fa-icon>
                                </div>
                                <p>Associated Risks</p>
                            </button>
                            <button id="control-register-edit-button" mat-menu-item class="menu-button neat"
                                (click)="openAddControlDialog_0192(d.data,'EDIT', false);">
                                <div class="icon">
                                    <fa-icon [icon]="edit"></fa-icon>
                                </div>
                                <p>Edit</p>
                            </button>
                            <button id="control-register-delete-button" mat-menu-item class="menu-button neat"
                                (click)="deleteControlTableEntry_0558(d.data)" [disabled]="d.data.riskCount > 0">
                                <div class="icon">
                                    <fa-icon [icon]="trash"></fa-icon>
                                </div>
                                <p>Delete</p>
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </dx-data-grid>
        </div>
        <!-- <div *ngIf="displayLoader" id="risk-register-mat-spinner" class="loader">
            <mat-spinner></mat-spinner>
        </div> -->
    </div>
</div>