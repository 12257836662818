import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { APIService, dataset, datasetGet, DataToGet, DataToPost, GlobalService, IrlcoreAuthService } from '@irlca/irlcore';
import { ArmService } from '../../../arm.service';
import { RiskAssessmentDialogComponent } from '../risk-assessment-dialog/risk-assessment-dialog.component';

@Component({
    selector: 'app-control-dialog',
    templateUrl: './control-dialog.component.html',
    styleUrls: ['./control-dialog.component.scss']
})
export class ControlDialogComponent implements OnInit {
    isConfirmMode: boolean = false;
    confirmMessage!: string;
    justification!: string;
    formGroup: FormGroup;
    textAreaForm!: FormGroup;
    entryID!: number;
    userData: any;
    controlCategories: any[] = [];
    selectedControlCategory: any;

    // icons
    times = faTimes

    constructor(
        public dialogRef: MatDialogRef<RiskAssessmentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private globalService: GlobalService,
        public auth: IrlcoreAuthService,
        public apiService: APIService,
        private armService: ArmService

    ) {
        this.formGroup = this.fb.group({
            control: [this.data.row.control, Validators.required],
            controlREF: [this.data.row.controlREF, Validators.required],
            controlCategory: [this.data.row.controlCategoryID, Validators.required],
            controlDescription: [this.data.row.controlDescription, Validators.required],
        });
    }


    async ngOnInit() {
        await this.prepareDataForGetControl_0190();

        this.textAreaForm = this.fb.group({
            textArea: [null, Validators.required]
        });
        this.entryID = this.data.entryID;
    }

    async prepareDataForGetControl_0190() {
        let datasets: datasetGet[] = [];

        let control: datasetGet = {
            parameters: {},
            storedProcedure: "ControlCategory"
        };

        datasets.push(control);
        let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Control", datasets);

        await this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
            if (this.globalService.configurableParameters.addRiskPreventionControls) {
                this.controlCategories = fromDB[0];
            } else {
                this.controlCategories = fromDB[0].filter((category: any) => category.category !== 'Correction')
            }
        }).catch((err: any) => {
            console.error(err);
            let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Control Categories");
            window.alert(userErrMsg)
        });
    }

    saveControl_0561(justification: string) {
        let index = this.controlCategories.findIndex(x => x.iD == this.formGroup.get('controlCategory')!.value);
        let datasets: dataset[] = [];
        let controlDataset!: dataset;
        let auditTrail: any[] = [];
        let toDB: DataToPost;

        if (this.data.state === 'ADD') {
            controlDataset = {
                data: [{
                    iD: this.entryID,
                    clientID: this.globalService.clientID,
                    entityID: this.globalService.entityID,
                    controlCategoryID: this.formGroup.get('controlCategory')!.value,
                    category: this.controlCategories[index].category,
                    control: this.formGroup.get('control')!.value,
                    controlREF: this.formGroup.get('controlREF')!.value,
                    controlDescription: this.formGroup.get('controlDescription')!.value,
                    statementType: "INSERT"
                }],
                dataset: "Control"
            }
            auditTrail = auditTrail.concat(this.globalService.buildAuditTrail_0115({}, controlDataset.data[0], "tblControl"));
            datasets.push(controlDataset);
            toDB = new DataToPost(this.globalService.clientID, this.globalService.userID, justification, "Add Control", datasets, auditTrail);
        } else {
            let oldDataset: dataset = {
                data: [{
                    iD: this.data.row.iD,
                    clientID: this.data.row.clientID,
                    entityID: this.data.row.entityID,
                    controlCategoryID: this.data.row.controlCategoryID,
                    category: this.data.row.category,
                    control: this.data.row.control,
                    controlREF: this.data.row.controlREF,
                    controlDescription: this.data.row.controlDescription,
                }],
                dataset: "Control"
            }
            controlDataset = {
                data: [{
                    iD: this.data.row.iD,
                    clientID: this.data.row.clientID,
                    entityID: this.data.row.entityID,
                    controlCategoryID: this.formGroup.get('controlCategory')!.value,
                    category: this.controlCategories[index].category,
                    control: this.formGroup.get('control')!.value,
                    controlREF: this.formGroup.get('controlREF')!.value,
                    controlDescription: this.formGroup.get('controlDescription')!.value,
                    statementType: "UPDATE"
                }],
                dataset: "Control"
            }
            auditTrail = auditTrail.concat(this.globalService.buildAuditTrail_0115(oldDataset.data[0], controlDataset.data[0], "tblControl"));
            datasets.push(controlDataset);
            toDB = new DataToPost(this.globalService.clientID, this.globalService.userID, justification, "Edit Control", datasets, auditTrail);
        }

        this.apiService.postDataToBackend_0051(this.apiService.urlForSinglePost, toDB).then((result: any) => {
            if (result) {
                this.dialogRef.close({ closeType: 'save' });
            }
        }).catch((err: any) => {
            console.error(err);
            let userErrMsg = this.armService.constructUserErrorMessage(err, "Control");
            window.alert(userErrMsg)
        });
    }
}
