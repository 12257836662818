<div class="dialog">
    <div class="dialog-header">
        <div class="header-text center">
            <h2 *ngIf="data.state === 'ADD'">Add Control</h2>
            <h2 *ngIf="data.state === 'EDIT'">Edit Control</h2>
        </div>
        <div class="align-items-start">
            <div class="header-icon">
                <button mat-button class="icon-btn dialog-close-btn" (click)="dialogRef.close()">
                    <div class="icon"><fa-icon [icon]="times"></fa-icon>

                    </div>
                </button>
            </div>
        </div>
    </div>
    <div class="dialog-content" *ngIf="formGroup">
        <div class="required-items"><i>Required Items</i>
            <span class="required-asterisk">*</span>
        </div>
        <form [formGroup]="formGroup">
            <mat-form-field class="padding-between" floatLabel="always">
                <mat-label>Control Category
                </mat-label>
                <mat-select formControlName="controlCategory" id="control-dialog-control-category-dropdown"
                    placeholder="Select Control Category...">
                    <mat-option *ngFor="let category of controlCategories; let i = index;" [value]="category.iD"
                        id="control-dialog-control-category-option-{{i}}"
                        (onSelectionChange)="selectedControlCategory = category">
                        <p>{{category.category}}</p>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="padding-between" floatLabel="always">
                <mat-label>Control
                </mat-label>
                <textarea rows="3" matInput type="text" formControlName="control" id="control-dialog-control-text-box"
                    placeholder="Enter Control..." maxlength="2000"></textarea>
            </mat-form-field>
            <mat-form-field class="padding-between" floatLabel="always">
                <mat-label>Control Reference
                </mat-label>
                <textarea rows="1" #REFinput matInput type="text" formControlName="controlREF"
                    id="control-dialog-control-reference-text-box" placeholder="Enter Control Reference..."
                    maxlength="200"></textarea>
                <div class="character-count">
                    <mat-hint>{{REFinput.value?.length || 0}}/200</mat-hint>
                </div>
            </mat-form-field>
            <mat-form-field class="padding-between" floatLabel="always">
                <mat-label>Control Description
                </mat-label>
                <textarea rows="3" matInput type="text" formControlName="controlDescription"
                    id="control-dialog-control-description-text-box"
                    placeholder="Enter Control Description..." maxlength="4000"></textarea>
            </mat-form-field>
        </form>
    </div>
    <div class="dialog dialog-footer confirm-mode">
        <div class="only-buttons">
            <button class="btn cancel" mat-button id="dialog-add-note-cancel-button"
                (click)="dialogRef.close({ closeType: 'cancel' })" >
                <p>Cancel</p>
            </button>
            <button class="btn primary" [disabled]="!formGroup.valid || formGroup.pristine"
                id="dialog-add-note-confirm-button" mat-button appDebounceClick 
                (debounceClick)="saveControl_0561('N/A')" [debounceTime]="200">
                <p>Done</p>
            </button>
        </div>
    </div>
</div>