<div class="review-container">
    <div class="page-card">
        <div class="page-card-header">
            <h3>RISK REVIEW</h3>
        </div>
        <div class="page-card-body">
            <div class="development-message">
                <label>Objective</label>
                <div class="message-card">
                    <p>Risk management should be an ongoing part of the quality management process. A mechanism to review or monitor events should be implemented. The output/results of the risk management 
                        process should be reviewed to take into account new knowledge and experience. Once a quality risk management process has been initiated, that process should continue to be utilized 
                        for events that might impact the original quality risk management decision, whether these events are planned (e.g., results of product review, inspections, audits, change control) 
                        or unplanned (e.g., root cause from failure investigations, recall). The frequency of any review should be based upon the level of risk. Risk review might include reconsideration 
                        of risk acceptance decisions (Risk Control).
                    </p>
                    <div style="margin-top: 10px; color:#0850b2">Excerpt taken from: International Conference on Harmonization (ICH) guideline Q9 on Quality Risk
                        Management – June 2006 – Part 4, Section F (4.6)
                        </div>
                </div>
                <label>Status</label>
                <div class="message-card">

                    <div class="banner">
                        <h6>ADDITIONAL FEATURES UNDER DEVELOPMENT</h6>
                    </div>
                    <p>The Periodic Review of Risk Assessment Feature is available in the Application. Additional Risk Review Features are planned for 2022</p>

                </div>
            </div>
            <div class="development-picture">
             <img src="../../../assets/images/UnderConstruction.png" height="600">  
            </div>
        </div>
    </div>
</div>
