import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faInfoCircle, faTimes, faCheck, faTimesSquare, faCheckSquare } from '@fortawesome/pro-solid-svg-icons';
import { GlobalService } from '@irlca/irlcore';
import { ArmService } from 'src/app/arm.service';

@Component({
  selector: 'app-approvals-dialog',
  templateUrl: './approvals-dialog.component.html',
  styleUrls: ['./approvals-dialog.component.scss']
})
export class ApprovalsDialogComponent implements OnInit {



  times = faTimes;
  infoCirlce = faInfoCircle;
  check = faCheck;
  timesSquare = faTimesSquare;
  checkSquare = faCheckSquare;

  constructor(public dialogRef: MatDialogRef<ApprovalsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public globalService : GlobalService, public armService: ArmService) { }

  ngOnInit(): void {
  }

}
