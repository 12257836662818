import { Component, OnInit } from '@angular/core';
import { ArmService } from '../../../arm.service';
import { Router } from '@angular/router';
import { GlobalService } from '@irlca/irlcore';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { ARMDialogService } from 'src/app/shared/dialog/dialog.service';

@Component({
  selector: 'app-register-mitigating-action',
  templateUrl: './register-mitigating-action.component.html',
  styleUrls: ['./register-mitigating-action.component.scss']
})
export class RegisterMitigatingActionComponent implements OnInit {

  selectedRiskAssessment: any;
  updateTaskPull: boolean = false;
  // icons 
  arrowLeft = faArrowLeft;

  constructor(
    public armService: ArmService,
    private router: Router,
    public globalService: GlobalService,
    private dialogService: ARMDialogService
  ) { }

  ngOnInit() {
    this.updateTaskPull = !this.updateTaskPull;
  }

  navigateToExpandedDetailView_0409() {
    this.router.navigate(['/risk-assessment/risk-assessment-expanded-detail', { currentTab: 'mitigatingActions' }]);
  }

  navigateToRiskRecord_0409() {
    this.router.navigate(['/register/risk-record', { currentTab: 'riskControl' }]);
  }

  openAssociatedRisksDialog_0192(event: any) {
    let associatedRisksData = {
      minWidth: '60vw',
      maxWidth: '60vw',
      data: {
        mode: 'register',
        action: event
      }
    }

    this.dialogService.openDialog_0088("TaskRisksDialogComponent", associatedRisksData)
  }
}
