<div class="tools-container">
    <div class="page-card">
        <div class="page-card-header">
            <h3>TOOLS</h3>
        </div>
        <div class="page-card-body">
            <div class="development-message">
                <label>Objective</label>
                <div class="message-card">
                    <p>Risk management tools are utilized as part of the Quality Risk Management (QRM) process to aid in the identification of risks through a structured and disciplined approach. The use of risk management tools allows for a consistent and repeatable approach by the end-user, all the while ensuring no significant potential risks are overlooked.
                        Leveraging digital technology and intuitive user interfaces, digital versions of commonly used Risk Management tools are introduced. This digital interface will simplify processes, ensure consistency in approaches, and leverage analytics and risk libraries previously not able to be captured through standard approaches.
                    </p>
                </div>
                <label>Status</label>
                <div class="message-card">

                    <div class="banner">
                        <h6>ADDITIONAL FEATURES UNDER DEVELOPMENT</h6>
                    </div>
                    <p>The Failure Mode and Effect (FMEA) tool Feature is available in the Application. Basic Risk Assessment Feature functionality enables
                        Preliminary Hazard Analysis (PHA) style Risk Assessments to be performed. Functionaity for the following risk management tools is planned for release early in 2022:</p>
                    <ul>
                        <li>Fault Tree Analysis (FTA)</li>
                        <li>Risk Ranking and Filtering (RRF)</li>
                        <li>Hazard Analysis and Critical Control Points (HAACP)</li>
                        <li>Fishbone/Ishikawa</li>
                        <li>Process Mapping</li>
                        <li>Strength, Weakness, Opportunities and Threats Analysis (SWOT)</li>
                        <li>Supplier, Inputs, Process, Outputs and Customers (SIPOC)</li>
                        <li>Hazard and Operability Study (HAZOP)</li>
                        <li>Bow Tie</li>
                    </ul>    

                </div>
            </div>
            <div class="development-picture">
             <img src="../../../assets/images/UnderConstruction.png" height="600">  
            </div>
        </div>
    </div>
</div>

