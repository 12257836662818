<div class="dialog risk-record-SOD">
    <div class="dialog-header">
        <div class="header-text center">
            <h2 id="failureCause-dialog-title" *ngIf="selectedTab == 'severity'">Severity Rating </h2>
            <h2 id="failureCause-dialog-title" *ngIf="selectedTab == 'occurrence'">Occurrence Rating </h2>
            <h2 id="failureCause-dialog-title" *ngIf="selectedTab == 'detectability'">Detection Rating </h2>

        </div>
        <div class="align-items-start">
            <div class="header-icon">
                <button mat-button class="icon-btn dialog-close-btn" id="failureCause-dialog-close-btn"
                    (click)="dialogRef.close()">
                    <div class="icon">
                        <fa-icon [icon]="times"></fa-icon>
                    </div>
                </button>
            </div>
        </div>
    </div>
    <div class="dialog-content SOD-dialog">
        <div class="main-content" *ngIf="selectedTab == 'severity'">
            <form [formGroup]="failureEffectForm">
                <div *ngIf="(riskCategories && riskCategories.length > 0)" class="general-table-overflow">
                    <div class="table-container category">
                        <table mat-table [dataSource]="severityCategoryDataSource">
                            <ng-container matColumnDef="severityRating">
                                <th mat-header-cell *matHeaderCellDef> Rating</th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="SOD-rating">
                                        <div class="SOD-number">
                                            <div id="riskRecord-ratingDialog-initialSeverity">
                                                <div class="SOD-Label">{{element.rating}}</div>
                                            </div>
                                        </div>
                                        <div class="SOD-classification">
                                            <div class="level" [style.backgroundColor]="element.classificationColor" 
                                            [style.color]="element.classificationTextColor"
                                                id="riskRecord-ratingDialog-initialSeverityClassification">
                                                <h3>{{element.classification}}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="{{column.SODCategory}}" *ngFor="let column of riskCategories">
                                <th mat-header-cell *matHeaderCellDef width="15%">{{column.SODCategory}}<div
                                        [ngClass]="{ 'selectedCategory': (column.SODCategory == selectedCategory )}">
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element; let j = index;"
                                    (click)="setSelectedCardAndRating('severity', element[column.SODCategory + 'Description'], element[column.SODCategory + 'ID'], element.SODRatingID, element.rating, element, element[column.SODCategory + 'SODRatingID'], element[column.SODCategory + 'Classification'], element[column.SODCategory + 'ClassificationColor'], column.SODCategory, element[column.SODCategory + 'ClassificationTextColor'])"
                                    [ngClass]="{'selectedInitialCard' :element[column.SODCategory + 'ID'] == selectedSeverityCard_initial, 'selectedResidualCard': element[column.SODCategory + 'ID'] == selectedSeverityCard_residual }"
                                    #tooltip="matTooltip" [matTooltip]="element[column.SODCategory + 'Description']">
                                    <div class="rating-card-container">
                                        <div class="card-type">
                                            <p
                                                *ngIf="element[column.SODCategory + 'ID'] == selectedSeverityCard_residual">

                                                <b>Residual Risk Level</b>
                                            </p>
                                            <p
                                                *ngIf="element[column.SODCategory + 'ID'] == selectedSeverityCard_initial">

                                                <b>Initial Risk Level</b>
                                            </p>
                                        </div>
                                        <div class="card-text">
                                            <p class="truncate-text-4-lines"
                                                [ngStyle]="{'font-weight': (column.SODCategory == selectedCategory ) ? 'bold' : 'normal'}">
                                                {{element[column.SODCategory +'Description'] | slice:0:150}}</p>
                                        </div>
                                    </div>
                                </td>

                            </ng-container>
                            <tr mat-header-row
                                *matHeaderRowDef="severityCategoryDataSourceDisplayedColumns; sticky: true">
                            </tr>
                            <tr mat-row
                                *matRowDef="let row; columns: severityCategoryDataSourceDisplayedColumns; let i = index;">
                            </tr>
                        </table>
                    </div>
                </div>
            </form>
        </div>
        <div class="main-content" *ngIf="selectedTab === 'occurrence'">
            <form [formGroup]="failureCauseFormOccurrence">
                <div *ngIf="(occurrenceCategories && occurrenceCategories.length > 0)" class="general-table-overflow">
                    <div class="table-container category">
                        <table mat-table [dataSource]="occurrenceCategoryDataSource">
                            <ng-container matColumnDef="occurrenceRating">
                                <th mat-header-cell *matHeaderCellDef> Rating</th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="SOD-rating">
                                        <div class="SOD-number">
                                            <div id="riskRecord-ratingDialog-initialSeverity">
                                                <div class="SOD-Label">{{element.rating}}</div>
                                            </div>
                                        </div>
                                        <div class="SOD-classification">
                                            <div class="level" [style.backgroundColor]="element.classificationColor"
                                            [style.color]="element.classificationTextColor"
                                                id="riskRecord-ratingDialog-initialSeverityClassification">
                                                <h3> {{element.classification}}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="{{column.SODCategory}}"
                                *ngFor="let column of occurrenceCategories">
                                <th mat-header-cell *matHeaderCellDef width="15%"> {{column.SODCategory}}<div
                                        [ngClass]="{ 'selectedCategory': (column.SODCategory == selectedCategory )}">
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element; let j = index;"
                                    (click)="setSelectedCardAndRating('occurrence', element[column.SODCategory + 'Description'], element[column.SODCategory + 'ID'], element.SODRatingID, element.rating, element, element[column.SODCategory + 'SODRatingID'], element[column.SODCategory + 'Classification'], element[column.SODCategory + 'ClassificationColor'], column.SODCategory, element[column.SODCategory + 'ClassificationTextColor'])"
                                    [ngClass]="{'selectedInitialCard' : element[column.SODCategory + 'ID'] == selectedOccurrenceCard_initial, 'selectedResidualCard': element[column.SODCategory + 'ID'] == selectedOccurrenceCard_residual}"
                                    #tooltip="matTooltip" [matTooltip]="element[column.SODCategory + 'Description']">
                                    <div class="rating-card-container">
                                        <div class="card-type">
                                            <p
                                                *ngIf="element[column.SODCategory + 'ID'] == selectedOccurrenceCard_residual">

                                                <b>Residual Risk Level</b>
                                            </p>
                                            <p
                                                *ngIf="element[column.SODCategory + 'ID'] == selectedOccurrenceCard_initial">

                                                <b>Initial Risk Level</b>
                                            </p>
                                        </div>
                                        <div class="card-text">
                                            <p class="truncate-text-4-lines"
                                                [ngStyle]="{'font-weight': (column.SODCategory == selectedCategory ) ? 'bold' : 'normal'}">
                                                {{element[column.SODCategory +'Description'] | slice:0:150}}</p>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row
                                *matHeaderRowDef="occurrenceCategoryDataSourceDisplayedColumns; sticky: true">
                            </tr>
                            <tr mat-row
                                *matRowDef="let row; columns: occurrenceCategoryDataSourceDisplayedColumns; let i = index;">
                            </tr>
                        </table>
                    </div>
                </div>
            </form>
        </div>
        <div class="main-content" *ngIf="selectedTab === 'detectability'">
            <form [formGroup]="failureCauseFormDetectability">
                <div *ngIf="(detectabilityCategories && detectabilityCategories.length > 0)"
                    class="general-table-overflow">
                    <div class="table-container category">
                        <table mat-table [dataSource]="detectabilityCategoryDataSource">
                            <ng-container matColumnDef="detectabilityRating">
                                <th mat-header-cell *matHeaderCellDef> Rating</th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="SOD-rating">
                                        <div class="SOD-number">
                                            <div id="riskRecord-ratingDialog-initialSeverity">
                                                <div class="SOD-Label">{{element.rating}}</div>
                                            </div>
                                        </div>
                                        <div class="SOD-classification">
                                            <div class="level" [style.backgroundColor]="element.classificationColor"
                                            [style.backgroundColor]="element.classificationColor"
                                                id="riskRecord-ratingDialog-initialSeverityClassification">
                                                <h3> {{element.classification}}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="{{column.SODCategory}}"
                                *ngFor="let column of detectabilityCategories">
                                <th mat-header-cell *matHeaderCellDef width="15%"> {{column.SODCategory}} <div
                                        [ngClass]="{ 'selectedCategory': (column.SODCategory == selectedCategory )}">
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element; let j = index;"
                                    (click)="setSelectedCardAndRating('detectability', element[column.SODCategory + 'Description'], element[column.SODCategory + 'ID'], element.SODRatingID, element.rating, element, element[column.SODCategory + 'SODRatingID'], element[column.SODCategory + 'Classification'], element[column.SODCategory + 'ClassificationColor'], column.SODCategory, element[column.SODCategory + 'ClassificationTextColor'])"
                                    [ngClass]="{'selectedInitialCard' : element[column.SODCategory + 'ID'] == selectedDetectabilityCard_initial, 'selectedResidualCard': element[column.SODCategory + 'ID'] == selectedDetectabilityCard_residual }"
                                    #tooltip="matTooltip" [matTooltip]="element[column.SODCategory + 'Description']">
                                    <div class="rating-card-container">
                                        <div class="card-type">
                                            <p
                                                *ngIf="element[column.SODCategory + 'ID'] == selectedDetectabilityCard_residual">
                                                <b>Residual Risk Level</b>
                                            </p>
                                            <p
                                                *ngIf="element[column.SODCategory + 'ID'] == selectedDetectabilityCard_initial">
                                                <b>Initial Risk Level</b>
                                            </p>
                                        </div>
                                        <div class="card-text">
                                            <p class="truncate-text-4-lines"
                                                [ngStyle]="{'font-weight': (column.SODCategory == selectedCategory ) ? 'bold' : 'normal'}">
                                                {{element[column.SODCategory +'Description'] | slice:0:150}}</p>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row
                                *matHeaderRowDef="detectabilityCategoryDataSourceDisplayedColumns; sticky: true">
                            </tr>
                            <tr mat-row
                                *matRowDef="let row; columns: detectabilityCategoryDataSourceDisplayedColumns; let i = index;">
                            </tr>
                        </table>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="dialog-footer">
        <div class="only-buttons">
            <button mat-button class="btn cancel" id="failureCause-dialog-cancel-btn" (click)="dialogRef.close()">
                <p>Cancel</p>
            </button>
            <button mat-button class="btn done" id="failureCause-dialog-done-btn" [disabled]="failureCauseFormValid_XXXX()"
                appDebounceClick (debounceClick)="saveFailureCauseDialog_XXXX()" [debounceTime]="200" >
                <p>Done</p>
            </button>
        </div>
    </div>
</div>