<div class="risk-profile-dashboard">
    <div class="mainchart-treatment-container">
        <div class="mainchart-container">
            <div class="chart-body">
                <dx-chart id="chart" title={{chartTitle}} [dataSource]="riskCategoriesGraphData" [rotated]="true"
                    (onLegendClick)="legendClick($event)">
                    <dxi-series valueField="high" name="High Risks" color="#E44D25"></dxi-series>
                    <dxi-series valueField="medium" name="Medium Risks" color="#FFA500"></dxi-series>
                    <dxi-series valueField="low" name="Low Risks" color="#5AA454"></dxi-series>
                    <dxi-value-axis position="left" precision=1>
                        <dxo-title text={{yAxisLabel}}></dxo-title>
                    </dxi-value-axis>
                    <dxo-common-series-settings argumentField="name" type="stackedBar">
                        <dxo-label [visible]="true" [format]="{
                              type: 'fixedPoint',
                              precision: '0'
                            }">
                        </dxo-label>
                    </dxo-common-series-settings>
                    <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="left">
                    </dxo-legend>
                    <dxo-tooltip [enabled]="true" location="edge" [customizeTooltip]="customizeTooltip">
                    </dxo-tooltip>
                </dx-chart>
            </div>
        </div>
        <div class="treatment-container">
            <div class="risk-profile-sub-header">
                <h4>Overall Treatment</h4>
            </div>
            <div class="general-treatment-container">
                <div class="treatment-option-container">
                    <div class="risk-decision">
                        {{riskProfileData.acceptedHighRisks +
                        riskProfileData.acceptedMediumRisks + riskProfileData.acceptedLowRisks}}
                    </div>
                    <div class="risk-decision">
                        Accept
                    </div>
                </div>
                <div class="treatment-option-container">
                    <div class="risk-decision">{{riskProfileData.avoidedHighRisks +
                        riskProfileData.avoidedMediumRisks + riskProfileData.avoidedLowRisks}}</div>
                    <div class="risk-decision">Action - Eliminate</div>
                </div>
            </div>
            <div class="general-treatment-container">
                <div class="treatment-option-container">
                    <div class="risk-decision">{{riskProfileData.reducedHighRisks +
                        riskProfileData.reducedMediumRisks + riskProfileData.reducedLowRisks}}</div>
                    <div class="risk-decision">Action - Reduce</div>
                </div>
                <div class="treatment-option-container">
                    <div class="risk-decision">{{riskProfileData.pendingHighRisks +
                        riskProfileData.pendingMediumRisks + riskProfileData.pendingLowRisks}}</div>
                    <div class="risk-decision">Pending</div>
                </div>
            </div>

        </div>

    </div>
    <div class="risk-profile-container">
        <div class="risk-profile-distribution-container">
            <div class="risk-profile-sub-header">
                <h4>Risk Profile</h4>
            </div>
            <div class="risk-profile-chart-body">
                <app-risk-profile [riskProfileData]="riskProfileData" [isHorizontal]="true"></app-risk-profile>
            </div>
        </div>
    </div>
</div>