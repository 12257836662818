import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { IrlcoreAuthService, GlobalService } from '@irlca/irlcore';
import { ArmService } from '../arm.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

    @ViewChild('menuTrigger', { static: false })
    public menuTrigger!: MatMenuTrigger;

    @ViewChild('menuTriggerDash', { static: false })
    public menuTriggerDash!: MatMenuTrigger;

    @ViewChild('menuTriggerSettings', { static: false })
    public menuTriggerSettings!: MatMenuTrigger;

    constructor(
        public auth: IrlcoreAuthService,
        public globalService: GlobalService,
        public armService: ArmService
    ) { }

    userAuthenticated: boolean = false;
    registerMenuTimeout: any;
    dashboardMenuTimeout: any;
    SettingsMenuTimeout: any;

    async ngOnInit() {
        this.globalService.devMode = false;
        this.userAuthenticated = await this.auth.isAuthenticated_0150();

        // Bind local variable to authentication state
        this.auth.$authenticationState.subscribe(
            (isAuthenticated: boolean) => this.userAuthenticated = isAuthenticated);
    }

    onRegisterMenuLeave_0538() {
        this.registerMenuTimeout = setTimeout(() => {
            this.menuTrigger.closeMenu();;
        }, 150);
    }

    onDashboardMenuLeave_0538() {
        this.dashboardMenuTimeout = setTimeout(() => {
            this.menuTriggerDash.closeMenu();;
        }, 150);
    }

    onSettingsMenuLeave_0538() {
        this.SettingsMenuTimeout = setTimeout(() => {
            this.menuTriggerSettings.closeMenu();;
        }, 150);
    }

    onRegisterMenuEnter_0539() {
        clearTimeout(this.registerMenuTimeout);
    }

    onDashboardMenuEnter_0539() {
        clearTimeout(this.dashboardMenuTimeout);
    }

    onSettingsMenuEnter_0539() {
        clearTimeout(this.SettingsMenuTimeout);
    }
}
