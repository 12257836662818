<div class="dialog">
    <div class="dialog-header">
        <div class="header-text center">
            <h2 >Filter Controls</h2>
        </div>
        <div class="align-items-start">
            <div class="header-icon">
                <button mat-button class="icon-btn dialog-close-btn" id="control-register-filtering-dialog-close-button" (click)="dialogRef.close()">
                    <div class="icon"><fa-icon [icon]="times"></fa-icon>
                    </div>
                </button>
            </div>
        </div>
    </div>
    <div class="dialog-content filter">
        <div class="row filter-search-container justify-content-center">
            <div class="col-10 d-flex justify-content-center">
                <input matInput type="text" class="search-options-input white-background" placeholder="Search filter options..."
                    [(ngModel)]="searchedTerm" id="control-register-filtering-dialog-search-input">
                <button mat-button mat-icon-button aria-label="Clear" (click)="searchedTerm = '';"
                    [ngClass]="{'hidden': searchedTerm == ''}" id="control-register-filtering-dialog-search-input-clear">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        <div class="scrollable-content">
            <div class="filter-section no-padding">
                <div *ngFor="let title of filterTitles" id="control-register-filtering-dialog-{{title.key}}-title-div" class="{{title.class}} sorting-row"
                (click)="sortFilterOptionsControl_0562(title.key, false)">
                    <div id="control-register-filtering-dialog-{{title.key}}-title-text" >
                        {{title.name}}
                    </div>
                    <div id="risk-register-filteringDialog-sort-{{title.key}}">
                        <button *ngIf="!filterListIsSorted[title.key]" id="control-register-filtering-dialog-sort-DESC-{{title.key}}"><fa-icon [icon]="longArrowAltDown"></fa-icon></button>
                        <button *ngIf="filterListIsSorted[title.key]" id="control-register-filtering-dialog-sort-ASC-{{title.key}}"><fa-icon [icon]="longArrowAltUp"></fa-icon></button>
                    </div>
                </div>
            </div>
            <div class="container-search-body-scrollable padding-box">
                <div class="filter-list border single" id="control-register-filter-dialog-filter-list-ID">
                    <div *ngFor="let category of displayFilterObjects.category | riskRegisterFilterSearch: searchedTerm: 'category': true; let i=index;"
                        class="filter-item" [hidden]="!category.visible" id="filter-item-category-{{i}}">
                        <label class="filter-value-container" *ngIf="category.visible" >{{category.filterValue}}
                            <input type="checkbox" id="control-register-filtering-dialog-categoryCheckboxInput-{{i}}"
                                (click)="toggleFilterOptionListControl_0482('category', category.filterValue, $event);"
                                [checked]="category.checked">
                            <span id="control-register-filtering-dialog-categoryCheckboxSpan-{{i}}"
                                [ngClass]="{'checkmark-visible': category.checked }" class="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="dialog-footer">
        <div id="action-group">
            <div class="only-buttons">
                <button mat-button id="control-register-filtering-dialog-cancel-button" class="btn cancel"
                    (click)="closeControlFilterDialog_0118(dialogRef)" ><p>Cancel</p>
                </button>
                <button mat-button id="control-register-filtering-dialog-clear-button" class="btn secondary"
                    (click)="clearSelectedControlFilters_0564()" [disabled]="disabledFlagClearAll" ><p>Clear All</p>
                </button>
                <button mat-button id="control-register-filtering-dialog-apply-button" class="btn done"
                    (click)="exportFilterDialogObjectControlRegisterFilterDialog_0563()" [disabled]="disabledFlagApply" ><p>Apply</p>
                </button>
            </div>
        </div>
    </div>
</div>