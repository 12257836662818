<div class="dialog">
    <div class="dialog-header">
        <div class="header-text center">
            <h2 id="failureCause-dialog-title" *ngIf="selectedTab == 'severity'">Cause Prevention Controls </h2>
            <h2 id="failureCause-dialog-title" *ngIf="selectedTab == 'occurrence'">Prevention Controls </h2>
            <h2 id="failureCause-dialog-title" *ngIf="selectedTab == 'detectability'">Detection Controls </h2>
        </div>
        <div class="align-items-start">
            <div class="header-icon">
                <button mat-button class="icon-btn dialog-close-btn" id="failureCause-dialog-close-btn"
                    (click)="dialogRef.close()">
                    <div class="icon">
                        <fa-icon [icon]="times"></fa-icon>
                    </div>
                </button>
            </div>
        </div>
    </div>
    <div class="dialog-content">
        <div class="main-content" *ngIf="selectedTab == 'severity'">
            <div
                *ngIf="displayContainer == 'control' && this.globalService.configurableParameters.addRiskPreventionControls">
                <dx-data-grid #grid id="gridContainer" class="controls-table" [dataSource]="controlDataSource"
                    keyExpr="iD" [showBorders]="true" [remoteOperations]="true" [allowColumnResizing]="true"
                    [wordWrapEnabled]="true" (onSelectionChanged)="selectRow($event)" [(selectedRowKeys)]="selectedRows"
                    (onSaving)="updateRow($event)">
                    <dxo-editing mode="form" [allowAdding]="true">
                    </dxo-editing>
                    <dxo-selection showCheckBoxesMode="always" mode="multiple" [allowSelectAll]="true"></dxo-selection>
                    <dxi-column dataField="controlIDREF" alignment="center" caption="Control ID">
                        <dxo-form-item [colSpan]="1" [visible]="false"></dxo-form-item>
                    </dxi-column>
                    <dxi-column dataField="controlREF" alignment="center" caption="Control REF">
                        <dxo-form-item [colSpan]="1" [editorOptions]="{ maxLength: 200 }"></dxo-form-item>
                    </dxi-column>
                    <dxi-column dataField="control" caption="Control Title"></dxi-column>
                    <dxi-column dataField="controlDescription" caption="Control Description">
                        <dxo-form-item [colSpan]="2" editorType="dxTextArea" [editorOptions]="{ height: 100 }">
                        </dxo-form-item>
                    </dxi-column>
                </dx-data-grid>
                <div class="selected-data">
                    <h3>Selected Controls:</h3>
                    <p>
                        {{
                        grid.selectedRowKeys.length
                        ? (grid.instance.getSelectedRowsData() | stringifyControl)
                        : "No Controls Selected"
                        }}
                    </p>
                </div>
            </div>
        </div>
        <div class="main-content" *ngIf="selectedTab === 'occurrence'">
            <dx-data-grid #grid id="gridContainer" class="controls-table"
                [dataSource]="causePreventionControlDataSource" keyExpr="iD" [showBorders]="true"
                [remoteOperations]="true" [allowColumnResizing]="true" [wordWrapEnabled]="true" [rowAlternationEnabled]="true"
                (onSelectionChanged)="selectRow($event)" [(selectedRowKeys)]="selectedRowsCausePreventative"
                (onSaving)="updateRow($event)">
                <dxo-editing mode="form" [allowAdding]="true">
                </dxo-editing>
                <dxo-selection showCheckBoxesMode="always" mode="multiple" [allowSelectAll]="true"></dxo-selection>
                <dxi-column dataField="controlIDREF" alignment="center" width="5vw" caption="Control ID">
                    <dxo-form-item [colSpan]="1" [visible]="false">
                    </dxo-form-item>
                </dxi-column>
                <dxi-column dataField="controlREF" width="10vw"  caption="Control REF">
                    <dxo-form-item [editorOptions]="{ maxLength: 200 }">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxo-form-item>
                </dxi-column>
                <dxi-column dataField="control" width="10vw" caption="Control Title" [editorOptions]="{ maxLength: 2000 }">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>
                <dxi-column dataField="controlDescription" caption="Control Description">
                    <dxo-form-item [colSpan]="2" editorType="dxTextArea" [editorOptions]="{ height: 100, maxLength: 4000 }">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxo-form-item>
                </dxi-column>
                <dxo-scrolling mode="infinite"></dxo-scrolling>
            </dx-data-grid>
            <div class="selected-data">
                <h3>Selected Controls:</h3>
                <p>
                    {{
                    grid.selectedRowKeys.length
                    ? (grid.instance.getSelectedRowsData() | stringifyControl)
                    : "No Controls Selected"
                    }}
                </p>
            </div>
        </div>
        <div class="main-content" *ngIf="selectedTab === 'detectability'">
            <dx-data-grid #grid id="gridContainer" class="controls-table" [dataSource]="detectabilityControlDataSource"
                keyExpr="iD" [showBorders]="true" [remoteOperations]="true" [allowColumnResizing]="true"
                [wordWrapEnabled]="true" [rowAlternationEnabled]="true" (onSelectionChanged)="selectRow($event)"
                [(selectedRowKeys)]="selectedRowsDetectability" (onSaving)="updateRow($event)">
                <dxo-editing mode="form" [allowAdding]="true">
                </dxo-editing>
                <dxo-selection showCheckBoxesMode="always" mode="multiple" [allowSelectAll]="true"></dxo-selection>
                <dxi-column dataField="controlIDREF" alignment="center" width="5vw" caption="Control ID">
                    <dxo-form-item [colSpan]="1" [visible]="false"></dxo-form-item>
                </dxi-column>
                <dxi-column dataField="controlREF" width="10vw" caption="Control REF">
                    <dxo-form-item [editorOptions]="{ maxLength: 200 }">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxo-form-item>
                </dxi-column>
                <dxi-column dataField="control" width="10vw" caption="Control Title">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>
                <dxi-column dataField="controlDescription" caption="Control Description">
                    <dxo-form-item [colSpan]="2" editorType="dxTextArea" [editorOptions]="{ height: 100 }">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxo-form-item>
                </dxi-column>
                <dxo-scrolling mode="infinite"></dxo-scrolling>
            </dx-data-grid>
            <div class="selected-data">
                <h3>Selected Controls:</h3>
                <p>
                    {{
                    grid.selectedRowKeys.length
                    ? (grid.instance.getSelectedRowsData() | stringifyControl)
                    : "No Controls Selected"
                    }}
                </p>
            </div>
        </div>
    </div>
    <div class="dialog-footer">
        <div class="only-buttons">
            <button mat-button class="btn cancel" id="failureCause-dialog-cancel-btn" (click)="dialogRef.close()">
                <p>Cancel</p>
            </button>
            <button mat-button class="btn done" id="failureCause-dialog-done-btn"
                [disabled]="failureCauseFormValid_XXXX()" appDebounceClick (debounceClick)="saveFailureCauseDialog_XXXX()" [debounceTime]="200">
                <p>Done</p>
            </button>
        </div>
    </div>
</div>