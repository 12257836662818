import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IrlcoreAuthConfig, IrlcoreAuthenticationModule, IrlcoreAuthGuard, IrlcoreAuthService, IrlcoreSecurityManagementConfig, IrlcoreSecurityManagementModule, IrlcoreSecurityManagementService } from '@irlca/irlcore';
import { environment } from 'src/environments/environment';
import { RiskProfileComponent } from './risk-profile/risk-profile.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTableExporterModule } from 'mat-table-exporter';
import { RiskRecordComponent } from '../content/risk-assessment/risk-record/risk-record.component';
import { RiskAssessmentTeamComponent } from '../content/risk-assessment/risk-assessment-expanded-detail/risk-assessment-team/risk-assessment-team.component';
import { AuditTrailComponent } from './audit-trail/audit-trail.component';
import { ViewAttachmentComponent } from './view-attachment/view-attachment.component';
import { InactiveTimeoutDialogComponent } from './dialog/inactive-timeout-dialog/inactive-timeout-dialog.component';
import { ItemGroupOrderDialogComponent } from './dialog/item-group-order-dialog/item-group-order-dialog.component';

import { TooltipListPipe } from '../content/risk-assessment/risk-record/tooltip-list-pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { AttachmentsComponent } from './attachments/attachments.component';
import { DxDataGridModule, DxListModule, DxTemplateModule } from 'devextreme-angular';
import { TaskRisksDialogComponent } from './dialog/task-risks-dialog/task-risks-dialog.component';
import { ApprovalsDialogComponent } from './dialog/approvals-dialog/approvals-dialog.component';
// import { StringifyControlsPipe } from './dialog/cotrol-pipe';
import { DebounceClickDirective } from '../debounce-click.directive';

const securityManagementConfig: IrlcoreSecurityManagementConfig = {
  // // URI of application. This will used to request / save Security Managment User Data
  applicationBaseURL: environment.applicationBaseURL,
};

// _____________________________
// Authentication Bootstrap Data
// ------------------------------
const authConfig: IrlcoreAuthConfig = {
  systemID: 2,
  configBaseURL: 'https://10.18.64.52:44311/', // Virtual PC: IC001P
  // configBaseURL:   'http://localhost:40000',

  // Should irlcore automatically retrieve user Permitted Features
  autoRetrieveUserFeatures: true,

  // URI of application. This will used to obtain users credentials e.g. Permitted Features (aka Permissions)
  applicationBaseURL: environment.applicationBaseURL,
};
@NgModule({
  declarations: [RiskProfileComponent, RiskRecordComponent, RiskAssessmentTeamComponent, AuditTrailComponent, ViewAttachmentComponent,
    InactiveTimeoutDialogComponent, TooltipListPipe, ItemGroupOrderDialogComponent, PdfViewerComponent, AttachmentsComponent, TaskRisksDialogComponent, ApprovalsDialogComponent, DebounceClickDirective],
  imports: [
    CommonModule,
    MatDialogModule,
    MatMenuModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatCardModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    DragDropModule,
    MatRadioModule,
    MatCheckboxModule,
    MatTableExporterModule,
    MatProgressSpinnerModule,
    IrlcoreAuthenticationModule.initServerConfig(authConfig), // Initialise Authentication Module passing in application name and config server URI
    IrlcoreSecurityManagementModule.initConfig(securityManagementConfig),
    FontAwesomeModule,
    DxListModule,
    DxTemplateModule,
    DxDataGridModule,
  ],
  entryComponents: [ApprovalsDialogComponent],
  exports: [CommonModule,
    MatDialogModule,
    MatMenuModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatCardModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    DragDropModule,
    MatRadioModule,
    MatCheckboxModule,
    MatTableExporterModule,
    RiskProfileComponent,
    RiskRecordComponent,
    RiskAssessmentTeamComponent,
    AuditTrailComponent,
    ViewAttachmentComponent,
    PdfViewerComponent,
    AttachmentsComponent,
    DebounceClickDirective
  ],
  providers: [],// Initialise Authentication Module passing in application name and config server URI
  //   IrlcoreSecurityManagementModule]
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],

})
export class SharedModule { }
