import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { APIService, dataset, datasetGet, DataToGet, GlobalService } from '@irlca/irlcore';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ArmService } from '../../../arm.service';

@Component({
  selector: 'app-risk-assessment-lead-dialog',
  templateUrl: './risk-assessment-lead-dialog.component.html',
  styleUrls: ['./risk-assessment-lead-dialog.component.scss']
})
export class RiskAssessmentLeadDialogComponent implements OnInit {

  currentRiskAssessmentLead: any = {};
  riskAssessmentLeadForm!: FormGroup;
  userList: any[] = [];
  raTeam: any[] = [];
  filteredUsers!: Observable<any>;
  riskAssessmentLeadSelected: boolean = false;
  memberIDValues: number[] = [];

  // icons
  times = faTimes
  
  constructor(public dialogRef: MatDialogRef<RiskAssessmentLeadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder,
    private globalService: GlobalService, private apiService: APIService, private armService: ArmService) { }

  ngOnInit() {
    this.prepareDataForGetRiskAssessmentLead_0190();
  }

  createRiskAssessmentLeadFormGroup_0410() {
    return this.fb.group({
      riskAssessmentLeadName: [this.currentRiskAssessmentLead.fullName, Validators.required],
      email: [this.currentRiskAssessmentLead.email],
      contactNumber: [this.currentRiskAssessmentLead.contactNumber],
      site: [this.currentRiskAssessmentLead.country],
      departmentTextArea: [this.currentRiskAssessmentLead.departmentName],
    });
  }

  prepareDataForGetRiskAssessmentLead_0190() {
    let datasets: datasetGet[] = [];

    let singleUserDataset: datasetGet = {
      parameters: {
        clientID: null,
        entityID: null,
        departmentID: null,
        searchValue: null,
        userID: this.data.riskAssessmentLeadID
      },
      storedProcedure: "User"
    };

    let userDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        departmentID: null,
        searchValue: null,
        userID: null
      },
      storedProcedure: "User"
    };

    let teamDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        riskAssessmentID: this.armService.selectedRiskAssessment.iD
      },
      storedProcedure: "RiskAssessmentTeamMember"
    }

    datasets.push(singleUserDataset, userDataset, teamDataset);
    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Risk Assessment Lead dialog", datasets);

    this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      this.currentRiskAssessmentLead = fromDB[0][0];
      this.userList = fromDB[1];
      this.raTeam = fromDB[2];
      this.raTeam.forEach(element => {
        this.memberIDValues.push(element.userID);
      });
      this.riskAssessmentLeadForm = this.createRiskAssessmentLeadFormGroup_0410();
      this.filteredUsers = this.riskAssessmentLeadForm.get('riskAssessmentLeadName')!.valueChanges
        .pipe(
          startWith(''),
          map(value => this.filterUsers_0411(value))
        );
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Risk Assessment Lead");
      window.alert(userErrMsg)
    });
  }

  filterUsers_0411(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.userList.filter(option => option.fullName.toLowerCase().includes(filterValue));
  }

  getUserID_0412(userFullName: string): number {
    let user = this.userList.filter(user => user.fullName === userFullName);
    if (user.length >= 1)
      return user[0].userID;
    else
      return 1;
  }

  onEnterUser_0247(evt: any, user: any, memberForm: FormGroup) {
    if (evt.source.selected) {
      this.updateReadOnly_0048(user, memberForm);
    }
  }

  updateReadOnly_0048(user: any, memberForm: FormGroup) {
    memberForm.get('email')!.setValue(user.email);
    memberForm.get('contactNumber')!.setValue(user.contactNumber);
    memberForm.get('site')!.setValue(user.country);
    memberForm.get('departmentTextArea')!.setValue(user.departmentName);
  }

  saveRiskAssessmentLead_0540() {
    this.dialogRef.close({
      riskAssessmentLeadID: this.getUserID_0412(this.riskAssessmentLeadForm.value.riskAssessmentLeadName),
      riskAssessmentLeadName: this.riskAssessmentLeadForm.value.riskAssessmentLeadName
    });
  }
}
