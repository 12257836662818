import { Component, HostListener, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { IrlcoreAuthService, APIService, datasetGet, DataToGet, GlobalService, dataset, DataToPost } from '@irlca/irlcore';
import { Router } from '@angular/router';
import { ARMDialogService } from '../../../shared/dialog/dialog.service';
import { RegisterService } from '../register.service';
import { ExportType, MatTableExporterDirective } from 'mat-table-exporter';
import * as cloneDeep from 'lodash';
import { FilterService } from '../../../filter.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { ArmService } from '../../../arm.service'
import * as moment from 'moment'
import { faEdit, faEllipsisV, faExclamationTriangle, faFileExport, faFilter, faPlus, faTimes, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
@Component({
    selector: 'app-register-control',
    templateUrl: './register-control.component.html',
    styleUrls: ['./register-control.component.scss']
})
export class RegisterControlComponent implements OnInit, AfterViewInit {
    columnsToDisplay: string[] = ["controlID", "controlREF", "category", "control", "controlDescription"];
    emptyRow: any = { iD: null, controlID: null, controlCategoryID: null, category: null, control: null, controlDescription: null };
    controls: any[] = [];
    currentPage!: number;
    quickFilterIsSelected: boolean = false;
    tableHidden: boolean = false;
    entryID: number = -1;
    filters: any[] = [];
    pageEvent!: PageEvent;
    filterValue: any = '';
    filterValues: any = {};
    filterSelectObj: any = [];
    displayLoader: boolean = true;
    displayLoaderExport: boolean = false;
    selectedFilterOptions: any[] = [];
    filterApplied: boolean = false;
    dataSource: MatTableDataSource<any>;


    @ViewChild(MatSort, { static: false })
    set sort(value: MatSort) {
        this.dataSource.sort = value;
    }
    @ViewChild(MatPaginator, { static: false })
    set paginator(value: MatPaginator) {
        this.dataSource.paginator = value;
    }

    @ViewChild('menuTriggerDash', { static: false })
    public menuTriggerDash!: MatMenuTrigger;

    @ViewChild(MatTableExporterDirective, { static: true }) exporter!: MatTableExporterDirective;

    // icons
    edit = faEdit
    filter = faFilter
    times = faTimes
    trash = faTrash
    fileExport = faFileExport
    plus = faPlus
    ellipsisVertical = faEllipsisV
    exclamationTriangle = faExclamationTriangle

    constructor(
        public globalService: GlobalService,
        public auth: IrlcoreAuthService,
        private apiService: APIService,
        private dialogService: ARMDialogService,
        public registerService: RegisterService,
        private filterService: FilterService,
        private router: Router,
        private armService: ArmService,
    ) {
        this.dataSource = new MatTableDataSource<any[]>();
    }

    // defineCustomSortControlRegister_XXXX() {
    //     let reA = /[^a-zA-Z]/g;
    //     let reN = /[^0-9]/g;

    //     this.dataSource.sortData = (data, sort: MatSort) => {
    //         const isAsc = sort.direction === 'asc';
    //         if (!sort.active || sort.direction == '') {
    //             return data;
    //         }
    //         return this.dataSource.data.sort((a, b) => {
    //             switch (sort.active) {
    //                 case "controlID": case "category": {
    //                     let comparatorResult = 0;
    //                     if (a[sort.active] != null && b[sort.active] != null) {
    //                         if (a[sort.active] > b[sort.active]) {
    //                             comparatorResult = 1;
    //                         } else if (a[sort.active] < b[sort.active]) {
    //                             comparatorResult = -1;
    //                         }
    //                     } else if (a[sort.active] != null) {
    //                         comparatorResult = 1;
    //                     } else if (b[sort.active] != null) {
    //                         comparatorResult = -1;
    //                     }
    //                     return comparatorResult * (isAsc ? 1 : -1);
    //                 }
    //                 default: {
    //                     if (a[sort.active] != null && b[sort.active] == null) {
    //                         return 1 * (isAsc ? 1 : -1);
    //                     }
    //                     else if (a[sort.active] == null && b[sort.active] != null) {
    //                         return -1 * (isAsc ? 1 : -1);
    //                     }
    //                     else if (a[sort.active] == null && b[sort.active] == null) {
    //                         return 0 * (isAsc ? 1 : -1);
    //                     }
    //                     else {
    //                         let aA = a[sort.active].replace(reA, "");
    //                         let bA = b[sort.active].replace(reA, "");
    //                         if (aA === bA) {
    //                             let aN = parseInt(a[sort.active].replace(reN, ""), 10);
    //                             let bN = parseInt(b[sort.active].replace(reN, ""), 10);
    //                             return (aN === bN ? 0 : aN > bN ? 1 : -1) * (isAsc ? 1 : -1);
    //                         } else {
    //                             return (aA > bA ? 1 : -1) * (isAsc ? 1 : -1);
    //                         }
    //                     }
    //                 }
    //             }
    //         });
    //     }
    // }

    ngOnInit() {
        if (this.registerService.navigateControlRegister) {
            this.filterApplied = this.registerService.controlsFilterApplied;
            this.displayLoader = false;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            setTimeout(() => {
                this.dataSource.data = this.registerService.filteredControls;
            });

            this.selectedFilterOptions = this.registerService.selectedFilterOptions;
            this.controls = this.registerService.allControls;
            if (this.registerService.controlRegisterFilterValue) {
                this.filterValue = this.registerService.controlRegisterFilterValue;
                this.dataSource.filter = this.filterValue.trim().toLowerCase();
            }
            this.registerService.navigateControlRegister = false;
        } else {
            this.prepareDataForGetControls_0190();
        }
    }

    ngAfterViewInit() {
        // this.defineCustomSortControlRegister_XXXX();
        // setTimeout(() => {
        //     this.registerService.calcPageSizeInRows_0129()
        // });
    }

    async prepareDataForGetControls_0190() {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        let datasets: datasetGet[] = [];

        let controls: datasetGet = {
            parameters: {
                clientID: this.globalService.clientID,
                entityID: this.globalService.entityID,
                controlCategoryID: null,
            },
            storedProcedure: "Control"
        };

        datasets.push(controls);
        let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Control Register", datasets);

        await this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
            this.controls = fromDB[0];
            setTimeout(() => {
                this.displayLoader = false;
                this.dataSource.data = this.controls;
            });
        }).catch((err: any) => {
            console.error(err);
            let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Controls");
            window.alert(userErrMsg)
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        // this.registerService.calcPageSizeInRows_0129();
        // DOC Removed to stop control register disappearing after export - check with Padraic why it was done in the first place

        // let temp: any = cloneDeep(this.dataSource.data);
        // setTimeout(() => { // neccessary to update the dom asynchronously so the values will not be updated when the verification loop is running
        //     this.dataSource.data = [];
        //     this.dataSource.data = temp;
        // });
        // this.handlePaginator_0304(event);
    }

    // DOC Removed as paginater seems to be removed - check with Padraic

    // handlePaginator_0304(event: any) {
    //     this.currentPage = event.pageIndex;
    // }

    // applyFilterControls_0557(event: any) {
    //     if (event) {
    //         setTimeout(() => {
    //             let filterValue = (event.target as HTMLInputElement).value;
    //             this.dataSource.filter = filterValue.trim().toLowerCase();
    //             this.currentPage = 0;
    //         });
    //     } else {
    //         setTimeout(() => {
    //             let filterValue = '';
    //             this.dataSource.filter = filterValue.trim().toLowerCase();
    //             this.currentPage = 0;
    //         });
    //     }
    // }

    // openControlsFilterDialog_0192() {
    //     if (this.selectedFilterOptions.length > 0) {
    //         this.filterApplied = true;
    //     }

    //     const dialogConfigData = {
    //         minWidth: '30vw',
    //         maxWidth: '30vw',
    //         data: {
    //             controls: this.controls,
    //             filteredControls: this.dataSource.data,
    //             selectedFilterOptions: this.selectedFilterOptions,
    //             filterApplied: this.filterApplied,
    //         }
    //     }

    //     const dialogRef = this.dialogService.openDialog_0088("ControlRegisterFilterDialogComponent", dialogConfigData);

    //     dialogRef.afterClosed().subscribe((result: any) => {
    //         if (result && result.closeType === 'apply') {                
    //             this.dataSource.data = result.filteredControls;
    //             this.selectedFilterOptions = result.selectedFilterOptions;
    //             this.filters = [];
    //             this.selectedFilterOptions.forEach(element => {
    //                 this.filters.push(element.filterValue);
    //             });
    //             if (this.selectedFilterOptions.length > 0) {
    //                 this.filterApplied = true;
    //             } else {
    //                 this.filterApplied = false;
    //             }
    //         }
    //     });
    // }

    // clearFiltersControlRegister_0470() {
    //     this.dataSource.data = this.controls;
    //     this.selectedFilterOptions = [];
    //     this.filterApplied = false;
    //     this.filters = [];
    // }

    // exportToExcelControlRegister_0480() {
    //     this.tableHidden = true;
    //     this.displayLoaderExport = true;

    //     this.exporter.exportTable(ExportType.XLSX, {
    //         fileName: "Control_Register_" + moment().format('L').toString(),
    //     });

    //     this.exporter.exportCompleted.subscribe(() => {
    //         this.tableHidden = false;
    //         this.displayLoaderExport = false;
    //     });
    // }

    // removeFilterOptionControls_0560(filter: any) {
    //     let index = this.selectedFilterOptions.findIndex(item =>
    //         (filter.filterValue === item.filterValue));
    //     this.selectedFilterOptions.splice(index, 1);
    //     if (this.selectedFilterOptions.length == 0) {
    //         this.dataSource.data = this.controls;
    //         this.filterApplied = false;
    //     } else {
    //         let filteredControls = this.filterService.filterControls_0559(this.controls, this.selectedFilterOptions);
    //         this.dataSource.data = filteredControls;
    //     }
    // }

    openAddControlDialog_0192(row: any, state: string, add: boolean) {
        const dialogConfigData = {
            maxWidth: '40vw',
            minWidth: '40vw',
            data: {
                controls: this.controls,
                row: row,
                state: state,
                addControl: add,
                entryID: this.entryID,
            }
        }

        const dialogRef = this.dialogService.openDialog_0088("ControlDialogComponent", dialogConfigData);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result && result.closeType === "save") {
                this.prepareDataForGetControls_0190();
            }
        });
    }

    deleteControlTableEntry_0558(row: any) {
        let auditTrail: any[] = [];
        let datasets: dataset[] = [];
        let controlDataset!: dataset;

        controlDataset = {
            data: [{
                iD: row.iD,
                clientID: row.clientID,
                entityID: row.entityID,
                controlCategoryID: row.controlCategoryID,
                controlCategoryName: row.category,
                control: row.control,
                controlREF: row.controlREF,
                controlDescription: row.controlDescription,
                statementType: "DELETE"
            }],
            dataset: "Control"
        }
        auditTrail = auditTrail.concat(this.globalService.buildAuditTrail_0115({}, controlDataset.data[0], "tblControl"));
        datasets.push(controlDataset);
        let toDB: DataToPost = new DataToPost(this.globalService.clientID, this.globalService.userID, 'N/A', "Delete Control", datasets, auditTrail);

        this.apiService.postDataToBackend_0051(this.apiService.urlForSinglePost, toDB).then((result: any) => {
            if (result) {
                this.prepareDataForGetControls_0190();
            }
        }).catch((err: any) => {
            console.error(err);
            let userErrMsg = this.armService.constructUserErrorMessage(err, "Controls");
            window.alert(userErrMsg)
        });
    }

    openControlRisksDialog_0192(row: any) {
        const dialogConfigData = {
            minWidth: '60vw',
            maxWidth: '60vw',
            data: {
                row: row,
                controls: this.controls,
                filteredControls: this.dataSource.data,
                selectedFilterOptions: this.selectedFilterOptions,
                filterApplied: this.filterApplied,
                filterValue: this.filterValue
            }
        }

        const dialogRef = this.dialogService.openDialog_0088("ControlRisksDialogComponent", dialogConfigData);
    }


    onExporting(e: any) {
        let date = moment().format('DD/MMM/YYYY').toString();

        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Control Register');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Control_Register_' + date + '.xlsx');
            });
        });
        e.cancel = true;
    }


}
