import { Component, OnInit } from '@angular/core';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { APIService, datasetGet, DataToGet, GlobalService } from '@irlca/irlcore';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboard-mitigating-action',
  templateUrl: './dashboard-mitigating-action.component.html',
  styleUrls: ['./dashboard-mitigating-action.component.scss']
})
export class DashboardMitigatingActionComponent implements OnInit {

  actionsChartData: any = [];
  actionsOverViewData: any = {};
  actionStatusData: any = {};
  view: any[] = [1700, 300];
  yearsArray: any = [];
  currentYearIndex: number = 0;

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Month';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Number of Risk Assessments';
  animations: boolean = true;
  legend: boolean = true;
  showLabels: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  timeline: boolean = false;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';
  months: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];


  colorScheme = {
    domain: ['#5AA454', '#C7B42C', '#40f720', '#DEEB34', '#4DD1E3', '#fA3C2A', '#DA56F5', '#FFBB33', '#AAAAAA']
  };

  statusColorScheme = {
    domain: ['#DEEB34', '#FFA500', '#7aa3e5', '#5AA454', '#AAAAAA']
  }

  RAGcolorScheme = {
    domain: ['#E44D25', '#FFA500', '#5AA454']
  };

  // icons
  caretLeft = faCaretLeft
  caretRight = faCaretRight
  
  constructor(private globalService: GlobalService, private apiService: APIService) { Object.assign(this, this.actionsChartData) }

  ngOnInit() {
    this.prepareDataForGetMitigatingActionGraphs("Bar");
  }

  prepareDataForGetMitigatingActionGraphs(chartType: string) {
    let datasets: datasetGet[] = [];

    let action: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        productID: null,
        functionalAreaID: null,
        categoryID: null,
        ownerID: null,
        businessProcessID: null,
        timebased: 1
      },
      storedProcedure: "MitigatingActionGraphs"
    };

    datasets.push(action);

    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "Notes", datasets);

    // this.apiService.getDataFromBackend_0090(this.globalService.serverBaseURL() + "/Dashboard/getGraphs", dataToGet).then((fromDB: any) => {
    //   this.actionsOverViewData = fromDB[0];
    //   this.actionStatusData = fromDB[1];
    //   this.yearsArray = Object.keys(this.actionsOverViewData);
    //   this.currentYearIndex = this.yearsArray.length - 1;
    // }); 
  }

  updateCurrentYear_XXXX(amount: number) {
    this.currentYearIndex += amount;
  }

  formatDate(val: any) {
    return moment().month(val - 1).format("MMMM");
  }

  // This function was defined in the HTML file but did not exist in the ts file, to allow the application to build an empty function has been added
  // This function should be updated with the correct functionality
  // onSelect(event) {

  // }

}
