import { Component, OnInit } from '@angular/core';
import { RegisterService } from './register.service';
import { ArmService } from '../../arm.service';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(
      public registerService: RegisterService,
      public armService: ArmService
  ) { }

  ngOnInit() {
  }

}
