<div class="landscape-container">
    <div class="page-card">
        <div class="page-card-header">
            <h3>LANDSCAPE</h3>
        </div>
        <div class="page-card-body">
            <div class="development-message">
                <label>Objective</label>
                <div class="message-card">
                    <p>Quality Risk management principles and approaches are effectively utilized across organizations. These principles and approaches are governed by Risk Management processes which enables a coordinated approach that an organization’s operating units and functions undertake to manage risk.
                        The holistic approach to the management of quality risks sets the foundation to enable a global view of quality risks and the management and actioning on these risks. The quality risk landscape is made up of many risks, risks of various levels, risks of various categories, and even risks that have 
                        an interconnectivity with other risks.  This platform will capitalizes on the enterprise approach to Risk Management by enabling a cross functional view of risks, controls, and activities. This view 
                        and assessment allows for risk-based decisions through various levels of the organization and effective prioritization of resources and efforts on those risks that matter most. 
                    </p>
                </div>
                <label>Status</label>
                <div class="message-card">
                    <div class="banner">
                        <h6>UNDER DEVELOPMENT</h6>
                    </div>
                    <p>This Feature is currently planned for release in 2022</p>
 

                </div>
            </div>
            <div class="development-picture">
             <img src="../../../assets/images/UnderConstruction.png" height="600">  
            </div>
        </div>
    </div>
</div>

