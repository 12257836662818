import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '@irlca/irlcore';
import { ArmService } from '../arm.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

  constructor(
    public globalService: GlobalService,
    public router: Router, private armService: ArmService) { }

  ngOnInit() {
    this.onResize();
  }

  @HostListener('window:resize')
  onResize() {
    this.armService.appWidth = document.getElementById('app-content')!.offsetWidth;
    this.armService.appHeight = document.getElementById('app-content')!.offsetHeight;
  }
}
