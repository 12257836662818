import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faLongArrowAltDown, faLongArrowAltUp, faTimes } from '@fortawesome/pro-solid-svg-icons';
import * as _ from 'lodash';
import { cloneDeep, isEqual } from 'lodash';
import { FilterService } from '../../../filter.service';

@Component({
    selector: 'app-control-register-filter-dialog',
    templateUrl: './control-register-filter-dialog.component.html',
    styleUrls: ['./control-register-filter-dialog.component.scss']
})
export class ControlRegisterFilterDialogComponent implements OnInit {
    displayFilterObjects: any;
    filteredListOfControls: any;
    selectedFilterOptions: any[] = [];
    filterListIsSorted: any = {
        category: true,
    };
    filterTitles = [
        { name: "Control Category", key: "category", class: "col-4" },
    ];
    firstToggleCall: boolean = false;
    searchedTerm: string = "";
    disabledFlagApply: boolean = true;
    disabledFlagClearAll: boolean = false;
    originalDisplayFilterObjects: any;
    firstRun: boolean = true;

    // icons
    times = faTimes
    longArrowAltDown = faLongArrowAltDown
    longArrowAltUp = faLongArrowAltUp

    constructor(
        public dialogRef: MatDialogRef<ControlRegisterFilterDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private filterService: FilterService
    ) { }

    ngOnInit() {
        if (this.data.filterApplied) {
            this.selectedFilterOptions = cloneDeep(this.data.selectedFilterOptions);
            this.displayFilterObjects = this.filterService.getFilterOptionsControl_0477(this.data.controls);            

            this.selectedFilterOptions.forEach((element: any) => {
                this.firstToggleCall = true;
                let mockEvent = {target: {checked: true}};
                this.toggleFilterOptionListControl_0482(element.type, element.filterValue, mockEvent);
                this.firstToggleCall = false;
            });
        } else {
            this.displayFilterObjects = this.filterService.getFilterOptionsControl_0477(this.data.controls);
        }
        let keys = Object.keys(this.displayFilterObjects);
        for (let key of keys) {
            this.sortFilterOptionsControl_0562(key, false);
        }
        this.originalDisplayFilterObjects = cloneDeep(this.displayFilterObjects);
        this.firstRun = false;
        this.checkIfClearALLChangeControlRegisterFilterDialog_XXXX();
        this.checkIfApplyChangeControlRegisterFilterDialog_XXXX();
    }

    toggleFilterOptionListControl_0482(key: string, value: string, event: any) {        
        let checked: boolean = event.target.checked;
        this.checkFilterTypeControlFilterDialog_0474(key, checked, value);

        if (!this.firstToggleCall) {
            this.updateSelectedFilterOptionsControlFilterDialog_0473(key, checked, value);
        }

        this.filteredListOfControls = this.filterService.filterControls_0559(this.data.controls, this.selectedFilterOptions);

        let filterOptionsForFilteredInspections = this.filterService.getFilterOptionsControl_0477(this.filteredListOfControls);

        this.displayFilterObjects = this.determineFilterOptionVisibilityControlFilterDialog_0471(key, checked, this.displayFilterObjects, filterOptionsForFilteredInspections);
        this.checkIfClearALLChangeControlRegisterFilterDialog_XXXX();
        this.checkIfApplyChangeControlRegisterFilterDialog_XXXX()
    }

    checkFilterTypeControlFilterDialog_0474(key: string, checked: boolean, value: string) {
        this.displayFilterObjects[key].forEach((element: any) => {
            if (element.filterValue == value) { element.checked = checked; }
        });
    }

    updateSelectedFilterOptionsControlFilterDialog_0473(key: string, checked: boolean, value: string) {
        if (checked) {
            this.selectedFilterOptions.push({
                type: key,
                filterValue: value
            });

        } else {
            let indexToSplice = this.selectedFilterOptions.findIndex(filterOption => filterOption.filterValue == value);
            this.selectedFilterOptions.splice(indexToSplice, 1);
        }
    }

    checkIfClearALLChangeControlRegisterFilterDialog_XXXX() {
        let keys = Object.keys(this.displayFilterObjects);
        this.disabledFlagClearAll = true;
        for (let key of keys) {
            if (!this.disabledFlagClearAll) {
                break;
            }
            this.displayFilterObjects[key].forEach((element: any) => {
                if (element.checked) {
                    this.disabledFlagClearAll = false;
                }
            });
        }
    }

    checkIfApplyChangeControlRegisterFilterDialog_XXXX() {
        if (!this.firstRun) {
            let tempDisplayFilterObjects = cloneDeep(this.displayFilterObjects);
            let tempOriginalDisplayFilterObjects = cloneDeep(this.originalDisplayFilterObjects);

            let keys = Object.keys(this.displayFilterObjects);
            for (let key of keys) {
                tempDisplayFilterObjects[key].sort((s1: any, s2: any) => {
                    if (s1.filterValue < s2.filterValue) {
                        return 1;
                    }
                    else if (s1.filterValue > s2.filterValue) {
                        return -1;
                    }
                    else {
                        return 0;
                    }
                });
            }

            let keys2 = Object.keys(this.originalDisplayFilterObjects);
            for (let key of keys2) {
                tempOriginalDisplayFilterObjects[key].sort((s1: any, s2: any) => {
                    if (s1.filterValue < s2.filterValue) {
                        return 1;
                    }
                    else if (s1.filterValue > s2.filterValue) {
                        return -1;
                    }
                    else {
                        return 0;
                    }
                });
            }

            if (JSON.stringify(tempDisplayFilterObjects) === JSON.stringify(tempOriginalDisplayFilterObjects)) {
                this.disabledFlagApply = true
            } else {
                this.disabledFlagApply = false
            }
        }
    }

    determineFilterOptionVisibilityControlFilterDialog_0471(key: string, checked: boolean, displayFilterObjects: any, filterOptionsForFilteredInspections: any) {
        let staticFilteredKeys = Object.keys(displayFilterObjects);
        staticFilteredKeys.forEach(staticFilteredKey => {
            if (staticFilteredKey !== key && checked) {
                displayFilterObjects[staticFilteredKey].forEach((filterOption: any) => {
                    if (filterOption.checked) {
                        filterOption.visible = true;
                    } else {
                        filterOption.visible = filterOptionsForFilteredInspections[staticFilteredKey].some((element: any) => element.filterValue == filterOption.filterValue);
                    }
                });
            } else if (!checked) {
                displayFilterObjects[staticFilteredKey].forEach((filterOption: any) => {
                    if (filterOption.checked) {
                        filterOption.visible = true;
                    } else {
                        filterOption.visible = filterOptionsForFilteredInspections[staticFilteredKey].some((element: any) => element.filterValue == filterOption.filterValue);
                    }
                });
            }
        });

        return displayFilterObjects;
    }

    exportFilterDialogObjectControlRegisterFilterDialog_0563() {
        if (this.selectedFilterOptions.length == 0) {
            this.filteredListOfControls = this.data.controls;
        }
        this.dialogRef.close({
            closeType: "apply",
            filteredControls: this.filteredListOfControls,
            selectedFilterOptions: this.selectedFilterOptions,
        });
    }

    clearSelectedControlFilters_0564(): void {
        this.selectedFilterOptions = [];
        this.displayFilterObjects = this.filterService.getFilterOptionsControl_0477(this.data.controls);
        let keys = Object.keys(this.displayFilterObjects);
        for (let key of keys) {
            this.sortFilterOptionsControl_0562(key, true);
        }
    }

    closeControlFilterDialog_0118(dialogRef: MatDialogRef<ControlRegisterFilterDialogComponent>): void {
        dialogRef.close({ closeType: "cancel" });
    }

    sortFilterOptionsControl_0562(type: any, switchFlag: boolean): void {
        if (switchFlag) {
            this.filterListIsSorted[type] = !this.filterListIsSorted[type];
        }
        if (!this.filterListIsSorted[type]) {
            this.displayFilterObjects[type] = <Array<any>>this.displayFilterObjects[type].sort((s1: any, s2: any) => {
                if (s1.filterValue < s2.filterValue) {
                    return 1;
                }
                else if (s1.filterValue > s2.filterValue) {
                    return -1;
                }
                else {
                    return 0;
                }
            });
        }
        else {
            this.displayFilterObjects[type] = <Array<any>>this.displayFilterObjects[type].sort((s1: any, s2: any) => {
                if (s1.filterValue > s2.filterValue) {
                    return 1;
                }
                else if (s1.filterValue < s2.filterValue) {
                    return -1;
                }
                else {
                    return 0;
                }
            });
        }
        this.filterListIsSorted[type] = !this.filterListIsSorted[type];
        this.checkIfClearALLChangeControlRegisterFilterDialog_XXXX();
        this.checkIfApplyChangeControlRegisterFilterDialog_XXXX()
    }
}