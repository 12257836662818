import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { APIService, datasetGet, DataToGet, GlobalService, IrlcoreAuthService } from '@irlca/irlcore';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ArmService } from '../../../arm.service';
import { HeatmapService } from '../../../heatmap.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DataSource from 'devextreme/data/data_source';
import { cloneDeep } from 'lodash';
import { faArrowAltLeft, faArrowAltRight } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-dashboard-heatmap',
  templateUrl: './dashboard-heatmap.component.html',
  styleUrls: ['./dashboard-heatmap.component.scss']
})
export class DashboardHeatmapComponent implements OnInit {

  userData: any;
  riskAssessments: any[] = [];
  selectedRiskAssessment: any;
  riskAssessmentCounter = 0;
  risksList: any[] = [];
  filteredRisksList: any[] = [];
  selectedRisk: any;
  elementID: string = 'riskheatmap';
  risksToDisplay: any = [];
  width: any;
  height: any;
  // today!: Date;
  dashboardForm!: FormGroup;
  filteredOptions!: Observable<any[]>;
  dataSource!: DataSource;
  selectedHeatmapView: string = "Risk Treatment Plan";
  riskScoreType: string = 'RPN';

  // icons
  arrowAltRight = faArrowAltRight
  arrowAltLeft = faArrowAltLeft
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid!: DxDataGridComponent;

  constructor(
    public auth: IrlcoreAuthService,
    private apiService: APIService,
    public globalService: GlobalService,
    private fb: FormBuilder,
    public armService: ArmService,
    public heatmapService: HeatmapService
  ) { }

  // DOC Comment: Can be moved to ngAfterViewInit
  @HostListener('window:resize')
  onResize() {
    this.resizeHeatMapChart();
    setTimeout(() => {
      this.heatmapService.drawHeatmap(this.selectedRiskAssessment.riskAssessmentTypeID);
    }, 200);
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: any) {
    if ((e.clientX >= this.heatmapService.xCanvas) && (e.clientX <= this.heatmapService.xCanvas + this.heatmapService.heatmapContainerWidth) && e.clientY >= this.heatmapService.yCanvas && (e.clientY <= this.heatmapService.yCanvas + this.heatmapService.heatmapContainerHeight)) {
      this.heatmapService.tooltipPopulation(e, this.filteredRisksList);
    }
  }

  async ngOnInit() {
    this.heatmapService.dropdownDisplayValue = this.selectedHeatmapView;
    this.heatmapService.dashboardView = true;
    // this.today = new Date(Date.now());
    await this.getTokenData();
    await this.prepareDataForGetRiskAssessment_0190();
    this.heatmapService.canvasContext = <HTMLCanvasElement>document.getElementById(this.elementID);
    this.heatmapService.context = this.heatmapService.canvasContext.getContext('2d');
    this.createDashboardForm();
    this.filteredOptions = this.dashboardForm.get('riskAssessmentTitle')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  ngOnDestroy() {
    this.heatmapService.initialRiskClick = false;
    this.heatmapService.dashboardView = false;
  }

  resizeHeatMapChart() {
    this.heatmapService.heatmapContainerWidth = (document.getElementById('heatmap-risk-assessment-container')!.offsetWidth) * 0.5;
    let appcontainerheight = document.getElementById('app-container')!.offsetHeight;
    // (xCanvas, yCanvas) is the top left point of the heatmap, including the heatmap legends
    let navbarWidth = document.getElementById('app-nav')!.offsetWidth;
    let riskDashboardXMargin = (document.getElementById('risk-dashboard-option')!.offsetWidth) * 0.005;
    this.heatmapService.xCanvas = navbarWidth + riskDashboardXMargin;
    let appHeader = document.getElementById('app-header')!.offsetHeight;
    let riskDashboardYMargin = appcontainerheight * 0.005;
    let heatmapFilter = document.getElementById('heatmap-filter')!.offsetHeight;
    let heatmapRiskAssessmentContainer = document.getElementById('heatmap-risk-assessment-container')!.offsetHeight
    this.heatmapService.yCanvas = (appHeader + riskDashboardYMargin + heatmapFilter + heatmapRiskAssessmentContainer);
    this.heatmapService.heatmapContainerHeight = (appcontainerheight - this.heatmapService.yCanvas) * .95;
    var canvas = <HTMLCanvasElement>document.getElementById(this.elementID);
    canvas.width = this.heatmapService.heatmapContainerWidth;
    canvas.height = this.heatmapService.heatmapContainerHeight;
    this.heatmapService.heatmapWidth = this.heatmapService.heatmapContainerWidth * 0.9;
    this.heatmapService.heatmapHeight = this.heatmapService.heatmapContainerHeight * 0.9;
    this.heatmapService.riskPointRadius = this.heatmapService.heatmapHeight * .025;
    this.heatmapService.heatmapCellWidth = this.heatmapService.heatmapWidth * .20;
  }

  createDashboardForm() {
    if (this.selectedRiskAssessment) {
      this.dashboardForm = this.fb.group({
        riskAssessmentREF: new FormControl(this.selectedRiskAssessment.riskAssessmentREF),
        riskAssessmentTitle: new FormControl(this.selectedRiskAssessment.riskAssessmentTitle),
        riskQuestion: new FormControl(this.selectedRiskAssessment.riskQuestion),
      });
    } else {
      this.dashboardForm = this.fb.group({
        riskAssessmentREF: new FormControl(''),
        riskAssessmentTitle: new FormControl(''),
        riskQuestion: new FormControl(''),
      });
    }
  }

  async getTokenData() {
    this.userData = await this.auth.getUserTokenData_0437();
  }

  async prepareDataForGetRiskAssessment_0190() {
    let datasets: datasetGet[] = [];
    let riskAssessment: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        userID: this.userData.userID
      },
      storedProcedure: "DAMRiskAssessment"
    };
    let riskDataset: datasetGet = {
      parameters: {
        iD: null,
        riskAssessmentID: null,
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID
      },
      storedProcedure: "RiskHeatmap"
    };

    datasets.push(riskAssessment, riskDataset);
    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Dashboard Risk Assessment", datasets);
    await this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      this.riskAssessments = fromDB[0];
      this.riskAssessments.sort((a, b) => a.iD - b.iD);
      this.selectedRiskAssessment = this.riskAssessments[this.riskAssessmentCounter];
      this.risksList = fromDB[1];
   
      // for (let j = 0; j < this.risksList.length; j++) {
      // if (this.risksList[j].totalActions > 0) {
      //   this.calcTimelineData(this.risksList[j]);
      // }
      // }     

      if (this.selectedRiskAssessment.riskAssessmentTypeID === 6 || this.selectedRiskAssessment.riskAssessmentTypeID === 1) {
        this.riskScoreType = 'RS';
      } else {
        this.riskScoreType = 'RPN';

      }
      this.filteredRisksList = this.risksList.filter(x => x.riskAssessmentID === this.selectedRiskAssessment.iD);
      for (let i = 0; i < this.filteredRisksList.length; i++) {
        this.filteredRisksList[i]['displayID'] = this.heatmapService.getRiskIdLabel_XXXX(i);
      }    
      this.selectedRisk = this.filteredRisksList[0];
      this.heatmapService.risksToDisplay = this.filteredRisksList;
      setTimeout(() => {
        this.dataSource = new DataSource({
          store: {
            type: "array",
            key: "iD",
            data: this.filteredRisksList
          }
        });
      });
      this.onResize();
      this.createDashboardForm();
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Risk Assessment");
      window.alert(userErrMsg)
    });
  }

  //DF Comment: Does this filter for the search functionality to select a Risk Assessment
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.riskAssessments.filter(option => option.riskAssessmentTitle.toLowerCase().includes(filterValue));
  }

  onSelectTitle(riskAssessmentTitle: any) {
    this.riskAssessmentCounter = this.riskAssessments.findIndex(riskAssessment => riskAssessment.riskAssessmentTitle === riskAssessmentTitle)

    this.selectedRiskAssessment = this.riskAssessments[this.riskAssessmentCounter];

    this.dashboardForm.controls['riskAssessmentREF'].setValue(this.selectedRiskAssessment.riskAssessmentREF);
    this.dashboardForm.controls['riskAssessmentTitle'].setValue(this.selectedRiskAssessment.riskAssessmentTitle);
    this.dashboardForm.controls['riskQuestion'].setValue(this.selectedRiskAssessment.riskQuestion)

    if (this.selectedRiskAssessment.riskAssessmentTypeID === 6 || this.selectedRiskAssessment.riskAssessmentTypeID === 1) {
      this.riskScoreType = 'RS';
    } else {
      this.riskScoreType = 'RPN';

    }
    this.filteredRisksList = this.risksList.filter(x => x.riskAssessmentID === this.selectedRiskAssessment.iD);
    for (let i = 0; i < this.filteredRisksList.length; i++) {
      this.filteredRisksList[i]['displayID'] = this.heatmapService.getRiskIdLabel_XXXX(i);
    }

    this.dataSource = new DataSource({
      store: {
        type: "array",
        key: "iD",
        data: this.filteredRisksList
      }
    });

    this.selectedRisk = this.filteredRisksList[0];
    this.heatmapService.risksToDisplay = this.filteredRisksList;
    this.heatmapService.drawHeatmap(this.selectedRiskAssessment.riskAssessmentTypeID);
  }

  calcTimelineData(risk: any) {
    //   //DF Comment: For future development Nov 2021
    //   var totalDays = (moment(risk.earliestCreationDate)).diff(moment(risk.latestDueDate), 'days');
    //   var todayDays = (moment(risk.earliestCreationDate)).diff(moment(this.today), 'days');
    //   let percent = (todayDays / totalDays) * 100;
    //   if (risk.totalActions > 0) {
    //     if (risk.overdueActions > 0) {
    //       if ((moment(risk.latestDueDate).isBefore(moment(this.today))) && risk.riskStatus === 'Reduction WIP') {
    //         risk.status = "Overdue";
    //         risk.percentage = 100;
    //       } else {
    //         risk.status = "Overdue";
    //         risk.percentage = 100;
    //       }
    //     } else {
    //       if ((moment(risk.latestDueDate).isAfter(moment(this.today)))) {
    //         risk.status = "In Progress";
    //         risk.percentage = percent;
    //       } else {
    //         risk.status = "Complete";
    //         risk.percentage = 100;

    //       }
    //     }
    //   }
  }

  changeRiskAssessment_XXXX(direction: string) {
    this.filteredRisksList.forEach(x => {
      x.clicked = false;
    })
    if (direction === 'up') {

      if (this.riskAssessmentCounter === this.riskAssessments.length - 1) {
        this.riskAssessmentCounter = 0;
      } else {
        this.riskAssessmentCounter++;
      }
    } if (direction === 'down') {

      if (this.riskAssessmentCounter === 0) {
        this.riskAssessmentCounter = this.riskAssessments.length - 1;
      } else {
        this.riskAssessmentCounter--;
      }
    }
    this.heatmapService.initialRiskClick = false;
    this.selectedRiskAssessment = this.riskAssessments[this.riskAssessmentCounter];
    this.filteredRisksList = this.risksList.filter(x => x.riskAssessmentID === this.selectedRiskAssessment.iD);
    for (let i = 0; i < this.filteredRisksList.length; i++) {
      this.filteredRisksList[i]['displayID'] = this.heatmapService.getRiskIdLabel_XXXX(i);
    }
    
    this.dataSource = new DataSource({
      store: {
        type: "array",
        key: "iD",
        data: this.filteredRisksList
      }
    });
    if (this.selectedRiskAssessment.riskAssessmentTypeID === 6 || this.selectedRiskAssessment.riskAssessmentTypeID === 1) {
      this.riskScoreType = 'RS';
    } else {
      this.riskScoreType = 'RPN';

    }
    this.heatmapService.risksToDisplay = [];
    this.heatmapService.risksToDisplay = this.filteredRisksList;
    this.dataGrid.instance.clearSorting();
    this.heatmapService.tooltipVisible = false;
    this.heatmapService.selectedRiskTooltip = [];
    this.dashboardForm.controls['riskAssessmentREF'].setValue(this.selectedRiskAssessment.riskAssessmentREF);
    this.dashboardForm.controls['riskQuestion'].setValue(this.selectedRiskAssessment.riskQuestion);
    this.dashboardForm.controls['riskAssessmentTitle'].setValue(this.selectedRiskAssessment.riskAssessmentTitle);
    this.heatmapService.collisionPointsArray = [];
    this.heatmapService.drawHeatmap(this.selectedRiskAssessment.riskAssessmentTypeID);
  }

  lineFunction() {
    //   //DF Comment: For future development Nov 2021
    //   let a1 = (y2 - y1);
    //   let b1 = (x1 - x2); // negative

    //   let v = (x2 - x1) * (x2 - x1);
    //   let t = (y2 - y1) * (y2 - y1);
    //   let d = Math.sqrt(v + t);
    //   var START_ANGLE = 0;
    //   var END_ANGLE = 2 * Math.PI;

    //   let percentageCompletePlanned = (risks[i].percentage / 100);

    //   let radiusPlanned = d * percentageCompletePlanned;


    //   let slope = (y2 - y1) / (x2 - x1);
    //   if (slope < 0) {
    //     slope = -(slope)
    //   }
    //   if (risks[i].initialRPN < risks[i].residualRPN) {
    //     slope = -(slope)
    //   }
    //   let c1 = -(slope * x1) + y1;
    //   let ySquaredACoef = slope * slope;
    //   let ySquaredBCoef = slope * c1 * 2;
    //   let ySquaredCCoef = c1 * c1;

    //   let BigA = x2 * 2;
    //   let BigB = y2 * 2;
    //   let BigCPlanned = ((x2 * x2) + (y2 * y2)) - (radiusPlanned * radiusPlanned);

    //   let xCoefForCircle = -BigA;
    //   let yCoefForCircle = -BigB;
    //   let cCoefForCirclePlanned = BigCPlanned;
    //   // let cCoefForCircleActual = BigCActual;
    //   //calculate intersection between line and circle 
    //   let a = 1 + ySquaredACoef;
    //   let b = ySquaredBCoef + xCoefForCircle + (yCoefForCircle * slope);
    //   let cPlanned = cCoefForCirclePlanned + ySquaredCCoef + (yCoefForCircle * c1);
    //   // let cActual = cCoefForCircleActual + ySquaredCCoef + (yCoefForCircle * c1);
    //   // This has a bug in it 
    //   let rootx1Planned = (-b + Math.sqrt((b * b) - (4 * a * cPlanned))) / (2 * a);
    //   let rootx2Planned = (-b - Math.sqrt((b * b) - (4 * a * cPlanned))) / (2 * a);

    //   let rooty1Planned = (slope * rootx1Planned) + c1;
    //   let rooty2Planned = (slope * rootx2Planned) + c1;

    //   let x3;
    //   let y3;
    //   let x4;
    //   //let y4;
    //   if (rootx1Planned < x2) {
    //     x3 = rootx1Planned;
    //     y3 = rooty1Planned;
    //   } else {
    //     x3 = rootx2Planned;
    //     y3 = rooty2Planned;
    //   }

  }

  selectRow(event: any) {
    let risk: any = [];
    if (event.currentDeselectedRowKeys.length > 1) {
      this.heatmapService.risksToDisplay = cloneDeep(this.filteredRisksList);
      this.heatmapService.initialRiskClick = false;
      this.heatmapService.drawHeatmap(this.selectedRiskAssessment.riskAssessmentTypeID);
    } else if (event.currentSelectedRowKeys.length > 0) {
      risk = this.filteredRisksList.filter(risk => risk.iD === event.currentSelectedRowKeys[0]);
      this.heatmapService.selectRisksToDisplay(risk[0], this.filteredRisksList);
    } else if (event.currentDeselectedRowKeys.length > 0) {
      risk = this.filteredRisksList.filter(risk => risk.iD === event.currentDeselectedRowKeys[0]);
      this.heatmapService.selectRisksToDisplay(risk[0], this.filteredRisksList);
    }
  }

  changeHeatmapView() {
    this.heatmapService.dropdownDisplayValue = this.selectedHeatmapView;
    this.onResize();
  }

  onCellPreparedEliminatedRisk(e: any) {
    if (e.rowType == "data" && e.data.riskDecision === "Eliminate" && e.data.riskStatus === "Managed") {
      e.cellElement.css("font-style", "italic");
      e.cellElement.css("color", "#c9e0ff");
    }
  }
}