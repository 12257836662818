import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { APIService, datasetGet, DataToGet, GlobalService, IrlcoreAuthService } from '@irlca/irlcore';
import { ArmService } from '../../arm.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  // Component Variables
  allRiskDataDB: any = {};
  filteredRisks: any = {};
  riskCategories: any = [];
  filterConditions: any = {
    categories: [],
    entities: [],
    impactedFunctionalAreas: [],
    impactedBusinessProcesses: [],
    availableYears: []
  }
  selectedFilter: any = {};


  constructor(
    public globalService: GlobalService,
    public router: Router,
    private apiService: APIService,
    public auth: IrlcoreAuthService,
    private armService: ArmService) {
  }

  ngOnInit() {
    this.prepareDataForGetRiskProfileGraphs_0190();
  }

  async prepareDataForGetRiskProfileGraphs_0190() {
    let datasets: datasetGet[] = [];

    let riskProfile: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        categoryID: null,
        startDate: null,
        endDate: null,
        riskAssessmentID: null
      },
      storedProcedure: "RiskProfileGraphs"
    };

    let sodCategoryDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entity: this.globalService.entityID,
        sodType: 'Severity'
      },
      storedProcedure: "SODCategory"
    };

    datasets.push(riskProfile, sodCategoryDataset);

    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Dashboard", datasets);
    await this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      this.allRiskDataDB = fromDB[0];
      // Change date format to allow access functions such as getFullYear()
      for (let i = 0; i < this.allRiskDataDB.length; i++) {
        this.allRiskDataDB[i].creationDate = new Date(this.allRiskDataDB[i].creationDate);
        this.allRiskDataDB[i].approvedDate = new Date(this.allRiskDataDB[i].approvedDate);
        this.allRiskDataDB[i].managedDate = new Date(this.allRiskDataDB[i].managedDate);
        this.allRiskDataDB[i].treatmentDecidedDate = new Date(this.allRiskDataDB[i].treatmentDecidedDate);
      };     
      this.riskCategories = fromDB[1];
      this.updateDashboard()
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Risk Profile");
      window.alert(userErrMsg)
    });
  }

  updateDashboard() {
    let filteredRiskData = this.armService.filterRiskDashboardDataset(this.selectedFilter, this.allRiskDataDB);
    this.filteredRisks = filteredRiskData.filteredRisks;
    this.filterConditions = filteredRiskData.filterConditions;
  }

}
