import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { APIService, datasetGet, DataToGet, GlobalService } from '@irlca/irlcore';
import { cloneDeep } from 'lodash';
import { ArmService } from '../../../arm.service';

@Component({
  selector: 'app-sod-rating-dialog',
  templateUrl: './sod-rating-dialog.component.html',
  styleUrls: ['./sod-rating-dialog.component.scss']
})
export class SODRatingDialogComponent implements OnInit {

  failureEffectForm!: FormGroup;
  riskCategories: any[] = [];
  failureCauseFormOccurrence!: FormGroup;
  failureCauseFormDetectability!: FormGroup;
  occurrenceCategories: any[] = [];
  detectabilityCategories: any[] = [];

  SODRatingDataSourceDisplayedColumns: string[] = ["SODRating", "SODClassification", "SODDescription"];
  selectedSODRatingOccurrence: any = {};
  selectedSODRatingDetectability: any = {};
  selectedSODRatingSeverity: any = {};
  SODRatings: any[] = [];
  SODRatingDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  severityCategoryRatings: any[] = [];
  occurrenceCategoryRatings: any[] = [];
  detectabilityCategoryRatings: any[] = [];
  entryID: number = -1;
  failureEffectEntryID: number = -1;
  selectedTab!: string;
  selectedSODRatingOccurrenceUpdated: boolean = false;
  selectedSODRatingDetectabilityUpdated: boolean = false;
  selectedSODRatingSeverityUpdated: boolean = false;

  selectedSODRatingUpdated: boolean = false;
  severityCategoryDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  severityCategoryDataSourceDisplayedColumns: string[] = ["severityRating"];
  detectabilityCategoryDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  detectabilityCategoryDataSourceDisplayedColumns: string[] = ["detectabilityRating"];
  occurrenceCategoryDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  occurrenceCategoryDataSourceDisplayedColumns: string[] = ["occurrenceRating"];
  allRiskCategories: any[] = [];

  selectedSeverityCard_initial!: number;
  selectedOccurrenceCard_initial!: number;
  selectedDetectabilityCard_initial!: number;

  selectedSeverityCard_residual!: number;
  selectedOccurrenceCard_residual!: number;
  selectedDetectabilityCard_residual!: number;
  selectedCategoryColors: any[] = [];
  categoryColors: any[] = [];
  selectedCategory!: string;
  // icons
  times = faTimes
  constructor(public dialogRef: MatDialogRef<SODRatingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder,
    private apiService: APIService, public globalService: GlobalService,
    public armService: ArmService) { }

  ngOnInit() {

    this.calculatePositionOfSelectedCard(this.data.selectedRisk)
    this.selectedTab = this.data.SODType;

    if (this.data.isInitial) {
      this.failureCauseFormOccurrence = this.createFailureCauseOccurrenceForm_0410();
      this.failureCauseFormDetectability = this.createFailureCauseDetectabilityForm_0410();
      this.failureEffectForm = this.createFailureEffectForm_0410();
    }
    else {
      this.failureCauseFormOccurrence = this.createResidualFailureCauseOccurrenceForm_0410();
      this.failureCauseFormDetectability = this.createResidualFailureCauseDetectabilityForm_0410();
      this.failureEffectForm = this.createResidualFailureEffectForm_0410();

    }

    this.prepareDialogDetails_XXXX();
    this.prepareDataForGetFailureCauseDialog_0190();
    this.prepareDataForGetFailureEffectDialog_0190();
  }

  calculatePositionOfSelectedCard(selectedRisk: any) {
    this.selectedSeverityCard_initial = selectedRisk.severityID;
    this.selectedOccurrenceCard_initial = selectedRisk.occurrenceID;
    this.selectedDetectabilityCard_initial = selectedRisk.detectabilityID;

    this.selectedSeverityCard_residual = selectedRisk.residualSeverityID;
    this.selectedOccurrenceCard_residual = selectedRisk.residualOccurrenceID;
    this.selectedDetectabilityCard_residual = selectedRisk.residualDetectabilityID;


  }
  createFailureEffectForm_0410() {
    return this.fb.group({
      failureEffect: [this.data.selectedRisk.failureEffect, Validators.required],
      SODCategoryID: [this.data.selectedRisk.severityCategoryID, Validators.required],
      severityJustification: [this.data.selectedRisk.severityJustification, Validators.required]
    });
  }

  createResidualFailureEffectForm_0410() {
    return this.fb.group({
      failureEffect: [this.data.selectedRisk.failureEffect, Validators.required],
      SODCategoryID: [this.data.selectedRisk.residualSeverityCategoryID, Validators.required],
      severityJustification: [this.data.selectedRisk.residualSeverityJustification, Validators.required]
    });
  }

  createFailureCauseOccurrenceForm_0410() {
    return this.fb.group({
      occurrenceCategoryID: [this.data.selectedRisk.occurrenceCategoryID, Validators.required],
      occurrenceJustification: [this.data.selectedRisk.occurrenceJustification, Validators.required]
    });
  }

  createResidualFailureCauseOccurrenceForm_0410() {
    return this.fb.group({
      occurrenceCategoryID: [this.data.selectedRisk.residualOccurrenceCategoryID, Validators.required],
      occurrenceJustification: [this.data.selectedRisk.residualOccurrenceJustification, Validators.required]
    });
  }

  createFailureCauseDetectabilityForm_0410() {
    return this.fb.group({
      failureCause: [this.data.selectedRisk.failureCause, Validators.required],
      detectabilityCategoryID: [this.data.selectedRisk.detectabilityCategoryID, Validators.required],
      detectabilityJustification: [this.data.selectedRisk.detectabilityJustification, Validators.required]
    });
  }

  createResidualFailureCauseDetectabilityForm_0410() {
    return this.fb.group({
      failureCause: [this.data.selectedRisk.failureCause, Validators.required],
      detectabilityCategoryID: [this.data.selectedRisk.residualDetectabilityCategoryID],
      detectabilityJustification: [this.data.selectedRisk.residualDetectabilityJustification]
    });
  }

  prepareDataForGetFailureEffectDialog_0190() {
    let datasets: datasetGet[] = [];

    let sodCategoryDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entity: this.globalService.entityID,
        sodType: 'Severity'
      },
      storedProcedure: "SODCategory"
    };

    let sodRatingDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entity: this.globalService.entityID,
        SODCategoryID: null
      },
      storedProcedure: "SODDescription"
    };
    let sodCategoryDescriptionDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        sodType: 'Severity'
      },
      storedProcedure: "SODCategoryDescription"
    }

    datasets.push(sodCategoryDataset, sodRatingDataset, sodCategoryDescriptionDataset);
    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Failure Effect", datasets);

    this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      this.riskCategories = fromDB[0];
      this.SODRatings = fromDB[1];
      this.severityCategoryRatings = fromDB[2];
      this.allRiskCategories = this.allRiskCategories.concat(fromDB[0]);
      this.filterDisplay();
      this.setRatingTable_XXXX(this.data.selectedRisk.severityCategoryID);
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Failure Effect Dialog Data");
      window.alert(userErrMsg)
    });
  }

  filterDisplay() {
    if (this.data.isInitial) {
      this.selectedCategory = this.data.selectedRisk.severityCategoryName;
    } else {
      this.selectedCategory = this.data.selectedRisk.residualSeverityCategoryName;
    }

    for (let a = 0; a < this.riskCategories.length; a++) {
      this.severityCategoryDataSourceDisplayedColumns.push(this.riskCategories[a].SODCategory)
    }
    let displayArray: any[] = [];
    const uniqueRatings = [... new Set(this.SODRatings.map(data => data.SODRating))];
    for (let i = 0; i < uniqueRatings.length; i++) {
      let obj: any = {};
      obj['rating'] = uniqueRatings[i];
      for (let j = 0; j < this.severityCategoryRatings.length; j++) {
        if (uniqueRatings[i] == this.severityCategoryRatings[j].SODRating) {
          obj['classificationColor'] = this.severityCategoryRatings[j].SODClassificationColour;
          obj['classification'] = this.severityCategoryRatings[j].SODClassification;
          obj['classificationTextColor'] = this.severityCategoryRatings[j].SODClassificationTextColour;
          obj[this.severityCategoryRatings[j].SODCategory + 'Classification'] = this.severityCategoryRatings[j].SODClassification;
          obj[this.severityCategoryRatings[j].SODCategory + 'ClassificationColor'] = this.severityCategoryRatings[j].SODClassificationColour;
          obj[this.severityCategoryRatings[j].SODCategory + 'ClassificationTextColor'] = this.severityCategoryRatings[j].SODClassificationTextColour;
          obj[this.severityCategoryRatings[j].SODCategory + 'Description'] = this.severityCategoryRatings[j].SODDescription;
          obj[this.severityCategoryRatings[j].SODCategory + 'ID'] = this.severityCategoryRatings[j].SODRatingID;
          obj[this.severityCategoryRatings[j].SODCategory + 'SODRatingID'] = this.severityCategoryRatings[j].SODRatingID;

        }
      }
      displayArray.push(obj);
    }

    const uniqueSevCategories = [... new Set(this.severityCategoryRatings.map(data => data.SODCategory))];


    if (this.data.SODType == "severity") {
      for (let k = 0; k < uniqueSevCategories.length; k++) {
        for (let l = 0; l < this.severityCategoryRatings.length; l++) {
          if (uniqueSevCategories[k] == this.severityCategoryRatings[l].SODCategory) {
            this.categoryColors.splice(k, 0, {
              'category': uniqueSevCategories[k],
              'rating': this.severityCategoryRatings[l].SODRating,
              'classificationColor': this.severityCategoryRatings[l].SODClassificationColour,
              'classificationTextColor': this.severityCategoryRatings[l].SODClassificationTextColour,
              'classification': this.severityCategoryRatings[l].SODClassification
            })
          }
        }
      }
    }
    this.severityCategoryDataSource.data = displayArray;
  }

  prepareDataForGetFailureCauseDialog_0190() {
    let datasets: datasetGet[] = [];

    let occurrenceCategoryDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entity: this.globalService.entityID,
        sodType: 'Occurrence'
      },
      storedProcedure: "SODCategory"
    };

    let detectabilityCategoryDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entity: this.globalService.entityID,
        sodType: 'Detectability'
      },
      storedProcedure: "SODCategory"
    };

    let sodRatingDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entity: this.globalService.entityID,
        SODCategoryID: null
      },
      storedProcedure: "SODDescription"
    };

    let occurrenceCategoryDescriptionDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        sodType: 'Occurrence'
      },
      storedProcedure: "SODCategoryDescription"
    }

    let detectabilityCategoryDescriptionDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        sodType: 'Detectability'
      },
      storedProcedure: "SODCategoryDescription"
    }

    datasets.push(occurrenceCategoryDataset, detectabilityCategoryDataset, sodRatingDataset, occurrenceCategoryDescriptionDataset, detectabilityCategoryDescriptionDataset);
    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Failure Cause", datasets);

    this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      this.occurrenceCategories = fromDB[0];
      this.detectabilityCategories = fromDB[1];
      this.SODRatings = fromDB[2];
      this.occurrenceCategoryRatings = fromDB[3];
      this.detectabilityCategoryRatings = fromDB[4];
      this.allRiskCategories = this.allRiskCategories.concat(fromDB[0], fromDB[1]);
      this.filterOccurrenceDetectability();
      this.setRatingTable_XXXX(this.data.selectedRisk.occurrenceCategoryID);
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Failure Cause Dialog Data");
      window.alert(userErrMsg)
    });
  }

  filterOccurrenceDetectability() {
    if (this.data.isInitial) {
      if (this.data.SODType == "occurrence") {
        this.selectedCategory = this.data.selectedRisk.occurrenceCategoryName;
      } else if (this.data.SODType == "detectability") {
        this.selectedCategory = this.data.selectedRisk.detectabilityCategoryName;
      }
    } else {
      if (this.data.SODType == "occurrence") {
        this.selectedCategory = this.data.selectedRisk.residualOccurrenceCategoryName;
      } else if (this.data.SODType == "detectability") {
        this.selectedCategory = this.data.selectedRisk.residualDetectabilityCategoryName;
      }
    }
    for (let a = 0; a < this.occurrenceCategories.length; a++) {
      this.occurrenceCategoryDataSourceDisplayedColumns.push(this.occurrenceCategories[a].SODCategory)
    }
    for (let a = 0; a < this.detectabilityCategories.length; a++) {
      this.detectabilityCategoryDataSourceDisplayedColumns.push(this.detectabilityCategories[a].SODCategory)
    }

    let occurrenceDisplayArray: any[] = [];
    let detectabilityDisplayArray: any[] = [];
    const uniqueRatings = [... new Set(this.SODRatings.map(data => data.SODRating))];

    for (let i = 0; i < uniqueRatings.length; i++) {
      let occObj: any = {};
      let detObj: any = {};
      occObj['rating'] = uniqueRatings[i];
      detObj['rating'] = uniqueRatings[i];
      for (let j = 0; j < this.occurrenceCategoryRatings.length; j++) {
        if (uniqueRatings[i] == this.occurrenceCategoryRatings[j].SODRating) {
          occObj['classificationColor'] = this.occurrenceCategoryRatings[j].SODClassificationColour;
          occObj['classification'] = this.occurrenceCategoryRatings[j].SODClassification;
          occObj['classificationTextColor'] = this.occurrenceCategoryRatings[j].SODClassificationTextColour;
          occObj[this.occurrenceCategoryRatings[j].SODCategory + 'Classification'] = this.occurrenceCategoryRatings[j].SODClassification;
          occObj[this.occurrenceCategoryRatings[j].SODCategory + 'ClassificationColor'] = this.occurrenceCategoryRatings[j].SODClassificationColour;
          occObj[this.occurrenceCategoryRatings[j].SODCategory + 'ClassificationTextColor'] = this.occurrenceCategoryRatings[j].SODClassificationTextColour;
          occObj[this.occurrenceCategoryRatings[j].SODCategory + 'Description'] = this.occurrenceCategoryRatings[j].SODDescription;
          occObj[this.occurrenceCategoryRatings[j].SODCategory + 'ID'] = this.occurrenceCategoryRatings[j].SODRatingID;
          occObj[this.occurrenceCategoryRatings[j].SODCategory + 'SODRatingID'] = this.occurrenceCategoryRatings[j].SODRatingID;
        }
      }

      occurrenceDisplayArray.push(occObj);
      for (let j = 0; j < this.detectabilityCategoryRatings.length; j++) {
        if (uniqueRatings[i] == this.detectabilityCategoryRatings[j].SODRating) {
          detObj['classificationColor'] = this.detectabilityCategoryRatings[j].SODClassificationColour;
          detObj['classification'] = this.detectabilityCategoryRatings[j].SODClassification;
          detObj['classificationTextColor'] = this.detectabilityCategoryRatings[j].SODClassificationTextColour;
          detObj[this.detectabilityCategoryRatings[j].SODCategory + 'Classification'] = this.detectabilityCategoryRatings[j].SODClassification;
          detObj[this.detectabilityCategoryRatings[j].SODCategory + 'ClassificationColor'] = this.detectabilityCategoryRatings[j].SODClassificationColour;
          detObj[this.detectabilityCategoryRatings[j].SODCategory + 'ClassificationTextColor'] = this.detectabilityCategoryRatings[j].SODClassificationTextColor;
          detObj[this.detectabilityCategoryRatings[j].SODCategory + 'Description'] = this.detectabilityCategoryRatings[j].SODDescription;
          detObj[this.detectabilityCategoryRatings[j].SODCategory + 'ID'] = this.detectabilityCategoryRatings[j].SODRatingID;
          detObj[this.detectabilityCategoryRatings[j].SODCategory + 'SODRatingID'] = this.detectabilityCategoryRatings[j].SODRatingID;
        }
      }
      detectabilityDisplayArray.push(detObj);
    }


    if (this.data.SODType == "occurrence") {
      const uniqueOccCategories = [... new Set(this.occurrenceCategoryRatings.map(data => data.SODCategory))];
      for (let k = 0; k < uniqueOccCategories.length; k++) {
        for (let l = 0; l < this.occurrenceCategoryRatings.length; l++) {
          if (uniqueOccCategories[k] == this.occurrenceCategoryRatings[l].SODCategory) {
            this.categoryColors.splice(k, 0, {
              'category': uniqueOccCategories[k],
              'rating': this.occurrenceCategoryRatings[l].SODRating,
              'classificationColor': this.occurrenceCategoryRatings[l].SODClassificationColour,
              'classificationTextColor': this.occurrenceCategoryRatings[l].SODClassificationTextColour,
              'classification': this.occurrenceCategoryRatings[l].SODClassification
            })
          }
        }
      }
    }

    if (this.data.SODType == "detectability") {
      const uniqueDetCategories = [... new Set(this.detectabilityCategoryRatings.map(data => data.SODCategory))];
      for (let k = 0; k < uniqueDetCategories.length; k++) {
        for (let l = 0; l < this.detectabilityCategoryRatings.length; l++) {
          if (uniqueDetCategories[k] == this.detectabilityCategoryRatings[l].SODCategory) {
            this.categoryColors.splice(k, 0, {
              'category': uniqueDetCategories[k],
              'rating': this.detectabilityCategoryRatings[l].SODRating,
              'classificationColor': this.detectabilityCategoryRatings[l].SODClassificationColour,
              'classificationTextColor': this.detectabilityCategoryRatings[l].SODClassificationTextColour,
              'classification': this.detectabilityCategoryRatings[l].SODClassification
            })
          }
        }
      }
    }


    this.occurrenceCategoryDataSource.data = occurrenceDisplayArray;
    this.detectabilityCategoryDataSource.data = detectabilityDisplayArray;

    this.setDialogColors(this.selectedCategory);

  }

  setRatingTable_XXXX(SODCategoryID: number) {
    this.SODRatingDataSource.data = [];
    if (SODCategoryID)
      this.SODRatingDataSource.data = this.SODRatings.filter(rating => rating.SODCategoryID == SODCategoryID);
  }

  saveFailureCauseDialog_XXXX() {
    let failureCause = {
      iD: this.data.selectedRisk.failureCauseID,
      clientID: this.globalService.clientID,
      entityID: this.globalService.entityID,
      failureModeID: this.data.selectedRisk.failureModeID,
      failureCause: this.failureCauseFormDetectability.value.failureCause,
      occurrenceID: this.selectedSODRatingOccurrence.iD,
      occurrenceName: this.selectedSODRatingOccurrence.SODDescription,
      occurrenceJustification: this.failureCauseFormOccurrence.value.occurrenceJustification,
      detectabilityID: this.selectedSODRatingDetectability.iD,
      detectabilityName: this.selectedSODRatingDetectability.SODDescription,
      detectabilityJustification: this.failureCauseFormDetectability.value.detectabilityJustification,
      residualOccurrenceID: this.data.selectedRisk.residualOccurrenceID,
      residualOccurrence: this.data.selectedRisk.residualOccurrence,
      residualOccurrenceJustification: this.data.selectedRisk.residualOccurrenceJustification,
      residualDetectabilityID: this.data.selectedRisk.residualDetectabilityID,
      residualDetectability: this.data.selectedRisk.residualDetectability,
      residualDetectabilityJustification: this.data.selectedRisk.residualDetectabilityJustification,
      displayedFMEA: true,
      statementType: this.data.statementType
    }

    let failureEffect = {
      iD: this.data.selectedRisk.failureEffectID,
      clientID: this.globalService.clientID,
      entityID: this.globalService.entityID,
      failureModeID: this.data.selectedRisk.failureModeID,
      failureEffect: this.failureEffectForm.value.failureEffect,
      severityID: this.selectedSODRatingSeverity.iD,
      severity: this.selectedSODRatingSeverity.SODRating,
      severityJustification: this.failureEffectForm.value.severityJustification,
      residualSeverityID: this.data.selectedRisk.residualSeverityID,
      residualSeverityJustification: this.data.selectedRisk.residualSeverityJustification,
      displayedFMEA: true,
      statementType: this.data.statementType
    }

    this.dialogRef.close({

      data: {
        failureCause: failureCause,
        selectedOccurrence: this.selectedSODRatingOccurrence,
        selectedDetectability: this.selectedSODRatingDetectability,
        occurrenceCategoryID: this.failureCauseFormOccurrence.value.occurrenceCategoryID,
        occurrenceCategoryName: this.getRiskCategory_0412(this.failureCauseFormOccurrence.value.occurrenceCategoryID),
        detectabilityCategoryID: this.failureCauseFormDetectability.value.detectabilityCategoryID,
        detectabilityCategoryName: this.getRiskCategory_0412(this.failureCauseFormDetectability.value.detectabilityCategoryID),
        severityCategoryID: this.failureEffectForm.value.SODCategoryID,
        severityCategoryName: this.getRiskCategory_0412(this.failureEffectForm.value.SODCategoryID),
        controlEntryID: this.data.controlCauseEntryID,
        statementType: this.data.statementType,
        failureCauseFormUpdated: (this.failureCauseFormOccurrence.dirty || this.failureCauseFormDetectability.dirty
          || (this.selectedSODRatingOccurrenceUpdated && (this.data.isInitial && (this.data.selectedRisk.occurrenceID != this.selectedSODRatingOccurrence.iD)) || (!this.data.isInitial && (this.data.selectedRisk.residualOccurrenceID != this.selectedSODRatingOccurrence.iD)))
          || (this.selectedSODRatingDetectabilityUpdated && (this.data.isInitial && (this.data.selectedRisk.detectabilityID != this.selectedSODRatingDetectability.iD)) || (!this.data.isInitial && (this.data.selectedRisk.residualDetectabilityID != this.selectedSODRatingDetectability.iD)))),
        selectedSODRatingFailureCauseUpdated: ((this.selectedSODRatingOccurrenceUpdated || this.selectedSODRatingDetectabilityUpdated)
          && (this.data.isInitial && (this.data.selectedRisk.occurrence != this.selectedSODRatingOccurrence.SODRating || this.data.selectedRisk.detectability != this.selectedSODRatingDetectability.SODRating)
            || (!this.data.isInitial && (this.data.selectedRisk.residualOccurrence != this.selectedSODRatingOccurrence.SODRating || this.data.selectedRisk.residualDetectability != this.selectedSODRatingDetectability.SODRating)))),
        failureEffect: failureEffect,
        selectedSeverity: this.selectedSODRatingSeverity,
        SODCategoryID: this.failureEffectForm.value.SODCategoryID,
        SODCategory: this.getRiskCategory_0412(this.failureEffectForm.value.SODCategoryID),
        failureEffectFormUpdated: (this.failureEffectForm.dirty || (this.selectedSODRatingUpdated && (this.data.isInitial && (this.data.selectedRisk.severityID != this.selectedSODRatingSeverity.iD)) || (!this.data.isInitial && (this.data.selectedRisk.residualSeverityID != this.selectedSODRatingSeverity.iD)))),
        selectedSODRatingFailureEffectUpdated: this.selectedSODRatingUpdated && (this.data.isInitial && (this.data.selectedRisk.severity != this.selectedSODRatingSeverity.SODRating)
          || (!this.data.isInitial && (this.data.selectedRisk.residualSeverity != this.selectedSODRatingSeverity.SODRating))),
      }
    });
  }


  getRiskCategory_0412(SODCategoryID: string): number {
    let riskCategory = this.SODRatings.filter(riskCategoryObj => riskCategoryObj.iD === SODCategoryID);
    if (riskCategory.length >= 1) {
      return riskCategory[0].SODCategory;
    }
    else
      return -1;
  }

  prepareDialogDetails_XXXX() {
    if (this.data.isInitial) {
      this.selectedSODRatingOccurrence.iD = this.data.selectedRisk.occurrenceID;
      this.selectedSODRatingOccurrence.SODRating = this.data.selectedRisk.occurrence;
      this.selectedSODRatingOccurrence.SODClassification = this.data.selectedRisk.occurrenceClassification;
      this.selectedSODRatingOccurrence.SODClassificationColour = this.data.selectedRisk.occurrenceClassificationColour;
      this.selectedSODRatingOccurrence.SODDescription = this.data.selectedRisk.occurrenceDescription;
      this.selectedSODRatingDetectability.iD = this.data.selectedRisk.detectabilityID;
      this.selectedSODRatingDetectability.SODRating = this.data.selectedRisk.detectability;
      this.selectedSODRatingDetectability.SODClassification = this.data.selectedRisk.detectabilityClassification;
      this.selectedSODRatingDetectability.SODClassificationColour = this.data.selectedRisk.detectabilityClassificationColour;
      this.selectedSODRatingDetectability.SODDescription = this.data.selectedRisk.detectabilityDescription;
      this.selectedSODRatingSeverity.iD = this.data.selectedRisk.severityID;
      this.selectedSODRatingSeverity.SODRating = this.data.selectedRisk.severity;
      this.selectedSODRatingSeverity.SODClassification = this.data.selectedRisk.severityClassification;
      this.selectedSODRatingSeverity.SODClassificationColour = this.data.selectedRisk.severityClassificationColour;
      this.selectedSODRatingSeverity.SODDescription = this.data.selectedRisk.severityDescription;
    } else {
      this.selectedSODRatingOccurrence.iD = this.data.selectedRisk.residualOccurrenceID;
      this.selectedSODRatingOccurrence.SODRating = this.data.selectedRisk.residualOccurrence;
      this.selectedSODRatingOccurrence.SODClassification = this.data.selectedRisk.residualOccurrenceClassification;
      this.selectedSODRatingOccurrence.SODClassificationColour = this.data.selectedRisk.residualOccurrenceClassificationColour;
      this.selectedSODRatingOccurrence.SODDescription = this.data.selectedRisk.residualOccurrenceDescription;
      this.selectedSODRatingDetectability.iD = this.data.selectedRisk.residualDetectabilityID;
      this.selectedSODRatingDetectability.SODRating = this.data.selectedRisk.residualDetectability;
      this.selectedSODRatingDetectability.SODClassification = this.data.selectedRisk.residualDetectabilityClassification;
      this.selectedSODRatingDetectability.SODClassificationColour = this.data.selectedRisk.residualDetectabilityClassificationColour;
      this.selectedSODRatingDetectability.SODDescription = this.data.selectedRisk.residualDetectabilityDescription;
      this.selectedSODRatingSeverity.iD = this.data.selectedRisk.residualSeverityID;
      this.selectedSODRatingSeverity.SODRating = this.data.selectedRisk.residualSeverity;
      this.selectedSODRatingSeverity.SODClassification = this.data.selectedRisk.residualSeverityClassification;
      this.selectedSODRatingSeverity.SODClassificationColour = this.data.selectedRisk.residualSeverityClassificationColour;
      this.selectedSODRatingSeverity.SODDescription = this.data.selectedRisk.residualSeverityDescription;
    }
  }

  failureCauseFormValid_XXXX() {
    if (this.selectedSODRatingOccurrenceUpdated || this.selectedSODRatingSeverityUpdated || this.selectedSODRatingDetectabilityUpdated)
      return false;
    else return true;
  }

  setSelectedCardAndRating(type: string, option: any, id: number, ratingID: number, rating: any, element: any, SODRatingID: number, classification: string, classificationColor: string, category: string, classificationTextColor: string) {
    this.setSelectedCard(type, SODRatingID, id);
    this.setRating(type, option, id, ratingID, rating, element, SODRatingID, classification, classificationColor, category, classificationTextColor);
    this.setDialogColors(category);
  }

  setSelectedCard(type: any, cell: any, column: any) {
    if (this.data.isInitial) {
      if (type == 'severity') {
        this.selectedSeverityCard_initial = cell;
      } else if (type == 'occurrence') {
        this.selectedOccurrenceCard_initial = cell;
      } else if (type == 'detectability') {
        this.selectedDetectabilityCard_initial = cell;
      }
    }
    if (!this.data.isInitial) {
      if (type == 'severity') {
        this.selectedSeverityCard_residual = cell;
      } else if (type == 'occurrence') {
        this.selectedOccurrenceCard_residual = cell;
      } else if (type == 'detectability') {
        this.selectedDetectabilityCard_residual = cell;
      }
    }

  }

  setRating(type: string, option: any, id: number, ratingID: number, rating: any, element: any, SODRatingID: number, classification: string, classificationColor: string, category: string, classificationTextColor: string) {
    if (type == 'severity') {
      this.selectedSODRatingSeverityUpdated = true;
      this.selectedSODRatingUpdated = true;
      this.selectedSODRatingSeverity.SODRating = rating;
      this.selectedSODRatingSeverity.iD = SODRatingID;
      this.selectedSODRatingSeverity.SODClassification = classification;
      this.selectedSODRatingSeverity.SODClassificationColour = classificationColor;
      this.selectedSODRatingSeverity.SODClassificationTextColour = classificationTextColor;

      this.selectedSODRatingSeverity.SODDescription = option;
      this.failureEffectForm.controls['SODCategoryID'].setValue(id);
      if (this.globalService.configurableParameters.sodCategoryAsDescription) {
        this.failureEffectForm.controls['severityJustification'].setValue(option);
      }

    } else if (type == 'occurrence') {
      this.selectedSODRatingOccurrenceUpdated = true;
      this.selectedSODRatingOccurrence.SODRating = rating;
      this.selectedSODRatingOccurrence.iD = SODRatingID;
      this.selectedSODRatingOccurrence.SODClassification = classification;
      this.selectedSODRatingOccurrence.SODClassificationColour = classificationColor;
      this.selectedSODRatingOccurrence.SODClassificationTextColour = classificationTextColor;
      this.selectedSODRatingOccurrence.SODDescription = option;
      this.failureCauseFormOccurrence.controls['occurrenceCategoryID'].setValue(id);
      if (this.globalService.configurableParameters.sodCategoryAsDescription) {
        this.failureCauseFormOccurrence.controls['occurrenceJustification'].setValue(option);
      }

    } else if (type == 'detectability') {
      this.selectedSODRatingDetectabilityUpdated = true;
      this.selectedSODRatingDetectability.SODRating = rating;
      this.selectedSODRatingDetectability.iD = SODRatingID;
      this.selectedSODRatingDetectability.SODClassification = classification;
      this.selectedSODRatingDetectability.SODClassificationColour = classificationColor;
      this.selectedSODRatingDetectability.SODClassificationTextColour = classificationTextColor;
      this.selectedSODRatingDetectability.SODDescription = option;
      this.failureCauseFormDetectability.controls['detectabilityCategoryID'].setValue(id);
      if (this.globalService.configurableParameters.sodCategoryAsDescription) {
        this.failureCauseFormDetectability.controls['detectabilityJustification'].setValue(option);
      }
    }
  }

  setDialogColors(category: string) {
    // DOC: Revisit this function. Set all colours to grey on initialisation if category does not exist. Initial colours are currently being set elsewhere before this function is called.
    // Font colour should also be set

    if (category) {
      this.selectedCategory = category;
      let filteredColors = this.categoryColors.filter((item: any) => item.category == category);

      filteredColors.sort((a, b) => {
        if (a.rating > b.rating) { return 1 }
        if (a.rating < b.rating) { return -1 }
        return 0;
      });

      if (this.data.SODType == "severity") {
        for (let i = 0; i < this.severityCategoryDataSource.data.length; i++) {
          this.severityCategoryDataSource.data[i].classificationColor = filteredColors[i].classificationColor;
          this.severityCategoryDataSource.data[i].classification = filteredColors[i].classification;
          this.severityCategoryDataSource.data[i].classificationTextColor = filteredColors[i].classificationTextColor;
        }
      }
      if (this.data.SODType == "occurrence") {
        for (let i = 0; i < this.occurrenceCategoryDataSource.data.length; i++) {
          this.occurrenceCategoryDataSource.data[i].classificationColor = filteredColors[i].classificationColor;
          this.occurrenceCategoryDataSource.data[i].classification = filteredColors[i].classification;
          this.occurrenceCategoryDataSource.data[i].classificationTextColor = filteredColors[i].classificationTextColor;
        }
      }
      if (this.data.SODType == "detectability") {
        for (let i = 0; i < this.detectabilityCategoryDataSource.data.length; i++) {
          this.detectabilityCategoryDataSource.data[i].classificationColor = filteredColors[i].classificationColor;
          this.detectabilityCategoryDataSource.data[i].classification = filteredColors[i].classification;
          this.detectabilityCategoryDataSource.data[i].classificationTextColor = filteredColors[i].classificationTextColor;
        }
      }
    }
  }
}
