import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APIService, dataset, datasetGet, DataToGet, DataToPost, GlobalService } from '@irlca/irlcore';
import { ArmService } from '../../../arm.service';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { cloneDeep } from 'lodash';
import { DxDataGridComponent } from 'devextreme-angular';
import 'devextreme/integration/jquery';

@Component({
  selector: 'app-item-group-order-dialog',
  templateUrl: './item-group-order-dialog.component.html',
  styleUrls: ['./item-group-order-dialog.component.scss']
})
export class ItemGroupOrderDialogComponent implements OnInit {

  itemGroups: any[] = [];
  oldItemGroups: any[] = [];
  failureModeDataSource: any[] = [];
  failureModes: any[] = [];
  oldFailureModes: any[] = [];
  linkedFailureModes: any[] = [];
  itemGroupColumnsToDisplay = ['order', 'itemGroupName'];
  failureModeColumnsToDisplay = ['failureMode'];
  itemGroupsToDelete: any[] = [];
  itemGroupsToUpdate: any[] = [];
  failureModesToDelete: any[] = [];
  failureModesToUpdate: any[] = [];
  failureModeEditMode: boolean = false;
  itemGroupEditMode: boolean = false;
  failureModeUpdated: boolean = false;
  itemGroupUpdated: boolean = false;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid!: DxDataGridComponent;

  // icons
  times = faTimes;

  constructor(public dialogRef: MatDialogRef<ItemGroupOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private armService: ArmService,
    public globalService: GlobalService, private apiService: APIService) {
    this.onReorder = this.onReorder.bind(this);
  }

  ngOnInit() {
    this.prepareDataForGetItemGroups_0190();
  }

  prepareDataForGetItemGroups_0190() {
    let datasets: datasetGet[] = [];

    let itemGroupDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        riskAssessmentID: this.data.selectedRiskAssessmentID
      },
      storedProcedure: "ItemGroup"
    };

    let failureModeDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        riskAssessmentID: this.data.selectedRiskAssessmentID
      },
      storedProcedure: "FailureMode"
    };

    datasets.push(itemGroupDataset, failureModeDataset);

    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Risk Assessment Expanded Detail", datasets);

    this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      this.itemGroups = cloneDeep(fromDB[0]);
      this.oldItemGroups = cloneDeep(fromDB[0]);
      this.failureModes = cloneDeep(fromDB[1]);
      this.oldFailureModes = cloneDeep(fromDB[1]);
      // Mocking the click event so that the first itemGroup is clicked when dialog is initiated
      let event = { data: { iD: this.itemGroups[0].iD } };
      this.getLinkedFailureModes_XXXX(event);
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Risk Assessment Expanded Detail Data");
      window.alert(userErrMsg)
    });
  }

  prepareDataForPostItemGroups_XXXX() {
    let datasets: any[] = [];
    let auditTrail: any[] = [];
    let itemGroupDatasetToPost: dataset = {
      data: [],
      dataset: "ItemGroup"
    }

    let failureModeDatasetToPost: dataset = {
      data: [],
      dataset: "FailureMode"
    }

    if (this.itemGroupUpdated) {
      let orderCount = 1;

      this.itemGroups.forEach(element => {
        let itemGroupDataset = {
          iD: element.iD,
          clientID: this.globalService.clientID,
          entityID: this.globalService.entityID,
          riskAssessmentID: element.riskAssessmentID,
          itemGroupName: element.itemGroupName,
          order: orderCount,
          statementType: 'UPDATE'
        }

        let itemGroupIndex = this.oldItemGroups.findIndex(itemGroup => itemGroup.iD === itemGroupDataset.iD);
        let oldItemGroup = {
          iD: this.oldItemGroups[itemGroupIndex].iD,
          clientID: this.globalService.clientID,
          entityID: this.globalService.entityID,
          riskAssessmentID: this.oldItemGroups[itemGroupIndex].riskAssessmentID,
          itemGroupName: this.oldItemGroups[itemGroupIndex].itemGroupName,
          order: this.oldItemGroups[itemGroupIndex].order,
        };

        orderCount += 1;
        itemGroupDatasetToPost.data.push(itemGroupDataset);
        auditTrail = auditTrail.concat(this.globalService.buildAuditTrail_0115(oldItemGroup, itemGroupDataset, 'tblItemGroup'));
      });

      if (this.itemGroupsToDelete.length > 0) {
        this.itemGroupsToDelete.forEach(element => {
          let itemGroupDataset = {
            iD: element.iD,
            clientID: this.globalService.clientID,
            entityID: this.globalService.entityID,
            riskAssessmentID: element.riskAssessmentID,
            itemGroupName: element.itemGroupName,
            order: 0,
            statementType: 'DELETE'
          }

          itemGroupDatasetToPost.data.push(itemGroupDataset);
          auditTrail = auditTrail.concat(this.globalService.buildAuditTrail_0115({}, itemGroupDataset, 'tblItemGroup'));
        });
      }

      datasets.push(itemGroupDatasetToPost);
    }

    if (this.failureModesToUpdate.length > 0 || this.failureModesToDelete.length > 0) {
      failureModeDatasetToPost.data = failureModeDatasetToPost.data.concat(this.failureModesToUpdate, this.failureModesToDelete);
      datasets.push(failureModeDatasetToPost);

      let datasetIndex = 0;
      if (datasets.length > 1) {
        datasetIndex = 1;
      }

      if (datasets[datasetIndex].data.length > 0) {
        datasets[datasetIndex].data.forEach((element: any) => {
          if (element.statementType === 'DELETE') {
            auditTrail = this.globalService.buildAuditTrail_0115({}, element, 'tblFailureMode');
          } else if (element.statementType === 'UPDATE') {
            let failureModeIndex = this.oldFailureModes.findIndex(failureMode => failureMode.iD === element.iD);
            let oldFailureMode = {
              iD: this.oldFailureModes[failureModeIndex].iD,
              clientID: this.globalService.clientID,
              entityID: this.globalService.entityID,
              itemGroupID: this.oldFailureModes[failureModeIndex].itemGroupID,
              itemGroupName: this.oldFailureModes[failureModeIndex].itemGroupName,
              failureMode: this.oldFailureModes[failureModeIndex].failureMode,
            };

            auditTrail = auditTrail.concat(this.globalService.buildAuditTrail_0115(oldFailureMode, element, 'tblFailureMode'));
          }
        });
      }
    }

    if (datasets.length > 0) {
      let toDB = new DataToPost(this.globalService.clientID, this.globalService.userID, "", "View Risk Assessment", datasets, auditTrail, null);
      this.apiService.postDataToBackend_0051(this.apiService.urlForSinglePost, toDB).then(() => {
        this.dialogRef.close(true);
      }).catch((err: any) => {
        console.error(err);
        let userErrMsg = this.armService.constructUserErrorMessage(err, "Audit Trail");
        window.alert(userErrMsg)
      });
    }
  }

  getLinkedFailureModes_XXXX(event: any) {
    let itemGroupID = event.data.iD;
    let tempFailureModes = this.failureModes.filter(failureModeObj => failureModeObj.itemGroupID === itemGroupID);
    this.failureModeDataSource = tempFailureModes;
  }

  updateItemGroup(event: any) {
    this.itemGroupEditMode = false;
    if (event.changes[0].type !== 'remove')
      this.itemGroupUpdated = true;
  }

  updateFailureMode(event: any) {
    let failureModeToUpdate: any = {
      iD: event.changes[0].data.iD,
      clientID: this.globalService.clientID,
      entityID: this.globalService.entityID,
      itemGroupID: event.changes[0].data.itemGroupID,
      itemGroupName: event.changes[0].data.itemGroupName,
      failureMode: event.changes[0].data.failureMode,
      statementType: 'UPDATE'
    };

    this.failureModesToUpdate.push(failureModeToUpdate);
    this.failureModeEditMode = false;
    this.failureModeUpdated = true;
  }

  deleteItemGroup(event: any) {
    let itemGroupToDelete: any = {
      iD: event.data.iD,
      clientID: this.globalService.clientID,
      entityID: this.globalService.entityID,
      riskAssessmentID: event.data.riskAssessmentID,
      itemGroupName: event.data.itemGroupName,
      order: 0,
      statementType: 'DELETE'
    };

    this.itemGroupsToDelete.push(itemGroupToDelete);
    this.itemGroupEditMode = false;
  }

  deleteFailureMode(event: any) {
    let failureModeToDelete: any = {
      iD: event.data.iD,
      clientID: this.globalService.clientID,
      entityID: this.globalService.entityID,
      itemGroupID: event.data.itemGroupID,
      itemGroupName: event.data.itemGroupName,
      failureMode: event.data.failureMode,
      statementType: 'DELETE'
    };

    this.failureModesToDelete.push(failureModeToDelete);
    this.failureModeEditMode = false;
    this.failureModeUpdated = true;
  }

  onReorder(event: any) {
    this.itemGroupUpdated = true;
    const visibleRows = event.component.getVisibleRows();
    const toIndex = this.itemGroups.indexOf(visibleRows[event.toIndex].data);
    const fromIndex = this.itemGroups.indexOf(event.itemData);

    this.itemGroups.splice(fromIndex, 1);
    this.itemGroups.splice(toIndex, 0, event.itemData);
    this.itemGroups.forEach((itemGroup, index) => {
      itemGroup.order = index + 1;
    });

    this.dataGrid.focusedRowIndex = toIndex;
    let itemGroupObj = { data: { iD: this.itemGroups[toIndex].iD } };
    this.getLinkedFailureModes_XXXX(itemGroupObj);
  }

  onCellPreparedItemGroup(e: any) {
    if (e.columnIndex == 3 && e.rowType == "data") {
      // DOC Comment: Add check to see if itemGroup has any linked failureModes - probably shouldn't be possible to delete itemGroup if linkedFailureModes exist 
      // This could be a count returned from the DB OR linkedFailureModes could be assigned to each itemGroup at the beginning
      let temp = this.armService.selectedRiskAssessmentAssociatedRisks.filter((risk: any) => e.data.iD === risk.itemGroupID);
      if (temp.length > 0)
        e.cellElement.find(".dx-link-delete").remove();
    }
  }

  onCellPreparedFailureMode(e: any) {
    if (e.columnIndex == 1 && e.rowType == "data") {
      let temp = this.armService.selectedRiskAssessmentAssociatedRisks.filter((risk: any) => e.data.iD === risk.failureModeID);
      if (temp.length > 0)
        e.cellElement.find(".dx-link-delete").remove();
    }
  }

  onEditStartItemGroup(event: any) {
    let itemGroupIndex = event.component.getRowIndexByKey(event.key);
    this.dataGrid.focusedRowIndex = itemGroupIndex;
    let itemGroupObj = { data: { iD: this.itemGroups[itemGroupIndex].iD } };
    this.getLinkedFailureModes_XXXX(itemGroupObj);
    this.itemGroupEditMode = true;
  }
}