import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { APIService, dataset, datasetGet, DataToGet, DataToPost, GlobalService } from '@irlca/irlcore';
import { cloneDeep } from 'lodash';
import { ArmService } from '../../../arm.service';
import * as _ from 'lodash';
import DataSource from 'devextreme/data/data_source';
import { xor, isEqual, sortBy } from 'lodash';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-sod-control-dialog',
  templateUrl: './sod-control-dialog.component.html',
  styleUrls: ['./sod-control-dialog.component.scss'],
})
export class SODControlDialogComponent implements OnInit {
  controls: any[] = [];
  controlCategory: any[] = [];
  selectedControls: any[] = [];
  selectedCausePreventionControl: any[] = [];
  selectedDetectabilityControl: any[] = [];
  riskPreventionControls: any[] = [];
  causePreventionControls: any[] = [];
  detectabilityControls: any[] = [];
  controlsForDataset: any[] = [];
  failureEffectControlsForDataset: any[] = []
  selectedRows: number[] = [];
  selectedRowsCausePreventative: number[] = [];
  selectedRowsDetectability: number[] = [];

  controlDataSource!: DataSource;
  causePreventionControlDataSource!: DataSource;
  detectabilityControlDataSource!: DataSource;

  SODRatingDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  severityCategoryDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  detectabilityCategoryDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  occurrenceCategoryDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  controlDataSourceDisplayedColumns: string[] = ["controlID", "control"];
  SODRatingDataSourceDisplayedColumns: string[] = ["SODRating", "SODClassification", "SODDescription"];
  severityCategoryDataSourceDisplayedColumns: string[] = ["severityRating", "classification"];
  detectabilityCategoryDataSourceDisplayedColumns: string[] = ["detectabilityRating", "classification"];
  occurrenceCategoryDataSourceDisplayedColumns: string[] = ["occurrenceRating", "classification"];

  failureEffectForm!: FormGroup;
  failureCauseFormOccurrence!: FormGroup;
  failureCauseFormDetectability!: FormGroup;

  selectedSODRatingOccurrence: any = {};
  selectedSODRatingDetectability: any = {};
  selectedSODRatingSeverity: any = {};
  SODRatings: any[] = [];
  severityCategoryRatings: any[] = [];
  occurrenceCategoryRatings: any[] = [];
  detectabilityCategoryRatings: any[] = [];
  savedControl: any = {};

  createControlFailureEffectM2MDataset!: dataset;
  createControlFailureCauseM2MDataset!: dataset;

  entryID: number = -1;
  failureEffectEntryID: number = -1;
  selectedTab: string = 'severity';
  displayContainer: string = 'control';

  selectedSODRatingOccurrenceUpdated: boolean = false;
  selectedSODRatingDetectabilityUpdated: boolean = false;
  selectedSODRatingSeverityUpdated: boolean = false;
  selectedCausePreventionControlsUpdated: boolean = false;
  selectedDetectabilityControlsUpdated: boolean = false;
  selectedControlsUpdated: boolean = false;
  firstEdit: boolean = false;
  showSeverity: boolean = false;
  controlAdded: boolean = false;

  // icons 
  times = faTimes
  
  constructor(public dialogRef: MatDialogRef<SODControlDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder,
    private apiService: APIService, public globalService: GlobalService,
    public armService: ArmService) { }

  ngOnInit() {
    this.selectedTab = this.data.SODType;

    this.selectedControls = cloneDeep(this.data.controls);
    this.selectedCausePreventionControl = cloneDeep(this.data.causePreventionControls);
    this.selectedDetectabilityControl = cloneDeep(this.data.detectionControls);
    this.prepareDataForGetControlCategory_0190();
    this.prepareDataForGetFailureCauseDialog_0190();
    this.prepareDataForGetFailureEffectDialog_0190();
  }

  prepareDataForGetControlCategory_0190() {
    let datasets: datasetGet[] = [];

    let controlCategoryDataset: datasetGet = {
      parameters: {},
      storedProcedure: "ControlCategory"
    };

    datasets.push(controlCategoryDataset);
    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Control", datasets);

    this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      this.controlCategory = fromDB[0];
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Failure Effect Dialog Data");
      window.alert(userErrMsg)
    });
  }

  prepareDataForGetFailureEffectDialog_0190() {
    let datasets: datasetGet[] = [];

    let controlDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entity: this.globalService.entityID,
        controlCategoryID: 3,
      },
      storedProcedure: "Control"
    };

    datasets.push(controlDataset);
    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Failure Effect", datasets);

    this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      let searchableObj = fromDB[0];
      for (let i = 0; i < fromDB[0].length; i++) {
        searchableObj[i] = _.omit(searchableObj[i], ['clientID', 'entityID', 'riskCount']);
      }
      this.controls = searchableObj;

      //look for way to update the data in the datasource - currently using workaround
      this.controlDataSource = new DataSource({
        store: {
          type: "array",
          key: "iD",
          data: this.controls
        }
      });
      this.selectedRows = [];
      this.selectedControls.forEach((control: any) => {
        this.selectedRows.push(control.controlID);
      });
      if (this.controlAdded) {
        if (this.savedControl.type == 'severity') {
          let index = this.controls.findIndex(x =>
            x.control == this.savedControl.control.control &&
            x.controlREF == this.savedControl.control.controlREF &&
            x.controlDescription == this.savedControl.control.controlDescription
          );
          this.selectedRows.push(this.controls[index].iD);
          this.controlAdded = false;
        }
      }
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Failure Effect Dialog Data");
      window.alert(userErrMsg)
    });
  }

  createControlFailureEffectM2MDataset_XXXX(control: any, statementType: string, entryID: number) {
    if (statementType === 'UPDATE') {
      this.failureEffectControlsForDataset.push({
        iD: entryID,
        controlID: control.controlID,
        failureEffectID: this.data.selectedRisk.failureEffectID,
        statementType: statementType
      });
    } else {
      let failureEffectID;
      if (this.data.selectedRisk.failureEffectID > 0) {
        failureEffectID = this.data.selectedRisk.failureEffectID;
      } else {
        failureEffectID = this.globalService.dependantValue;
      }
      this.failureEffectControlsForDataset.push({
        iD: entryID,
        controlID: control.controlID,
        failureEffectID: failureEffectID,
        statementType: statementType
      });
    }
  }

  prepareDataForGetFailureCauseDialog_0190() {
    let datasets: datasetGet[] = [];

    let occurrenceControlDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entity: this.globalService.entityID,
        controlCategoryID: 2,
      },
      storedProcedure: "Control"
    };

    let detectabilityControlDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entity: this.globalService.entityID,
        controlCategoryID: 1,
      },
      storedProcedure: "Control"
    };

    datasets.push(occurrenceControlDataset, detectabilityControlDataset);
    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Failure Cause", datasets);

    this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      let searchableObj = fromDB[0];
      for (let i = 0; i < fromDB[0].length; i++) {
        searchableObj[i] = _.omit(searchableObj[i], ['clientID', 'entityID', 'riskCount']);
      }
      this.causePreventionControls = searchableObj;

      let searchableObj2 = fromDB[1];
      for (let i = 0; i < fromDB[1].length; i++) {
        searchableObj2[i] = _.omit(searchableObj2[i], ['clientID', 'entityID', 'riskCount']);
      }
      this.detectabilityControls = searchableObj2;

      this.causePreventionControlDataSource = new DataSource({
        store: {
          type: "array",
          key: "iD",
          data: this.causePreventionControls
        }
      });
      this.detectabilityControlDataSource = new DataSource({
        store: {
          type: "array",
          key: "iD",
          data: this.detectabilityControls
        }
      });
      this.selectedRowsCausePreventative = [];
      this.selectedRowsDetectability = [];
      this.selectedCausePreventionControl.forEach((control: any) => {
        this.selectedRowsCausePreventative.push(control.controlID);
      });
      this.selectedDetectabilityControl.forEach((control: any) => {
        this.selectedRowsDetectability.push(control.controlID);
      });
      if (this.controlAdded) {
        if (this.savedControl.type == 'occurrence') {
          let index = this.causePreventionControls.findIndex(x =>
            x.control == this.savedControl.control.control &&
            x.controlREF == this.savedControl.control.controlREF &&
            x.controlDescription == this.savedControl.control.controlDescription
          );
          this.selectedRowsCausePreventative.push(this.causePreventionControls[index].iD);
          this.controlAdded = false;
        } else if (this.savedControl.type == 'detectability') {
          let index = this.detectabilityControls.findIndex(x =>
            x.control == this.savedControl.control.control &&
            x.controlREF == this.savedControl.control.controlREF &&
            x.controlDescription == this.savedControl.control.controlDescription
          );
          this.selectedRowsDetectability.push(this.detectabilityControls[index].iD);
          this.controlAdded = false;
        }
      }

    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Failure Cause Dialog Data");
      window.alert(userErrMsg)
    });
  }

  saveFailureCauseDialog_XXXX() {
    let originalSelectedCausePreventativeIDs: number[] = [];
    this.data.causePreventionControls.forEach((control: any) => {
      originalSelectedCausePreventativeIDs.push(control.controlID);
    });
    let diffArray = xor(originalSelectedCausePreventativeIDs, this.selectedRowsCausePreventative);

    diffArray.forEach(iD => {
      let insertIndex = this.causePreventionControls.findIndex((control: any) => control.iD === iD);
      let deleteIndex = this.data.causePreventionControls.findIndex((control: any) => control.controlID === iD);
      if (originalSelectedCausePreventativeIDs.indexOf(iD) < 0) {
        this.createControlFailureCauseM2MDataset_XXXX(this.causePreventionControls[insertIndex], "INSERT", this.data.controlCauseEntryID, true);
        this.data.controlCauseEntryID -= 1;
      } else {
        this.createControlFailureCauseM2MDataset_XXXX(this.data.causePreventionControls[deleteIndex], "DELETE", this.data.causePreventionControls[deleteIndex].iD, true);
      }
    });

    let originalSelectedDetectabilityIDs: number[] = [];
    this.data.detectionControls.forEach((control: any) => {
      originalSelectedDetectabilityIDs.push(control.controlID);
    });
    let diffArrayDetection = xor(originalSelectedDetectabilityIDs, this.selectedRowsDetectability);

    diffArrayDetection.forEach(iD => {
      let index = this.detectabilityControls.findIndex((control: any) => control.iD === iD);
      let deleteIndex = this.data.detectionControls.findIndex((control: any) => control.controlID === iD);
      if (originalSelectedDetectabilityIDs.indexOf(iD) < 0) {
        this.createControlFailureCauseM2MDataset_XXXX(this.detectabilityControls[index], "INSERT", this.data.controlCauseEntryID, false);
        this.data.controlCauseEntryID -= 1;
      } else {
        this.createControlFailureCauseM2MDataset_XXXX(this.data.detectionControls[deleteIndex], "DELETE", this.data.detectionControls[deleteIndex].iD, false);
      }
    });

    let controlFailureCauseM2MDataset: dataset = {
      data: this.controlsForDataset,
      dataset: "ControlFailureCauseM2M"
    }

    let originalSelectedsControlIDs: number[] = [];
    this.data.controls.forEach((control: any) => {
      originalSelectedsControlIDs.push(control.controlID)
    });
    let diffArraySeverity = xor(originalSelectedsControlIDs, this.selectedRows);

    diffArraySeverity.forEach(iD => {
      let index = this.controls.findIndex((control: any) => control.iD === iD);
      let deleteIndex = this.data.controls.findIndex((control: any) => control.controlID === iD);
      if (originalSelectedsControlIDs.indexOf(iD) < 0) {
        this.createControlFailureEffectM2MDataset_XXXX(this.controls[index], "INSERT", this.failureEffectEntryID);
        this.failureEffectEntryID -= 1;
      } else {
        this.createControlFailureEffectM2MDataset_XXXX(this.data.controls[deleteIndex], "DELETE", this.data.controls[deleteIndex].iD);
      }
    });

    let controlFailureEffectM2MDataset: dataset = {
      data: this.failureEffectControlsForDataset,
      dataset: "ControlFailureEffectM2M"
    }

    this.dialogRef.close({
      data: {
        occurrenceControls: this.selectedCausePreventionControl,
        detectabilityControls: this.selectedDetectabilityControl,
        controlFailureCauseM2MDataset: controlFailureCauseM2MDataset,
        controlEntryID: this.data.controlCauseEntryID,
        statementType: this.data.statementType,
        failureCauseControlsUpdated: (this.selectedCausePreventionControlsUpdated || this.selectedDetectabilityControlsUpdated),
        riskPreventionControls: this.selectedControls,
        controlFailureEffectM2MDataset: controlFailureEffectM2MDataset,
        failureEffectControlsUpdated: this.selectedControlsUpdated
      }
    });
  }

  createControlFailureCauseM2MDataset_XXXX(control: any, statementType: string, entryID: number, isOccurrenceControl: boolean) {
    if (statementType === 'UPDATE') {
      this.controlsForDataset.push({
        iD: entryID,
        controlID: control.controlID,
        failureCauseID: this.data.selectedRisk.failureCauseID,
        isOccurrenceControl: isOccurrenceControl,
        statementType: statementType
      });
    } else {
      let failureCauseID;
      if (this.data.selectedRisk.failureCauseID > 0) {
        failureCauseID = this.data.selectedRisk.failureCauseID;
      } else {
        failureCauseID = this.globalService.dependantValue;
      }
      this.controlsForDataset.push({
        iD: entryID,
        controlID: control.controlID,
        failureCauseID: failureCauseID,
        isOccurrenceControl: isOccurrenceControl,
        statementType: statementType
      });
    }
  }

  failureCauseFormValid_XXXX() {
    if (
      this.selectedCausePreventionControlsUpdated || this.selectedControlsUpdated || this.selectedDetectabilityControlsUpdated
    )
      return false;
    else return true;
  }

  selectRow(event: any) {
    switch (this.selectedTab) {
      case 'severity': {
        this.selectedControls = event.selectedRowsData;
        if (isEqual(sortBy(this.selectedControls), sortBy(this.data.controls))) {
          this.selectedControlsUpdated = false;
        } else {
          this.selectedControlsUpdated = true;
        }
        break;
      }
      case 'occurrence': {
        this.selectedCausePreventionControl = event.selectedRowsData;
        if (isEqual(sortBy(this.selectedCausePreventionControl), sortBy(this.data.causePreventionControls))) {
          this.selectedCausePreventionControlsUpdated = false;
        } else {
          this.selectedCausePreventionControlsUpdated = true;
        }
        break;
      }
      case 'detectability': {
        this.selectedDetectabilityControl = event.selectedRowsData;
        if (isEqual(sortBy(this.selectedDetectabilityControl), sortBy(this.data.detectionControls))) {
          this.selectedDetectabilityControlsUpdated = false;
        } else {
          this.selectedDetectabilityControlsUpdated = true;
        }
        break;
      }
    }
  }

  updateRow(event: any) {
    this.controlAdded = true;
    let datasets: dataset[] = [];
    let controlDataset!: dataset;
    let auditTrail: any[] = [];
    let toDB: DataToPost;
    let index: number = 0;

    switch (this.selectedTab) {
      case 'severity': {
        index = this.controlCategory.findIndex(x => x.category == 'Correction');
        controlDataset = {
          data: [{
            iD: -1,
            clientID: this.globalService.clientID,
            entityID: this.globalService.entityID,
            controlCategoryID: this.controlCategory[index].iD,
            category: this.controlCategory[index].category,
            control: event.changes[0].data.control,
            controlREF: event.changes[0].data.controlREF,
            controlDescription: event.changes[0].data.controlDescription,
            statementType: "INSERT"
          }],
          dataset: "Control"
        }
        auditTrail = auditTrail.concat(this.globalService.buildAuditTrail_0115({}, controlDataset.data[0], "tblControl"));
        datasets.push(controlDataset);
        toDB = new DataToPost(this.globalService.clientID, this.globalService.userID, 'N/A', "Add Control", datasets, auditTrail);
        this.apiService.postDataToBackend_0051(this.apiService.urlForSinglePost, toDB).then((result: any) => {
          if (result) {
            if (result) {
              this.savedControl = {
                control: event.changes[0].data,
                type: 'severity',
              }
              this.prepareDataForGetFailureEffectDialog_0190();
            }
          }
        }).catch((err: any) => {
          console.error(err);
          let userErrMsg = this.armService.constructUserErrorMessage(err, "Control");
          window.alert(userErrMsg)
        });
        break;
      }
      case 'occurrence': {
        index = this.controlCategory.findIndex(x => x.category == 'Prevention');
        controlDataset = {
          data: [{
            iD: -1,
            clientID: this.globalService.clientID,
            entityID: this.globalService.entityID,
            controlCategoryID: this.controlCategory[index].iD,
            category: this.controlCategory[index].category,
            control: event.changes[0].data.control,
            controlREF: event.changes[0].data.controlREF,
            controlDescription: event.changes[0].data.controlDescription,
            statementType: "INSERT"
          }],
          dataset: "Control"
        }
        auditTrail = auditTrail.concat(this.globalService.buildAuditTrail_0115({}, controlDataset.data[0], "tblControl"));
        datasets.push(controlDataset);
        toDB = new DataToPost(this.globalService.clientID, this.globalService.userID, 'N/A', "Add Control", datasets, auditTrail);

        this.apiService.postDataToBackend_0051(this.apiService.urlForSinglePost, toDB).then((result: any) => {
          if (result) {
            this.savedControl = {
              control: event.changes[0].data,
              type: 'occurrence',
            }
            this.prepareDataForGetFailureCauseDialog_0190();
          }
        }).catch((err: any) => {
          console.error(err);
          let userErrMsg = this.armService.constructUserErrorMessage(err, "Control");
          window.alert(userErrMsg)
        });
        break;
      }
      case 'detectability': {
        index = this.controlCategory.findIndex(x => x.category == 'Detection');
        controlDataset = {
          data: [{
            iD: -1,
            clientID: this.globalService.clientID,
            entityID: this.globalService.entityID,
            controlCategoryID: this.controlCategory[index].iD,
            category: this.controlCategory[index].category,
            control: event.changes[0].data.control,
            controlREF: event.changes[0].data.controlREF,
            controlDescription: event.changes[0].data.controlDescription,
            statementType: "INSERT"
          }],
          dataset: "Control"
        }
        auditTrail = auditTrail.concat(this.globalService.buildAuditTrail_0115({}, controlDataset.data[0], "tblControl"));
        datasets.push(controlDataset);
        toDB = new DataToPost(this.globalService.clientID, this.globalService.userID, 'N/A', "Add Control", datasets, auditTrail);
        this.apiService.postDataToBackend_0051(this.apiService.urlForSinglePost, toDB).then((result: any) => {
          if (result) {
            this.savedControl = {
              control: event.changes[0].data,
              type: 'detectability',
            }
            this.prepareDataForGetFailureCauseDialog_0190();
          }
        }).catch((err: any) => {
          console.error(err);
          let userErrMsg = this.armService.constructUserErrorMessage(err, "Control");
          window.alert(userErrMsg)
        });
        break;
      }
    }
  }
}
