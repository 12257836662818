<div class="mitigating-actions-container">
    <div class="page-card-back-btn-container" *ngIf="armService.expandedTaskView">
        <button class="back-btn" *ngIf="armService.expandedTaskView && armService.taskReturn === 'expanded-detail'"
            id="expanded-task-close-button" (click)="navigateToExpandedDetailView_0409()"
            [ngClass]="{'disabled': globalService.editModeBool}">
            <fa-icon class="white-icon" [icon]="arrowLeft"></fa-icon>
        </button>
        <button class="back-btn" *ngIf="armService.expandedTaskView && armService.taskReturn === 'risk-record'"
            id="expanded-task-close-button" (click)="navigateToRiskRecord_0409()"
            [ngClass]="{'disabled': globalService.editModeBool}">
            <fa-icon class="white-icon" [icon]="arrowLeft"></fa-icon>
        </button>
    </div>
    <div class="page-card back-btn" *ngIf="armService.expandedTaskView">
        <div class="container">
            <irlcore-tasks-master [masterDetail]="true" [filteredView]="true" [addTask]="false" [tableHeight]="'85vh'"
                [riskID]="armService.selectedRisk.iD" (expandedView)="armService.expandedView = $event;"
                (associationsDialogTrigger)="openAssociatedRisksDialog_0192($event)">
            </irlcore-tasks-master>
        </div>
    </div>
    <div class=" page-card" *ngIf="!armService.expandedTaskView">
        <div class="container">
            <irlcore-tasks-master [masterDetail]="true" [filteredView]="false" [addTask]="false" [tableHeight]="'85vh'"
                (expandedView)="armService.expandedView = $event;"
                (associationsDialogTrigger)="openAssociatedRisksDialog_0192($event)">
            </irlcore-tasks-master>
        </div>
    </div>
</div>