<div class="risk-dashboard-option" id="risk-dashboard-option">
    <div class="risk-dashboard-heatmap">
        <div class="heatmap-filter-container" id="heatmap-filter">
            <div class="title">
                <h4>Risk Assessment Heatmap</h4>
            </div>
            <div class="display-selection">
                <h3 class="show-residual-text">Display: </h3>
                <dx-select-box id="heatmapView" [(value)]="selectedHeatmapView"
                    [items]="['Current Risk Status','Planned Risk Status','Risk Treatment Plan']"
                    (onValueChanged)="changeHeatmapView()">
                </dx-select-box>
            </div>
        </div>

        <div class="heatmap-risk-assessment-container" id="heatmap-risk-assessment-container">
            <div class="heatmap-select-risk-assessment-container">

                <div class="select-risk-assessment-container">
                    <div class="select-risk-assessment-container" *ngIf="riskAssessments.length > 0">
                        <div class="select-container">
                            <form [formGroup]="dashboardForm" class="justify-content-between">
                                <label>Risk Assessment ID</label>
                                <div class="iD-dropdown">
                                    <mat-select placeholder="{{selectedRiskAssessment.riskAssessmentREF}}"
                                        formControlName="riskAssessmentREF">
                                        <mat-option *ngFor="let iDs of riskAssessments; let i = index;"
                                            value="riskAssessmentREF" id="team-TeamMemberDialogComponent-{{i}}"
                                            (click)="onSelectTitle(iDs.riskAssessmentTitle)">
                                            {{iDs.riskAssessmentREF}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                                <label>Risk Assessment Title</label>
                                <div>
                                    <mat-form-field style="width: 100%;">
                                        <input type="text" matInput id="risk-assessment-title-dropdown"
                                            formControlName="riskAssessmentTitle" [matAutocomplete]="auto1" size="150">
                                        <mat-autocomplete #auto1="matAutocomplete">
                                            <mat-option *ngFor="let option of filteredOptions | async; let i = index;"
                                                value="{{option.riskAssessmentTitle}}"
                                                id="risk-assessment-title-dropdown-{{i}}"
                                                (click)="onSelectTitle(option.riskAssessmentTitle)">
                                                {{option.riskAssessmentTitle}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>

                            </form>
                        </div>
                        <div class="risk-question">
                            <p>{{selectedRiskAssessment.riskQuestion}}</p>
                        </div>
                    </div>
                    <div class="risk-assessment-navigation-container">

                        <div class="navigation-arrow-container">
                            <div class="risk-assessment-navigation">
                                <button mat-button class="btn icon-btn icon-btn-primary large"
                                    (click)="changeRiskAssessment_XXXX('down')">
                                    <fa-icon [icon]="arrowAltLeft"></fa-icon>
                                </button>
                            </div>
                            <div class="risk-assessment-navigation">
                                <button mat-button class="btn icon-btn icon-btn-primary large"
                                    (click)="changeRiskAssessment_XXXX('up')">
                                    <fa-icon [icon]="arrowAltRight"></fa-icon>
                                </button>
                            </div>
                        </div>
                        <div class="risk-assessment-count">
                            <h6 *ngIf="riskAssessments.length > 0">{{riskAssessmentCounter + 1}} of
                                {{riskAssessments.length}} </h6>
                            <h6 *ngIf="riskAssessments.length == 0">{{riskAssessmentCounter}} of
                                {{riskAssessments.length}}
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="heatmap-risk-assessment-tab-pages" id="heatmap-risk-assessment-tab-pages">
                    <!-- <p>Tab pages</p> -->
                </div>

            </div>

            <div class="heatmap-content-container">
                <div class="heatmap-chart">
                    <div class="row" *ngIf="heatmapService.displayHeatmapLegend">
                        <div class="tooltip">
                            <table class="info">
                                <tr>
                                    <th class="main-axis-label" colspan="3">
                                        <h2>Legend</h2>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="icon-column" colspan="1">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" width="48.589" height="47.939"
                                            viewBox="0 0 48.589 47.939">
                                            <defs>
                                                <filter id="Ellipse_56" x="0" y="0" width="48.589" height="47.939"
                                                    filterUnits="userSpaceOnUse">
                                                    <feOffset dy="3" input="SourceAlpha" />
                                                    <feGaussianBlur stdDeviation="3" result="blur" />
                                                    <feFlood flood-opacity="0.161" />
                                                    <feComposite operator="in" in2="blur" />
                                                    <feComposite in="SourceGraphic" />
                                                </filter>
                                            </defs>
                                            <g id="blue-dot" transform="translate(-2966 -1815)">
                                                <g transform="matrix(1, 0, 0, 1, 2966, 1815)" filter="url(#Ellipse_56)">
                                                    <g id="Ellipse_56-2" data-name="Ellipse 56"
                                                        transform="translate(10 7)" fill="#080004"
                                                        stroke="rgba(227,227,227,0.33)" stroke-width="1">
                                                        <ellipse cx="14.295" cy="13.97" rx="14.295" ry="13.97"
                                                            stroke="none" />
                                                        <ellipse cx="14.295" cy="13.97" rx="14.795" ry="14.47"
                                                            fill="none" />
                                                    </g>
                                                </g>
                                                <text id="A" transform="translate(2990.294 1840.47)" fill="#ffffff"
                                                    font-size="12" font-family="Lato-Bold, Lato" font-weight="700">
                                                    <tspan x="-4" y="0">A</tspan>
                                                </text>
                                            </g>
                                        </svg>

                                    </th>
                                    <th class="text-column" colspan="2">
                                        <p> Black dot indicates current Risk Level</p>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="icon-column" colspan="1">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" width="48.589" height="47.939"
                                            viewBox="0 0 48.589 47.939">
                                            <defs>
                                                <filter id="Ellipse_56" x="0" y="0" width="48.589" height="47.939"
                                                    filterUnits="userSpaceOnUse">
                                                    <feOffset dy="3" input="SourceAlpha" />
                                                    <feGaussianBlur stdDeviation="3" result="blur" />
                                                    <feFlood flood-opacity="0.161" />
                                                    <feComposite operator="in" in2="blur" />
                                                    <feComposite in="SourceGraphic" />
                                                </filter>
                                            </defs>
                                            <g id="blue-dot" transform="translate(-2966 -1815)">
                                                <g transform="matrix(1, 0, 0, 1, 2966, 1815)" filter="url(#Ellipse_56)">
                                                    <g id="Ellipse_56-2" data-name="Ellipse 56"
                                                        transform="translate(10 7)" fill="#fafafa"
                                                        stroke="rgba(227,227,227,0.33)" stroke-width="1">
                                                        <ellipse cx="14.295" cy="13.97" rx="14.295" ry="13.97"
                                                            stroke="none" />
                                                        <ellipse cx="14.295" cy="13.97" rx="14.795" ry="14.47"
                                                            fill="none" />
                                                    </g>
                                                </g>
                                                <text id="A" transform="translate(2990.294 1840.47)" fill="#080004"
                                                    font-size="12" font-family="Lato-Bold, Lato" font-weight="700">
                                                    <tspan x="-4" y="0">A</tspan>
                                                </text>
                                            </g>
                                        </svg>
                                    </th>
                                    <th class="text-column" colspan="2">
                                        <p> White dot indicates target Risk Level</p>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="icon-column" colspan="1">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" width="48.589" height="47.939"
                                            viewBox="0 0 48.589 47.939">
                                            <defs>
                                                <filter id="Ellipse_56" x="0" y="0" width="48.589" height="47.939"
                                                    filterUnits="userSpaceOnUse">
                                                    <feOffset dy="3" input="SourceAlpha" />
                                                    <feGaussianBlur stdDeviation="3" result="blur" />
                                                    <feFlood flood-opacity="0.161" />
                                                    <feComposite operator="in" in2="blur" />
                                                    <feComposite in="SourceGraphic" />
                                                </filter>
                                            </defs>
                                            <g id="blue-dot" transform="translate(-2966 -1815)">
                                                <g transform="matrix(1, 0, 0, 1, 2966, 1815)" filter="url(#Ellipse_56)">
                                                    <g id="Ellipse_56-2" data-name="Ellipse 56"
                                                        transform="translate(10 7)" fill="#89CFF0"
                                                        stroke="rgba(51,110,190,0.33)" stroke-width="1">
                                                        <ellipse cx="14.295" cy="13.97" rx="14.295" ry="13.97"
                                                            stroke="none" />
                                                        <ellipse cx="14.295" cy="13.97" rx="14.795" ry="14.47"
                                                            fill="none" />
                                                    </g>
                                                </g>
                                                <text id="A" transform="translate(2990.294 1840.47)" fill="#080004"
                                                    font-size="12" font-family="Lato-Bold, Lato" font-weight="700">
                                                    <tspan x="-4" y="0">A</tspan>
                                                </text>
                                            </g>
                                        </svg>
                                    </th>
                                    <th class="text-column" colspan="2">
                                        <p> Blue dot indicates previous Risk Level</p>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="icon-column" colspan="1">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" width="48.589" height="47.939"
                                            viewBox="0 0 48.589 47.939">
                                            <defs>
                                                <filter id="Ellipse_56" x="0" y="0" width="48.589" height="47.939"
                                                    filterUnits="userSpaceOnUse">
                                                    <feOffset dy="3" input="SourceAlpha" />
                                                    <feGaussianBlur stdDeviation="3" result="blur" />
                                                    <feFlood flood-opacity="0.161" />
                                                    <feComposite operator="in" in2="blur" />
                                                    <feComposite in="SourceGraphic" />
                                                </filter>
                                            </defs>
                                            <g id="red-text" transform="translate(-2966 -1815)">
                                                <g transform="matrix(1, 0, 0, 1, 2966, 1815)" filter="url(#Ellipse_56)">
                                                    <g id="Ellipse_56-2" data-name="Ellipse 56"
                                                        transform="translate(10 7)" fill="none"
                                                        stroke="rgba(227,227,227,0.33)" stroke-width="1">
                                                        <ellipse cx="14.295" cy="13.97" rx="14.295" ry="13.97"
                                                            stroke="none" />
                                                        <ellipse cx="14.295" cy="13.97" rx="14.795" ry="14.47"
                                                            fill="none" />
                                                    </g>
                                                </g>
                                                <text id="A" transform="translate(2990.294 1840.47)" fill="#d04d43"
                                                    font-size="12" font-family="Lato-Bold, Lato" font-weight="700">
                                                    <tspan x="-4" y="0">A</tspan>
                                                </text>
                                            </g>
                                        </svg>
                                    </th>
                                    <th class="text-column" colspan="2">
                                        <p> Any Risk where the text is Red indicates there are Overdue Mitigating Action
                                        </p>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="icon-column" colspan="1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26"
                                            viewBox="0 0 26 26">
                                            <g id="square" transform="translate(-2954 -2189)">
                                                <rect id="Rectangle_3882" data-name="Rectangle 3882" width="26"
                                                    height="26" transform="translate(2954 2189)" fill="#080004" />
                                                <text id="A" transform="translate(2967 2206.5)" fill="#fff"
                                                    font-size="12" font-family="Lato-Bold, Lato" font-weight="700">
                                                    <tspan x="-4" y="0">A</tspan>
                                                </text>
                                            </g>
                                        </svg>
                                    </th>
                                    <th class="text-column" colspan="2">
                                        <p> Square box indicates the Risk will be eliminated after completing Mitigating
                                            Actions</p>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="icon-column" colspan="1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="5"
                                            viewBox="0 0 72 5">
                                            <g id="black_line" data-name="black line"
                                                transform="translate(-2928 -1971)">
                                                <line id="Line_568" data-name="Line 568" x2="66"
                                                    transform="translate(2930.5 1973.5)" fill="none" stroke="#080004"
                                                    stroke-linecap="round" stroke-width="2" />
                                                <circle id="Ellipse_57" data-name="Ellipse 57" cx="2.5" cy="2.5" r="2.5"
                                                    transform="translate(2928 1971)" fill="#080004" />
                                                <circle id="Ellipse_58" data-name="Ellipse 58" cx="2.5" cy="2.5" r="2.5"
                                                    transform="translate(2995 1971)" fill="#080004" />
                                            </g>
                                        </svg>
                                    </th>
                                    <th class="text-column" colspan="2">
                                        <p> Link indicating Mitigating Actions are complete</p>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="icon-column" colspan="1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="5"
                                            viewBox="0 0 72 5">
                                            <g id="dashed-line" transform="translate(-2928 -1971)">
                                                <line id="Line_568" data-name="Line 568" x2="66"
                                                    transform="translate(2930.5 1973.5)" fill="none" stroke="#080004"
                                                    stroke-linecap="round" stroke-width="2" stroke-dasharray="8 7" />
                                                <circle id="Ellipse_57" data-name="Ellipse 57" cx="2.5" cy="2.5" r="2.5"
                                                    transform="translate(2928 1971)" fill="#080004" />
                                                <circle id="Ellipse_58" data-name="Ellipse 58" cx="2.5" cy="2.5" r="2.5"
                                                    transform="translate(2995 1971)" fill="#080004" />
                                            </g>
                                        </svg>
                                    </th>
                                    <th class="text-column" colspan="2">
                                        <p> Link indicating Mitigating Actions are WIP</p>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="icon-column" colspan="1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="5"
                                            viewBox="0 0 72 5">
                                            <g id="red-line" transform="translate(-2928 -1971)">
                                                <line id="Line_568" data-name="Line 568" x2="66"
                                                    transform="translate(2930.5 1973.5)" fill="none" stroke="#d04d43"
                                                    stroke-linecap="round" stroke-width="2" stroke-dasharray="8 7" />
                                                <circle id="Ellipse_57" data-name="Ellipse 57" cx="2.5" cy="2.5" r="2.5"
                                                    transform="translate(2928 1971)" fill="#d04d43" />
                                                <circle id="Ellipse_58" data-name="Ellipse 58" cx="2.5" cy="2.5" r="2.5"
                                                    transform="translate(2995 1971)" fill="#d04d43" />
                                            </g>
                                        </svg>
                                    </th>
                                    <th class="text-column" colspan="2">
                                        <p> Link indicating Mitigating Actions are overdue</p>
                                    </th>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="row" *ngIf="heatmapService.displayInfoTooltip">
                        <div class="tooltip">
                            <table class="info">
                                <tr>
                                    <th class="main-axis-label p-0" rowspan="7">
                                        <div class="sideways-label">
                                            <h2>Occurence</h2>
                                        </div>
                                    </th>
                                    <th class="main-axis-label" colspan="7">
                                        <h2>Detection</h2>
                                    </th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>
                                        <p>Very High</p>
                                    </th>
                                    <th>
                                        <p>High</p>
                                    </th>
                                    <th>
                                        <p>Moderate</p>
                                    </th>
                                    <th>
                                        <p>Low</p>
                                    </th>
                                    <th>
                                        <p>Very Low</p>
                                    </th>
                                </tr>
                                <tr>
                                    <td colspan="2">Frequent</td>
                                    <td class="table-classification table-value-a">
                                        <p>A</p>
                                    </td>
                                    <td class="table-classification table-value-b">
                                        <p>B</p>
                                    </td>
                                    <td class="table-classification table-value-c">
                                        <p>C</p>
                                    </td>
                                    <td class="table-classification table-value-d">
                                        <p>D</p>
                                    </td>
                                    <td class="table-classification table-value-e">
                                        <p>E</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">Probable</td>
                                    <td class="table-classification table-value-a">
                                        <p>A</p>
                                    </td>
                                    <td class="table-classification table-value-b">
                                        <p>B</p>
                                    </td>
                                    <td class="table-classification table-value-c">
                                        <p>C</p>
                                    </td>
                                    <td class="table-classification table-value-d">
                                        <p>D</p>
                                    </td>
                                    <td class="table-classification table-value-d">
                                        <p>D</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">Occassional</td>
                                    <td class="table-classification table-value-a">
                                        <p>A</p>
                                    </td>
                                    <td class="table-classification table-value-b">
                                        <p>B</p>
                                    </td>
                                    <td class="table-classification table-value-b">
                                        <p>B</p>
                                    </td>
                                    <td class="table-classification table-value-c">
                                        <p>C</p>
                                    </td>
                                    <td class="table-classification table-value-c">
                                        <p>C</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">Remote</td>
                                    <td class="table-classification table-value-a">
                                        <p>A</p>
                                    </td>
                                    <td class="table-classification table-value-a">
                                        <p>A</p>
                                    </td>
                                    <td class="table-classification table-value-b">
                                        <p>B</p>
                                    </td>
                                    <td class="table-classification table-value-b">
                                        <p>B</p>
                                    </td>
                                    <td class="table-classification table-value-b">
                                        <p>B</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">Improbable</td>
                                    <td class="table-classification table-value-a">
                                        <p>A</p>
                                    </td>
                                    <td class="table-classification table-value-a">
                                        <p>A</p>
                                    </td>
                                    <td class="table-classification table-value-a">
                                        <p>A</p>
                                    </td>
                                    <td class="table-classification table-value-a">
                                        <p>A</p>
                                    </td>
                                    <td class="table-classification table-value-b">
                                        <p>B</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="col-3 tooltip-container" id="heatmap-risk-tooltip" *ngIf="heatmapService.tooltipVisible"
                        [ngStyle]="{'left.px': (heatmapService.xRenderPos), 'top.px': (heatmapService.yRenderPos)}">
                        <table class="heatmap-risk-tooltip"
                            *ngFor="let selectedRiskTooltips of heatmapService.selectedRiskTooltip">
                            <tr class="mid-row">
                                <th>
                                    <p>Risk: {{selectedRiskTooltips.displayID}}</p>
                                </th>
                                <th>
                                    <p>Initial</p>
                                </th>
                                <th>
                                    <p>Residual</p>
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <p>Severity</p>
                                </td>
                                <td>
                                    <p>{{selectedRiskTooltips.severity}}</p>
                                    <p>{{selectedRiskTooltips.initialSODCategory}}</p>
                                </td>
                                <td *ngIf="selectedRiskTooltips.residualSeverity">
                                    <p>{{selectedRiskTooltips.residualSeverity}}</p>
                                    <p>{{selectedRiskTooltips.residualSODCategory}}</p>
                                </td>
                                <td *ngIf="!selectedRiskTooltips.residualSeverity">
                                    <p>N/A</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Occurrence</p>
                                </td>
                                <td>
                                    <p>{{selectedRiskTooltips.occurrence}}</p>
                                </td>
                                <td *ngIf="selectedRiskTooltips.residualOccurrence">
                                    <p>{{selectedRiskTooltips.residualOccurrence}}</p>
                                </td>
                                <td *ngIf="!selectedRiskTooltips.residualOccurrence">
                                    <p>N/A</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Detection</p>
                                </td>
                                <td>
                                    <p>{{selectedRiskTooltips.detectability}}</p>
                                </td>
                                <td *ngIf="selectedRiskTooltips.residualDetectability">
                                    <p>{{selectedRiskTooltips.residualDetectability}}</p>
                                </td>
                                <td *ngIf="!selectedRiskTooltips.residualDetectability">
                                    <p>N/A</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Risk Level</p>
                                </td>
                                <td class="risk-level" *ngIf="selectedRiskTooltips.initialRiskLevel" [ngClass]="{'low': selectedRiskTooltips.initialRiskLevel == 'Low',
                            'medium': selectedRiskTooltips.initialRiskLevel == 'Medium',
                            'high': selectedRiskTooltips.initialRiskLevel == 'High'}">
                                    <p>{{selectedRiskTooltips.initialRiskLevel}}</p>
                                </td>
                                <td *ngIf="!selectedRiskTooltips.initialRiskLevel">
                                    <p>N/A</p>
                                </td>


                                <td class="risk-level" *ngIf="selectedRiskTooltips.residualRiskLevel" [ngClass]="{'low': selectedRiskTooltips.residualRiskLevel == 'Low',
                            'medium': selectedRiskTooltips.residualRiskLevel == 'Medium',
                            'high': selectedRiskTooltips.residualRiskLevel == 'High'}">
                                    <p>{{selectedRiskTooltips.residualRiskLevel}}</p>
                                </td>
                                <td *ngIf="!selectedRiskTooltips.residualRiskLevel">
                                    <p>N/A</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>RPN</p>
                                </td>
                                <td>
                                    <p>{{selectedRiskTooltips.initialRPN}}</p>
                                </td>
                                <td *ngIf="selectedRiskTooltips.residualRPN">
                                    <p>{{selectedRiskTooltips.residualRPN}}</p>
                                </td>
                                <td *ngIf="!selectedRiskTooltips.residualRPN">
                                    <p>N/A</p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <canvas id="riskheatmap"></canvas>
                </div>
                <div class="heatmap-legend">
                    <div class="general-table heatmap" id="riskAssessmentRisk-table">
                        <dx-data-grid id="gridContainer" [dataSource]="dataSource" [showBorders]="true"
                            [remoteOperations]="true" [allowColumnResizing]="true" [wordWrapEnabled]="true"
                            (onSelectionChanged)="selectRow($event)"
                            (onCellPrepared)="onCellPreparedEliminatedRisk($event)">
                            <dxo-selection showCheckBoxesMode="always" mode="multiple" [allowSelectAll]="false">
                            </dxo-selection>
                            <dxi-column alignment="center" dataField="riskRef" caption="Risk"
                                cellTemplate="riskCellTemplate" [width]="150">
                            </dxi-column>
                            <dxi-column alignment="left" dataField="failureCause" dataType="string"
                                cellTemplate="maxHeightFailureCauseCellTemplate">
                            </dxi-column>
                            <dxi-column alignment="left" dataField="failureEffect" dataType="string"
                                cellTemplate="maxHeightFailureEffectCellTemplate">
                            </dxi-column>
                            <dxi-column alignment="center" dataField="initialRPN" caption={{riskScoreType}}
                                cellTemplate="rpnCellTemplate" [width]="100"></dxi-column>
                            <div *dxTemplate="let d of 'riskCellTemplate'" class="rpn-column">
                                <div class="column-cell">
                                    <div class="top">
                                        <p>{{d.data.displayID}}</p>
                                        <div class="row risk-level high" *ngIf="d.data.currentRiskLevel === 'High'">
                                            <p>H</p>
                                        </div>
                                        <div class="row risk-level medium" *ngIf="d.data.currentRiskLevel === 'Medium'">
                                            <p>M</p>
                                        </div>
                                        <div class="row risk-level low" *ngIf="d.data.currentRiskLevel === 'Low'">
                                            <p>L</p>
                                        </div>
                                    </div>
                                    <p>{{d.data.riskRef}}</p>
                                </div>
                            </div>
                            <div *dxTemplate="let d of 'rpnCellTemplate'" class="rpn-column">
                                <div
                                    *ngIf="selectedRiskAssessment.riskAssessmentTypeID != 6 && selectedRiskAssessment.riskAssessmentTypeID != 1">
                                    <div class="initial-rpn">{{d.data.initialRPN}}</div>
                                    <div class="dx-icon-arrowdown"
                                        *ngIf="d.data.riskDecision==='Reduce' || d.data.riskDecision==='Eliminate'">
                                    </div>
                                    <div class="residual-rpn" *ngIf="d.data.residualRPN">{{d.data.residualRPN}}</div>
                                    <div class="residual-rpn" *ngIf="d.data.riskDecision==='Eliminate'">
                                        <p>Eliminated</p>
                                    </div>
                                </div>
                                <div
                                    *ngIf="selectedRiskAssessment.riskAssessmentTypeID === 6 || selectedRiskAssessment.riskAssessmentTypeID === 1">
                                    <div class="initial-rpn">{{d.data.initialRS}}</div>
                                    <div class="dx-icon-arrowdown" *ngIf="d.data.residualRS"></div>
                                    <div class="residual-rpn" *ngIf="d.data.residualRS">{{d.data.residualRS}}</div>
                                </div>
                            </div>
                            <div class="truncate-text-3-lines"
                                *dxTemplate="let d of 'maxHeightFailureCauseCellTemplate'" #tooltip="matTooltip"
                                [matTooltip]="d.value">
                                {{d.value}}
                            </div>
                            <div id="failureEffect" class="truncate-text-3-lines"
                                *dxTemplate="let d of 'maxHeightFailureEffectCellTemplate'" #tooltip="matTooltip"
                                [matTooltip]="d.value">
                                {{d.value}}
                            </div>
                            <dxo-load-panel [enabled]="false"></dxo-load-panel>
                            <dxo-scrolling mode="infinite"></dxo-scrolling>
                        </dx-data-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>