import { Component, OnInit } from '@angular/core';
import { IrlcoreAuthService, GlobalService } from '@irlca/irlcore';
import { ArmService } from '../arm.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userAuthenticated: boolean = false;

  constructor(public auth: IrlcoreAuthService,
    public globalService: GlobalService,
    public armService: ArmService) { }

  async ngOnInit() {
    this.userAuthenticated = await this.auth.isAuthenticated_0150();

    // Bind local variable to authentication state
    this.auth.$authenticationState.subscribe(
      (isAuthenticated: boolean) => this.userAuthenticated = isAuthenticated);
  }

  logout() {
    this.auth.logout_0432('/login');
  }
}
