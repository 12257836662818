import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { APIService, dataset, datasetGet, DataToGet, DataToPost, DialogService, GlobalService, IrlcoreAuthService } from '@irlca/irlcore';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ARMDialogService } from '../../../shared/dialog/dialog.service';
import { ArmService } from '../../../arm.service';
import { cloneDeep } from 'lodash';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { RegisterService } from '../../register/register.service';
import * as moment from 'moment';
import { faArrowCircleRight, faArrowLeft, faArrowRight, faCheckCircle, faEdit, faEllipsisH, faExclamationTriangle, faFileExport, faPlus, faUserCheck, faUserClock, faUserTimes, faEye, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-risk-record',
  templateUrl: './risk-record.component.html',
  styleUrls: ['./risk-record.component.scss']
})
export class RiskRecordComponent implements OnInit {

  riskDecisions: any[] = ['Accept', 'Action'];
  editModeBool: boolean = false;

  // DOC Comment: I think these booleans can be removed throughout as they were initially used to 
  // determine whether the FailureEffect/FailureCause descriptions were updated
  failureEffectEditedBool: boolean = false;
  failureCauseEditedBool: boolean = false;

  selectedSection: any;
  selectedRisk: any = {};
  oldRisk: any = {};
  riskPreventionControlDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  oldRiskPreventionControls: any[] = [];
  causePreventionControlDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  oldCausePreventionControls: any[] = [];
  detectionControlDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  oldDetectionControls: any[] = [];
  controlDisplayedColumns: string[] = ['controlID', 'control'];
  riskStatuses: any[] = [];
  displayRiskStatuses: any[] = [];
  users: any[] = [];
  riskRecordDashboard: any = {};
  filteredUsers!: Observable<any>;
  riskRecordForm!: FormGroup;
  datasets: any[] = [];
  riskRecordDataset!: dataset;
  failureEffectDataset!: dataset;
  failureCauseDataset!: dataset;
  controlFailureEffectM2MDataset: dataset = { data: [], dataset: 'ControlFailureEffectM2M' };
  controlFailureCauseM2MDataset: dataset = { data: [], dataset: 'ControlFailureCauseM2M' };
  auditTrail: any[] = [];
  myParam: string = '';
  today: any;
  base64String: string = '';
  auditTrailPDF: any = null;
  stopBS: boolean = false;
  displayLoader: boolean = false;

  viewAuditTrailBool: boolean = false;
  mitigatingActionsAndTeam: any = {};
  mitigationPercentage: number = 0;
  mitigationOverdue: boolean = false;
  totalMitigationMonths: number = 0;
  totalMitigationMonthsLeft: number = 0;

  failureEffectControlEntryID: number = -1;
  failureCauseControlEntryID: number = -1;

  riskRecordDisplayObject: any;
  riskConfirmedDeniedBool: boolean = false;
  selectedSODRatingFailureEffectUpdated: boolean = false;
  selectedSODRatingFailureCauseUpdated: boolean = false;
  failureCauseControlsUpdated: boolean = false;
  failureEffectControlsUpdated: boolean = false;

  recordUsers: any[] = [];
  proximityPercentage!: number;
  sections: any = {
    initialRiskAnalysis: {
      route: 'initialRiskAnalysis',
      title: 'Initial Risk Analysis'
    },
    riskControl: {
      route: 'riskControl',
      title: 'Risk Control'
    },
    residualRiskAnalysis: {
      route: 'residualRiskAnalysis',
      title: 'Residual Risk Analysis'
    },
    details: {
      route: 'details',
      title: 'Details'
    },
    team: {
      route: 'team',
      title: 'Team'
    }
  };

  displayInstructions: any = {};
  tasksDeleted: number = 0;

  // showInstructionConfiguration: boolean = !this.globalService.configurableParameters.turnOffTutorial;

  // addInitialSeverityComment: boolean = false;
  // addResidualSeverityComment: boolean = false;

  // addInitialOccurrenceComment: boolean = false;
  // addResidualOccurrenceComment: boolean = false;
  // addInitialDetectabilityComment: boolean = false;
  // addResidualDetectabilityComment: boolean = false;

  // showSeverityJustificationOption: boolean = false;
  // showSeverityJustificationOptionEdit: boolean = false;
  // showOccurrenceJustificationOption: boolean = false;
  // showOccurrenceJustificationOptionEdit: boolean = false;
  // showDetectabilityJustificationOption: boolean = false;
  // showDetectabilityJustificationOptionEdit: boolean = false;

  riskPermission: string = "";
  riskStatusUpdated: boolean = false;
  riskOwnerUpdated: boolean = false;

  mitigatingActions: any[] = [];

  showSeverityInstructionSet: boolean = false;
  showOccurrenceInstructionSet: boolean = false;
  showDetectabilityInstructionSet: boolean = false;

  occurrenceGuidanceStepStatus: boolean = false;
  detectabilityGuidanceStepStatus: boolean = false;

  severityUpdated: boolean = false;
  occurrenceUpdated: boolean = false;
  detectabilityUpdated: boolean = false;
  updateTaskPull: boolean = false;

  // icons
  arrowLeft = faArrowLeft;
  arrowRight = faArrowRight;
  ellipsisHorizontal = faEllipsisH;
  plus = faPlus;
  exclamationTriangle = faExclamationTriangle;
  checkCircle = faCheckCircle;
  edit = faEdit;
  userCheck = faUserCheck;
  userTimes = faUserTimes;
  userClock = faUserClock;
  arrowCirlceRight = faArrowCircleRight;
  fileExport = faFileExport;
  eye = faEye;
  timesCircle = faTimesCircle;

  @ViewChild('InitialRiskGauge', { static: false })
  canvas!: ElementRef<HTMLCanvasElement>;
  public initialRiskContext!: CanvasRenderingContext2D;

  constructor(public armService: ArmService,
    public globalService: GlobalService,
    public apiService: APIService,
    private fb: FormBuilder,
    private dialogService: ARMDialogService,
    private router: Router,
    private route: ActivatedRoute,
    public irlcoreDialogService: DialogService,
    public registerService: RegisterService,
    public auth: IrlcoreAuthService) { }

  ngOnInit() {
    this.initialiseRiskRecordComponent();
  }

  initialiseRiskRecordComponent() {
    this.postViewRecordToAuditTrailRiskRecord_XXXX();
    if (this.armService.selectedRiskInitiateApproval) {
      this.selectedRisk = this.armService.selectedRisk;
      this.initiateNewRevisionRisk_XXXX();
      this.armService.selectedRiskInitiateApproval = false;
      this.armService.selectedRiskReportID = null;
    } else {
      this.prepareDataForGetRiskRecord_0190();
      this.prepareDataForGetRecordCollaborators_0190();
    }

    this.today = Date.now();

    // DOC Comment: Both of these checks are doing a very similar thing but are done differently. One uses the Angular 
    // Router and the other uses an ArmService boolean - should be updated to both use the same method
    this.route.params.subscribe((params: Params) => this.myParam = params['currentTab']);
    if (this.myParam === 'riskControl') {
      this.selectedSection = this.sections[Object.keys(this.sections)[1]];
    } else {
      this.selectedSection = this.sections[Object.keys(this.sections)[0]];
    }

    if (this.armService.navigateFromRiskRecord) {
      this.selectedSection = this.sections['details'];
      this.armService.navigateFromRiskRecord = false;
    }

    this.auth.$pdfReady.subscribe((pdf: any) => {
      if (pdf && this.stopBS) {
        this.auth.downloadPDF_0422(this.auditTrailPDF)
        this.stopBS = false;
        this.displayLoader = false;
      }
    });
  }

  postViewRecordToAuditTrailRiskRecord_XXXX() {
    let datasets: dataset[] = [];
    let auditTrailDataset: dataset = {
      data: [{
        userName: this.globalService.currentUser.fullName,
        userID: this.globalService.userID,
        riskREF: this.armService.selectedRisk.riskREF,
        riskID: this.armService.selectedRisk.iD
      }],
      dataset: "AuditTrail - Risk"
    }
    datasets.push(auditTrailDataset);
    let toDB = new DataToPost(this.globalService.clientID, this.globalService.userID, "", "View Risk Assessment", datasets, [], null);
    this.apiService.postDataToBackend_0051(this.globalService.serverBaseURL() + '/riskRiskAssessment/recordRiskAssessmentAccess', toDB).then((result: any) => {
      if (result) { } else {
        console.log("post unsuccessful");
      }
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Audit Trail");
      window.alert(userErrMsg)
    });
  }

  @HostListener('window:resize')
  onResize() {
    this.renderRiskRecordDashboard_XXXX(this.initialRiskContext);
  }

  closeAuditTrail() {
    this.viewAuditTrailBool = false;

    setTimeout(() => {
      this.renderRiskRecordDashboard_XXXX(this.initialRiskContext);
    }, 100);

  }

  async prepareDataForGetRecordCollaborators_0190() {
    let datasets: datasetGet[] = [];

    let riskAssessmentUsers: datasetGet = {
      parameters: {
        riskID: this.armService.selectedRisk.iD,
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID
      },
      storedProcedure: "DAMRiskRiskAssessmentM20Users"
    };

    datasets.push(riskAssessmentUsers);
    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Record Collaborators DAM", datasets);

    await this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      this.recordUsers = fromDB[0];
      this.determineRiskRecordDAMPermission();
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Record Collaborators");
      window.alert(userErrMsg)
    });
  }

  determineRiskRecordDAMPermission() {
    let matchFound = false;
    for (let index = 0; index < this.recordUsers.length; index++) {
      if (this.globalService.userID == this.recordUsers[index].userID) {
        matchFound = true;
        if (this.armService.selectedRisk.permissionName !== "Owner") {
          this.riskPermission = this.recordUsers[index].permissionName;
        }
        else {
          this.riskPermission = "Owner";
        }
      }
    }
    if (!matchFound)
      this.riskPermission = this.armService.selectedRiskAssessment.permissionName;
  }

  openDataAccessManagementDialog_0192() {
    let damConfigData = {
      minWidth: '60vw',
      maxWidth: '60vw',
      data: {
        selectedTableRowID: this.armService.selectedRisk.iD,
        recordUsers: this.recordUsers,
        tableName: "tblRisk"
      }
    }

    const dialogRef = this.irlcoreDialogService.openDialog_0088("DataAccessManagementComponent", damConfigData)
    dialogRef.afterClosed().subscribe(() => {
      this.prepareDataForGetRecordCollaborators_0190();
    });
  }

  previewRiskAssessmentReport() {
    this.armService.selectedRiskInitiateApproval = false;
    this.armService.riskForReport = this.selectedRisk;
    this.router.navigate(['/risk-assessment/risk-report'])
  }

  prepareDataForGetRiskRecord_0190() {
    let datasets: datasetGet[] = [];

    let riskRecordDataset: datasetGet = {
      parameters: {
        iD: this.armService.selectedRisk.riskID,
        clientID: this.globalService.clientID,
        entity: this.globalService.entityID,
        SODCategoryID: null,
        riskStatusID: null,
      },
      storedProcedure: "Risk"
    };

    let riskPreventionControlDataset: datasetGet = {
      parameters: {
        iD: null,
        controlID: null,
        failureEffectID: this.armService.selectedRisk.failureEffectID,
      },
      storedProcedure: "ControlFailureEffectM2M"
    };

    let causePreventionControlDataset: datasetGet = {
      parameters: {
        iD: null,
        controlID: null,
        failureCauseID: this.armService.selectedRisk.failureCauseID,
        isOccurrenceControl: true
      },
      storedProcedure: "ControlFailureCauseM2M"
    };

    let detectionControlDataset: datasetGet = {
      parameters: {
        iD: null,
        controlID: null,
        failureCauseID: this.armService.selectedRisk.failureCauseID,
        isOccurrenceControl: false
      },
      storedProcedure: "ControlFailureCauseM2M"
    };

    let riskStatusDataset: datasetGet = {
      parameters: {
      },
      storedProcedure: "RiskStatus"
    };

    let userDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        departmentID: null,
        searchValue: null,
      },
      storedProcedure: "User"
    };

    let riskRecordDashboardDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entity: this.globalService.entityID,
        riskID: this.armService.selectedRisk.iD,
      },
      storedProcedure: "RiskRecordDashboard"
    }

    datasets.push(riskRecordDataset, riskPreventionControlDataset, causePreventionControlDataset, detectionControlDataset, riskStatusDataset, userDataset, riskRecordDashboardDataset);
    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Risk Record", datasets);

    this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      this.selectedRisk = fromDB[0][0];
      this.selectedRisk.riskQuestion = this.armService.selectedRiskAssessment.riskQuestion;
      this.selectedRisk.proximity = this.armService.selectedRiskAssessment.riskAssessmentProximity;
      this.selectedRisk.riskAssessmentTool = this.armService.selectedRiskAssessment.riskAssessmentTool;
      this.selectedRisk["permissionName"] = this.armService.selectedRiskAssessment.permissionName;
      if (this.selectedRisk.permissionName == undefined) {
        this.selectedRisk["permissionName"] = this.riskPermission;
      }
      this.oldRisk = cloneDeep(this.selectedRisk);
      this.actionEliminateSetup();
      this.armService.selectedRisk = this.selectedRisk;
      this.armService.selectedRisk["riskID"] = this.selectedRisk.iD;

      this.riskPreventionControlDataSource.data = fromDB[1];
      this.oldRiskPreventionControls = fromDB[1];
      this.causePreventionControlDataSource.data = fromDB[2];

      this.oldCausePreventionControls = fromDB[2];
      this.detectionControlDataSource.data = fromDB[3];
      this.oldDetectionControls = fromDB[3];
      this.riskStatuses = fromDB[4];
      this.displayRiskStatuses = fromDB[4].slice(0, 2);
      this.users = fromDB[5];

      this.riskRecordDashboard = fromDB[6][0];

      this.calcProximity_XXXX();
      this.calcMitigationPercentage_XXXX();

      // DOC Comment: only needed depending on configurable parameter 
      this.initialiseDisplayInstructions();

      if (this.selectedSection.route === 'initialRiskAnalysis')
        this.setRiskRecordDisplayObject_XXXX(true);
      else this.setRiskRecordDisplayObject_XXXX(false);
      this.riskRecordForm = this.createRiskRecordFormGroup_0410();
      this.onResize();
      this.filteredUsers = this.riskRecordForm.get('riskOwnerName')!.valueChanges
        .pipe(
          startWith(''),
          map(value => this.filterUsers_0411(value))
        );

      this.mitigatingActions = cloneDeep(this.globalService.tasks);
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Risk Record");
      window.alert(userErrMsg)
    });

  }

  createRiskRecordFormGroup_0410() {
    return this.fb.group({
      riskOwnerName: [this.selectedRisk.riskOwnerName],
      riskStatusID: [this.selectedRisk.riskStatusID],
      riskDecision: [this.selectedRisk.riskDecision],
      riskEliminate: [this.selectedRisk.isActionEliminate],
      riskDecisionJustification: [this.selectedRisk.riskDecisionJustification],
      initialSeverityJustification: [this.selectedRisk.severityJustification],
      residualSeverityJustification: [this.selectedRisk.residualSeverityJustification],
      initialOccurrenceJustification: [this.selectedRisk.occurrenceJustification],
      residualOccurrenceJustification: [this.selectedRisk.residualOccurrenceJustification],
      initialDetectabilityJustification: [this.selectedRisk.detectabilityJustification],
      residualDetectabilityJustification: [this.selectedRisk.residualDetectabilityJustification]
    });

  }

  filterUsers_0411(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.users.filter(option => option.fullName.toLowerCase().includes(filterValue));
  }

  prepareDataForPostRiskRecord_0450() {
    this.createRiskRecordDatasets_XXXX();
    let toDB = new DataToPost(this.globalService.clientID, this.globalService.userID, "Test reason", "Update Risk Record", this.datasets, this.auditTrail);
    this.apiService.postDataToBackend_0051(this.globalService.serverBaseURL() + '/riskRiskAssessment/saveRiskAssessmentExpandedDetail', toDB).then((data: any) => {
      if (data) {
        this.editModeBool = false;
        this.globalService.editModeBool = false;
        this.armService.selectedRisk = this.selectedRisk;
        this.armService.selectedRisk["riskID"] = this.selectedRisk.iD;
        if (this.controlFailureCauseM2MDataset)
          this.controlFailureCauseM2MDataset.data = [];
        if (this.controlFailureEffectM2MDataset)
          this.controlFailureEffectM2MDataset.data = [];
        if (this.riskRecordDataset)
          this.riskRecordDataset.data = [];
        if (this.failureCauseDataset)
          this.failureCauseDataset.data = [];
        if (this.failureEffectDataset)
          this.failureEffectDataset.data = [];
        this.failureCauseEditedBool = false;
        this.failureEffectEditedBool = false;
        this.selectedSODRatingFailureEffectUpdated = false;
        this.selectedSODRatingFailureCauseUpdated = false;
        this.failureCauseControlsUpdated = false;
        this.failureEffectControlsUpdated = false;
        this.riskConfirmedDeniedBool = false
        this.globalService.tasksDataset.data = [];
        this.globalService.extendingTaskDataset.data = [];
        this.globalService.taskLinkingDataset.data = [];
        this.globalService.tasksAuditTrailDataset = [];
        this.globalService.tasksMitigatingActionRiskM2MAuditTrailDataset = [];
        this.auditTrail = [];
        this.datasets = [];
        this.severityUpdated = false;
        this.occurrenceUpdated = false;
        this.detectabilityUpdated = false;
        this.riskStatusUpdated = false;
        this.riskOwnerUpdated = false;
        this.failureEffectControlEntryID = -1;
        this.failureCauseControlEntryID = -1;
        this.updateTaskPull = !this.updateTaskPull;
        this.prepareDataForGetRiskRecord_0190();
      }
    }).catch((err: any) => {
      console.error(err);
      this.cancelChanges_XXXX();
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Risk Record");
      window.alert(userErrMsg)
    });
  }

  createRiskRecordDatasets_XXXX() {
    let currentRiskStatus: any = this.getCurrentRiskStatus_XXXX();
    if (this.riskRecordForm.controls['riskOwnerName'].dirty || this.riskRecordForm.controls['riskStatusID'].dirty || this.riskRecordForm.controls['riskDecision'].dirty || this.riskRecordForm.controls['riskEliminate'].dirty
      || this.riskRecordForm.controls['riskDecisionJustification'].dirty || this.riskConfirmedDeniedBool || this.selectedSODRatingFailureEffectUpdated
      || this.selectedSODRatingFailureCauseUpdated || (currentRiskStatus.currentRiskStatus !== this.selectedRisk.riskStatusID)) {
      let riskOwnerID = this.getUserID_0412(this.riskRecordForm.value.riskOwnerName);
      let riskOwnerName = this.riskRecordForm.value.riskOwnerName;
      if (riskOwnerID !== this.oldRisk.riskOwnerID) {
        this.selectedRisk.isRejected = null;
        this.selectedRisk.ownershipDate = null;
      }

      let tempOldRisk = {
        iD: this.oldRisk.iD,
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID,
        riskREF: this.oldRisk.riskRef,
        categoryID: this.oldRisk.categoryID,
        riskStatusID: this.oldRisk.riskStatusID,
        riskStatusName: this.oldRisk.riskStatus,
        failureCauseID: this.oldRisk.failureCauseID,
        failureEffectID: this.oldRisk.failureCauseID,
        riskOwnerID: this.oldRisk.riskOwnerID,
        riskOwnerName: this.oldRisk.riskOwnerName,
        isRejected: this.oldRisk.isRejected,
        approvedDate: this.oldRisk.approvedDate,
        ownershipDate: this.oldRisk.ownershipDate,
        creationDate: this.oldRisk.creationDate,
        initialOccurrenceVsDetectability: this.oldRisk.initialOccurrenceVsDetectability,
        initialRiskLevel: this.oldRisk.initialRiskLevel,
        initialRPN: this.oldRisk.initialRPN,
        initialRS: this.oldRisk.initialRS,
        riskDecision: this.oldRisk.riskDecision,
        riskDecisionJustification: this.oldRisk.riskDecisionJustification,
        residualOccurrenceVsDetectability: this.oldRisk.residualOccurrenceVsDetectability,
        residualRiskLevel: this.oldRisk.residualRiskLevel,
        residualRPN: this.oldRisk.residualRPN,
        residualRS: this.oldRisk.residualRS,
        riskVersion: this.oldRisk.riskVersion,
        readyForApproval: this.oldRisk.readyForApproval,
        managedDate: this.oldRisk.managedDate,
        treatmentDecidedDate: this.oldRisk.treatmentDecidedDate,
        isTemplate: this.oldRisk.isTemplate,
      }

      let riskDecision: string = this.riskRecordForm.value.riskDecision;

      if (this.riskRecordForm.value.riskDecision === "Action" && this.riskRecordForm.value.riskEliminate) {
        riskDecision = "Eliminate";
      } else if (this.riskRecordForm.value.riskDecision === "Action" && !this.riskRecordForm.value.riskEliminate) {
        riskDecision = "Reduce";
      };


      let treatmentDecidedDate: Date = this.selectedRisk.treatmentDecidedDate;
      if (riskDecision !== this.oldRisk.riskDecision) {
        treatmentDecidedDate = new Date();
      }
      this.riskRecordDataset = {
        data: [{
          iD: this.selectedRisk.iD,
          clientID: this.globalService.clientID,
          entityID: this.globalService.entityID,
          riskREF: this.selectedRisk.riskRef,
          categoryID: this.selectedRisk.categoryID,
          failureCauseID: this.selectedRisk.failureCauseID,
          failureEffectID: this.selectedRisk.failureEffectID,
          riskStatusID: currentRiskStatus.currentRiskStatus,
          riskStatusName: currentRiskStatus.currentRiskStatusName,
          riskOwnerID: riskOwnerID,
          riskOwnerName: riskOwnerName,
          isRejected: this.selectedRisk.isRejected,
          ownershipDate: this.selectedRisk.ownershipDate,
          creationDate: this.selectedRisk.creationDate,
          initialOccurrenceVsDetectability: this.armService.calculateOccurrenceVsDetectabilityMedicalDevice_XXXX(this.selectedRisk.occurrence, this.selectedRisk.detectability),
          initialRiskLevel: this.selectedRisk.initialRiskLevel,
          initialRPN: this.selectedRisk.initialRPN,
          initialRS: this.selectedRisk.initialRS,
          riskDecision: riskDecision,
          riskDecisionJustification: this.riskRecordForm.value.riskDecisionJustification,
          residualOccurrenceVsDetectability: this.armService.calculateOccurrenceVsDetectabilityMedicalDevice_XXXX(this.selectedRisk.residualOccurrence, this.selectedRisk.residualDetectability),
          residualRiskLevel: this.selectedRisk.residualRiskLevel,
          residualRPN: this.selectedRisk.residualRPN,
          residualRS: this.selectedRisk.residualRS,
          riskVersion: this.selectedRisk.riskVersion,
          isTemplate: this.selectedRisk.isTemplate,
          approvedDate: this.selectedRisk.approvedDate,
          readyForApproval: this.riskRecordReadyForApproval(),
          managedDate: this.selectedRisk.managedDate,
          treatmentDecidedDate: treatmentDecidedDate,
          statementType: 'UPDATE'
        }],
        dataset: "Risk",
      }

      this.auditTrail = this.globalService.buildAuditTrail_0115(tempOldRisk, this.riskRecordDataset.data[0], "tblRisk");
      // Checks if Risk dataset hasn't actually been updated (Only happens when Severity OR Occurrence OR Detectability is updated individually on a risk that has no RPN yet)
      if (this.auditTrail.length > 0) {
        this.datasets.push(this.riskRecordDataset);
      }
    }

    if (this.failureEffectEditedBool || this.selectedSODRatingFailureEffectUpdated || this.riskRecordForm.controls['initialSeverityJustification'].dirty || this.riskRecordForm.controls['residualSeverityJustification'].dirty || this.severityUpdated) {
      this.failureEffectDataset = {
        data: [{
          iD: this.selectedRisk.failureEffectID,
          clientID: this.globalService.clientID,
          entityID: this.globalService.entityID,
          failureModeID: this.selectedRisk.failureModeID,
          failureEffect: this.selectedRisk.failureEffect,
          severityID: this.selectedRisk.severityID,
          severityJustification: this.riskRecordForm.value.initialSeverityJustification,
          residualSeverityID: this.selectedRisk.residualSeverityID,
          residualSeverityJustification: this.riskRecordForm.value.residualSeverityJustification,
          statementType: "UPDATE"
        }],
        dataset: "FailureEffect"
      }
      this.datasets.push(this.failureEffectDataset);

      let oldFailureEffect: any = {
        iD: this.selectedRisk.failureEffectID,
        clientID: this.selectedRisk.clientID,
        entityID: this.selectedRisk.entityID,
        failureModeID: this.oldRisk.failureModeID,
        failureEffect: this.oldRisk.failureEffect,
        severityID: this.oldRisk.severityID,
        severityJustification: this.oldRisk.severityJustification,
        residualSeverityID: this.oldRisk.residualSeverityID,
        residualSeverityJustification: this.oldRisk.residualSeverityJustification,
      }
      this.auditTrail = this.auditTrail.concat(this.globalService.buildAuditTrail_0115(oldFailureEffect, this.failureEffectDataset.data[0], "tblFailureEffect"));
    }

    if (this.controlFailureEffectM2MDataset.data.length > 0) {
      this.datasets.push(this.controlFailureEffectM2MDataset);
      for (var i = 0; i < this.controlFailureEffectM2MDataset.data.length; i++) {
        this.auditTrail = this.auditTrail.concat(this.globalService.buildAuditTrail_0115({}, this.controlFailureEffectM2MDataset.data[i], "tblControlFailureEffectM2M"));
      }
    }

    if (this.failureCauseEditedBool || this.selectedSODRatingFailureCauseUpdated || this.riskRecordForm.controls['initialOccurrenceJustification'].dirty || this.riskRecordForm.controls['residualOccurrenceJustification'].dirty || this.riskRecordForm.controls['initialDetectabilityJustification'].dirty || this.riskRecordForm.controls['residualDetectabilityJustification'].dirty || this.occurrenceUpdated || this.detectabilityUpdated) {
      this.failureCauseDataset = {
        data: [{
          iD: this.selectedRisk.failureCauseID,
          clientID: this.globalService.clientID,
          entityID: this.globalService.entityID,
          failureModeID: this.selectedRisk.failureModeID,
          failureCause: this.selectedRisk.failureCause,
          occurrenceID: this.selectedRisk.occurrenceID,
          occurrenceJustification: this.riskRecordForm.value.initialOccurrenceJustification,
          residualOccurrenceID: this.selectedRisk.residualOccurrenceID,
          residualOccurrenceJustification: this.riskRecordForm.value.residualOccurrenceJustification,
          detectabilityID: this.selectedRisk.detectabilityID,
          detectabilityJustification: this.riskRecordForm.value.initialDetectabilityJustification,
          residualDetectabilityID: this.selectedRisk.residualDetectabilityID,
          residualDetectabilityJustification: this.riskRecordForm.value.residualDetectabilityJustification,
          statementType: "UPDATE"
        }],
        dataset: "FailureCause"
      }
      this.datasets.push(this.failureCauseDataset);

      let oldFailureCause: any = {
        iD: this.selectedRisk.failureEffectID,
        clientID: this.selectedRisk.clientID,
        entityID: this.selectedRisk.entityID,
        failureModeID: this.oldRisk.failureModeID,
        failureCause: this.oldRisk.failureCause,
        occurrenceID: this.oldRisk.occurrenceID,
        occurrenceJustification: this.oldRisk.occurrenceJustification,
        detectabilityID: this.oldRisk.detectabilityID,
        detectabilityJustification: this.oldRisk.detectabilityJustification,
        residualOccurrenceID: this.oldRisk.residualOccurrenceID,
        residualOccurrenceJustification: this.oldRisk.residualOccurrenceJustification,
        residualDetectabilityID: this.oldRisk.residualDetectabilityID,
        residualDetectabilityJustification: this.oldRisk.residualDetectabilityJustification
      }

      this.auditTrail = this.auditTrail.concat(this.globalService.buildAuditTrail_0115(oldFailureCause, this.failureCauseDataset.data[0], "tblFailureCause"));
    }

    if (this.controlFailureCauseM2MDataset.data.length > 0) {
      this.datasets.push(this.controlFailureCauseM2MDataset);
      for (var i = 0; i < this.controlFailureCauseM2MDataset.data.length; i++) {
        this.auditTrail = this.auditTrail.concat(this.globalService.buildAuditTrail_0115({}, this.controlFailureCauseM2MDataset.data[i], "tblControlFailureCauseM2M"));
      }
    }

    if (this.globalService.tasksDataset.data && this.globalService.tasksDataset.data.length > 0) {
      this.globalService.tasksDataset.data.sort((a: any, b: any) => {
        if (a.statementType == "INSERT" && b.statementType != "INSERT") { return -1 }
        return 0;
      });
      this.datasets.push(this.globalService.tasksDataset);
      this.globalService.tasksAuditTrailDataset.sort((a: any, b: any) => {
        if (a.statementType == "INSERT" && b.statementType != "INSERT") { return -1 }
        return 0;
      })
      this.auditTrail = this.auditTrail.concat(this.globalService.tasksAuditTrailDataset);

      if (this.globalService.extendingTaskDataset.data && this.globalService.extendingTaskDataset.data.length > 0) {
        this.datasets.push(this.globalService.extendingTaskDataset);
        this.auditTrail = this.auditTrail.concat(this.globalService.tasksExtendedMitigatingActionAuditTrailDataset);
      }
    }

    if (this.globalService.taskLinkingDataset.data && this.globalService.taskLinkingDataset.data.length > 0) {
      this.datasets.push(this.globalService.taskLinkingDataset);
      this.auditTrail = this.auditTrail.concat(this.globalService.tasksMitigatingActionRiskM2MAuditTrailDataset);
    }
  }

  navigateToMitigatingActionRegister_0409() {
    this.armService.expandedTaskView = true;
    this.router.navigate(['/register/mitigating-action-register']);
  }

  confirmRiskOwnership_0500(confirm: boolean) {
    if (confirm) {
      this.selectedRisk.isRejected = false;
      this.selectedRisk.ownershipDate = new Date(Date.now());
    } else {
      this.selectedRisk.isRejected = true;
      this.selectedRisk.ownershipDate = null;
    }

    this.riskConfirmedDeniedBool = true;
  }

  async prepareDataForGetAuditTrail() {
    this.armService.auditTrailTableNames = [];
    let datasets: datasetGet[] = [];
    let riskAuditTrailDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        tableName: 'tblRisk',
        entryID: this.selectedRisk.iD,
        statementType: null,
        userID: null
      },
      storedProcedure: 'AuditTrail'
    }
    datasets.push(riskAuditTrailDataset);
    this.armService.auditTrailTableNames.push('tblRisk');

    let failureEffectAuditTrailDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        tableName: 'tblFailureEffect',
        entryID: this.selectedRisk.failureEffectID,
        statementType: null,
        userID: null
      },
      storedProcedure: 'AuditTrail'
    }
    datasets.push(failureEffectAuditTrailDataset);
    this.armService.auditTrailTableNames.push('tblFailureEffect');

    let failureCauseAuditTrailDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        tableName: 'tblFailureCause',
        entryID: this.selectedRisk.failureCauseID,
        statementType: null,
        userID: null
      },
      storedProcedure: 'AuditTrail'
    }
    datasets.push(failureCauseAuditTrailDataset);
    this.armService.auditTrailTableNames.push('tblFailureCause');

    if (this.recordUsers.length) {
      for (var i = 0; i < this.recordUsers.length; i++) {
        let DAMAuditTrailDataset: datasetGet = {
          parameters: {
            clientID: this.globalService.clientID,
            tableName: 'tblSysUserTablePermission',
            entryID: this.recordUsers[i].iD,
            statementType: null,
            userID: null,
            startDate: null,
            endDate: null,
          },
          storedProcedure: 'AuditTrail'
        }
        datasets.push(DAMAuditTrailDataset);
      }
      this.armService.auditTrailTableNames.push('tblSysUserTablePermission');
    }
    await this.prepareDataForGetTeamAndMitigatingActions().then(() => {
      if (this.mitigatingActionsAndTeam[0].length > 0) {
        for (var i = 0; i < this.mitigatingActionsAndTeam[0].length; i++) {
          let riskAssessmentTeamAuditTrailDataset: datasetGet = {
            parameters: {
              clientID: this.globalService.clientID,
              tableName: 'tblRiskAssessmentTeamMember',
              entryID: this.mitigatingActionsAndTeam[0][i].iD,
              statementType: null,
              userID: null
            },
            storedProcedure: 'AuditTrail'
          }
          datasets.push(riskAssessmentTeamAuditTrailDataset);
        }
        this.armService.auditTrailTableNames.push('tblRiskAssessmentTeamMember');
      }

      if (this.mitigatingActionsAndTeam[1].length > 0) {
        for (var i = 0; i < this.mitigatingActionsAndTeam[1].length; i++) {
          let mitigatingActionsAuditTrailDataset: datasetGet = {
            parameters: {
              clientID: this.globalService.clientID,
              tableName: 'tblMitigatingActionRiskM2M',
              entryID: this.mitigatingActionsAndTeam[0][i].iD,
              statementType: null,
              userID: null
            },
            storedProcedure: 'AuditTrail'
          }
          datasets.push(mitigatingActionsAuditTrailDataset);
        }
        this.armService.auditTrailTableNames.push('tblMitigatingActionRiskM2M');
      }
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Team And Mitigating Actions");
      window.alert(userErrMsg)
    });

    if (this.oldRiskPreventionControls.length > 0) {
      for (var i = 0; i < this.oldRiskPreventionControls.length; i++) {
        let riskPreventionAuditTrailDataset: datasetGet = {
          parameters: {
            clientID: this.globalService.clientID,
            tableName: 'tblControlFailureEffectM2M',
            entryID: this.oldRiskPreventionControls[i].iD,
            statementType: null,
            userID: null
          },
          storedProcedure: 'AuditTrail'
        }
        datasets.push(riskPreventionAuditTrailDataset);
      }
      this.armService.auditTrailTableNames.push('tblControlFailureEffectM2M');
    }

    if (this.oldDetectionControls.length > 0) {
      for (var i = 0; i < this.oldDetectionControls.length; i++) {
        let detectionAuditTrailDataset: datasetGet = {
          parameters: {
            clientID: this.globalService.clientID,
            tableName: 'tblControlFailureCauseM2M',
            entryID: this.oldDetectionControls[i].iD,
            statementType: null,
            userID: null
          },
          storedProcedure: 'AuditTrail'
        }
        datasets.push(detectionAuditTrailDataset);
      }
      this.armService.auditTrailTableNames.push('tblControlFailureCauseM2M');
    }

    if (this.oldCausePreventionControls.length > 0) {
      for (var i = 0; i < this.oldCausePreventionControls.length; i++) {
        let causePreventionAuditTrailDataset: datasetGet = {
          parameters: {
            clientID: this.globalService.clientID,
            tableName: 'tblControlFailureCauseM2M',
            entryID: this.oldCausePreventionControls[i].iD,
            statementType: null,
            userID: null
          },
          storedProcedure: 'AuditTrail'
        }
        datasets.push(causePreventionAuditTrailDataset);
      }
      this.armService.auditTrailTableNames.push('tblControlFailureCauseM2M');
    }

    if (this.globalService.tasks.length > 0) {
      for (var i = 0; i < this.globalService.tasks.length; i++) {
        let tasksAuditTrailDataset: datasetGet = {
          parameters: {
            clientID: this.globalService.clientID,
            tableName: 'tblTask',
            entryID: this.globalService.tasks[i].iD,
            statementType: null,
            userID: null
          },
          storedProcedure: 'AuditTrail'
        }
        datasets.push(tasksAuditTrailDataset);
      }
      this.armService.auditTrailTableNames.push('tblTask');
    }

    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Audit Trail Risk Record", datasets);

    await this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      this.armService.auditTrailListView = fromDB;
      this.viewAuditTrailBool = true;
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Control Failure Effect & Control Failure Cause");
      window.alert(userErrMsg)
    });
  }

  async prepareDataForGetTeamAndMitigatingActions() {
    let datasets: datasetGet[] = [];
    let teamDataset: datasetGet = {
      parameters: {
        clientID: this.globalService.clientID,
        entity: this.globalService.entityID,
        riskAssessmentID: this.globalService.selectedRiskAssessment.iD,
      },
      storedProcedure: "RiskAssessmentTeamMember"
    };

    let mitigatingActionDataset: datasetGet = {
      parameters: {
        iD: null,
        riskID: this.selectedRisk.iD,
        actionID: null,
        clientID: this.globalService.clientID,
        entityID: this.globalService.entityID
      },
      storedProcedure: "MitigatingActionRiskM2M"
    };
    datasets.push(teamDataset, mitigatingActionDataset);

    let dataToGet = new DataToGet(this.globalService.clientID, this.globalService.userID, "View Risk Assessment Members And Mitigating Actions", datasets);

    await this.apiService.getDataFromBackend_0090(this.apiService.urlForSingleGet, dataToGet).then((fromDB: any) => {
      this.mitigatingActionsAndTeam = fromDB;
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Get Risk Assessment Team Members & Mitigating Actions");
      window.alert(userErrMsg)
    });
  }

  getUserID_0412(userFullName: string): number {
    let user = this.users.filter(user => user.fullName === userFullName);
    if (user.length >= 1)
      return user[0].userID;
    else
      return 1;
  }

  openRatingDialog_0192(isInitial: boolean, SODType: string) {
    let SODRatingDialogConfigData = {
      minWidth: '60vw',
      maxWidth: '60vw',
      panelClass: "ratingDialog",

      data: {
        isInitial: isInitial,
        SODType: SODType,
        selectedRisk: this.selectedRisk,
        detectabilityMandatory: this.armService.selectedRiskAssessment.detectabilityMandatory,
        statementType: 'UPDATE',
      }
    }

    const dialogRef = this.dialogService.openDialog_0088("SODRatingDialogComponent", SODRatingDialogConfigData)
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (isInitial) {
          if (SODType === "severity") {
            if (this.selectedRisk.severityID != result.data.selectedSeverity.iD) {
              this.severityUpdated = true;
            }
            this.displayInstructions.instructionSets[0].guidanceSteps[0].guidanceStepInitialComplete = true;
            this.displayInstructions.instructionSets[0].guidanceSteps[1].stepInitialEnabled = true;
            this.selectedRisk.severityID = result.data.selectedSeverity.iD;
            this.selectedRisk.severity = result.data.selectedSeverity.SODRating;
            this.selectedRisk.severityJustification = result.data.failureEffect.severityJustification;
            this.selectedRisk.severityClassification = result.data.selectedSeverity.SODClassification;
            this.selectedRisk.severityClassificationColour = result.data.selectedSeverity.SODClassificationColour;
            this.selectedRisk.severityClassificationTextColour = result.data.selectedSeverity.SODClassificationTextColour;

            this.selectedRisk.severityDescription = result.data.selectedSeverity.SODDescription;
            this.selectedRisk.severityCategoryID = result.data.severityCategoryID;
            this.selectedRisk.severityCategoryName = result.data.severityCategoryName;
          } else if (SODType === "occurrence") {
            if (this.selectedRisk.occurrenceID != result.data.selectedOccurrence.iD) {
              this.occurrenceUpdated = true;
            }
            this.displayInstructions.instructionSets[1].guidanceSteps[1].guidanceStepInitialComplete = true;
            this.displayInstructions.instructionSets[1].guidanceSteps[2].stepInitialEnabled = true;
            this.selectedRisk.occurrenceID = result.data.selectedOccurrence.iD;
            this.selectedRisk.occurrence = result.data.selectedOccurrence.SODRating;
            this.selectedRisk.occurrenceJustification = result.data.failureCause.occurrenceJustification;
            this.selectedRisk.occurrenceClassification = result.data.selectedOccurrence.SODClassification;
            this.selectedRisk.occurrenceClassificationTextColour = result.data.selectedOccurrence.SODClassificationTextColour;
            this.selectedRisk.occurrenceClassificationColour = result.data.selectedOccurrence.SODClassificationColour;

            this.selectedRisk.occurrenceDescription = result.data.selectedOccurrence.SODDescription;
            this.selectedRisk.occurrenceCategoryID = result.data.occurrenceCategoryID;
            this.selectedRisk.occurrenceCategoryName = result.data.occurrenceCategoryName;
          } else if (SODType === "detectability") {
            if (this.selectedRisk.detectabilityID != result.data.selectedDetectability.iD) {
              this.detectabilityUpdated = true;
            }
            this.displayInstructions.instructionSets[2].guidanceSteps[1].guidanceStepInitialComplete = true;
            this.displayInstructions.instructionSets[2].guidanceSteps[2].stepInitialEnabled = true;
            this.selectedRisk.detectabilityID = result.data.selectedDetectability.iD;
            this.selectedRisk.detectability = result.data.selectedDetectability.SODRating;
            this.selectedRisk.detectabilityJustification = result.data.failureCause.detectabilityJustification;
            this.selectedRisk.detectabilityClassification = result.data.selectedDetectability.SODClassification;
            this.selectedRisk.detectabilityClassificationColour = result.data.selectedDetectability.SODClassificationColour;
            this.selectedRisk.detectabilityClassificationTextColour = result.data.selectedDetectability.SODClassificationTextColour;
            this.selectedRisk.detectabilityDescription = result.data.selectedDetectability.SODDescription;
            this.selectedRisk.detectabilityCategoryID = result.data.detectabilityCategoryID;
            this.selectedRisk.detectabilityCategoryName = result.data.detectabilityCategoryName;
          }
        } else {
          if (SODType === "severity") {
            if (this.selectedRisk.residualSeverityID != result.data.selectedSeverity.iD) {
              this.severityUpdated = true;
            }
            this.displayInstructions.instructionSets[0].guidanceSteps[0].guidanceStepResidualComplete = true;
            this.displayInstructions.instructionSets[0].guidanceSteps[1].stepResidualEnabled = true;
            this.selectedRisk.residualSeverityID = result.data.selectedSeverity.iD;
            this.selectedRisk.residualSeverity = result.data.selectedSeverity.SODRating;
            this.selectedRisk.residualSeverityJustification = result.data.failureEffect.severityJustification;
            this.selectedRisk.residualSeverityClassification = result.data.selectedSeverity.SODClassification;
            this.selectedRisk.residualSeverityClassificationColour = result.data.selectedSeverity.SODClassificationColour;
            this.selectedRisk.residualSeverityDescription = result.data.selectedSeverity.SODDescription;
            this.selectedRisk.residualSeverityCategoryID = result.data.severityCategoryID;
            this.selectedRisk.residualSeverityCategoryName = result.data.severityCategoryName;
          } else if (SODType === "occurrence") {
            if (this.selectedRisk.residualOccurrenceID != result.data.selectedOccurrence.iD) {
              this.occurrenceUpdated = true;
            }
            this.displayInstructions.instructionSets[1].guidanceSteps[1].guidanceStepResidualComplete = true;
            this.displayInstructions.instructionSets[1].guidanceSteps[2].stepResidualEnabled = true;
            this.selectedRisk.residualOccurrenceID = result.data.selectedOccurrence.iD;
            this.selectedRisk.residualOccurrence = result.data.selectedOccurrence.SODRating;
            this.selectedRisk.residualOccurrenceJustification = result.data.failureCause.occurrenceJustification;
            this.selectedRisk.residualOccurrenceClassification = result.data.selectedOccurrence.SODClassification;
            this.selectedRisk.residualOccurrenceClassificationColour = result.data.selectedOccurrence.SODClassificationColour;
            this.selectedRisk.residualOccurrenceDescription = result.data.selectedOccurrence.SODDescription;
            this.selectedRisk.residualOccurrenceCategoryID = result.data.occurrenceCategoryID;
            this.selectedRisk.residualOccurrenceCategoryName = result.data.occurrenceCategoryName;
          } else if (SODType === "detectability") {
            if (this.selectedRisk.residualDetectabilityID != result.data.selectedDetectability.iD) {
              this.detectabilityUpdated = true;
            }
            this.displayInstructions.instructionSets[2].guidanceSteps[1].guidanceStepResidualComplete = true;
            this.displayInstructions.instructionSets[2].guidanceSteps[2].stepResidualEnabled = true;
            this.selectedRisk.residualDetectabilityID = result.data.selectedDetectability.iD;
            this.selectedRisk.residualDetectability = result.data.selectedDetectability.SODRating;
            this.selectedRisk.residualDetectabilityJustification = result.data.failureCause.detectabilityJustification;
            this.selectedRisk.residualDetectabilityClassification = result.data.selectedDetectability.SODClassification;
            this.selectedRisk.residualDetectabilityClassificationColour = result.data.selectedDetectability.SODClassificationColour;
            this.selectedRisk.residualDetectabilityDescription = result.data.selectedDetectability.SODDescription;
            this.selectedRisk.residualDetectabilityCategoryID = result.data.detectabilityCategoryID;
            this.selectedRisk.residualDetectabilityCategoryName = result.data.detectabilityCategoryName;
          }
        }

        // DOC Comment: This doesn't really make sense as the failureCause & failureEffect can no longer be updated in this dialog
        // From here
        this.selectedRisk.failureCause = result.data.failureCause.failureCause;
        this.selectedRisk.severityCategoryID = result.data.SODCategoryID;
        this.selectedRisk.failureEffect = result.data.failureEffect.failureEffect;
        if (result.data.failureCauseFormUpdated) {
          this.failureCauseEditedBool = true;
        } // Down to here 

        if (result.data.selectedSODRatingFailureCauseUpdated) {
          this.selectedSODRatingFailureCauseUpdated = true;
        }
        if (result.data.selectedSODRatingFailureEffectUpdated) {
          this.selectedSODRatingFailureEffectUpdated = true;
        }

        if (isInitial) {
          let RSandRPNResult = this.armService.calculateRSandRPN_XXXX(this.selectedRisk.severity, this.selectedRisk.occurrence, this.selectedRisk.detectability)
          this.selectedRisk.initialRS = RSandRPNResult.RS;
          this.selectedRisk.initialRPN = RSandRPNResult.RPN;
        } else {
          let RSandRPNResult = this.armService.calculateRSandRPN_XXXX(this.selectedRisk.residualSeverity, this.selectedRisk.residualOccurrence, this.selectedRisk.residualDetectability)
          this.selectedRisk.residualRS = RSandRPNResult.RS;
          this.selectedRisk.residualRPN = RSandRPNResult.RPN;
        }

        if (this.armService.selectedRiskAssessment.riskAssessmentType === "General - with Detection") {
          if (isInitial) {
            this.selectedRisk.initialRiskLevel = this.armService.calculateRiskLevelGeneralWithDetection(this.selectedRisk.severity, this.selectedRisk.occurrence, this.selectedRisk.detectability);
          } else {
            this.selectedRisk.residualRiskLevel = this.armService.calculateRiskLevelGeneralWithDetection(this.selectedRisk.residualSeverity, this.selectedRisk.residualOccurrence, this.selectedRisk.residualDetectability);
          }

        } else if (this.armService.selectedRiskAssessment.riskAssessmentType === "General - without Detection") {
          if (isInitial) {
            this.selectedRisk.initialRiskLevel = this.armService.calculateRiskLevelGeneralWithoutDetection(this.selectedRisk.severity, this.selectedRisk.occurrence);
          } else {
            this.selectedRisk.residualRiskLevel = this.armService.calculateRiskLevelGeneralWithoutDetection(this.selectedRisk.residualSeverity, this.selectedRisk.residualOccurrence);
          }
        } else {
          if (isInitial) {
            this.selectedRisk.initialRiskLevel = this.armService.calculateRiskLevelMedicalDevice_XXXX(this.selectedRisk.severity, this.selectedRisk.occurrence, this.selectedRisk.detectability, this.armService.selectedRiskAssessment.detectabilityMandatory);
          } else {
            this.selectedRisk.residualRiskLevel = this.armService.calculateRiskLevelMedicalDevice_XXXX(this.selectedRisk.residualSeverity, this.selectedRisk.residualOccurrence, this.selectedRisk.residualDetectability, this.armService.selectedRiskAssessment.detectabilityMandatory);
          }
        }

        this.setRiskRecordDisplayObject_XXXX(isInitial);
        this.renderRiskRecordDashboard_XXXX(this.initialRiskContext);
      }
    });
  }

  openControlDialog_0192(isInitial: boolean, SODType: string) {
    // DOC Comment: - isInitial parameter is not required. If a control is added, it's added to the Risk, regardless of Initial or Residual 
    // All control lists aren't required to be sent to the dialog - should be sent based on the SODType. 
    // Same thing in the afterClosed() - no need to pass the two list of controls that we physically can't update to the dialog and then 
    // reset them when the dialog is closed 
    let sodControlDialogConfigData = {
      minWidth: '60vw',
      maxWidth: '60vw',
      data: {
        isInitial: isInitial,
        selectedRisk: this.selectedRisk,
        causePreventionControls: this.causePreventionControlDataSource.data,
        detectionControls: this.detectionControlDataSource.data,
        detectabilityMandatory: this.armService.selectedRiskAssessment.detectabilityMandatory,
        controlCauseEntryID: this.failureCauseControlEntryID,
        controls: this.riskPreventionControlDataSource.data,
        controlEffectEntryID: this.failureEffectControlEntryID,
        SODType: SODType,
        statementType: 'UPDATE'
      }
    }

    const dialogRef = this.dialogService.openDialog_0088("SODControlDialogComponent", sodControlDialogConfigData)
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.causePreventionControlDataSource.data = [];
        this.causePreventionControlDataSource.data = result.data.occurrenceControls;
        this.detectionControlDataSource.data = [];
        this.detectionControlDataSource.data = result.data.detectabilityControls;
        this.riskPreventionControlDataSource.data = [];
        this.riskPreventionControlDataSource.data = result.data.riskPreventionControls;
        if (result.data.failureCauseControlsUpdated) {
          this.failureCauseControlsUpdated = true;
        }
        this.controlFailureCauseM2MDataset.data = this.controlFailureCauseM2MDataset.data.concat(result.data.controlFailureCauseM2MDataset.data);

        this.failureCauseControlEntryID = result.data.controlEntryID;
        if (result.data.failureEffectControlsUpdated) {
          this.failureEffectControlsUpdated = true;
        }

        this.controlFailureEffectM2MDataset.data = this.controlFailureEffectM2MDataset.data.concat(result.data.controlFailureEffectM2MDataset.data);
        this.failureEffectControlEntryID = result.data.controlEntryID;
      }
    });
  }

  cancelChanges_XXXX() {
    this.selectedRisk = cloneDeep(this.oldRisk);
    this.actionEliminateSetup();
    this.riskRecordForm = this.createRiskRecordFormGroup_0410();
    this.filteredUsers = this.riskRecordForm.get('riskOwnerName')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterUsers_0411(value))
      );
    if (this.selectedSection.route === 'initialRiskAnalysis')
      this.setRiskRecordDisplayObject_XXXX(true);
    else if (this.selectedSection.route === 'residualRiskAnalysis')
      this.setRiskRecordDisplayObject_XXXX(false);
    this.renderRiskRecordDashboard_XXXX(this.initialRiskContext);
    this.riskPreventionControlDataSource.data = [];
    this.riskPreventionControlDataSource.data = cloneDeep(this.oldRiskPreventionControls);
    this.causePreventionControlDataSource.data = [];
    this.causePreventionControlDataSource.data = cloneDeep(this.oldCausePreventionControls);
    this.detectionControlDataSource.data = [];
    this.detectionControlDataSource.data = cloneDeep(this.oldDetectionControls);
    this.controlFailureEffectM2MDataset.data = [];
    this.controlFailureCauseM2MDataset.data = [];
    this.globalService.taskLinkingDataset.data = [];
    this.globalService.tasksDataset.data = [];
    this.globalService.extendingTaskDataset = []
    this.globalService.tasksAuditTrailDataset = [];
    this.globalService.tasksMitigatingActionRiskM2MAuditTrailDataset = [];
    this.datasets = [];
    this.auditTrail = [];
    this.failureEffectControlEntryID = -1;
    this.failureCauseControlEntryID = -1;
    this.failureEffectEditedBool = false;
    this.failureCauseEditedBool = false;
    this.riskConfirmedDeniedBool = false;
    this.selectedSODRatingFailureEffectUpdated = false;
    this.selectedSODRatingFailureCauseUpdated = false;
    this.failureCauseControlsUpdated = false;
    this.failureEffectControlsUpdated = false;
    this.riskStatusUpdated = false;
    this.riskOwnerUpdated = false;
    this.globalService.tasks = cloneDeep(this.mitigatingActions);

    this.initialiseDisplayInstructions();

    this.severityUpdated = false;
    this.occurrenceUpdated = false;
    this.detectabilityUpdated = false;
    this.editModeBool = false;
    this.globalService.editModeBool = false;
    if (this.selectedSection.route === 'residualRiskAnalysis' && this.selectedRisk.riskDecision != "Reduce") {
      this.selectedSection = this.sections['initialRiskAnalysis'];
    }

    this.updateTaskPull = !this.updateTaskPull;
  }

  // DOC Comment: Function name should be updated
  navigateToExpandedDetailView_0409() {
    this.globalService.editModeBool = false;
    if (this.registerService.navigateRiskRegister) {
      this.router.navigate(['/register/risk-register']);
    } else if (this.registerService.navigateControlRegister) {
      this.router.navigate(['/register/control-register']);
    } else if (this.registerService.navigateMitigatingActionsRegister) {
      this.router.navigate(['/register/mitigating-action-register']);
    } else {
      this.router.navigate(['/risk-assessment/risk-assessment-expanded-detail']);
    }
  }

  renderRiskRecordDashboard_XXXX(ctx: any) {
    let riskDecision = this.selectedRisk.riskDecision;
    //DF Comment: Do we need this one???
    if (!riskDecision) riskDecision = 'Pending'
    let rsRPN = 'RPN';
    let score: number;
    if (this.selectedRisk.initialRPN) {
      score = this.selectedRisk.initialRPN;
    } else {
      score = 0;
    }
    let residualRsRPN = 'RPN';
    let maxScore = 125;
    let residualScore = this.selectedRisk.residualRPN;
    if (this.selectedRisk.residualRPN) {
      residualScore = this.selectedRisk.residualRPN;
    } else {
      residualScore = 0;
    }



    var riskDashboardHeight = document.getElementById('risk-record-dashboard-canvas')!.offsetHeight * .98; //includes margin,border,padding 
    var riskDashboardWidth = document.getElementById('risk-record-navigation-container')!.offsetWidth * .385; //includes margin,border,padding
    ctx = this.canvas.nativeElement.getContext('2d');
    ctx.canvas.width = riskDashboardWidth;
    ctx.canvas.height = riskDashboardHeight;
    ctx.canvas.style.width = riskDashboardWidth + "px";
    ctx.canvas.style.height = riskDashboardHeight + "px";
    ctx.clearRect(0, 0, riskDashboardWidth, riskDashboardHeight);
    var radius = riskDashboardHeight * 0.5;
    var xGaugeInitial = riskDashboardWidth * 0.12;
    var xGaugeResidual = riskDashboardWidth * 0.87;
    var yGauge = (riskDashboardHeight * 0.5);
    var H1 = (ctx.canvas.height * 0.14 | 0) + "px Lato";
    var H2 = (ctx.canvas.height * 0.1 | 0) + "px Lato";
    var H3 = (ctx.canvas.height * 0.12 | 0) + "px Lato";
    var H4 = (ctx.canvas.height * 0.1 | 0) + "px Lato";

    if (this.selectedRisk.initialRiskLevel) {
      if (!this.armService.selectedRiskAssessment.detectabilityMandatory) {
        rsRPN = 'RS';
        if (this.selectedRisk.initialRS) {
          score = this.selectedRisk.initialRS;
        }
        else {
          score = 0;
        }
        maxScore = 25;
        residualRsRPN = 'RS';
        if (this.selectedRisk.residualRS) {
          residualScore = this.selectedRisk.residualRS;
        }
        else {
          residualScore = 0;
        }
      }
      this.drawDashboardDivider(riskDashboardWidth * 0.23, riskDashboardHeight, 25, ctx);
      this.armService.drawGauge_XXXX(xGaugeInitial, yGauge + (radius * 0.5), 180, radius, 15, score / maxScore / 2, this.armService.getGaugeColour_XXXX(this.selectedRisk.initialRiskLevel), ctx);
      ctx.beginPath();
      ctx.rect(xGaugeInitial - (riskDashboardWidth * 0.11 / 2), (riskDashboardHeight * 0.92) - (riskDashboardHeight * 0.14 / 1.3), (riskDashboardWidth * 0.11), (riskDashboardHeight * 0.14));
      ctx.fillStyle = this.armService.getGaugeColour_XXXX(this.selectedRisk.initialRiskLevel);
      ctx.fill();
      ctx.textAlign = "center";
      ctx.font = H3;
      ctx.fillStyle = "Black";
      ctx.fillText(rsRPN, xGaugeInitial, yGauge);
      ctx.font = H1;
      ctx.fillText(score, xGaugeInitial, yGauge + (radius * 0.5));
      ctx.font = H3;
      ctx.fillStyle = this.armService.primaryBlue;
      ctx.fillText("INITIAL", xGaugeInitial, (riskDashboardHeight * 0.15));
      ctx.fillStyle = "white";
      ctx.font = H4;
      ctx.fillText(this.selectedRisk.initialRiskLevel.toUpperCase(), xGaugeInitial, (riskDashboardHeight * 0.92));
      if (this.selectedRisk.riskDecision === "Action") {
        var startArcPosition = 270 / 180 * Math.PI;
        var progress = this.mitigationPercentage / 100;
        var progressColour = "black";
        var progressMessage = "On Time";
        if (this.mitigationOverdue) {
          progressColour = this.armService.highRiskColour;
          progressMessage = "Overdue"
        }
        else if (this.selectedRisk.riskStatus == "Managed") {
          progressMessage = "Complete"
        }
        this.drawDashboardDivider(riskDashboardWidth * 0.67, riskDashboardHeight, 25, ctx);
        ctx.beginPath();
        if (!this.selectedRisk.isActionEliminate) {
          if (residualScore > 0) {
            this.armService.drawGauge_XXXX(xGaugeResidual, yGauge + (radius * 0.5), 180, radius, 15, residualScore / maxScore / 2, this.armService.getGaugeColour_XXXX(this.selectedRisk.residualRiskLevel), ctx);
            ctx.beginPath();
            ctx.rect(xGaugeResidual - (riskDashboardWidth * 0.11 / 2), (riskDashboardHeight * 0.92) - (riskDashboardHeight * 0.14 / 1.3), (riskDashboardWidth * 0.11), (riskDashboardHeight * 0.14));
            ctx.fillStyle = this.armService.getGaugeColour_XXXX(this.selectedRisk.residualRiskLevel);
            ctx.fill();
            ctx.textAlign = "center";
            ctx.font = H3;
            ctx.fillStyle = "Black";
            ctx.fillText(residualRsRPN, xGaugeResidual, yGauge);
            ctx.font = H1;
            ctx.fillText(residualScore, xGaugeResidual, yGauge + (radius * 0.5));
            ctx.font = H3;
            ctx.fillStyle = this.armService.primaryBlue;
            ctx.fillText("RESIDUAL", xGaugeResidual, (riskDashboardHeight * 0.15));
          } else {
            ctx.font = H3;
            ctx.fillStyle = this.armService.primaryBlue;
            ctx.fillText("Awaiting Evaluation", xGaugeResidual, (riskDashboardHeight * 0.55));
          }
        }
        else {
          ctx.font = H3;
          ctx.fillStyle = this.armService.primaryBlue;
          ctx.fillText("Risk Eliminated", xGaugeResidual, (riskDashboardHeight * 0.55));
        }
        ctx.textAlign = "left";
        ctx.fillText("Risk Decision", riskDashboardWidth * 0.29, (riskDashboardHeight * 0.15));
        ctx.fillText("Mitigating Actions", riskDashboardWidth * 0.29, (riskDashboardHeight * 0.60));
        ctx.fillStyle = "Black";
        ctx.font = H1;
        ctx.fillText(riskDecision, riskDashboardWidth * 0.29, (riskDashboardHeight * 0.35));
        if (this.riskRecordDashboard.totalActions > 0) {
          ctx.fillStyle = "Black";
          ctx.font = H1;
          ctx.fillText(this.riskRecordDashboard.totalActions, riskDashboardWidth * 0.29, (riskDashboardHeight * 0.80));
          ctx.font = H3;
          ctx.fillStyle = this.armService.primaryBlue;
          let monthLabel: string = " Month"
          if (this.totalMitigationMonths > 1) {
            monthLabel = monthLabel + "s"
          }
          ctx.textAlign = "center";
          ctx.fillText(this.totalMitigationMonths + monthLabel, riskDashboardWidth * 0.58, (riskDashboardHeight * 0.15));
          ctx.font = H4;
          ctx.beginPath();
          ctx.arc((riskDashboardWidth * 0.58), (riskDashboardHeight * 0.5), (riskDashboardHeight * 0.31), 0, 2 * Math.PI);
          ctx.fillStyle = this.armService.backgroundColour;
          ctx.fill();
          if (this.totalMitigationMonthsLeft != 0) {
            ctx.fillStyle = "Black";
            ctx.fillText(this.totalMitigationMonthsLeft + " Months", (riskDashboardWidth * 0.58), (riskDashboardHeight * 0.5));
            ctx.fillText("left", (riskDashboardWidth * 0.58), (riskDashboardHeight * 0.6));
          } else {
            ctx.fillStyle = "Black";
            ctx.fillText("No", (riskDashboardWidth * 0.58), (riskDashboardHeight * 0.45));
            ctx.fillText("Time Left", (riskDashboardWidth * 0.58), (riskDashboardHeight * 0.55));
          }
          ctx.fillStyle = progressColour;
          ctx.fillText(progressMessage, (riskDashboardWidth * 0.58), (riskDashboardHeight * 0.92));
          ctx.strokeStyle = progressColour;
          ctx.beginPath();
          ctx.arc((riskDashboardWidth * 0.58), (riskDashboardHeight * 0.5), (riskDashboardHeight * 0.29), startArcPosition, startArcPosition + (2 * Math.PI) * progress);
          ctx.lineWidth = 5;
          ctx.stroke();
        } else {
          ctx.fillText("TBD", riskDashboardWidth * 0.29, (riskDashboardHeight * 0.80));
        }
        ctx.fillStyle = "white";
        ctx.textAlign = "center";
        ctx.font = H4;
        if (this.selectedRisk.residualRiskLevel) {
          ctx.fillText(this.selectedRisk.residualRiskLevel.toUpperCase(), xGaugeResidual, (riskDashboardHeight * 0.92));
        }
      } else {
        ctx.fillStyle = this.armService.primaryBlue;
        ctx.textAlign = "left";
        ctx.font = H3;
        ctx.fillText("Risk Decision", riskDashboardWidth * 0.37, (riskDashboardHeight * 0.40));
        ctx.fillStyle = "Black";
        ctx.font = H1;
        ctx.fillText(riskDecision, riskDashboardWidth * 0.37, (riskDashboardHeight * 0.65));
      }
    } else {
      ctx.fillStyle = this.armService.primaryBlue;
      ctx.textAlign = "left";
      ctx.font = H3;
      ctx.fillText("Risk Analysis / Evaluation", riskDashboardWidth * 0.1, (riskDashboardHeight * 0.40));
      ctx.fillStyle = "Black";
      ctx.font = H1;
      ctx.fillText('Pending', riskDashboardWidth * 0.1, (riskDashboardHeight * 0.65));
    }
  }

  drawDashboardDivider(x: number, y: number, angle: number, ctx: any) {
    ctx.beginPath();
    ctx.moveTo(x, 0);
    ctx.lineTo(x + angle, y * 0.5);
    ctx.lineTo(x, y);
    ctx.lineWidth = 7;
    ctx.strokeStyle = this.armService.dashboardColour;
    ctx.stroke();
  }

  calcProximity_XXXX() {
    let proximity = this.armService.selectedRiskAssessment.riskAssessmentProximity;
    let startDate = this.armService.selectedRiskAssessment.creationDate;
    var totalDays = (moment(startDate).add(proximity, 'M')).diff(moment(startDate), 'days');
    var today = moment(this.today).diff(moment(startDate), 'days');
    this.proximityPercentage = (today / totalDays) * 100;

    if (this.proximityPercentage > 100) {
      this.proximityPercentage = 100;
    }
  }

  calcMitigationPercentage_XXXX() {
    let earliestCreationDate: any = new Date(this.riskRecordDashboard.earliestCreationDate);
    let latestDueDate: any = new Date(this.riskRecordDashboard.latestDueDate);
    let today: any = new Date();
    this.mitigationPercentage = Math.round(((today - earliestCreationDate) / (latestDueDate - earliestCreationDate)) * 100);

    if (this.mitigationPercentage > 100) this.mitigationPercentage = 100;
    if (this.riskRecordDashboard.overdueActions > 0) this.mitigationOverdue = true;

    this.totalMitigationMonths = (latestDueDate.getFullYear() - earliestCreationDate.getFullYear()) * 12;
    this.totalMitigationMonths -= earliestCreationDate.getMonth();
    this.totalMitigationMonths += latestDueDate.getMonth();

    if (this.totalMitigationMonths <= 0 || !this.totalMitigationMonths) this.totalMitigationMonths = 0;

    this.totalMitigationMonthsLeft = (latestDueDate.getFullYear() - today.getFullYear()) * 12;
    this.totalMitigationMonthsLeft -= today.getMonth();
    this.totalMitigationMonthsLeft += latestDueDate.getMonth();

    if (this.totalMitigationMonthsLeft <= 0 || !this.totalMitigationMonthsLeft) this.totalMitigationMonthsLeft = 0;
  }

  setRiskRecordDisplayObject_XXXX(isInitial: boolean) {
    if (isInitial) {
      this.riskRecordDisplayObject = {
        severityID: this.selectedRisk.severityID,
        severity: this.selectedRisk.severity,
        severityClassification: this.selectedRisk.severityClassification,
        severityClassificationColour: this.selectedRisk.severityClassificationColour,
        severityClassificationTextColour: this.selectedRisk.severityClassificationTextColour,
        severityJustification: this.selectedRisk.severityJustification,
        severityDescription: this.selectedRisk.severityDescription,
        severityCategoryName: this.selectedRisk.severityCategoryName,
        occurrenceID: this.selectedRisk.occurrenceID,
        occurrence: this.selectedRisk.occurrence,
        occurrenceClassification: this.selectedRisk.occurrenceClassification,
        occurrenceClassificationColour: this.selectedRisk.occurrenceClassificationColour,
        occurrenceClassificationTextColour: this.selectedRisk.occurrenceClassificationTextColour,
        occurrenceJustification: this.selectedRisk.occurrenceJustification,
        occurrenceDescription: this.selectedRisk.occurrenceDescription,
        occurrenceCategoryName: this.selectedRisk.occurrenceCategoryName,
        detectabilityID: this.selectedRisk.detectabilityID,
        detectability: this.selectedRisk.detectability,
        detectabilityClassification: this.selectedRisk.detectabilityClassification,
        detectabilityClassificationColour: this.selectedRisk.detectabilityClassificationColour,
        detectabilityClassificationTextColour: this.selectedRisk.detectabilityClassificationTextColour,
        detectabilityJustification: this.selectedRisk.detectabilityJustification,
        detectabilityDescription: this.selectedRisk.detectabilityDescription,
        detectabilityCategoryName: this.selectedRisk.detectabilityCategoryName,
      }
    } else {
      this.riskRecordDisplayObject = {
        severityID: this.selectedRisk.residualSeverityID,
        severity: this.selectedRisk.residualSeverity,
        severityClassification: this.selectedRisk.residualSeverityClassification,
        severityClassificationColour: this.selectedRisk.residualSeverityClassificationColour,
        severityClassificationTextColour: this.selectedRisk.residualSeverityClassificationTextColour,
        severityJustification: this.selectedRisk.residualSeverityJustification,
        severityDescription: this.selectedRisk.residualSeverityDescription,
        severityCategoryName: this.selectedRisk.residualSeverityCategoryName,
        occurrenceID: this.selectedRisk.residualOccurrenceID,
        occurrence: this.selectedRisk.residualOccurrence,
        occurrenceClassification: this.selectedRisk.residualOccurrenceClassification,
        occurrenceClassificationColour: this.selectedRisk.residualOccurrenceClassificationColour,
        occurrenceClassificationTextColour: this.selectedRisk.residualOccurrenceClassificationTextColour,
        occurrenceJustification: this.selectedRisk.residualOccurrenceJustification,
        occurrenceDescription: this.selectedRisk.residualOccurrenceDescription,
        occurrenceCategoryName: this.selectedRisk.residualOccurrenceCategoryName,
        detectibilityID: this.selectedRisk.residualDetectabilityID,
        detectability: this.selectedRisk.residualDetectability,
        detectabilityClassification: this.selectedRisk.residualDetectabilityClassification,
        detectabilityClassificationColour: this.selectedRisk.residualDetectabilityClassificationColour,
        detectabilityClassificationTextColour: this.selectedRisk.residualDetectabilityClassificationTextColour,
        detectabilityJustification: this.selectedRisk.residualDetectabilityJustification,
        detectabilityDescription: this.selectedRisk.residualDetectabilityDescription,
        detectabilityCategoryName: this.selectedRisk.residualDetectabilityCategoryName,
      }
    }
  }

  navigateToRiskVersionControl_0409() {
    this.armService.selectedRisk = this.selectedRisk;
    this.armService.selectedRisk["riskID"] = this.selectedRisk.iD;
    this.armService.selectedRisk.riskQuestion = this.armService.selectedRiskAssessment.riskQuestion;
    this.armService.selectedRisk.proximity = this.armService.selectedRiskAssessment.riskAssessmentProximity;
    this.armService.selectedRisk.riskAssessmentTool = this.armService.selectedRiskAssessment.riskAssessmentTool;
    this.armService.selectedRisk["permissionName"] = this.armService.selectedRiskAssessment.permissionName;
    this.router.navigate(['/risk-assessment/risk-assessment-risk-version-control']);
  }

  initiateNewRevisionRisk_XXXX() {
    let datasets: dataset[] = [];
    let auditTrail: any[] = [];
    let toDB: DataToPost;

    let riskReportAttachmentID = this.armService.selectedRiskReportID;

    let oldRiskDataset: dataset = {
      data: [{
        iD: this.selectedRisk.iD,
        clientID: this.selectedRisk.clientID,
        entityID: this.selectedRisk.entityID,
        riskREF: '',
        categoryID: this.selectedRisk.categoryID,
        failureCauseID: this.selectedRisk.failureCauseID,
        failureCauseName: this.selectedRisk.failureCause,
        failureEffectID: this.selectedRisk.failureEffectID,
        failureEffectName: this.selectedRisk.failureEffect,
        riskStatusID: this.selectedRisk.riskStatusID,
        riskStatusName: this.selectedRisk.riskStatus,
        riskOwnerID: this.selectedRisk.riskOwnerID,
        riskOwnerName: this.selectedRisk.riskOwnerName,
        isRejected: this.selectedRisk.isRejected,
        ownershipDate: this.selectedRisk.ownershipDate,
        initialOccurrenceVsDetectability: this.selectedRisk.initialOccurrenceVsDetectability,
        initialRiskLevel: this.selectedRisk.initialRiskLevel,
        initialRPN: this.selectedRisk.initialRPN,
        initialRS: this.selectedRisk.initialRS,
        riskDecision: this.selectedRisk.riskDecision,
        riskDecisionJustification: this.selectedRisk.riskDecisionJustification,
        residualOccurrenceVsDetectability: this.selectedRisk.residualOccurrenceVsDetectability,
        residualRiskLevel: this.selectedRisk.residualRiskLevel,
        residualRPN: this.selectedRisk.residualRPN,
        residualRS: this.selectedRisk.residualRS,
        riskVersion: this.selectedRisk.riskVersion,
        approvedDate: this.selectedRisk.approvedDate,
        readyForApproval: this.selectedRisk.readyForApproval,
        managedDate: this.selectedRisk.managedDate,
        treatmentDecidedDate: this.selectedRisk.treatmentDecidedDate,
        isTemplate: this.selectedRisk.isTemplate,
      }],
      dataset: "Risk"
    };

    let riskDataset: dataset = {
      data: [{
        iD: this.selectedRisk.iD,
        clientID: this.selectedRisk.clientID,
        entityID: this.selectedRisk.entityID,
        riskREF: this.selectedRisk.riskRef,
        categoryID: this.selectedRisk.categoryID,
        failureCauseID: this.selectedRisk.failureCauseID,
        failureCauseName: this.selectedRisk.failureCause,
        failureEffectID: this.selectedRisk.failureEffectID,
        failureEffectName: this.selectedRisk.failureEffect,
        riskStatusID: this.selectedRisk.riskStatusID,
        riskStatusName: this.selectedRisk.riskStatus,
        riskOwnerID: this.selectedRisk.riskOwnerID,
        riskOwnerName: this.selectedRisk.riskOwnerName,
        isRejected: this.selectedRisk.isRejected,
        ownershipDate: this.selectedRisk.ownershipDate,
        initialOccurrenceVsDetectability: this.selectedRisk.initialOccurrenceVsDetectability,
        initialRiskLevel: this.selectedRisk.initialRiskLevel,
        initialRPN: this.selectedRisk.initialRPN,
        initialRS: this.selectedRisk.initialRS,
        riskDecision: this.selectedRisk.riskDecision,
        riskDecisionJustification: this.selectedRisk.riskDecisionJustification,
        residualOccurrenceVsDetectability: this.selectedRisk.residualOccurrenceVsDetectability,
        residualRiskLevel: this.selectedRisk.residualRiskLevel,
        residualRPN: this.selectedRisk.residualRPN,
        residualRS: this.selectedRisk.residualRS,
        riskVersion: this.selectedRisk.riskVersion + 1,
        isTemplate: this.selectedRisk.isTemplate,
        approvedDate: null,
        readyForApproval: this.selectedRisk.readyForApproval,
        managedDate: this.selectedRisk.managedDate,
        treatmentDecidedDate: this.selectedRisk.treatmentDecidedDate,
        statementType: "UPDATE"
      }],
      dataset: "Risk"
    };

    let riskVersionControlDataset: dataset = {
      data: [{
        iD: -1,
        clientID: this.selectedRisk.clientID,
        attachmentID: riskReportAttachmentID,
        riskID: this.selectedRisk.iD,
        version: this.selectedRisk.riskVersion,
        versionControlDescription: "Version " + this.selectedRisk.riskVersion + " of the risk: " + this.selectedRisk.riskRef,
        statementType: "INSERT"
      }],
      dataset: "RiskVersionControlAttachmentM2O"
    };

    auditTrail = auditTrail.concat(this.globalService.buildAuditTrail_0115(oldRiskDataset.data[0], riskDataset.data[0], "tblRisk"));
    auditTrail = auditTrail.concat(this.globalService.buildAuditTrail_0115({}, riskVersionControlDataset.data[0], "tblRiskVersionControlAttachmentM2O"));
    datasets.push(riskDataset, riskVersionControlDataset);

    toDB = new DataToPost(this.globalService.clientID, this.globalService.userID, "N/A", "Edit Control", datasets, auditTrail);

    this.apiService.postDataToBackend_0051(this.apiService.singlePostUrl, toDB).then((result: any) => {
      if (result) {
        this.armService.selectedRisk = this.selectedRisk;
        this.armService.selectedRisk["riskID"] = this.selectedRisk.iD;
        this.prepareDataForGetRiskRecord_0190();
        this.prepareDataForGetRecordCollaborators_0190();
      }
    }).catch((err: any) => {
      console.error(err);
      let userErrMsg = this.armService.constructUserErrorMessage(err, "Risk");
      window.alert(userErrMsg)
    });
  }

  createNewRiskReport_XXXX() {
    this.armService.selectedRiskInitiateApproval = true;
    this.armService.selectedRisk = this.selectedRisk;
    this.armService.riskForReport = this.selectedRisk;
    setTimeout(() => {
      this.router.navigate(['/risk-assessment/risk-report'])
    }, 200);
  }

  getCurrentRiskStatus_XXXX() {
    let currentRiskStatus = this.selectedRisk.riskStatusID;
    let currentRiskStatusName = this.selectedRisk.riskStatus;

    if (this.riskRecordForm.value.riskStatusID === 1) {
      currentRiskStatus = 1;
      currentRiskStatusName = 'For Analysis';
    }

    if (this.riskRecordForm.value.riskStatusID === 2) {
      currentRiskStatus = 2;
      currentRiskStatusName = 'No Risk';
    }

    if (this.selectedRisk.riskStatusID === 1 && this.selectedRisk.initialRiskLevel) {
      currentRiskStatus = 3;
      currentRiskStatusName = 'Initial Risk Level Determined';
    }

    if (this.selectedRisk.riskDecision && this.globalService.tasks.length === 0) {
      currentRiskStatus = 4;
      currentRiskStatusName = 'Treatment Decided';
    }

    // DOC Comment: Overwritten by tblTask DB Trigger - left here for display purposes
    if ((this.selectedRisk.riskDecision === 'Reduce' || this.selectedRisk.riskDecision === 'Eliminate' || this.selectedRisk.riskDecision === 'Action')
      && this.globalService.tasksDataset.data && this.globalService.tasksDataset.data.length > 0
      && !this.mitigatingActionsComplete_XXXX()) {
      currentRiskStatus = 5;
      currentRiskStatusName = 'Action WIP';
    }

    // DOC Comment: Overwritten by tblTask DB Trigger - left here for display purposes
    if (this.selectedRisk.riskDecision === 'Accept' || ((this.selectedRisk.riskDecision === 'Reduce' || this.selectedRisk.riskDecision === 'Eliminate' || this.selectedRisk.riskDecision === 'Action') && this.mitigatingActionsComplete_XXXX())) {
      currentRiskStatus = 6;
      currentRiskStatusName = 'Managed';
    }

    return { currentRiskStatus, currentRiskStatusName };
  }

  navigateToRiskAssessmentTool_0409() {
    this.armService.navigateFromRiskRecord = true;
    this.router.navigate(['/risk-assessment/fmea']);
  }

  async genReport_XXXX() {
    this.displayLoader = true;
    this.stopBS = true;
    this.base64String = await this.auth.getPDF();
    this.auditTrailPDF = await this.auth.base64ToArrayBufferAuditTrail_0139(this.base64String);
    this.auth.$pdfReady.next(true);
  }

  mitigatingActionsComplete_XXXX() {
    return (this.globalService.tasks.length > 0 && this.globalService.tasks.every((obj: any) => obj.status == 'Complete' || obj.status == "Cancelled"))
  }

  selectInstructionAction(guidanceStep: number) {
    let isInitial: boolean = true;
    if (this.selectedSection.route === 'residualRiskAnalysis') {
      isInitial = false;
    }
    if (this.editModeBool) {
      switch (guidanceStep) {
        case 101: {
          this.openRatingDialog_0192(isInitial, 'severity');
          break;
        }
        case 102: {
          if (isInitial) {
            this.displayInstructions.instructionSets[0].guidanceSteps[1].guidanceStepInitialComplete = true;
            this.displayInstructions.instructionSets[0].instructionInitialComplete = true;
          } else {
            this.displayInstructions.instructionSets[0].guidanceSteps[1].guidanceStepResidualComplete = true;
            this.displayInstructions.instructionSets[0].instructionResidualComplete = true;
          }
          break;
        }
        case 103: {
          this.openControlDialog_0192(isInitial, 'occurrence');
          if (isInitial) {
            this.displayInstructions.instructionSets[1].guidanceSteps[0].guidanceStepInitialComplete = true;
            this.displayInstructions.instructionSets[1].guidanceSteps[1].stepInitialEnabled = true;
          } else {
            this.displayInstructions.instructionSets[1].guidanceSteps[0].guidanceStepResidualComplete = true;
            this.displayInstructions.instructionSets[1].guidanceSteps[1].stepResidualEnabled = true;
          }
          break;
        }
        case 104: {
          this.openRatingDialog_0192(isInitial, 'occurrence');
          break;
        }
        case 105: {
          if (isInitial) {
            this.displayInstructions.instructionSets[1].guidanceSteps[2].guidanceStepInitialComplete = true;
            this.displayInstructions.instructionSets[1].instructionInitialComplete = true;
          } else {
            this.displayInstructions.instructionSets[1].guidanceSteps[2].guidanceStepResidualComplete = true;
            this.displayInstructions.instructionSets[1].instructionResidualComplete = true;
          }
          break;
        }
        case 106: {
          this.openControlDialog_0192(isInitial, 'detectability');
          if (isInitial) {
            this.displayInstructions.instructionSets[2].guidanceSteps[0].guidanceStepInitialComplete = true;
            this.displayInstructions.instructionSets[2].guidanceSteps[1].stepInitialEnabled = true;
          } else {
            this.displayInstructions.instructionSets[2].guidanceSteps[0].guidanceStepResidualComplete = true;
            this.displayInstructions.instructionSets[2].guidanceSteps[1].stepResidualEnabled = true;
          }
          break;
        }
        case 107: {
          this.openRatingDialog_0192(isInitial, 'detectability');
          break;
        }
        case 108: {
          if (isInitial) {
            this.displayInstructions.instructionSets[2].guidanceSteps[2].guidanceStepInitialComplete = true;
            this.displayInstructions.instructionSets[2].instructionInitialComplete = true;
          } else {
            this.displayInstructions.instructionSets[2].guidanceSteps[2].guidanceStepResidualComplete = true;
            this.displayInstructions.instructionSets[2].instructionResidualComplete = true;
          }
          break;
        }
        default: break;
      }
    } else {
      window.alert("Place Risk Record into Edit Mode")
    }
  }

  cancelOptionalGuidance(instructionSetIndex: number, guidanceStepIndex: number) {
    if (this.editModeBool) {
      if (this.selectedSection.route === 'initialRiskAnalysis') {
        this.displayInstructions.instructionSets[instructionSetIndex].guidanceSteps[guidanceStepIndex].cancelInitialOptionalGuidance = true;
      }
      else {
        this.displayInstructions.instructionSets[instructionSetIndex].guidanceSteps[guidanceStepIndex].cancelResidualOptionalGuidance = true;
      }
    }
    if (instructionSetIndex == 0) {
      this.selectInstructionAction(102);
    } else if (instructionSetIndex == 1) {
      this.selectInstructionAction(105);
    } else if (instructionSetIndex == 2) {
      this.selectInstructionAction(108);
    }

  }

  guidanceStepStrikeout(guidanceStep: any) {
    let strikeoutGuidanceStep: boolean = false;
    if (this.selectedSection.route === 'initialRiskAnalysis') {
      strikeoutGuidanceStep = guidanceStep.guidanceOptional && guidanceStep.guidanceInitialComplete;
    } else {
      strikeoutGuidanceStep = guidanceStep.guidanceOptional && guidanceStep.guidanceResidualComplete;
    }
    return strikeoutGuidanceStep;
  }

  initialiseInstructionSetIfPreviouslyComplete(isInitialSOD: boolean, instructionSetIndex: number) {
    if (isInitialSOD) {
      this.displayInstructions.instructionSets[instructionSetIndex].instructionInitialComplete = true;
    } else {
      this.displayInstructions.instructionSets[instructionSetIndex].instructionResidualComplete = true;
    }
  }

  initialiseDisplayInstructions() {

    this.displayInstructions = cloneDeep(this.globalService.instructions);
    if (this.selectedRisk.severity) {
      this.initialiseInstructionSetIfPreviouslyComplete(true, 0);
    }
    if (this.selectedRisk.residualSeverity) {
      this.initialiseInstructionSetIfPreviouslyComplete(false, 0);
    }
    if (this.selectedRisk.occurrence) {
      this.initialiseInstructionSetIfPreviouslyComplete(true, 1);
    }
    if (this.selectedRisk.residualOccurrence) {
      this.initialiseInstructionSetIfPreviouslyComplete(false, 1);
    }
    if (this.selectedRisk.detectability) {
      this.initialiseInstructionSetIfPreviouslyComplete(true, 2);
    }
    if (this.selectedRisk.residualDetectability) {
      this.initialiseInstructionSetIfPreviouslyComplete(false, 2);
    }
  }

  editRiskRecord() {
    this.editModeBool = true;
    this.globalService.editModeBool = true;

    // DOC Comment: seems like there should be a check for whether the instructions configurable paramtere is on or not
    this.displayInstructions.instructionSets[0].guidanceSteps[0].stepInitialEnabled = true;
    this.displayInstructions.instructionSets[0].guidanceSteps[0].stepResidualEnabled = true;
    this.displayInstructions.instructionSets[1].guidanceSteps[0].stepInitialEnabled = true;
    this.displayInstructions.instructionSets[1].guidanceSteps[0].stepResidualEnabled = true;
    this.displayInstructions.instructionSets[2].guidanceSteps[0].stepInitialEnabled = true;
    this.displayInstructions.instructionSets[2].guidanceSteps[0].stepResidualEnabled = true;
  }

  nextPrevRiskRecord(nextRiskRecord: boolean) {
    if (nextRiskRecord) {
      if (this.armService.indexOfSelectedRisk < this.armService.selectedRiskAssessmentAssociatedRisks.length - 1) {
        this.armService.indexOfSelectedRisk += 1;
      } else {
        this.armService.indexOfSelectedRisk = 0;
      }
    } else {
      if (this.armService.indexOfSelectedRisk > 0) {
        this.armService.indexOfSelectedRisk -= 1;
      } else {
        this.armService.indexOfSelectedRisk = this.armService.selectedRiskAssessmentAssociatedRisks.length - 1;
      }
    }
    this.armService.setGlobalSelectedRisk_0455(this.armService.selectedRiskAssessmentAssociatedRisks[this.armService.indexOfSelectedRisk]);
    this.armService.selectedRisk.riskQuestion = this.armService.selectedRiskAssessment.riskQuestion;
    this.armService.selectedRisk.proximity = this.armService.selectedRiskAssessment.riskAssessmentProximity;
    this.armService.selectedRisk.riskAssessmentTool = this.armService.selectedRiskAssessment.riskAssessmentTool;
    this.armService.selectedRisk["permissionName"] = this.armService.selectedRiskAssessment.permissionName;
    this.initialiseRiskRecordComponent();
  }

  openAssociatedRisksDialog_0192() {
    let associatedRisksData = {
      minWidth: '60vw',
      maxWidth: '60vw',
      data: {
        mode: 'riskRecord'
      }
    }

    const dialogRef = this.dialogService.openDialog_0088("TaskRisksDialogComponent", associatedRisksData)
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result.taskDeleted) {
          this.tasksDeleted += 1;
        }
      }
    });
  }

  riskRecordReadyForApproval(): boolean {
    let readyForApproval: boolean = false;

    if (this.riskRecordForm.value.riskStatusID === 2) {
      readyForApproval = true;
    } else if (this.riskRecordForm.value.riskDecision === 'Action' && this.riskRecordForm.value.riskEliminate && this.riskRecordForm.value.riskDecisionJustification && this.selectedRisk.initialRiskLevel && this.globalService.tasks.length > 0) {
      readyForApproval = true;
    } else if (this.riskRecordForm.value.riskDecision === 'Action' && !this.riskRecordForm.value.riskEliminate && this.selectedRisk.initialRiskLevel && this.selectedRisk.residualRiskLevel && this.globalService.tasks.length > 0) {
      readyForApproval = true;
    } else if (this.riskRecordForm.value.riskDecision === 'Accept' && this.riskRecordForm.value.riskDecisionJustification && this.selectedRisk.initialRiskLevel) {
      readyForApproval = true;
    }

    return readyForApproval;
  }

  actionEliminateSetup() {
    if (this.selectedRisk.riskDecision === "Eliminate") {
      this.selectedRisk["isActionEliminate"] = true;
      this.selectedRisk.riskDecision = "Action";
    } else if (this.selectedRisk.riskDecision === "Reduce") {
      this.selectedRisk["isActionEliminate"] = false;
      this.selectedRisk.riskDecision = "Action";
    }
  }
}