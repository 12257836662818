<div class="dialog">
    <div class="dialog-header">
        <div class="header-text center" >
            Risk Assessment Report Comments
        </div>
        <div class="align-items-start">
            <div class="header-icon">
                <button mat-button class="icon-btn dialog-close-btn" (click)="dialogRef.close({ closeType: 'cancel' })"
                    id="riskAssessmentReviewDialogCompenent-closeDialogIcon">
                    <div class="icon"><fa-icon [icon]="times"></fa-icon></div>
                </button>
            </div>
        </div>
    </div>
    <div class="dialog-content justify-content-center">
        <ng-container *ngIf="reviews.length > 0">
            <h2>Reviews</h2>
            <div *ngFor="let review of dataToDisplay;let i = index" class="">
                <div class="review-title">
                    <h3>Review {{reviews[i].stage}}</h3>
                    <div class="btn-group"> <button mat-button id="risk-assessment-review-comments-dialog-view-pdf-btn"
                            class="btn icon-btn icon-btn-white" (click)="navigateToPreviousRiskAssessmentReport(i)"><fa-icon [icon]="eye"></fa-icon> </button>
                    </div>
                </div>
                <ng-container *ngIf="review.length > 0">
                    <ng-container *ngFor="let comment of review">
                        <div class="" *ngIf="comment.comment != null">
                            <div class="username">
                                <div class="name">
                                    {{comment.firstName}} {{comment.lastName}}
                                </div>
                                <div class="date">
                                    {{comment.date | date:'yyyy-MM-dd HH:mm:ss'}}
                                </div>
                            </div>
                            <div class="comment">
                                <p class="text">
                                    {{comment.comment}}
                                </p>
                            </div>

                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="review.length == 0">
                    No Comments left in this Review.
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="approvals.length > 0">
            <h2>Approvals</h2>
            <div *ngFor="let approval of approvalToDisplay;let i = index" class="">
                <div class="review-title">
                    <h3 >Approval {{approvals[i].stage}}</h3>
                </div>
                <ng-container *ngIf="approval.length > 0">
                    <ng-container *ngFor="let comment of approval">
                        <div class="" *ngIf="comment.comment != null">
                            <div class="username">
                                <div class="name">
                                    {{comment.firstName}} {{comment.lastName}}
                                </div>
                                <div class="date">
                                    {{comment.date | date:'yyyy-MM-dd HH:mm:ss'}}
                                </div>
                            </div>
                            <div class="comment">
                                <p class="text">
                                    {{comment.comment}}
                                </p>
                            </div>
    
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="approval.length == 0">
                    No Comments left in this Approval.
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="approvals.length == 0 && reviews.length == 0">
            <h3>No Comments have been left in this risk assessment version.</h3>
        </ng-container>
    </div>
    <div class="dialog dialog-footer-justification">
        <div>
            <div class="only-buttons">
                <button mat-button class="btn cancel" (click)="dialogRef.close({ closeType: 'cancel' })"
                    id="ConfirmationDialogCompenent-cancel" ><p>Cancel</p></button>
            </div>
        </div>
    </div>
</div>