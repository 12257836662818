<div class="risk-profile-container" [ngClass]="(isHorizontal) ? 'horizontal' : 'vertical'">
    <div class="overall-risk-info">
        <div class="risk-level-colour high">

        </div>
        <div class="overall-risks">
            <p class="risk-level uppercase high">High Risks</p>
            <div class="total-risks-current-levels">
                <h1 class="number-of-risks">{{riskProfileData.currentHighRisks}}</h1>
                <h3 class="total-risks">From {{riskProfileData.totalHighRisks}}</h3>
            </div>
        </div>

        <div class="risk-profile-main-content">
            <div class="risk-treatment">
                <div class="risk-profile-title">
                    <p>Risk Treatment</p>
                </div>
                <div class="treatment">
                    <div class="risk-decision">
                        <div class="risk-level-indicator high"></div>
                        <p>Accept</p>
                    </div>
                    <p>{{riskProfileData.acceptedHighRisks}}</p>
                </div>
                <div class="treatment">
                    <div class="risk-decision">
                        <div class="risk-level-indicator reduce"></div>
                        <p>Action</p>
                    </div>
                    <p>{{riskProfileData.reducedHighRisks + riskProfileData.avoidedHighRisks}}</p>
                </div>
                <!-- <div class="treatment">
                    <div class="risk-decision">
                        <div class="risk-level-indicator avoid"></div>
                        <p>Action-Eliminate</p>
                    </div>
                    <p>{{riskProfileData.avoidedHighRisks}}</p>
                </div> -->
                <div class="treatment">
                    <div class="risk-decision">
                        <div class="risk-level-indicator high"></div>
                        <p>Pending</p>
                    </div>
                    <p>{{riskProfileData.pendingHighRisks}}</p>
                </div>
            </div>
            <div class="mitigation-results">
                <div class="risk-profile-title residual">
                    <p>Residual Risk</p>
                </div>
                <div class="d-flex">
                    <div class="arrow-left"></div>
                    <div class="mitigated-risks">
                        <div class="residual-risk-level">
                            <p>High</p>
                            <div class="residual-risk-count">
                                <div class="count high" *ngIf="riskProfileData.reducingHighToHighRisks > 0">
                                    {{riskProfileData.reducingHighToHighRisks}}
                                </div>
                                <div class="count high" *ngIf="riskProfileData.reducedHighToHighRisks > 0">
                                    {{riskProfileData.reducedHighToHighRisks}}
                                </div>
                            </div>
                        </div>
                        <div class="residual-risk-level">
                            <p>Medium</p>
                            <div class="residual-risk-count">
                                <div class="count high" *ngIf="riskProfileData.reducingHighToMediumRisks > 0">
                                    {{riskProfileData.reducingHighToMediumRisks}}
                                </div>
                                <div class="count medium" *ngIf="riskProfileData.reducedHighToMediumRisks > 0">
                                    {{riskProfileData.reducedHighToMediumRisks}}
                                </div>
                            </div>
                        </div>
                        <div class="residual-risk-level">
                            <p>Low</p>
                            <div class="residual-risk-count">
                                <div class="count high" *ngIf="riskProfileData.reducingHighToLowRisks > 0">
                                    {{riskProfileData.reducingHighToLowRisks}}
                                </div>
                                <div class="count low" *ngIf="riskProfileData.reducedHighToLowRisks > 0">
                                    {{riskProfileData.reducedHighToLowRisks}}</div>
                            </div>
                        </div>
                        <div class="residual-risk-level">
                            <p>Eliminate</p>
                            <div class="residual-risk-count">
                                <div class="count high" *ngIf="riskProfileData.eliminatingHighRisks > 0">
                                    {{riskProfileData.eliminatingHighRisks}}
                                </div>
                                <div class="count eliminated" *ngIf="riskProfileData.eliminatedHighRisks > 0">
                                    {{riskProfileData.eliminatedHighRisks}}</div>
                            </div>
                        </div>                      
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="overall-risk-info">
        <div class="risk-level-colour medium">

        </div>
        <div class="overall-risks">
            <p class="risk-level uppercase medium">Medium Risks</p>
            <div class="total-risks-current-levels">
                <h1 class="number-of-risks">{{riskProfileData.currentMediumRisks}}</h1>
                <h3 class="total-risks">From {{riskProfileData.totalMediumRisks}}</h3>
            </div>
        </div>


        <div class="risk-profile-main-content">
            <div class="risk-treatment">
                <div class="treatment risk-profile-title">
                    <p>Risk Treatment</p>
                </div>
                <div class="treatment">
                    <div class="risk-decision">
                        <div class="risk-level-indicator medium"></div>
                        <p>Accept</p>
                    </div>
                    <p>{{riskProfileData.acceptedMediumRisks}}</p>
                </div>
                <div class="treatment">
                    <div class="risk-decision">
                        <div class="risk-level-indicator reduce"></div>
                        <p>Action</p>
                    </div>
                    <p>{{riskProfileData.reducedMediumRisks + riskProfileData.avoidedMediumRisks}}</p>
                </div>
                <!-- <div class="treatment">
                    <div class="risk-decision">
                        <div class="risk-level-indicator avoid"></div>
                        <p>Action-Eliminate</p>
                    </div>
                    <p>{{riskProfileData.avoidedMediumRisks}}</p>
                </div> -->
                <div class="treatment">
                    <div class="risk-decision">
                        <div class="risk-level-indicator medium"></div>
                        <p>Pending</p>
                    </div>
                    <p>{{riskProfileData.pendingMediumRisks}}</p>
                </div>
            </div>
            <div class="mitigation-results">
                <div class="risk-profile-title residual">
                    <p>Residual Risk</p>
                </div>
                <div class="d-flex">
                    <div class="arrow-left"></div>
                    <div class="mitigated-risks">
                        <div class="residual-risk-level">
                            <p>High</p>
                            <div class="residual-risk-count">
                                <div class="count medium" *ngIf="riskProfileData.reducingMediumToHighRisks > 0">
                                    {{riskProfileData.reducingMediumToHighRisks}}
                                </div>
                                <div class="count high" *ngIf="riskProfileData.reducedMediumToHighRisks > 0">
                                    {{riskProfileData.reducedMediumToHighRisks}}
                                </div>
                            </div>
                        </div>
                        <div class="residual-risk-level">
                            <p>Medium</p>
                            <div class="residual-risk-count">
                                <div class="count medium" *ngIf="riskProfileData.reducingMediumToMediumRisks > 0">
                                    {{riskProfileData.reducingMediumToMediumRisks}}</div>
                                <div class="count medium" *ngIf="riskProfileData.reducedMediumToMediumRisks > 0">
                                    {{riskProfileData.reducedMediumToMediumRisks}}</div>
                            </div>
                        </div>
                        <div class="residual-risk-level">
                            <p>Low</p>
                            <div class="residual-risk-count">
                                <div class="count medium" *ngIf="riskProfileData.reducingMediumToLowRisks > 0">
                                    {{riskProfileData.reducingMediumToLowRisks}}
                                </div>
                                <div class="count low" *ngIf="riskProfileData.reducedMediumToLowRisks > 0">
                                    {{riskProfileData.reducedMediumToLowRisks}}
                                </div>
                            </div>
                        </div>
                        <div class="residual-risk-level">
                            <p>Eliminate</p>
                            <div class="residual-risk-count">
                                <div class="count medium" *ngIf="riskProfileData.eliminatingMediumRisks > 0">
                                    {{riskProfileData.eliminatingMediumRisks}}
                                </div>
                                <div class="count eliminated" *ngIf="riskProfileData.eliminatedMediumRisks > 0">
                                    {{riskProfileData.eliminatedMediumRisks}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <div class="overall-risk-info">
        <div class="risk-level-colour low">

        </div>
        <div class="overall-risks">
            <p class="risk-level uppercase low">Low Risks</p>
            <div class="total-risks-current-levels">
                <h1 class="number-of-risks">{{riskProfileData.currentLowRisks}}</h1>
                <h3 class="total-risks">From {{riskProfileData.totalLowRisks}}</h3>
            </div>
        </div>

        <div class="risk-profile-main-content">
            <div class="risk-treatment">
                <div class="treatment risk-profile-title">
                    <p>Risk Treatment</p>
                </div>
                <div class="treatment">
                    <div class="risk-decision">
                        <div class="risk-level-indicator low"></div>
                        <p>Accept</p>
                    </div>
                    <p>{{riskProfileData.acceptedLowRisks}}</p>
                </div>
                <div class="treatment">
                    <div class="risk-decision">
                        <div class="risk-level-indicator reduce"></div>
                        <p>Action</p>
                    </div>
                    <p>{{riskProfileData.reducedLowRisks + riskProfileData.avoidedLowRisks}}</p>
                </div>
                <!-- <div class="treatment">
                    <div class="risk-decision">
                        <div class="risk-level-indicator avoid"></div>
                        <p>Action-Eliminate</p>
                    </div>
                    <p>{{riskProfileData.avoidedLowRisks}}</p>
                </div> -->
                <div class="treatment">
                    <div class="risk-decision">
                        <div class="risk-level-indicator low"></div>
                        <p>Pending</p>
                    </div>
                    <p>{{riskProfileData.pendingLowRisks}}</p>
                </div>
            </div>
            <div class="mitigation-results">
                <div class="risk-profile-title residual">
                    <p>Residual Risk</p>
                </div>
                <div class="d-flex">
                    <div class="arrow-left"></div>
                    <div class="mitigated-risks">
                        <div class="residual-risk-level">
                            <p>High</p>
                            <div class="residual-risk-count">
                                <div class="count low" *ngIf="riskProfileData.reducingLowToHighRisks > 0">
                                    {{riskProfileData.reducingLowToHighRisks}}
                                </div>
                                <div class="count high" *ngIf="riskProfileData.reducedLowToHighRisks > 0">
                                    {{riskProfileData.reducedLowToHighRisks}}
                                </div>
                            </div>
                        </div>
                        <div class="residual-risk-level">
                            <p>Medium</p>
                            <div class="residual-risk-count">
                                <div class="count low" *ngIf="riskProfileData.reducingLowToMediumRisks > 0">
                                    {{riskProfileData.reducingLowToMediumRisks}}
                                </div>
                                <div class="count medium" *ngIf="riskProfileData.reducedLowToMediumRisks > 0">
                                    {{riskProfileData.reducedLowToMediumRisks}}
                                </div>
                            </div>
                        </div>
                        <div class="residual-risk-level">
                            <p>Low</p>
                            <div class="residual-risk-count">
                                <div class="count low" *ngIf="riskProfileData.reducingLowToLowRisks > 0">
                                    {{riskProfileData.reducingLowToLowRisks}}</div>
                                <div class="count low" *ngIf="riskProfileData.reducedLowToLowRisks > 0">
                                    {{riskProfileData.reducedLowToLowRisks}}</div>
                            </div>
                        </div>
                        <div class="residual-risk-level">
                            <p>Eliminate</p>
                            <div class="residual-risk-count">
                                <div class="count low" *ngIf="riskProfileData.eliminatingLowRisks > 0">
                                    {{riskProfileData.eliminatingLowRisks}}
                                </div>
                                <div class="count eliminated" *ngIf="riskProfileData.eliminatedLowRisks > 0">
                                    {{riskProfileData.eliminatedLowRisks}}</div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>