import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { IrlcoreAuthService } from '@irlca/irlcore';
import { interval, Subscription } from "rxjs";

@Component({
  selector: 'app-inactive-timeout-dialog',
  templateUrl: './inactive-timeout-dialog.component.html',
  styleUrls: ['./inactive-timeout-dialog.component.scss']
})
export class InactiveTimeoutDialogComponent implements OnInit {

  // icons
  times = faTimes;

  private subscription!: Subscription;

  public dDay = new Date();
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  secondsInAMinute = 60;

  public timeDifference!: number;
  public secondsToDday: number = 0;
  public minutesToDday: number = 0;
  public hoursToDday: number = 0;

  constructor(public dialogRef: MatDialogRef<InactiveTimeoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public auth: IrlcoreAuthService) {
  }

  ngOnInit() {
    this.dDay.setTime(this.dDay.getTime() + this.data.warningTimeLeft);
    this.subscription = interval(1000)
      .subscribe(() => { this.getTimeDifference(); });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getTimeDifference() {
    this.timeDifference = this.dDay.getTime() - new Date().getTime();

    if (this.timeDifference < 1000) {
      this.dialogRef.close();
      this.auth.logout_0432('/login');
    } else {
      this.allocateTimeUnits(this.timeDifference);
    }
  }

  allocateTimeUnits(timeDifference: number) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.secondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.secondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.secondsInAMinute) % this.hoursInADay);
  }
}
