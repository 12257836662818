import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { RegisterComponent } from './register.component';
import { RegisterRiskComponent } from './register-risk/register-risk.component';
import { RegisterMitigatingActionComponent } from './register-mitigating-action/register-mitigating-action.component';
import { RegisterControlComponent } from './register-control/register-control.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTableExporterModule } from 'mat-table-exporter';
import { IrlcoreAuthConfig, IrlcoreAuthenticationModule, IrlcoreAuthGuard, IrlcoreSecurityManagementConfig, IrlcoreSecurityManagementModule } from '@irlca/irlcore';
import { environment } from 'src/environments/environment';
import { SharedModule } from 'src/app/shared/shared.module';
import { RiskRecordComponent } from '../risk-assessment/risk-record/risk-record.component';
import { DxDataGridModule, DxSelectBoxModule, DxCheckBoxModule, DxButtonModule } from 'devextreme-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

const securityManagementConfig: IrlcoreSecurityManagementConfig = {
  // // URI of application. This will used to request / save Security Managment User Data
  applicationBaseURL: environment.applicationBaseURL,
};

// _____________________________
// Authentication Bootstrap Data
// ------------------------------
const authConfig: IrlcoreAuthConfig = {
  systemID: 2,
  configBaseURL: 'https://10.18.64.52:44311/', // Virtual PC: IC001P
  // configBaseURL:   'http://localhost:40000',

  // Should irlcore automatically retrieve user Permitted Features
  autoRetrieveUserFeatures: true,

  // URI of application. This will used to obtain users credentials e.g. Permitted Features (aka Permissions)
  applicationBaseURL: environment.applicationBaseURL,
};


const routes: Routes = [
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [IrlcoreAuthGuard], data: { featureName: ['View Risk Register'], guardedRedirect: "irlcore/securitymanagement/forbidden" },
    children: [
      {
        path: '',
        redirectTo: 'risk-register',
        pathMatch: 'full'
      },
      {
        path: 'risk-register',
        component: RegisterRiskComponent,
        canActivate: [IrlcoreAuthGuard], data: { featureName: ['View Risk Register'], guardedRedirect: "irlcore/securitymanagement/forbidden" },
      },
      {
        path: 'mitigating-action-register',
        component: RegisterMitigatingActionComponent,
        canActivate: [IrlcoreAuthGuard], data: { featureName: ['View Mitigating Action Register'], guardedRedirect: "irlcore/securitymanagement/forbidden" },
      },
      {
        path: 'control-register',
        component: RegisterControlComponent,
        canActivate: [IrlcoreAuthGuard], data: { featureName: ['View Control'], guardedRedirect: "irlcore/securitymanagement/forbidden" },
      },
      {
        path: 'risk-record',
        component: RiskRecordComponent,
        canActivate: [IrlcoreAuthGuard], data: { featureName: ['View Risk Record'], guardedRedirect: "irlcore/securitymanagement/forbidden" },
      }
    ]
  },
];
@NgModule({
  declarations: [RegisterControlComponent, RegisterMitigatingActionComponent, RegisterRiskComponent, RegisterComponent ],
  imports: [
    RouterModule.forChild(routes),
    MatBadgeModule,
    MatDialogModule,
    MatMenuModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatCardModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    DragDropModule,
    MatRadioModule,
    MatCheckboxModule,
    MatTableExporterModule,
    CommonModule,
    MatProgressSpinnerModule,
    SharedModule,
    IrlcoreAuthenticationModule.initServerConfig(authConfig), // Initialise Authentication Module passing in application name and config server URI
    IrlcoreSecurityManagementModule.initConfig(securityManagementConfig),
    DxDataGridModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxButtonModule,
    FontAwesomeModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class RegisterModule { }
