<div class="risk-dashboard">
    <div class="chart-row">
        <div class="chart-container">
            <dx-chart id="chart" title="Risk Levels" [dataSource]="riskGraph" [rotated]="false"
                (onLegendClick)="legendClick($event)">
                <dxi-series valueField="high" name="High Risks" color="#E44D25"></dxi-series>
                <dxi-series valueField="medium" name="Medium Risks" color="#FFA500"></dxi-series>
                <dxi-series valueField="low" name="Low Risks" color="#5AA454"></dxi-series>
                <dxi-value-axis>
                    <dxo-title text={{yAxisLabel}}></dxo-title>
                </dxi-value-axis>
                <dxo-common-series-settings argumentField="name" type="line">
                    <dxo-label [visible]="true" [format]="{
                      type: 'fixedPoint',
                      precision: '0'
                    }">
                    </dxo-label>
                </dxo-common-series-settings>
                <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="left">
                </dxo-legend>
                <dxo-tooltip [enabled]="true" location="edge" [customizeTooltip]="customizeTooltip">
                </dxo-tooltip>
            </dx-chart>

        </div>
        <div class="chart-container future">
            <!-- <dx-chart id="chart" title="Overdue Risks Mitigations" [dataSource]="overdueRiskGraph" [rotated]="false"
                (onLegendClick)="legendClick($event)">
                <dxi-series valueField="high" name="High Risks" color="#E44D25"></dxi-series>
                <dxi-series valueField="medium" name="Medium Risks" color="#FFA500"></dxi-series>
                <dxi-series valueField="low" name="Low Risks" color="#5AA454"></dxi-series>
                <dxi-value-axis>
                    <dxo-title text={{yAxisLabel}}></dxo-title>
                </dxi-value-axis>
                <dxo-common-series-settings argumentField="name" type="line">
                    <dxo-label [visible]="true" [format]="{
                      type: 'fixedPoint',
                      precision: '0'
                    }">
                    </dxo-label>
                </dxo-common-series-settings>
                <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="left">
                </dxo-legend>
                <dxo-tooltip [enabled]="true" location="edge" [customizeTooltip]="customizeTooltip">
                </dxo-tooltip>
            </dx-chart> -->
        </div>

    </div>
    <div class="chart-row">
        <div class="chart-container">
            <dx-chart id="chart" title="High Risk Treatment" [dataSource]="riskGraph" [rotated]="false"
                (onLegendClick)="legendClick($event)">
                <dxi-series valueField="accept" name="Accepted" color="#E44D25"></dxi-series>
                <dxi-series valueField="reduce" name="Action-Reduced" color="#0850b2"></dxi-series>
                <dxi-series valueField="eliminate" name="Action-Eliminated" color="#909090"></dxi-series>
                <dxi-value-axis>
                    <dxo-title text={{yAxisLabel}}></dxo-title>
                </dxi-value-axis>#909090
                <dxo-common-series-settings argumentField="name" type="stackedBar">
                    <dxo-label [visible]="true" [format]="{
                      type: 'fixedPoint',
                      precision: '0'
                    }">
                    </dxo-label>
                </dxo-common-series-settings>
                <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="left">
                </dxo-legend>
                <dxo-tooltip [enabled]="true" location="edge" [customizeTooltip]="customizeTooltip">
                </dxo-tooltip>
            </dx-chart>
        </div>
        <div class="chart-container future">
            <!-- <p>Chart 4</p> -->
        </div>

    </div>
</div>