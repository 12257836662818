<div class="dialog">
    <div class="dialog-header">
        <div class="header-text center">
            <h2>Order</h2>
        </div>
        <div class="align-items-start">
            <div class="header-icon">
                <button mat-button class="icon-btn dialog-close-btn" (click)="dialogRef.close()">
                    <div class="icon">
                        <fa-icon [icon]="times"></fa-icon>
                    </div>
                </button>
            </div>
        </div>
    </div>
    <div class="dialog-content">
        <h3>Drag and Drop to reorder {{globalService.configurableParameters.itemGroup}}s</h3>
        <div class="dialog-body">
            <div class="dialog-table">
                <dx-data-grid id="gridContainer" [dataSource]="itemGroups" keyExpr="order" [showBorders]="true"
                    [remoteOperations]="true" [focusedRowEnabled]="true" [columns]="itemGroupColumnsToDisplay"
                    (onRowClick)="getLinkedFailureModes_XXXX($event)" (onRowRemoved)="deleteItemGroup($event)"
                    (onSaved)="updateItemGroup($event)" (onEditingStart)="onEditStartItemGroup($event)"
                    (onEditCanceled)="itemGroupEditMode = false;" (onCellPrepared)="onCellPreparedItemGroup($event)"
                    [focusedRowKey]="1" [columnAutoWidth]="true">
                    <dxo-row-dragging [allowReordering]="true" [onReorder]="onReorder" [showDragIcons]="true">
                    </dxo-row-dragging>
                    <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true">
                    </dxo-editing>
                </dx-data-grid>
            </div>
            <div class="dialog-table">
                <dx-data-grid id="gridContainer" [dataSource]="failureModeDataSource" keyExpr="iD" [showBorders]="true"
                    [remoteOperations]="true" [focusedRowEnabled]="true" [columns]="failureModeColumnsToDisplay"
                    (onRowRemoved)="deleteFailureMode($event)" (onSaved)="updateFailureMode($event)"
                    (onEditingStart)="failureModeEditMode = true;" (onEditCanceled)="failureModeEditMode = false;"
                    (onCellPrepared)="onCellPreparedFailureMode($event)" [columnAutoWidth]="true">
                    <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true">
                    </dxo-editing>
                </dx-data-grid>
            </div>
        </div>
        <br />
        <p>If a {{globalService.configurableParameters.itemGroup}} or Failure Mode is linked to an existing risk it can
            not be deleted.</p>
    </div>
    <div class="dialog-footer">
        <div class="only-buttons">
            <button mat-button class="btn cancel" (click)="dialogRef.close()">
                <p>Cancel</p>
            </button>
            <button mat-button appDebounceClick (debounceClick)="prepareDataForPostItemGroups_XXXX()"
                [debounceTime]="200" class="btn primary" [disabled]="failureModeEditMode || itemGroupEditMode
                || (!failureModeUpdated && (!itemGroupUpdated && itemGroupsToDelete.length == 0))">
                <p>Done</p>
            </button>
        </div>
    </div>
</div>