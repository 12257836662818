<div class="dialog" *ngIf="riskAssessmentLeadForm">
    <div class="dialog-header">
        <div class="header-text center">
            <h2>Risk Assessment Lead</h2>
        </div>
        <div class="align-items-start">
            <div class="header-icon">
                <button mat-button class="icon-btn dialog-close-btn" (click)="dialogRef.close()">
                    <div class="icon"><fa-icon [icon]="times"></fa-icon></div>
                </button>
            </div>
        </div>
    </div>
    <div class="dialog-content">
        <div class="required-items"><i>Required Items</i>
            <span class="required-asterisk">*</span>
        </div>
        <form [formGroup]="riskAssessmentLeadForm">
            <mat-form-field floatLabel="always" id="riskAssessmentLead-name">
                <mat-label>Risk Assessment Lead
                </mat-label>
                <input matInput type="text" formControlName="riskAssessmentLeadName"
                    placeholder="Select Risk Assessment Lead..." [matAutocomplete]="auto"
                    (keyup)="riskAssessmentLeadSelected = false">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let user of filteredUsers | async; let i = index;" value="{{user.fullName}}"
                        id="riskAssessmentLead-dialog-userList-option-{{i}}"
                        (onSelectionChange)="onEnterUser_0247($event, user, riskAssessmentLeadForm)"
                        (click)="riskAssessmentLeadSelected = true;" [disabled]="memberIDValues.includes(user.userID)">
                        <p>{{user.fullName}} (<span class="email">{{user.email}}</span>)</p>
                    </mat-option>
                </mat-autocomplete>
                <span class="form-field-message" *ngIf="(filteredUsers | async).length == 0">
                    Invalid Selection. Does not exist in list of Users </span>
            </mat-form-field>
            <div class="read-only-area">
                <div class="row risk-assessment-lead-dialog">
                    <div class="col-6" id="email">
                        <mat-form-field floatLabel="always">
                            <mat-label >Email</mat-label>
                            <textarea matInput name="emailTextArea" formControlName="email" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="2" readonly
                                id="riskAssessmentLead-email"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-6" id="contact-number">
                        <mat-form-field floatLabel="always">
                            <mat-label >Contact Number</mat-label>
                            <textarea matInput name="contactNoTextArea" formControlName="contactNumber"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
                                cdkAutosizeMaxRows="2" readonly id="riskAssessmentLead-contactNumber"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row risk-assessment-lead-dialog">
                    <div class="col-6" id="site">
                        <mat-form-field floatLabel="always">
                            <mat-label >Site</mat-label>
                            <textarea matInput name="entityTextArea" formControlName="site" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="2" readonly
                                id="riskAssessmentLead-site"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-6" id="department-read-only">
                        <mat-form-field floatLabel="always">
                            <mat-label >Department</mat-label>
                            <textarea matInput name="contactNoTextArea" formControlName="departmentTextArea"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
                                cdkAutosizeMaxRows="2" readonly id="riskAssessmentLead-departmentReadOnly"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="dialog-footer">
        <div class="only-buttons">
            <button mat-button class="btn cancel" (click)="dialogRef.close()"><p>Cancel</p></button>
            <button mat-button class="btn done" [disabled]="!riskAssessmentLeadSelected" 
                appDebounceClick (debounceClick)="saveRiskAssessmentLead_0540()" [debounceTime]="200"><p>Done</p></button>
        </div>
    </div>
</div>